import { ObjectPermission } from './object-permissions.model';
import { WorkspaceUserPermission } from './workspace-user-permission.model';

export enum PermissionLevel {
    Authenticated = 0, // No limited access is required for the given resource. Consider this any authenticated user. Value: 0
    WorkspaceAccess = 1 << 0, // 1
    ReadPeople = 1 << 1, // 2
    ReadJobs = 1 << 2, // 4
    ReadSites = 1 << 3, // 8
    ReadAssessments = 1 << 4, // 16
    EditPeople = 1 << 5, // 32
    EditAndReadPeople = EditPeople | ReadPeople, // 34
    EditJobs = 1 << 6, // 64
    EditAndReadJobs = EditJobs | ReadJobs, // 68
    EditSites = 1 << 7, // 128
    EditAndReadSites = EditSites | ReadSites, // 136
    EditAssessments = 1 << 8, // 256
    EditAndReadAssessments = EditAssessments | ReadAssessments, // 272
    ReadComparisons = 1 << 9, // 512
    EditComparisons = 1 << 10, // 1024
    EditAndReadComparisons = EditComparisons | ReadComparisons, // 1024
    // Above this is the regular user permissions, full normal permissions number for testing use is: 2047

    ReadObject = 1 << 11, // 2048
    EditObject = 1 << 12, // 4096
    ShareEvaluations = 1 << 13, // 8192  -- Evaluation refers to both assessments and comparisons
    StarEvaluations = 1 << 14, // 16384

    // Bits 15 -> 22 are left blank on purpose
    AdminRead = 1 << 23, // 8,388,608
    DeleteResources = 1 << 24, // 16,777,216
    EditItemPermissions = 1 << 25, // 33,554,432
    EditWorkspacePermissions = 1 << 26, // 67,108,864
    ManageWorkspace = 1 << 27, // 134,217,728
    ManageWorkspaceSubscriptions = 1 << 28, // 268,435,456

    Read = PermissionLevel.WorkspaceAccess | PermissionLevel.ReadAssessments | PermissionLevel.ReadComparisons | PermissionLevel.ReadJobs | PermissionLevel.ReadPeople | PermissionLevel.ReadSites,

    Edit = PermissionLevel.WorkspaceAccess | PermissionLevel.EditAssessments | PermissionLevel.EditComparisons | PermissionLevel.EditJobs | PermissionLevel.EditPeople | PermissionLevel.EditSites,

    EditAndRead = PermissionLevel.WorkspaceAccess |
        PermissionLevel.EditAssessments |
        PermissionLevel.EditComparisons |
        PermissionLevel.EditJobs |
        PermissionLevel.EditPeople |
        PermissionLevel.EditSites |
        PermissionLevel.ReadAssessments |
        PermissionLevel.ReadComparisons |
        PermissionLevel.ReadJobs |
        PermissionLevel.ReadPeople |
        PermissionLevel.ReadSites,

    PrivilegedActions = PermissionLevel.ShareEvaluations |
        PermissionLevel.StarEvaluations |
        PermissionLevel.AdminRead |
        PermissionLevel.DeleteResources |
        PermissionLevel.EditItemPermissions |
        PermissionLevel.EditWorkspacePermissions |
        PermissionLevel.ManageWorkspace |
        PermissionLevel.ManageWorkspaceSubscriptions,

    PowerUser = PermissionLevel.WorkspaceAccess | // Current Valid Power user number for reference: 134211583  (this has all bits below manage worksapce set except object bits)
        PermissionLevel.ReadPeople |
        PermissionLevel.ReadJobs |
        PermissionLevel.ReadSites |
        PermissionLevel.ReadAssessments |
        PermissionLevel.EditPeople |
        PermissionLevel.EditJobs |
        PermissionLevel.EditSites |
        PermissionLevel.EditAssessments |
        PermissionLevel.ReadComparisons |
        PermissionLevel.EditComparisons |
        PermissionLevel.ShareEvaluations |
        PermissionLevel.StarEvaluations |
        PermissionLevel.AdminRead |
        PermissionLevel.DeleteResources |
        PermissionLevel.EditItemPermissions |
        PermissionLevel.EditWorkspacePermissions,

    Administer = PermissionLevel.PowerUser | // Current Valid Admin User for reference: 536864767 (this has all bits below  and including manage worksapce subscriptions set except object bits)
        PermissionLevel.ManageWorkspace |
        PermissionLevel.ManageWorkspaceSubscriptions,

    Custom = PermissionLevel.WorkspaceAccess,

    Disabled = PermissionLevel.Authenticated
}

export enum PermissionLevels {
    'system.permission-level.custom' = PermissionLevel.Custom,
    'system.permission-level.read' = PermissionLevel.Read,
    'system.permission-level.edit' = PermissionLevel.Edit,
    'system.permission-level.power-user' = PermissionLevel.PowerUser,
    'system.permission-level.administrator' = PermissionLevel.Administer,
    'system.permission-level.none' = PermissionLevel.Disabled
}

/**
 * Determine whether user has a permission
 * @param workspaceUserPermission User's permissions
 * @param permissionLevel Permission level to check
 * @param objectPermission The object level permission override if it exists
 * @returns Returns true if the user has the permission
 */
export function hasPermission(workspaceUserPermission: WorkspaceUserPermission | undefined, permissionLevel: PermissionLevel | undefined): boolean {
    // Permissions are required, return if not provided
    if (!workspaceUserPermission || !permissionLevel) return false;

    // Check the user has the permission level
    return (workspaceUserPermission.permissionLevel & permissionLevel) === permissionLevel;
}
