import { Component } from '@angular/core';
import { AppRoutes } from '../../models/routes.model';

@Component({
    selector: 'ta-privacy-and-data',
    templateUrl: './privacy-and-data.component.html',
    styleUrls: ['./privacy-and-data.component.scss']
})
export class PrivacyAndDataComponent {
    AppRoutes = AppRoutes;

    constructor() {}
}
