import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, take } from 'rxjs';

import { BladeService } from '@ta/app/shared/services/blade.service';
import { filter } from 'rxjs/operators';
import { FormName } from '@ta/app/shared/models/form.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';
import { User } from '@ta/app/shared/models/user.model';
import { UserService } from '@ta/app/shared/services/user.service';
import { Constants } from '@ta/app/shared/models/constants.model';

@Component({
    selector: 'ta-blade-account-details',
    templateUrl: './blade-account-details.html',
    styleUrls: ['./blade-account-details.scss']
})
export class BladeAccountDetailsComponent implements OnInit, OnDestroy {
    /**
     * Current saved version of user details.
     * Immutable, should only be set by response from user service.
     */
    userDetails?: User;

    /**
     * Form that facilitates updates to user details.
     */
    userDetailsForm!: FormGroup;

    /**
     * Name of the form used in this component
     */
    formName: string = FormName.USER_DETAILS;

    /**
     * Locally store the navigation target after closing blades
     */
    navigateTo: string | null = null;

    /**
     * Whether to show the discard changed prompt
     */
    showDiscardChangesPrompt: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * Single subscription to simplify clean-up on destroy
     */
    private _subscription?: Subscription;

    /**
     * Store UX state
     */
    showChangePasswordModal = false;
    showDeleteAccountModal = false;
    showResetMfaModal = false;
    hasClickedSave = false;
    disableResetMfa = false;
    disableChangePassword = false;

    constructor(
        private readonly _auth0Service: Auth0Service,
        private readonly _bladeService: BladeService,
        private readonly _linkedFormService: LinkedFormService,
        private readonly _userService: UserService
    ) {}

    ngOnInit(): void {
        // Set up the user details form
        this.userDetailsForm = new FormGroup({
            name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
            email: new FormControl('', [Validators.maxLength(100), Validators.pattern(Constants.EMAIL_REGEX)])
        });
        this._linkedFormService.addForm(this.userDetailsForm, this.formName, true);

        // Handle navigation away from dirty form
        this._subscription = this._linkedFormService.navigateFromDirtyBlade$.pipe(filter(([navigateTo, formKey]) => formKey === this.formName)).subscribe(([navigateTo, formKey]) => {
            this.navigateTo = navigateTo;
            this.showDiscardChangesPrompt.next(true);
        });

        // Fetch current user details
        this._subscription = this._userService.getUser().subscribe((result) => {
            this.userDetails = result;
            this.userDetailsForm.reset();
            this.userDetailsForm.patchValue(this.userDetails ?? {});
            this.hasClickedSave = false;
        });
    }

    ngOnDestroy(): void {
        // Clean up subscriptions
        this._subscription?.unsubscribe();
        // Clean up linked forms service
        this._linkedFormService.removeForm(this.formName);
    }

    /**
     * Get errors relating to a specific form control
     * @returns One or more errors, as a string
     */
    getErrors = (formControlName: string, friendlyControlName: string): string => this._linkedFormService.getErrors(this.formName, formControlName, friendlyControlName);

    /**
     * Handle save user details
     */
    handleSave(): void {
        this._linkedFormService.setFormElementsDisabled();

        // Handle invalid form
        if (this.userDetailsForm.invalid) {
            this._linkedFormService.setFormElementsEnabled();
            return;
        }

        this._userService.updateUser(this.userDetailsForm.get('name')?.value).subscribe(() => {
            this._linkedFormService.setFormElementsEnabled();
        });
    }

    /**
     * Handle discard changes to user details
     */
    handleDiscard(): void {
        this._linkedFormService.removeForm(this.formName);
        this._bladeService.closeBladesAndMenus(this.navigateTo, true);
    }

    /**
     * Handle change password button click
     */
    onChangePassword(): void {
        this._userService.changePassword();
        this.disableChangePassword = true;
        this.showChangePasswordModal = true;
    }

    /**
     * Handle confirmation of change password modal
     */
    onChangePasswordConfirm(): void {
        this.disableChangePassword = false;
        this.showChangePasswordModal = false;
    }

    /**
     * Handle delete account button click
     */
    onDeleteAccount(): void {
        this.showDeleteAccountModal = true;
    }

    /**
     * Handle confirmation of delete account modal
     */
    onDeleteAccountConfirm(): void {
        this.showDeleteAccountModal = false;
        this._userService
            .deleteUser()
            .pipe(take(1))
            .subscribe(() => {
                this._auth0Service.logout({ returnTo: window.location.origin });
            });
    }

    /**
     * Handle cancel of delete account modal
     */
    onDeleteAccountCancel(): void {
        this.showDeleteAccountModal = false;
    }

    /**
     * Handle reset mfa button click
     */
    onResetMfa(): void {
        this.disableResetMfa = true;
        this.showResetMfaModal = true;
    }

    /**
     * Handle confirmation f reset mfa modal
     */
    onResetMfaConfirm(): void {
        this._userService.resetMfa().subscribe(() => {
            this.disableResetMfa = false;
            // Log out after deleting the MFA enrollment, to encourage the user to set it again immediately
            this._auth0Service.logout({ returnTo: window.location.origin });
        });
        this.showResetMfaModal = false;
    }

    /**
     * Handle cancel of reset mfa modal
     */
    onResetMfaCancel(): void {
        this.disableResetMfa = false;
        this.showResetMfaModal = false;
    }
}
