import { WorkspaceRoutes } from '@ta/app/shared/models/routes.model';

export enum RelationshipObjectType {
    JOB = 1,
    SITE = 2,
    PERSON = 3
}

export const RelationshipObjectTypeRoutes: Map<RelationshipObjectType, string> = new Map([
    [RelationshipObjectType.JOB, WorkspaceRoutes.JOBS],
    [RelationshipObjectType.SITE, WorkspaceRoutes.SITES],
    [RelationshipObjectType.PERSON, WorkspaceRoutes.PEOPLE]
]);
