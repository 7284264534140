import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppQueryParams } from '@ta/app/shared/models/routes.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';

@Injectable({
    providedIn: 'root'
})
export class BladeService {
    constructor(private readonly _linkedFormService: LinkedFormService, private readonly _router: Router) {}

    /**
     * Closes any open blades and menus while maintaining current route and query params
     * @param forceClose Force close without checking form state. Handy for closing blades / menus when discarding changes.
     */
    closeBladesAndMenus(navigateTo: string | null = null, forceClose: boolean = false): void {
        this.setBladeAndMenu(navigateTo, null, null, null, forceClose);
    }

    /**
     * Set state of blades and menus in the top navbar.
     * @param blade Name of blade, as defined in BladeQueryParamValues. Set null to close all blades.
     * @param menu Name of menu, as defined in MenuQueryParamValues. Set null to close all menus.
     * @param forceClose Force close without checking form state. Handy for closing blades / menus when discarding changes.
     */
    setBladeAndMenu(navigateTo: string | null, blade: string | null, menu: string | null, tab: string | null, forceClose: boolean = false): void {
        // Handle navigation away from a dirty form
        if (this._linkedFormService.isBladeDirty() && !forceClose) {
            this._linkedFormService.handleNavigateFromDirtyBlade(navigateTo);
            return;
        }

        // Handle case when navigation is not relative (there is a navigation and a blade state change).
        if (navigateTo) {
            // If we have a destination to navigate to, first handle baldes, then handle main navigation
            // This resolves an issue where, if you have a dirty component and a dirty blade, then both discard
            // Changes messages would appear at the same time.
            this._router.navigate([], { queryParams: { [AppQueryParams.BLADE]: blade, [AppQueryParams.MENU]: menu, [AppQueryParams.TAB]: tab }, queryParamsHandling: 'merge' }).then(() => {
                this._router.navigate([navigateTo], { queryParams: { [AppQueryParams.BLADE]: blade, [AppQueryParams.MENU]: menu, [AppQueryParams.TAB]: tab }, queryParamsHandling: 'merge' });
            });
            return;
        }

        // Handle relative navigation (just the blade state is changed)
        this._router.navigate([], { queryParams: { [AppQueryParams.BLADE]: blade, [AppQueryParams.MENU]: menu, [AppQueryParams.TAB]: tab }, queryParamsHandling: 'merge' });
    }
}
