/**
 * feature flags for our app
 */
export interface FeatureFlags {
    FEATURE_HELP: boolean;
    FEATURE_NOTIFICATIONS: boolean;
    FEATURE_SEARCH: boolean;
    FEATURE_REPORTING: boolean;
    FEATURE_COMPARISONS: boolean;
    FEATURE_TAG_MANAGEMENT: boolean;
    FEATURE_ASSESSMENT_SHARE: boolean;
    FEATURE_ASSESSMENT_DUPLICATE: boolean;
    FEATURE_ASSESSMENT_REPORT: boolean;
    FEATURE_DASHBOARD_RECENT_ACTIVITY: boolean;
    FEATURE_PERSON_ASSESSMENT: boolean;
    FEATURE_MY_DATA_DOWNLOAD: boolean;
    FEATURE_SUBSCRIPTION_MANAGEMENT: boolean;
}

/**
 * helper type for our feature toggle keys
 */
export type FeatureFlagsKeys = keyof FeatureFlags;

/**
 * feature toggles for our app
 * (enum of keys used to check for feature flags against our environment)
 */
export const FeatureFlag: { [key in FeatureFlagsKeys]: FeatureFlagsKeys } = {
    FEATURE_HELP: 'FEATURE_HELP',
    FEATURE_NOTIFICATIONS: 'FEATURE_NOTIFICATIONS',
    FEATURE_SEARCH: 'FEATURE_SEARCH',
    FEATURE_REPORTING: 'FEATURE_REPORTING',
    FEATURE_COMPARISONS: 'FEATURE_COMPARISONS',
    FEATURE_TAG_MANAGEMENT: 'FEATURE_TAG_MANAGEMENT',
    FEATURE_ASSESSMENT_SHARE: 'FEATURE_ASSESSMENT_SHARE',
    FEATURE_ASSESSMENT_DUPLICATE: 'FEATURE_ASSESSMENT_DUPLICATE',
    FEATURE_ASSESSMENT_REPORT: 'FEATURE_ASSESSMENT_REPORT',
    FEATURE_DASHBOARD_RECENT_ACTIVITY: 'FEATURE_DASHBOARD_RECENT_ACTIVITY',
    FEATURE_PERSON_ASSESSMENT: 'FEATURE_PERSON_ASSESSMENT',
    FEATURE_MY_DATA_DOWNLOAD: 'FEATURE_MY_DATA_DOWNLOAD',
    FEATURE_SUBSCRIPTION_MANAGEMENT: 'FEATURE_SUBSCRIPTION_MANAGEMENT'
};
