<div class="subscription-assessment-data-display-container">
    <h4>{{ 'system.subscriptions.assessment-data' | translate }}</h4>
    <div class="bar-graph">
        <ta-usage-bar [simple]="false" [totalUsed]="totalUsed" [totalCap]="totalCap" totalCapLabel="system.usage-bar.total-cap" [activeUsed]="activeUsed" [activeCap]="activeCap"></ta-usage-bar>
    </div>
    <div class="legend">
        <div class="legend__section">
            <div class="legend__section__item">
                <div class="legend__section__item__title">
                    {{ 'system.subscriptions.total-units' | translate }}
                </div>
                <div class="legend__section__item__label legend__section__item__label--dark-green" [class.legend__section__item__label--exceeded]="totalCap - totalUsed < 0"></div>
                <div class="legend__section__item__text">{{ 'system.used' | translate }}</div>
                <div class="legend__section__item__amount" [class.legend__section__item__amount--exceeded]="totalCap - totalUsed < 0">{{ totalUsed }}</div>
            </div>
            <div class="legend__section__item legend__section__item--pad">
                <div class="legend__section__item__title">
                    {{ 'system.subscriptions.active-units' | translate }}
                </div>
                <div class="legend__section__item__label" [class.legend__section__item__label--exceeded]="activeCap - activeUsed < 0"></div>
                <div class="legend__section__item__text">{{ 'system.used' | translate }}</div>
                <div class="legend__section__item__amount" [class.legend__section__item__amount--exceeded]="activeCap - activeUsed < 0">{{ activeUsed }}</div>
            </div>
        </div>
        <div class="legend__section">
            <div class="legend__section__item">
                <div class="legend__section__item__label legend__section__item__label--dark-grey" [class.legend__section__item__label--exceeded]="totalCap - totalUsed < 0"></div>
                <div class="legend__section__item__text">{{ 'system.remaining' | translate }}</div>
                <div class="legend__section__item__amount" [class.legend__section__item__amount--exceeded]="totalCap - totalUsed < 0">{{ totalCap - totalUsed }}</div>
            </div>
            <div class="legend__section__item legend__section__item--pad">
                <div class="legend__section__item__label legend__section__item__label--light-grey" [class.legend__section__item__label--exceeded]="activeCap - activeUsed < 0"></div>
                <div class="legend__section__item__text">{{ 'system.remaining' | translate }}</div>
                <div class="legend__section__item__amount" [class.legend__section__item__amount--exceeded]="activeCap - activeUsed < 0">{{ activeCap - activeUsed }}</div>
            </div>
        </div>
    </div>
</div>
