<div class="footer__container">
    <!-- Standard controls  -->
    <div *ngIf="(showDiscardChangesPrompt | async) !== true; else discardChanges" class="footer__container__controls">
        <p-button
            label="{{ 'system.button.cancel' | translate }}"
            (onClick)="onCancel()"
            styleClass="p-button-outlined p-button-secondary"
            [disabled]="(_linkedFormService.disabledState$ | async) === true"
        ></p-button>
        <p-button
            label="{{ saveButtonText | translate }}"
            class="footer__container__controls__button--pad"
            (onClick)="onSave()"
            [disabled]="!dirty"
            [loading]="(_linkedFormService.disabledState$ | async) === true"
        ></p-button>
    </div>

    <!-- Discard changes controls -->
    <ng-template #discardChanges>
        <span class="footer__container__message">{{ 'system.blade-controls.discard-changes-confirmation-text' | translate }}</span>
        <div class="footer__container__controls">
            <p-button label="{{ 'system.button.discard-cancel' | translate }}" (onClick)="onDiscardChangesCancel()" styleClass="p-button-outlined p-button-secondary"></p-button>
            <p-button class="footer__container__controls__button--pad" label="{{ 'system.button.discard' | translate }}" (onClick)="onDiscardChangesConfirm()" styleClass="p-button-danger"></p-button>
        </div>
    </ng-template>
</div>
