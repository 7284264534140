import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { ListboxModule } from 'primeng/listbox';
import { BadgeModule } from 'primeng/badge';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { AvatarModule } from 'primeng/avatar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TagModule } from 'primeng/tag';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { KnobModule } from 'primeng/knob';
import { MessageService } from 'primeng/api';
import { StepsModule } from 'primeng/steps';
import { PanelModule } from 'primeng/panel';
import { ChipsModule } from 'primeng/chips';
import { InputMaskModule } from 'primeng/inputmask';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipModule } from 'primeng/chip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressBarModule } from 'primeng/progressbar';
import { EditorModule } from 'primeng/editor';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { ImageModule } from 'primeng/image';

import { PageHeaderComponent } from '@ta/app/shared/components/page-header/page-header.component';
import { TagsSegmentComponent } from '@ta/app/shared/components/tags-segment/tags-segment.component';
import { LayoutStaticComponent } from '@ta/app/shared/components/layout-static/layout-static.component';
import { LayoutScrollComponent } from '@ta/app/shared/components/layout-scroll/layout-scroll.component';
import { LayoutWrapperComponent } from '@ta/app/shared/components/layout-wrapper/layout-wrapper.component';
import { RelationshipsDivisionComponent } from '@ta/app/shared/components/relationships-division/relationships-division.component';
import { RelationshipsSegmentComponent } from '@ta/app/shared/components/relationships-segment/relationships-segment.component';
import { RelationshipsSegmentSectionEditComponent } from '@ta/app/shared/components/relationships-section-edit/relationships-section-edit.component';
import { TabsComponent } from '@ta/app/shared/components/tabs/tabs.component';
import { SearchInputComponent } from '@ta/app/shared/components/search-input/search-input.component';
import { ContentDropdownComponent } from '@ta/app/shared/components/content-dropdown/content-dropdown.component';
import { ContentDropdownSimplyComponent } from '@ta/app/shared/components/content-dropdown-simply/content-dropdown-simply.component';
import { ModelWrapperComponent } from '@ta/app/shared/components/model-wrapper/model-wrapper.component';
import { AdminHeaderComponent } from '@ta/app/shared/components/admin-header/admin-header.component';
import { AuditComponent } from '@ta/app/shared/components/audit/audit.component';
import { ModelFormControlsComponent } from '@ta/app/shared/components/model-form-controls/model-form-controls.component';
import { AssessmentHeaderComponent } from '@ta/app/shared/components/assessment-header/assessment-header.component';
import { ObjectPermissionsDirectoryComponent } from '@ta/app/shared/components/object-permissions-directory/object-permissions-directory.component';
import { SearchAutoCompleteComponent } from '@ta/app/shared/components/search-autocomplete/search-autocomplete.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { PrivacyAndDataComponent } from './components/privacy-and-data/privacy-and-data.component';

import { DateLocaliserPipe } from '@ta/app/shared/pipes/date-localiser.pipe';
import { StepperScrollToTopDirective } from './directive/stepper-scroll-to-top.directive';
import { GoogleMapsModule } from '@angular/google-maps';
import { SubscriptionAssessmentDataDisplayComponent } from './components/subscription-assessment-data-display/subscription-assessment-data-display.component';
import { SubscriptionStorageDataDisplayComponent } from './components/subscription-storage-data-display/subscription-storage-data-display.component';
import { UsageBarComponent } from './components/usage-bar/usage-bar.component';
import { AssessmentDetailActivitySegmentItemComponent } from './components/assessment-detail-activity-segment-item/assessment-detail-activity-segment-item.component';
import { SortObjectPipe } from './pipes/sort-object.pipe';
import { ChartLegendComponent } from './components/chart-legend/chart-legend.component';
import { TooltipModule } from 'primeng/tooltip';
import { SubscriptionListItemComponent } from './components/subscription-list-item/subscription-list-item.component';
import { ToggleModule } from '../shared-modules/toggle/toggle.module';
import { AddTooltipIfTruncateDirective } from './directive/add-tooltip-if-truncate.directive';
import { EmptyDirectoryOverlayComponent } from './components/empty-directory-overlay/empty-directory-overlay.component';
import { AssessmentRestoreDialogComponent } from './components/assessment-restore-dialog/assessment-restore-dialog.component';
import { IsWristDeviationPipe } from './pipes/is-wrist-deviation.pipe';

const primeNgModules = [
    AvatarModule,
    ButtonModule,
    CardModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    MessageModule,
    MessagesModule,
    PaginatorModule,
    ScrollPanelModule,
    SkeletonModule,
    TableModule,
    BadgeModule,
    ListboxModule,
    ToastModule,
    AutoCompleteModule,
    CalendarModule,
    InputTextareaModule,
    ToastModule,
    ProgressSpinnerModule,
    TagModule,
    InputTextareaModule,
    InputMaskModule,
    CheckboxModule,
    SelectButtonModule,
    InputNumberModule,
    OverlayPanelModule,
    CheckboxModule,
    InputSwitchModule,
    KnobModule,
    StepsModule,
    PanelModule,
    ChipsModule,
    MultiSelectModule,
    ChipModule,
    RadioButtonModule,
    ProgressBarModule,
    EditorModule,
    MegaMenuModule,
    GoogleMapsModule,
    MenuModule,
    ImageModule,
    TooltipModule
];

const components = [
    LayoutStaticComponent,
    LayoutScrollComponent,
    LayoutWrapperComponent,
    PageHeaderComponent,
    AdminHeaderComponent,
    TagsSegmentComponent,
    RelationshipsSegmentComponent,
    RelationshipsDivisionComponent,
    RelationshipsSegmentSectionEditComponent,
    SearchInputComponent,
    TabsComponent,
    AuditComponent,
    ContentDropdownComponent,
    ContentDropdownSimplyComponent,
    AssessmentHeaderComponent,
    ModelWrapperComponent,
    ModelWrapperComponent,
    ModelFormControlsComponent,
    ObjectPermissionsDirectoryComponent,
    SearchAutoCompleteComponent,
    FilterDropdownComponent,
    StepperScrollToTopDirective,
    AddTooltipIfTruncateDirective,
    SubscriptionAssessmentDataDisplayComponent,
    SubscriptionStorageDataDisplayComponent,
    UsageBarComponent,
    AssessmentDetailActivitySegmentItemComponent,
    PrivacyAndDataComponent,
    ChartLegendComponent,
    SubscriptionListItemComponent,
    EmptyDirectoryOverlayComponent,
    AssessmentRestoreDialogComponent
];

const other = [DateLocaliserPipe, SortObjectPipe, IsWristDeviationPipe];

@NgModule({
    declarations: [...components, ...other],
    imports: [CommonModule, ToggleModule, RouterModule, ReactiveFormsModule, TranslateModule, ...primeNgModules],
    exports: [ToggleModule, ReactiveFormsModule, TranslateModule, ...components, ...other, ...primeNgModules],
    providers: [MessageService, DecimalPipe]
})
export class SharedModule {}
