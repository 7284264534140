<div class="workspace-create-model-container">
    <ta-model-wrapper [header]="inviteUserModelHeader" [body]="inviteUserModelBody" [footer]="inviteUserModelFooter" [visible]="true" (closeModel)="onCloseModel()">
        <ng-template #inviteUserModelHeader>
            <div class="header">
                <h4 *ngIf="currentStep === ModelSteps.START">{{ 'system.workspace.modal.select-workspace' | translate }}</h4>
                <h4 *ngIf="currentStep === ModelSteps.CREATE_STANDARD">{{ 'system.workspace.modal.create-workspace' | translate }}</h4>
                <h4 *ngIf="currentStep === ModelSteps.CREATE_PREMIUM">{{ 'system.workspace.modal.create-premium-request' | translate }}</h4>
                <h4 *ngIf="currentStep === ModelSteps.FINALISE_PREMIUM">{{ 'system.workspace.finalise.premium-workspace' | translate }}</h4>
            </div>
        </ng-template>

        <ng-template #inviteUserModelBody>
            <ng-container *ngIf="currentStep === ModelSteps.START">
                <div class="body">
                    <div class="body__item" [class.body__item--highlight]="nextStep == ModelSteps.CREATE_STANDARD" (click)="nextStep = ModelSteps.CREATE_STANDARD">
                        <i class="ta ta-building"></i>
                        <h4>{{ 'system.workspace.create.standard-title' | translate }}</h4>
                        <p>{{ 'system.workspace.create.standard-description' | translate }}</p>
                    </div>
                    <div class="body__item" [class.body__item--highlight]="nextStep == ModelSteps.CREATE_PREMIUM" (click)="nextStep = ModelSteps.CREATE_PREMIUM">
                        <i class="ta ta-building-bank"></i>
                        <h4>{{ 'system.workspace.create.premium-title' | translate }}</h4>
                        <p>{{ 'system.workspace.create.premium-description' | translate }}</p>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="currentStep === ModelSteps.CREATE_STANDARD || currentStep === ModelSteps.FINALISE_PREMIUM">
                <div>
                    <ta-workspace-create-standard [isFinalisationOfPremiumWorkspace]="currentStep == ModelSteps.FINALISE_PREMIUM"></ta-workspace-create-standard>
                </div>
            </ng-container>

            <ng-container *ngIf="currentStep === ModelSteps.CREATE_PREMIUM">
                <div>
                    <ta-workspace-create-premium></ta-workspace-create-premium>
                </div>
            </ng-container>
        </ng-template>

        <ng-template #inviteUserModelFooter>
            <div class="footer">
                <div class="footer__actions footer__actions--left">
                    <p-button
                        *ngIf="!showCancelConfirmation; else cancelConfirmation"
                        [disabled]="(linkedFormService.disabledState$ | async) === true"
                        label="{{ 'system.button.cancel' | translate }}"
                        styleClass="p-button-text p-button-secondary"
                        (onClick)="onCancel()"
                    ></p-button>
                    <ng-template #cancelConfirmation>
                        <span class="footer__actions__message">{{ 'system.blade-controls.discard-changes-confirmation-text' | translate }}</span>
                        <div>
                            <p-button label="{{ 'system.button.cancel' | translate }}" styleClass="p-button-text p-button-secondary" (onClick)="onCancelCancel()"></p-button>
                            <p-button label="{{ 'system.button.discard' | translate }}" styleClass="p-button-danger" (onClick)="onCancelConfirm()"></p-button>
                        </div>
                    </ng-template>
                </div>
                <div class="footer__actions footer__actions--right">
                    <p-button
                        *ngIf="!(currentStep == ModelSteps.FINALISE_PREMIUM)"
                        [disabled]="currentStep == ModelSteps.START || (linkedFormService.disabledState$ | async) === true"
                        label="{{ 'system.button.back' | translate }}"
                        styleClass="p-button-outlined p-button-secondary"
                        (onClick)="goTo(ModelSteps.START)"
                    ></p-button>
                    <p-button
                        *ngIf="currentStep == ModelSteps.START"
                        [disabled]="nextStep == undefined"
                        label="{{ 'system.button.next' | translate }}"
                        styleClass="p-button-primary"
                        (onClick)="goTo(nextStep!)"
                    ></p-button>
                    <p-button
                        *ngIf="currentStep == ModelSteps.CREATE_STANDARD"
                        [disabled]="!isDirtyAndValid(standardWorkspaceFormName) || (linkedFormService.disabledState$ | async) === true"
                        label="{{ 'system.button.submit' | translate }}"
                        styleClass="p-button-primary"
                        (onClick)="handleSubmitStandardRequest()"
                    ></p-button>
                    <p-button
                        *ngIf="currentStep == ModelSteps.FINALISE_PREMIUM"
                        [disabled]="!isDirtyAndValid(standardWorkspaceFormName) || (linkedFormService.disabledState$ | async) === true"
                        label="{{ 'system.button.submit' | translate }}"
                        styleClass="p-button-primary"
                        (onClick)="handleSubmitFinalisePremiumRequest()"
                    ></p-button>
                    <p-button
                        *ngIf="currentStep == ModelSteps.CREATE_PREMIUM"
                        [disabled]="!isDirtyAndValid(premiumWorkspaceRequestFormName) || (linkedFormService.disabledState$ | async) === true"
                        label="{{ 'system.button.submit' | translate }}"
                        styleClass="p-button-primary"
                        (onClick)="handleSubmitPremiumRequest()"
                    ></p-button>
                </div>
            </div>
        </ng-template>
    </ta-model-wrapper>
</div>
