<div class="terms-and-data-updates-component-container">
    <div class="header">
        <a routerLink="/workspace/"><i class="ta ta-logo-word"></i></a>
    </div>
    <div class="tabs">
        <!-- Tabs -->
        <ta-tabs [tabs]="tabs" [rightAlign]="true"></ta-tabs>
    </div>
    <p-scrollPanel #scroll [style]="{ width: '100%' }" class="scroll">
        <div class="updates-container">
            <div class="updates-container__body">
                <div class="updates-container__body__header">
                    <h1>Record of Updates</h1>
                </div>

                <h5>Updates to Terms of Service</h5>

                <p>Following is a log of all changes made to the <a [routerLink]="'/' + AppRoutes.TERMS">Terms of Service</a>.</p>
                <div class="update">
                    <p class="update__date">1 May 2022</p>
                    <p>First release of our Terms of Service</p>
                </div>

                <h5>Updates to Privacy & Data Policy</h5>

                <p>Following is a log of all changes made to the <a [routerLink]="'/' + AppRoutes.PRIVACY">Privacy & Data Policy</a>.</p>
                <div class="update">
                    <p class="update__date">1 May 2022</p>
                    <p>First release of our Privacy & Data Policy</p>
                </div>
            </div>
        </div>
    </p-scrollPanel>
</div>
