import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[taStepperScrollToTop]'
})
export class StepperScrollToTopDirective {
    @HostListener('activeIndexChange', ['$event'])
    selectionChanged(selection: any): void {
        this.process();
    }

    @HostListener('click', ['$event'])
    goto(event: any): void {
        this.process();
    }

    process(): void {
        setTimeout(() => {
            let top = document.getElementById('stepper-title');
            if (top !== null) {
                top.scrollIntoView();
                top = null;
            }
        }, 0);
    }
}
