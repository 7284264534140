import { FilterMetadata } from 'primeng/api';
import { QueryOptions } from '../models/query-options.model';

/**
 * Validates query options match model expected by API and adds global filters and active filter
 * @param options Existing query options as provided by primeng table
 * @param globalFilters list of globally filterable fields in table
 * @returns a valid query options object, including global and active filters
 */
export function ValidateQueryOptions(options: QueryOptions, globalFilters: string[], customFilters: { [s: string]: Array<FilterMetadata> | FilterMetadata }): any {
    // define filters like structure based on QueryOptions model
    const filters: { [s: string]: Array<FilterMetadata> } = {};

    // just bail if there are no options
    if (!options) return;

    // there is no nice way to iterate over the list of filters
    // so we treat them as an array of arrays
    Object.entries(options.filters).forEach((filter: any) => {
        // for each item, which looks like an instance of filters above
        if (Array.isArray(filter[1])) {
            const values: any[] = [];
            // parse filter values to remove null and Array(0) values
            filter[1].forEach((value: any) => {
                if (value.value !== null && Array.isArray(value.value) && value.value.length > 0) {
                    values.push(value);
                }
            });
            // if the value is an array then just add the filter to filters, as long as it's not an empty array which indicates no filters are selected
            if (values.length > 0) filters[filter[0]] = values;
        } else {
            if (filter[1] !== null) {
                // if the item is not an array, create a new array and add it to filters with the item
                filters[filter[0]] = new Array<FilterMetadata>();
                filters[filter[0]].push(filter[1]);
            }
        }
    });

    // this works much the same, appending each global filter to the filters array
    if (!!options.globalFilter) {
        globalFilters.forEach((filter) => {
            filters[filter] = new Array<FilterMetadata>();
            const meta: FilterMetadata = {
                value: options.globalFilter,
                matchMode: 'contains',
                operator: 'or'
            };
            filters[filter].push(meta);
        });
    }

    // Now apply any custom filters (usually a filter for active)
    Object.entries(customFilters).forEach((value: any) => {
        // for each item, which looks like an instance of filters above
        if (Array.isArray(value[1])) {
            // if the value is an array then just add the filter to filters
            filters[value[0]] = value[1];
        } else {
            // if the item is not an array, create a new array and add it to filters with the item
            filters[value[0]] = new Array<FilterMetadata>();
            filters[value[0]].push(value[1]);
        }
    });

    // overwrite old filters with validated filters
    options.filters = filters;

    return options;
}
