import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Country } from '@ta/app/shared/models/country.model';
import { EnvironmentService } from '@ta/app/shared/services/environment.service';

@Injectable({
    providedIn: 'root'
})
export class CountryService {
    constructor(private readonly _http: HttpClient, private readonly _envService: EnvironmentService) {}

    private readonly COUNTRY_LIST_ROUTE = 'Country/list';
    private readonly COUNTRY_ROUTE = 'country';

    private readonly _countries = new BehaviorSubject<Array<Country>>([]);
    readonly countries$ = this._countries.asObservable();

    private readonly _country = new BehaviorSubject<Country | undefined>(undefined);
    readonly country$ = this._country.asObservable();

    getCountries(): Observable<Array<Country>> {
        return this._http.get<any>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.COUNTRY_LIST_ROUTE}`).pipe(
            tap((countries) => {
                this._countries.next(countries);
            }),
            switchMap(() => this.countries$)
        );
    }

    getCountry(countryId: number): Observable<Country | undefined> {
        return this._http.get<any>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.COUNTRY_ROUTE}/${countryId}`).pipe(
            tap((country) => {
                this._country.next(country);
            }),
            switchMap(() => this.country$)
        );
    }
}
