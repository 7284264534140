<div class="directory-search-autocomplete">
    <span class="autocomplete-wrapper p-input-icon-left">
        <i class="ta ta-search"></i>
        <p-autoComplete
            [(ngModel)]="selectedItem"
            (onKeyUp)="handleKeyUp($event)"
            [suggestions]="filteredItems"
            field="name"
            (onSelect)="onSelectItem()"
            (completeMethod)="filterItems($event)"
            placeholder="{{ placeholder | translate }}"
            [maxlength]=200
        ></p-autoComplete>
    </span>
</div>
