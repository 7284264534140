import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Toggle } from '@ta/app/shared-modules/toggle/models/toggle.model';
import { ToggleService } from '@ta/app/shared-modules/toggle/services/toggle.service';
import { SubscriptionSort } from '@ta/app/shared/models/subscription/subscription-sort.model';
import { Subscription } from '@ta/app/shared/models/subscription/subscription.model';
import { EnvironmentService } from '@ta/app/shared/services/environment.service';
import { SubscriptionService } from '@ta/app/shared/services/subscription.service';
import { MenuItem } from 'primeng/api';
import { filter, Subject, take, takeUntil } from 'rxjs';

@Component({
    selector: 'ta-blade-account-subscriptions',
    templateUrl: './blade-account-subscriptions.component.html',
    styleUrls: ['./blade-account-subscriptions.component.scss']
})
export class BladeAccountSubscriptionsComponent {
    /**
     * List of subscriptions attached to the user's home workspace
     */
    subscriptions: Array<Subscription> = [];

    /**
     * Menu items shown when clicking the sort button
     */
    subscriptionListSortMenu: MenuItem[] = [];

    /**
     * The form relating to the add subscription plan modal
     */
    addSubscriptionPlanForm!: FormGroup;

    /**
     * Current sort direction
     */
    sortDirection: SubscriptionSort = SubscriptionSort.MOST_RELEVANT;

    /**
     * Whether to show the coming soon modal
     */
    showComingSoonPrompt = false;

    /**
     * Whether to show the add subscription plan modal
     */
    showAddSubscriptionPlanPrompt = false;

    /**
     * Handle cleaning up rxjs subscriptions
     */
    private _unsubscribe$: Subject<boolean> = new Subject();

    constructor(
        public environmentService: EnvironmentService,
        readonly subscriptionService: SubscriptionService,
        private readonly _translateService: TranslateService,
        public readonly toggleService: ToggleService
    ) {
        // Fetch and sort subscriptions list
        subscriptionService
            .getHomeWorkspaceSubscriptionList()
            .pipe(
                takeUntil(this._unsubscribe$),
                filter((subscriptions) => !!subscriptions)
            )
            .subscribe((subscriptions) => {
                this.subscriptions = this.sortSubscriptions(subscriptions!, this.sortDirection);
            });

        // Set up sort menu
        this.subscriptionListSortMenu = [
            { label: this._translateService.instant('system.subscriptions.sort.most-relevant'), command: () => this.onSortSubscriptionsList(SubscriptionSort.MOST_RELEVANT) },
            { label: this._translateService.instant('system.subscriptions.sort.activated-date'), command: () => this.onSortSubscriptionsList(SubscriptionSort.ACTIVATED_DATE) },
            { label: this._translateService.instant('system.subscriptions.sort.next-payment-date'), command: () => this.onSortSubscriptionsList(SubscriptionSort.NEXT_PAYMENT_DATE) }
        ];

        // Fetch subscription products
        this.subscriptionService.getHomeWorkspaceSubscriptionProductList().pipe(take(1)).subscribe();

        // Set up subscription product form
        this.addSubscriptionPlanForm = new FormGroup({
            id: new FormControl('')
        });
    }

    /**
     * Handle sorting the subscription list
     */
    onSortSubscriptionsList(sortBy: SubscriptionSort): void {
        // Set the newly sorted list
        this.subscriptions = this.sortSubscriptions(this.subscriptions, sortBy);
    }

    /**
     * Handle add plan button click
     */
    onAddPlan(): void {
        if (this.toggleService.isOn(Toggle.FEATURE_SUBSCRIPTION_MANAGEMENT)) {
            this.showAddSubscriptionPlanPrompt = true;
        } else {
            this.showComingSoonPrompt = true;
        }
    }

    /**
     * Hand confirm add plan button click
     */
    onAddPlanConfirm(): void {
        // Add the subscription to the workspace
        this.subscriptionService.addSubscriptionToHomeWorkspace(this.addSubscriptionPlanForm.get('id')!.value).pipe(take(1)).subscribe();
        // Close the modal
        this.showAddSubscriptionPlanPrompt = false;
        // Clear the selected plan
        this.addSubscriptionPlanForm.get('id')?.setValue(null);
    }

    /**
     * Handle edit clicked on subscription item
     */
    handleEdit(): void {
        this.showComingSoonPrompt = true;
    }

    /**
     * Handle cancel subscription clicked on subscription item
     */
    handleCancelSubscription(): void {
        this.showComingSoonPrompt = true;
    }

    /**
     * Returns a sorted list of subscriptions
     */
    sortSubscriptions(subscriptions: Array<Subscription>, sortDirection: SubscriptionSort): Array<Subscription> {
        switch (sortDirection) {
            case SubscriptionSort.MOST_RELEVANT: {
                return subscriptions.sort(
                    (a, b) =>
                        // First sort by status (ascending)
                        a.statusId - b.statusId ||
                        // Then sort by active units (descending)
                        b.allocatedActiveUnits - a.allocatedActiveUnits ||
                        // Then sort by total units (descending)
                        b.allocatedTotalUnits - a.allocatedTotalUnits ||
                        // Finally, sort by storage allocation (descending)
                        b.allocatedStorageBytes - a.allocatedStorageBytes
                );
            }
            case SubscriptionSort.ACTIVATED_DATE: {
                return subscriptions.sort((a, b) => new Date(a.activatedDate).getTime() - new Date(b.activatedDate).getTime());
            }
            case SubscriptionSort.NEXT_PAYMENT_DATE: {
                // nextPaymentDate is nullable. We hide this sort option when the user doesn't have permissions to read nextPaymentDate, but handle the null case just incase.
                if (subscriptions.findIndex((s) => !s.nextPaymentDueDate)) return subscriptions;
                return subscriptions.sort((a, b) => new Date(a.nextPaymentDueDate!).getTime() - new Date(b.nextPaymentDueDate!).getTime());
            }
        }
    }
}
