<div *ngIf="relationshipsToDisplay?.length !== 0" class="content">
    <div class="content__top" (click)="toggleExpanded()">
        <div class="content__top__title">
            <h4>{{ getTitle() | translate | uppercase }}</h4>
        </div>
        <i class="ta ta-chevron-down content__top__icon" *ngIf="!expanded"></i>
        <i class="ta ta-chevron-up content__top__icon" *ngIf="expanded"></i>
    </div>
    <ng-container *ngIf="expanded">
        <div *ngFor="let t of relationshipsToDisplay" class="content__item" (click)="routeToItem(t)">
            <div class="content__item__icon">
                <i class="{{ icon }}"></i>
            </div>
            <div class="content__item__text">
                <span class="content__item__text__name truncate" [class.content__item__text__name--inactive]="!active">{{ t.name }}</span>
                <span class="content__item__text__type">{{ getType(t.type, t.objectType) }}</span>
            </div>
        </div>
    </ng-container>
</div>
