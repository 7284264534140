import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@ta/app/shared/services/environment.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Invitation } from '@ta/app/core/models/invitation.model';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ApiError, ErrorCodes } from '@ta/app/shared/models/api-response.model';
import { Router } from '@angular/router';
import { AppQueryParams, AppRoutes, WorkspaceRoutes } from '@ta/app/shared/models/routes.model';
import { AcceptInvitationResponse } from '../models/accept-invitation-response.model';

@Injectable({
    providedIn: 'root'
})
export class InvitationService {
    /**
     * Invitation API routes
     */
    private readonly INVITATION_ROUTE = 'Invitation';

    /**
     * State
     */
    private readonly _invitation = new BehaviorSubject<Invitation | undefined>(undefined);
    readonly invitation$ = this._invitation.asObservable();

    private readonly _acceptInvitationResponse = new BehaviorSubject<AcceptInvitationResponse | undefined>(undefined);
    readonly acceptInvitationResponse$ = this._acceptInvitationResponse.asObservable();

    constructor(private readonly _http: HttpClient, private readonly _envService: EnvironmentService, private readonly _router: Router) {}

    /**
     * Get the details of an invitation
     */
    getInvitation(invitationGuid: string): Observable<any | undefined> {
        return this._http.get<Invitation>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.INVITATION_ROUTE}/${invitationGuid}`).pipe(
            tap((invitation) => {
                this._invitation.next(invitation);
            }),
            catchError((e) => {
                return this.handleInvitationError(e.error as ApiError);
            }),
            switchMap(() => this.invitation$)
        );
    }

    /**
     * Accept an invitation and create a new user
     */
    acceptInvitation(invitationGuid: string): Observable<AcceptInvitationResponse | undefined> {
        return this._http.post<AcceptInvitationResponse>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.INVITATION_ROUTE}/${invitationGuid}`, null).pipe(
            tap((acceptInvitationResponse) => {
                this._acceptInvitationResponse.next(acceptInvitationResponse);
            }),
            catchError((e) => {
                return this.handleInvitationError(e.error as ApiError);
            }),
            switchMap(() => this.acceptInvitationResponse$)
        );
    }

    private handleInvitationError<T>(error: ApiError): Observable<T | undefined> {
        // Handle user is already finalised
        if (error.errorCode === ErrorCodes.INVITE_FLOW_USER_ALREADY_FINALISED) {
            // Send them to the workspace in the invitation
            const workspaceGuid = error.errorData;
            this._router.navigate([AppRoutes.WORKSPACE, workspaceGuid, WorkspaceRoutes.DASHBOARD]);
            return of(undefined);
        }

        // Handle invitation has expired
        if (error.errorCode === ErrorCodes.INVITE_FLOW_INVITATION_HAS_EXPIRED) {
            // Mark the invitation as expired and throw an error for the resolver to handle
            const email = error.errorData;
            throw { email, isExpired: true };
        }

        // Otherwise, navigate to the error page
        this._router.navigate([AppRoutes.OOPS], { queryParams: { [AppQueryParams.ERROR]: error.errorCode }, queryParamsHandling: 'merge' });
        return of(undefined);
    }
}
