<p-card (click)="onCardClick()">
    <div class="card">
        <div class="card__header">
            <p-avatar [icon]="workspace.isHomeWorkspace ? 'ta-person' : 'ta-building'" shape="circle" size="large" [style]="avatarStyle"></p-avatar>
            <div class="card__header__title">
                <h4 class="card__header__title__display-name">{{ workspace.displayName || 'system.nav.workspace.personal' | translate }}</h4>
                <p class="card__header__title__legal-name">{{ workspace.legalName }}</p>
                <p class="card__header__title__country">{{ workspace.country }}</p>
            </div>
            <div class="card__header__actions">
                <p-button [icon]="workspace.isFavourite ? 'ta-heart-filled' : 'ta-heart'" styleClass="p-button-text p-button-plain p-button-lg" (click)="onFavouriteClick($event)"></p-button>
            </div>
        </div>

        <div class="card__footer">
            <p-button icon="ta-arrow-right" styleClass="p-button-text p-button-plain p-button-lg" (click)="onCardClick()"></p-button>
        </div>
    </div>
</p-card>
