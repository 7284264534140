<!-- Beadcrumbs -->
<div class="breadcrumbs">
    <ng-container *ngIf="crumbs.length">
        <div *ngFor="let crumb of crumbs; index as i">
            <a class="breadcrumbs__crumb" [routerLink]="crumb.route">{{ crumb.name | translate }}</a>
            <i class="breadcrumbs__chevron ta ta-chevron-right"></i>
        </div>
        <p class="breadcrumbs__crumb--bold">{{ assessmentHeader }}</p>
    </ng-container>
</div>

<!-- Page Heading + Actions -->
<div class="main">
    <div class="main__title">
        <p-button class="main__title__back" (onClick)="onNavigateBack()" icon="ta ta-arrow-left" styleClass="p-button-plain p-button-text"></p-button>
        <div class="main__title__headings">
            <h1 class="main__title__headings__heading">{{ assessmentHeader }}</h1>
            <p class="main__title__headings__subheading">{{ assessmentSubHeading }}</p>
        </div>
    </div>
</div>
