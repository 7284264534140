<div class="empty-directory-overlay-component">
    <div class="content">
        <i class="ta {{ icon }} content__icon"></i>
        <h2 class="content__title">{{ title }}</h2>
        <p class="content__text">{{ content }}</p>
        <div class="content__projected-content">
            <ng-content></ng-content>
        </div>
    </div>
</div>
