import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@ta/app/shared/shared.module';
import { IfToggleDirective } from './directives/if-toggle.directive';

const components = [IfToggleDirective];

@NgModule({
    declarations: [...components],
    imports: [CommonModule],
    exports: [...components]
})
export class ToggleModule {}
