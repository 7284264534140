import { Type } from '@angular/core';
import { FormElementBaseComponent } from '@ta/app/dynamic-form/form-element-base/form-element-base.component';
import { FormElementDropdownComponent } from '@ta/app/dynamic-form/form-element-dropdown/form-element-dropdown.component';
import { FormElementInputTextComponent } from '@ta/app/dynamic-form/form-element-input-text/form-element-input-text.component';
import { FormElementPanelDynamicComponent } from '@ta/app/dynamic-form/form-element-panel-dynamic/form-element-panel-dynamic.component';
import { FormElementPanelComponent } from '@ta/app/dynamic-form/form-element-panel/form-element-panel.component';

/**
 * Converts a SurveyJS component name (e.g. text) to a dynamic form component so we can add it dynamically at runtime
 * @param componentType the name of the survey js component we wish to render
 */
export const getSchemaComponentMapping = (componentType: string): Type<FormElementBaseComponent> => {
    switch (componentType) {
        case 'dropdown':
            return FormElementDropdownComponent;
        case 'panel':
            return FormElementPanelComponent;
        case 'paneldynamic':
            return FormElementPanelDynamicComponent;
        case 'text':
            return FormElementInputTextComponent;
        default:
            throw new Error('Invalid component type specified');
    }
};
