import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppQueryParams, BladeQueryParamValues, MenuQueryParamValues } from '@ta/app/shared/models/routes.model';
import { BladeService } from '@ta/app/shared/services/blade.service';

@Component({
    selector: 'ta-navbar-top-menu',
    // tslint:disable-next-line: no-host-metadata-property
    host: {
        '[class.active]': 'isBladeActive || isMenuActive || isRelatedBladeActive'
    },
    templateUrl: './navbar-top-menu.component.html',
    styleUrls: ['./navbar-top-menu.component.scss']
})
export class NavbarMenuComponent implements OnInit {
    /**
     * Name of icon in menu link
     */
    @Input() icon = '';

    /**
     * Blade to open
     */
    @Input() blade: string | null = null;

    /**
     * Menu to open
     */
    @Input() menu: string | null = null;

    /**
     * Whether the current blade is active
     */
    isBladeActive = false;

    /**
     * Whether the current menu is active
     */
    isMenuActive = false;

    /**
     * Whether a blade related to the current menu item is active
     */
    isRelatedBladeActive = false;

    accountRelatedBlades: Array<string> = [BladeQueryParamValues.ACCOUNT];

    constructor(private readonly _bladeService: BladeService, private readonly _route: ActivatedRoute) {}

    ngOnInit(): void {
        // Subscribe to queryParam changes, store current value
        this._route.queryParams.subscribe((queryParams: Params) => {
            this.isBladeActive = queryParams[AppQueryParams.BLADE] === this.blade;
            this.isMenuActive = queryParams[AppQueryParams.MENU] === this.menu;

            // Handle account related blades
            if (this.menu === MenuQueryParamValues.ACCOUNT) {
                this.isRelatedBladeActive = this.accountRelatedBlades.includes(queryParams[AppQueryParams.BLADE]);
            }
        });
    }

    /**
     * Handle menu clicked
     */
    onMenuClicked(): void {
        // If the blade is already open, remove the query param. Else, update the query param to open the correct blade.
        const newBladeQueryParam: string | null = this.isBladeActive ? null : this.blade;
        const newMenuQueryParam: string | null = this.isMenuActive ? null : this.menu;
        // Update query params
        this._bladeService.setBladeAndMenu(null, newBladeQueryParam, newMenuQueryParam, null);
    }
}
