import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteService } from '@ta/app/shared/services/site.service';
import { Subscription } from 'rxjs';
import { RelationshipObjectType, RelationshipObjectTypeRoutes } from '@ta/app/shared/models/relationship/relationship-object-type.model';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';
import { getRelationshipType } from '@ta/app/shared/utils/get-relationship-type';
import { JobService } from '@ta/app/shared/services/job.service';
import { PersonService } from '@ta/app/shared/services/person.service';
import { PersonType } from '@ta/app/shared/models/person/person-type.model';
import { RelationshipTense } from '@ta/app/shared/models/relationship/relationship-tense.model';
import { RelationshipType } from '@ta/app/shared/models/relationship/relationship-type.model';
import { Relationship } from '@ta/app/shared/models/relationship/relationships.model';
import { SiteType } from '@ta/app/shared/models/site/siteType.model';
import { JobType } from '@ta/app/shared/models/job/job-type.model';

@Component({
    selector: 'ta-relationships-division',
    templateUrl: './relationships-division.component.html',
    styleUrls: ['./relationships-division.component.scss']
})
export class RelationshipsDivisionComponent implements OnInit, OnDestroy, OnChanges {
    /**
     * The relationships to display
     */
    @Input()
    relationships?: Array<Relationship>;

    /**
     * The icon to display next to the title
     */
    @Input()
    icon!: string;

    /**
     * Whether the owner of the relationships is active
     */
    @Input()
    active = true;

    /**
     * Whether the relationships are expaned, or not.
     */
    @Input()
    expanded = true;

    /**
     * The object type (person, job, site)/ obejcts type (job.task, job.position) combination for the viewing context
     */
    @Input()
    viewingContext?: RelationshipType;

    /**
     * The object type (person, job, site)/ obejcts type (job.task, job.position) combination to be displayed
     */
    @Input()
    displayContext?: RelationshipType;

    /**
     * The tense which to display relationships for
     */
    @Input()
    displayTense?: RelationshipTense;

    /**
     * Whether the type of the viewing conext should be overridden/ignored
     * This is the case when the display context is set to an ALL option, the reson for having this
     * and the option is for maximum understandability, given this is a slightly complex structure.
     */
    @Input()
    overrideDisplayContext = false;

    /**
     * All possible types for person
     */
    personTypes?: PersonType[];

    /**
     * All possible types for job
     */
    jobTypes?: JobType[];

    /**
     * All possible types for site
     */
    siteTypes?: SiteType[];

    /**
     * The relationships to display within this component.
     */
    relationshipsToDisplay: Relationship[] = new Array<Relationship>();

    /**
     * The title to display above relationshipsToDisplay
     */
    title?: string;

    private _subscriptions = new Subscription();

    constructor(
        private readonly _personService: PersonService,
        private readonly _jobService: JobService,
        private readonly _siteService: SiteService,
        private readonly _router: Router,
        private readonly _workspaceService: WorkspaceService
    ) {}

    ngOnInit(): void {
        this._subscriptions.add(
            this._personService.personTypes$.subscribe((result) => {
                this.personTypes = result;
            })
        );

        this._subscriptions.add(
            this._jobService.jobTypes$.subscribe((result) => {
                this.jobTypes = result;
            })
        );

        this._subscriptions.add(
            this._siteService.siteTypes$.subscribe((result) => {
                this.siteTypes = result;
            })
        );

        this.processRelationships();
        this.title = this.getTitle();

        this.expanded = this.displayTense === RelationshipTense.PRESENT;
    }

    /**
     * Ensures that the relationship list for display is updated after edit.
     */
    ngOnChanges(): void {
        this.processRelationships();
    }

    ngOnDestroy(): void {
        this._subscriptions?.unsubscribe();
        this.processRelationships();
    }

    /**
     * Extract the relationships to be displayed based on the provided criteria.
     */
    processRelationships(): void {
        this.relationshipsToDisplay =
            this.relationships?.filter((relationship) => {
                const type = getRelationshipType(relationship.objectType, relationship.type);
                return relationship.tense === this.displayTense && (this.overrideDisplayContext || type === this.displayContext);
            }) ?? [];
    }

    routeToItem(relationship: Relationship): void {
        this._router.navigate([this._workspaceService.workspace?.globalId, RelationshipObjectTypeRoutes.get(relationship.objectType), relationship.id]);
    }

    /**
     * Toggle the expansion of the component.
     */
    toggleExpanded(): void {
        this.expanded = !this.expanded;
    }

    /**
     * Matches the provided tense and relationhsip combination
     * @returns The title that matches the tense/relationship combination provided.
     */
    getTitle(): string {
        switch (this.viewingContext) {
            case RelationshipType.PERSON_EMPLOYEE:
                switch (this.displayContext) {
                    case RelationshipType.JOB_ALL:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.performs';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-perform';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-performed';
                        }
                        break;

                    case RelationshipType.SITE_ALL:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.works-at';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-work-at';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-worked-at';
                        }
                        break;
                }
                break;

            case RelationshipType.PERSON_CLIENT:
                switch (this.displayContext) {
                    case RelationshipType.JOB_ALL:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.interacts-with';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-interact-with';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-interacted-with';
                        }
                        break;

                    case RelationshipType.SITE_ALL:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.interacts-at';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-interact-at';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-interacted-at';
                        }
                        break;
                }
                break;

            case RelationshipType.JOB_POSITION:
                switch (this.displayContext) {
                    case RelationshipType.PERSON_EMPLOYEE:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.performed-by';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-be-performed-by';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-performed-by';
                        }
                        break;

                    case RelationshipType.PERSON_CLIENT:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.services';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-service';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.formerly-serviced';
                        }
                        break;

                    case RelationshipType.SITE_ALL:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.performed-at';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-be-performed-at';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-performed-at';
                        }
                        break;

                    case RelationshipType.JOB_POSITION:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.relates-to';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-relate-to';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-related-to';
                        }
                        break;

                    case RelationshipType.JOB_TASK:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.includes';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-include';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-included';
                        }
                        break;
                }
                break;

            case RelationshipType.JOB_TASK:
                switch (this.displayContext) {
                    case RelationshipType.PERSON_EMPLOYEE:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.performed-by';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-be-performed-by';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-performed-by';
                        }
                        break;

                    case RelationshipType.PERSON_CLIENT:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.services';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-service';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.formerly-serviced';
                        }
                        break;

                    case RelationshipType.SITE_ALL:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.performed-at';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-be-performed-at';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-performed-at';
                        }
                        break;

                    case RelationshipType.JOB_POSITION:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.is-part-of';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-be-part-of';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-part-of';
                        }
                        break;

                    case RelationshipType.JOB_TASK:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.relates-to';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-relate-to';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-related-to';
                        }
                        break;
                }
                break;

            case RelationshipType.SITE_WORKPLACE:
                switch (this.displayContext) {
                    case RelationshipType.JOB_ALL:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.hosts';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-host';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-hosted';
                        }
                        break;

                    case RelationshipType.PERSON_ALL:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.is-used-by';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-be-used-by';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-used-by';
                        }
                        break;

                    case RelationshipType.SITE_WORKPLACE:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.relates-to';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-relate-to';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-related-to';
                        }
                        break;

                    case RelationshipType.SITE_SPECIFIC_LOCATION:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.includes';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-include';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-included';
                        }
                        break;
                }
                break;

            case RelationshipType.SITE_SPECIFIC_LOCATION:
                switch (this.displayContext) {
                    case RelationshipType.JOB_ALL:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.hosts';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-host';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-hosted';
                        }
                        break;

                    case RelationshipType.PERSON_ALL:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.is-used-by';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-be-used-by';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-used-by';
                        }
                        break;

                    case RelationshipType.SITE_WORKPLACE:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.located-at';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-be-located-at';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-located-at';
                        }
                        break;

                    case RelationshipType.SITE_SPECIFIC_LOCATION:
                        switch (this.displayTense) {
                            case RelationshipTense.PRESENT:
                                return 'system.relationship-titles.relates-to';
                            case RelationshipTense.FUTURE:
                                return 'system.relationship-titles.could-relate-to';
                            case RelationshipTense.PAST:
                                return 'system.relationship-titles.once-related-to';
                        }
                        break;
                }
        }

        return 'system.relationship-titles.placeholder';
    }

    /**
     * Gets the current persons type as a string based on its id
     * @returns The string value of the type
     */
    getType(typeId: number, objectType: RelationshipObjectType): string {
        switch (objectType) {
            case RelationshipObjectType.JOB:
                return this.jobTypes?.find((type) => type.id === typeId)?.type ?? 'N/A';

            case RelationshipObjectType.PERSON:
                return this.personTypes?.find((type) => type.id === typeId)?.type ?? 'N/A';

            case RelationshipObjectType.SITE:
                return this.siteTypes?.find((type) => type.id === typeId)?.type ?? 'N/A';
        }
    }
}
