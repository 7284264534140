import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { AppRoutes, InvitationParam, WorkspaceRoutes } from '@ta/app/shared/models/routes.model';
import { LocalStorageKey } from '@ta/app/shared/models/local-storage.model';

@Injectable({
    providedIn: 'root'
})
export class InvitationGuard implements CanActivate {
    constructor(private readonly _router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): Promise<true | UrlTree> {
        return this.checkInvitationIsPresent(route);
    }

    canActivateChild(route: ActivatedRouteSnapshot): Promise<true | UrlTree> {
        return this.checkInvitationIsPresent(route);
    }

    /**
     * Check that there is an invitation in thee guard before the resolver goes and fetches it
     * Note: this guard does not call the unauthenticated guard, despite the fact that users can access this page unauthenticated.
     * This is so that we can allow authenticated users to access invitations, and either get redirected to the correct workspace,
     * or present an error if the invite is for a different email address.
     */
    async checkInvitationIsPresent(route: ActivatedRouteSnapshot): Promise<true | UrlTree> {
        //////////////////////////////////////
        // Is the invitation present
        //////////////////////////////////////
        // Get invitation GUIDs from query params
        const invitationGuid = route.queryParams[InvitationParam.INVITATION_GUID];
        const workspaceGuid = route.queryParams[InvitationParam.WORKSPACE_GUID];

        // This shouldn't happen
        if (!invitationGuid && !workspaceGuid) {
            return this._router.createUrlTree([AppRoutes.ROOT]);
        }

        // If there is no invitation guid, then the invitation is for a finalised user
        // Redirect them to their new workspace
        if (!invitationGuid) {
            // Store the workspace guid, in case we need to log in
            localStorage.setItem(LocalStorageKey.INVITE_WORKSPACE_GUID, workspaceGuid);
            return this._router.createUrlTree([AppRoutes.WORKSPACE, workspaceGuid, WorkspaceRoutes.DASHBOARD]);
        }

        // Continue to the component
        return true;
    }
}
