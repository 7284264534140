<div class="content">
    <div class="prompt-wrapper">
        <div class="prompt">
            <img src="assets/images/logo-horizontal.svg" />
            <ng-content></ng-content>
        </div>
    </div>
    <div class="taskalyser-logo-div">
        <div class="circle">
            <img class="taskalyser-logo" src="assets/images/logo-white.svg" />
        </div>
    </div>
</div>

<footer class="footer">
    <ul>
        <li>
            <a [routerLink]="'/' + AppRoutes.PRIVACY" target="_blank">{{ 'system.auth.privacy-policy' | translate }}</a>
        </li>
        <li>
            <a [routerLink]="'/' + AppRoutes.TERMS" target="_blank">{{ 'system.auth.terms-of-service' | translate }}</a>
        </li>
    </ul>
</footer>
