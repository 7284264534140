import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'ta-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
    /**
     * Form control to bind to the search input
     */
    @Input() input!: FormControl;

    /**
     * Limit the length of the search input
     */
    @Input() maxLength?: number;

    /**
     * Whether the search input is disabled
     */
    @Input() disabled = false;

    /**
     * Whether the control is active
     */
    isActive = false;

    /**
     * Reference to search input
     */
    @ViewChild('search') searchElement!: ElementRef;

    constructor() {}

    /**
     * Handle the search icon being clicked
     */
    onSearchMousedown(event: any): void {
        if (this.disabled) {
            return;
        }
        // Toggle active
        if (!this.input.value) this.isActive = !this.isActive;
        // Focus input, if active
        if (this.isActive) {
            this.searchElement.nativeElement.focus();
        }
        // Prevent onblur from also firing
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * handle blur from search input
     */
    onBlur(): void {
        // Toggle off the search box
        if (!this.input.value) this.isActive = false;
    }

    /**
     * Show the search input
     */
    show(): void {
        this.isActive = true;
    }

    /**
     * Handle clear button
     */
    onClear(): void {
        this.input.reset();
        this.isActive = false;
    }
}
