// Please add to this alphabetically
export enum Toggle {
    ADMIN = 'ADMIN',
    POWER_USER = 'POWER_USER',
    ASSESSMENT_COMMENT_SUPRESS = 'ASSESSMENT_COMMENT_SUPRESS',
    ASSESSMENT_COMPLETE = 'ASSESSMENT_COMPLETE',
    ASSESSMENT_EDIT = 'ASSESSMENT_EDIT',
    ASSESSMENT_FILE_ADD = 'ASSESSMENT_FILE_ADD',
    ASSESSMENT_FILE_DELETE = 'ASSESSMENT_FILE_DELETE',
    ASSESSMENT_FILE_DOWNLOAD = 'ASSESSMENT_FILE_DOWNLOAD',
    ASSESSMENT_FILE_RENAME = 'ASSESSMENT_FILE_RENAME',
    ASSESSMENT_DUPLICATE = 'ASSESSMENT_DUPLICATE',
    ASSESSMENT_PERMANENTLY_DELETE = 'ASSESSMENT_PERMANENTLY_DELETE',
    ASSESSMENT_SHARE = 'ASSESSMENT_SHARE',
    COMPARISONS = 'COMPARISONS',
    COMPLETED_ASSESSMENT_EDIT = 'COMPLETE_ASSESSMENT_EDIT',
    DASHBOARD_RECENT_ACTIVITY = 'DASHBOARD_RECENT_ACTIVITY',
    DIRECTORIES = 'DIRECTORIES',
    MY_DATA_DOWNLOAD = 'MY_DATA_DOWNLOAD',
    FEATURE_ASSESSMENT_REPORT = 'FEATURE_ASSESSMENT_REPORT',
    FEATURE_SUBSCRIPTION_MANAGEMENT = 'FEATURE_SUBSCRIPTION_MANAGEMENT',
    HELP = 'HELP',
    JOBS = 'JOBS',
    JOBS_PERMANENTLY_DELETE = 'JOBS_PERMANENTLY_DELETE',
    JOB_EDIT = 'JOB_EDIT',
    NOTIFICATION = 'NOTIFICATION',
    OBJECT_LEVEL_PERMISSION_EDIT = 'OBJECT_LEVEL_PERMISSION_EDIT',
    PEOPLE = ' PEOPLE',
    PEOPLE_PERMANENTLY_DELETE = 'PEOPLE_PERMANENTLY_DELETE',
    PERSON_ASSESSMENT = 'PERSON_ASSESSMENT',
    PERSON_EDIT = 'PERSON_EDIT',
    REPORTING = 'REPORTING',
    SEARCH = 'SEARCH',
    SITES = 'SITES',
    SITES_PERMANENTLY_DELETE = 'SITES_PERMANENTLY_DELETE',
    SITE_EDIT = 'SITE_EDIT',
    SUBSCRIPTION_ASSESSMENT_COMPLETE = 'SUBSCRIPTION_ASSESSMENT_COMPLETE',
    SUBSCRIPTION_ASSESSMENT_CREATE = 'SUBSCRIPTION_ASSESSMENT_CREATE',
    SUBSCRIPTION_ASSESSMENT_FILE_UPLOAD = 'SUBSCRIPTION_ASSESSMENT_FILE_UPLOAD',
    SUBSCRIPTION_ASSESSMENT_RESTORE = 'SUBSCRIPTION_ASSESSMENT_RESTORE',
    SUBSCRIPTIONS_MANAGE = 'SUBSCRIPTIONS_MANAGE',
    TAG_MANAGE = 'TAG_MANAGE',
    USERS_MANAGE = 'USERS_MANAGE',
    WORKSPACE_DELETE = 'WORKSPACE_DELETE',
    WORKSPACE_SETTINGS_MANAGE = 'WORKSPACE_SETTINGS_MANAGE'
}

export interface ToggleContext {
    toggle: Toggle | undefined;
    toggleParams?: any;
}
