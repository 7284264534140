<div class="subscription-storage-data-display-container">
    <h4>{{ 'system.subscriptions.storage' | translate }}</h4>
    <div class="bar-graph" [class.bar-graph--aligned-legend]="forceLegendAlignment">
        <ta-usage-bar [totalUsed]="used" [totalCap]="total" [totalCapString]="totalFriendlyString" totalCapLabel="system.usage-bar.storage-cap"></ta-usage-bar>
    </div>
    <div class="legend">
        <div class="legend__section">
            <div class="legend__section__item">
                <div class="legend__section__item__label" [class.legend__section__item__label--exceeded]="used > total"></div>
                <div class="legend__section__item__text">{{ 'system.used' | translate }}</div>
                <div class="legend__section__item__amount" [class.legend__section__item__amount--exceeded]="used > total">{{ usedFriendlyString }}</div>
            </div>
        </div>
        <div class="legend__section">
            <div class="legend__section__item">
                <div class="legend__section__item__label legend__section__item__label--light-grey" [class.legend__section__item__label--exceeded]="used > total"></div>
                <div class="legend__section__item__text">{{ 'system.remaining' | translate }}</div>
                <div class="legend__section__item__amount" [class.legend__section__item__amount--exceeded]="used > total">{{ remainingFriendlyString }}</div>
            </div>
        </div>
    </div>
</div>
