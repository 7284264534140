import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    /**
     * Track toasts
     */
    private _toasts: Subject<Array<Message>> = new Subject();
    readonly toasts$ = this._toasts.asObservable();

    constructor() {}

    /**
     * Add a single toast
     */
    addToast(message: Message): void {
        if (!!message) {
            // the timeout is required to allow the toasts to be displayed after resolver redirects
            // without it they will just not show up.
            window.setTimeout(() => {
                this._toasts.next([message]);
            });
        }
    }

    /**
     * Add multiple toasts
     */
    addToasts(messages: Array<Message>): void {
        this._toasts.next(messages);
    }
}
