<p-dialog [draggable]="false" [modal]="true" header="{{ 'system.dialog.restore-assessment.heading' | translate }}" [(visible)]="show" (onHide)="onRestoreCancel()">
    <div class="assessment-restore-dialog">
        <div class="assessment-restore-dialog__description">
            <i class="ta ta-exclamation-triangle"></i>
            <p>{{ 'system.dialog.restore-assessment.content' | translate }}</p>
        </div>
        <div>
            <p-messages class="message newline" severity="error" *ngIf="toggleService.isOn(Toggle.SUBSCRIPTION_ASSESSMENT_RESTORE) === false">
                <ng-template pTemplate>
                    <i class="ta ta-dismiss-circle"></i>
                    <span>
                        {{ 'system.subscriptions.error.unarchive-assessment-insufficient-units-split.0' | translate }}
                        <b>{{ 'system-assessment.common.upgrade-your-subscription' | translate }}</b>
                        {{ 'system.subscriptions.error.unarchive-assessment-insufficient-units-split.1' | translate }}
                    </span>
                </ng-template>
            </p-messages>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button styleClass="p-button-outlined p-button-secondary" (onClick)="onRestoreCancel()" label="{{ 'system.button.delete-cancel' | translate }}"></p-button>
        <p-button
            [disabled]="toggleService.isOn(Toggle.SUBSCRIPTION_ASSESSMENT_RESTORE) === false"
            styleClass="p-button-primary"
            (onClick)="onRestoreConfirm()"
            label="{{ 'system.button.restore' | translate }}"
        ></p-button>
    </ng-template>
</p-dialog>
