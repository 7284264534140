import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterItem } from '@ta/app/shared/models/filter-item.model';

@Component({
    selector: 'ta-filter-dropdown',
    templateUrl: './filter-dropdown.component.html',
    styleUrls: ['./filter-dropdown.component.scss']
})
export class FilterDropdownComponent {
    /**
     * The filters available
     */
    @Input()
    filterItems: Array<FilterItem> = [];

    /**
     * The selected values of filterItems
     */
    @Input() selected: Array<FilterItem> = [];
    @Output() selectedChange = new EventEmitter<Array<FilterItem>>();

    @Output()
    filter = new EventEmitter<any>();

    @Output()
    clearFilter = new EventEmitter<any>();

    get selectedChild(): Array<FilterItem> {
        return this.selected;
    }
    set selectedChild(selected: Array<FilterItem>) {
        this.selectedChange.emit(selected);
    }

    constructor() {}
}
