/**
 * API action translate key prefix
 */
const PREFIX_SYSTEM_ACTION = 'system.action';

/**
 * API Actions
 */
export enum ApiAction {
    CREATE = 'Create',
    READ = 'Read',
    UPDATE = 'Update',
    DELETE = 'Delete',
    PERMANENTLY_DELETE = 'Permanently Delete',
    ARCHIVE = 'Archive',
    UNARCHIVE = 'Unarchive',
    SAVE = 'Save',
    UPLOAD = 'Upload',
    DUPLICATE = 'Duplicate',
    GENERIC = 'Generic'
}

/**
 * Translate keys for API action complete
 */
export const translateKeysApiActionComplete: { [key: string]: string } = {
    [ApiAction.CREATE]: `${PREFIX_SYSTEM_ACTION}.create.complete`,
    [ApiAction.DELETE]: `${PREFIX_SYSTEM_ACTION}.delete.complete`,
    [ApiAction.PERMANENTLY_DELETE]: `${PREFIX_SYSTEM_ACTION}.permanently-delete.complete`,
    [ApiAction.UPDATE]: `${PREFIX_SYSTEM_ACTION}.update.complete`,
    [ApiAction.ARCHIVE]: `${PREFIX_SYSTEM_ACTION}.archive.complete`,
    [ApiAction.UNARCHIVE]: `${PREFIX_SYSTEM_ACTION}.unarchive.complete`,
    [ApiAction.SAVE]: `${PREFIX_SYSTEM_ACTION}.save.complete`,
    [ApiAction.UPLOAD]: `${PREFIX_SYSTEM_ACTION}.upload.complete`,
    [ApiAction.DUPLICATE]: `${PREFIX_SYSTEM_ACTION}.duplicate.complete`,
    [ApiAction.GENERIC]: `${PREFIX_SYSTEM_ACTION}.generic.complete`
};
