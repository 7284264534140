import { BuildConfig } from '@ta/app/shared/models/build-config.model';
/*
 * ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask` for easier debugging.
 * do not enable in production, as this will impact performance
 */
import 'zone.js/plugins/zone-error';

/**
 * Build Configuration - Development
 * The variables set here are related to a specific build of the Angular application.
 * These variables are replaced based on the 'fileReplacements' array, set in angular.json.
 * E.g. `ng build --prod` replaces `build-config.ts` with `build-config.prod.ts`.
 *
 * Note: Variables that need to be replaced at deploy-time live in the EnvironmentService.
 */
export const buildConfig: BuildConfig = {
    // angular production flag
    PRODUCTION: false
};
