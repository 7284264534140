import { Injectable, isDevMode } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { UserService } from '@ta/app/shared/services/user.service';
import { EmailVerifiedGuard } from './email-verified.guard';
import { User as TaskAlyserUser } from '@ta/app/shared/models/user.model';
import { AppQueryParams, AppRoutes } from '../models/routes.model';
import { ErrorCodes } from '../models/api-response.model';

@Injectable()
export class UserFinalisedGuard implements CanActivate, CanActivateChild {
    constructor(private readonly _router: Router, private _userService: UserService, private readonly _emailVerifiedGuard: EmailVerifiedGuard) {}

    canActivate(): Promise<true | UrlTree> {
        return this.checkUserIsReadyForFinalisation(this._emailVerifiedGuard.canActivate());
    }

    canActivateChild(): Promise<true | UrlTree> {
        return this.checkUserIsReadyForFinalisation(this._emailVerifiedGuard.canActivateChild());
    }

    private async checkUserIsReadyForFinalisation(emailVerifiedGuard: Promise<true | UrlTree>): Promise<true | UrlTree> {
        //////////////////////////////////////
        // Check preceding guards
        //////////////////////////////////////
        const isEmailVerified = await emailVerifiedGuard;
        if (isEmailVerified instanceof UrlTree) {
            return isEmailVerified;
        }

        //////////////////////////////////////
        // Does the user record exist?
        //////////////////////////////////////
        // First, try to fetch the user cached within the user service
        let taskAlyserUser: TaskAlyserUser | undefined = await firstValueFrom(this._userService.user$);

        // If there is no user, fetch it from the API
        // Also, refetch the user if they are unregistered, to make sure we make the next call correctly
        // API will error if there is no user, so we need to catch the error
        let doesUserExist = true;
        if (!taskAlyserUser || !taskAlyserUser.isAuthProviderRegistered) {
            try {
                taskAlyserUser = await firstValueFrom(this._userService.getUser());
            } catch {
                doesUserExist = false;
            }
        }

        // If there is no TaskAlyser user, create one
        if (!doesUserExist) {
            try {
                taskAlyserUser = await firstValueFrom(this._userService.createUser());
            } catch {
                if (isDevMode()) {
                    return this._router.createUrlTree([AppRoutes.OOPS], {
                        queryParams: { [AppQueryParams.ERROR]: ErrorCodes.CLIENT_USER_FINALISED_GUARD_CREATE_USER_FAILED_API },
                        queryParamsHandling: 'merge'
                    });
                } else {
                    return this._router.createUrlTree([AppRoutes.OOPS]);
                }
            }
        }

        //////////////////////////////////////
        // Is current AuthId known?
        //////////////////////////////////////
        // If the AuthProviderId is not registered to the user, register it
        if (!taskAlyserUser?.isAuthProviderRegistered) {
            try {
                taskAlyserUser = await firstValueFrom(this._userService.registerAuthProviderId());
            } catch {
                if (isDevMode()) {
                    return this._router.createUrlTree([AppRoutes.OOPS], {
                        queryParams: { [AppQueryParams.ERROR]: ErrorCodes.CLIENT_USER_FINALISED_GUARD_REGISTER_AUTHPROVIDERID_FAILED_API },
                        queryParamsHandling: 'merge'
                    });
                } else {
                    return this._router.createUrlTree([AppRoutes.OOPS]);
                }
            }
        }

        //////////////////////////////////////
        // Is the user finalised?
        //////////////////////////////////////
        // If the user is not finalised, send them to the finalisation page
        if (!taskAlyserUser?.isUserFinalised) {
            return this._router.createUrlTree([AppRoutes.FINALISE]);
        }

        //////////////////////////////////////
        // What is the user's intended destination?
        //////////////////////////////////////
        // The user account is now finalised and they may access the application
        // The logic to handle where they are navigated to is handled within the
        // WorkspaceGuard so we can just allow the user through this guard to
        // let WorkspaceGuard pick up the flow from here.
        return true;
    }
}
