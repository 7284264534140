<!-- Acrylic overlay -->
<div class="blade-overlay" [ngClass]="{ 'blade-overlay--open': !!showWorkspaceSwitcher }" (click)="onOverlayClick()"></div>

<!-- Blade panel -->
<div class="blade-panel" [ngClass]="{ 'blade-panel--open': !!showWorkspaceSwitcher }" (click)="onBladeClick($event)">
    <!-- Navbar overlay -->
    <div class="blade-panel__header">
        <div class="blade-panel__header__workspace-switcher">
            <ta-navbar-top-menu icon="ta-office-apps"></ta-navbar-top-menu>
            <div class="blade-panel__header__separator"></div>
        </div>
        <h4 class="blade-panel__header__heading">{{ 'system.nav.workspace-switcher.heading' | translate }}</h4>
        <ta-navbar-top-menu class="blade-panel__header__close" icon="ta-dismiss" (click)="onCloseClick()"></ta-navbar-top-menu>
    </div>

    <!-- Blade panel content -->
    <div class="blade-panel__content">
        <!-- Action buttons-->
        <div class="blade-panel__content__actions">
            <p-button (onClick)="onAddWorkspace()" type="button" label="{{ 'system.button.new-workspace' | translate }}" icon="ta ta-add" styleClass="p-button-md" iconPos="left"></p-button>
        </div>
        <!-- Tabs -->
        <ta-tabs [tabs]="tabs"></ta-tabs>
        <!-- Filters-->
        <div class="blade-panel__content__filters">
            <ta-search-input *ngIf="this.currentTab === TabQueryParamValues.ALL" [input]="search" [maxLength]="100"></ta-search-input>
        </div>
        <!-- Cards -->
        <p-scrollPanel *ngIf="filteredWorkspaceList.length; else emptylist" [style]="{ width: '100%' }" class="blade-panel__content__scroll">
            <div class="blade-panel__content__cards">
                <ta-workspace-switcher-card *ngFor="let workspace of filteredWorkspaceList" [workspace]="workspace" class="blade-panel__content__card"></ta-workspace-switcher-card>
            </div>
        </p-scrollPanel>
        <!-- Empty list message -->
        <ng-template #emptylist>
            <p *ngIf="!filteredWorkspaceList.length" class="blade-panel__content__cards--empty">{{ 'system.nav.workspace-switcher.tab.empty' | translate }}</p>
        </ng-template>
    </div>
</div>

<ta-workspace-create-model
    *ngIf="createNewWorkspaceModal || isFinalisePremiumWorkspace"
    [currentStep]="initialStep"
    (closeModel)="closeWorkspaceToggle()"
    (requestStandardWorkspace)="createWorkspace()"
    (requestPremiumWorkspace)="submitPremiumWorkspaceRequest()"
    (finalisePremiumWorkspaceRequest)="finalisePremiumWorkspace()"
></ta-workspace-create-model>

<!--Premium workspace successfully sent request modal-->
<p-dialog [draggable]="false" [modal]="true" header="{{ 'system.workspace.modal.request-sent' | translate }}" [(visible)]="requestSent">
    <p>{{ 'system.workspace.modal.request-confirmation' | translate }}</p>
    <ng-template pTemplate="footer">
        <p-button (onClick)="requestSent = false" styleClass="p-button-text" label="{{ 'system.button.close' | translate }}"></p-button>
    </ng-template>
</p-dialog>

<!--Premium workspace finalisation incomplete modal-->
<p-dialog [draggable]="false" [modal]="true" header="{{ 'Incomplete request' | translate }}" [(visible)]="premiumIncomplete">
    <p>{{ 'system.workspace.modal.request-incomplete' | translate }}</p>
    <ng-template pTemplate="footer">
        <p-button (onClick)="premiumIncomplete = false" styleClass="p-button-text" label="{{ 'system.button.close' | translate }}"></p-button>
    </ng-template>
</p-dialog>
