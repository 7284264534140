<!-- Standard controls  -->
<div *ngIf="(showDiscardChangesPrompt | async) === false; else discardChanges" class="controls">
    <p-button (onClick)="onCancel()" [disabled]="(linkedFormService.disabledState$ | async) === true" class="controls__button" styleClass="p-button-outlined p-button-secondary">{{
        'system.button.cancel' | translate
    }}</p-button>
    <p-button (onClick)="onSave()" [disabled]="!form.dirty || !form.valid" [loading]="(linkedFormService.disabledState$ | async) === true" class="controls__button">{{
        'system.button.save-changes' | translate
    }}</p-button>
</div>

<!-- Discard changes controls -->
<ng-template #discardChanges>
    <div class="discard">
        <span class="discard__message">{{ 'system.blade-controls.discard-changes-confirmation-text' | translate }}</span>
        <div class="controls">
            <p-button (onClick)="onDiscardChangesCancel()" class="controls__button" styleClass="p-button-outlined p-button-secondary">{{ 'system.button.discard-cancel' | translate }}</p-button>
            <p-button (onClick)="onDiscardChangesConfirm()" class="controls__button" styleClass="p-button-danger">{{ 'system.button.discard' | translate }}</p-button>
        </div>
    </div>
</ng-template>
