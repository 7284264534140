<div class="oops-container">
    <div class="oops-container__body">
        <h1>{{ errorMessage?.title! | translate }}</h1>
        <p class="oops-container__body__subtitle">{{ errorMessage?.subtitle! | translate }}</p>
        <p>
            <strong>{{ errorMessage?.explanationTitle! | translate }}</strong>
        </p>
        <div class="oops-container__body__error-explanation">
            <p *ngFor="let message of errorMessage?.explanationMessages">{{ message | translate }}</p>
        </div>
        <p-button [routerLink]="redirectLocation" class="oops-container__body__button" styleClass="p-button-lg" label="{{ errorMessage?.buttonMessage! | translate }}"></p-button>
    </div>
    <div class="oops-container__logo">
        <img src="assets/images/logo__shattered__gradient.svg" />
    </div>
</div>
