<div class="blade-account-subscriptions-container">
    <p-scrollPanel [style]="{ width: '100%' }" class="scroll">
        <div class="content">
            <!-- Subscription usage graphs -->
            <div class="subscription-usage">
                <ta-subscription-assessment-data-display
                    [totalUsed]="(subscriptionService.homeWorkspaceSubscriptionSummary$ | async)!.usedTotalUnits"
                    [totalCap]="(subscriptionService.homeWorkspaceSubscriptionSummary$ | async)!.allocatedTotalUnits"
                    [activeUsed]="(subscriptionService.homeWorkspaceSubscriptionSummary$ | async)!.usedActiveUnits"
                    [activeCap]="(subscriptionService.homeWorkspaceSubscriptionSummary$ | async)!.allocatedActiveUnits"
                ></ta-subscription-assessment-data-display>
                <ta-subscription-storage-data-display
                    [used]="(subscriptionService.homeWorkspaceSubscriptionSummary$ | async)!.usedStorageBytes"
                    [total]="(subscriptionService.homeWorkspaceSubscriptionSummary$ | async)!.allocatedStorageBytes"
                    [forceLegendAlignment]="true"
                ></ta-subscription-storage-data-display>
            </div>

            <!-- Subscription header, sort, new plan etc -->
            <div class="subscription-list-header">
                <!-- Heading (left) -->
                <h3 class="subscription-list-header__heading">{{ 'system.subscriptions.admin.heading' | translate }}</h3>

                <!-- Actions (right) -->
                <div class="subscription-list-header__actions">
                    <!-- Sort button and menu -->
                    <p-menu #menu [popup]="true" [model]="subscriptionListSortMenu" appendTo="body"></p-menu>
                    <p-button
                        label="{{ 'system.button.sort-by' | translate }}"
                        icon="ta ta-chevron-down"
                        iconPos="right"
                        [styleClass]="'p-button-secondary p-button-text'"
                        (onClick)="menu.toggle($event)"
                    >
                        <i class="ta ta-filter subscription-list-header__actions__sort__icon"></i>
                    </p-button>
                    <!-- Add Plan -->
                    <p-button (onClick)="onAddPlan()" icon="ta ta-add" label="{{ 'system.subscriptions.admin.add-plan' | translate }}"></p-button>
                </div>
            </div>

            <!-- Subscription list -->
            <div class="subscription-list">
                <ta-subscription-list-item
                    *ngFor="let subscription of subscriptions"
                    [subscription]="subscription"
                    (edit)="handleEdit()"
                    (cancelSubscription)="handleCancelSubscription()"
                ></ta-subscription-list-item>
                <!-- Empty list message -->
                <p *ngIf="subscriptions.length === 0" class="subscription-list__empty-message">{{ 'system.subscriptions.admin.no-subscriptions' | translate }}</p>
            </div>
        </div>
    </p-scrollPanel>
</div>

<!-- Coming soon modal -->
<p-dialog [draggable]="false" [modal]="true" header="{{ 'system.dialog.subscriptions.coming-soon.heading' | translate }}" [(visible)]="showComingSoonPrompt">
    <div class="coming-soon">
        <p>{{ 'system.dialog.subscriptions.coming-soon.content1' | translate }}</p>
        <p>
            {{ 'system.dialog.subscriptions.coming-soon.content2a' | translate }}
            <a class="link" href="mailto:{{ environmentService.config.TASKALYSER.SALES_EMAIL }}">{{ environmentService.config.TASKALYSER.SALES_EMAIL }}</a>
            {{ 'system.dialog.subscriptions.coming-soon.content2b' | translate }}
        </p>
    </div>
    <ng-template pTemplate="footer">
        <p-button (click)="showComingSoonPrompt = false" label="{{ 'system.button.okay-got-it' | translate }}"></p-button>
    </ng-template>
</p-dialog>

<!-- Subscription plan modal -->
<p-dialog [draggable]="false" [modal]="true" header="{{ 'system.dialog.subscriptions.add-plan.heading' | translate }}" [(visible)]="showAddSubscriptionPlanPrompt">
    <div [formGroup]="addSubscriptionPlanForm" class="add-plan">
        <p-dropdown
            [dropdownIcon]="'ta ta-chevron-down'"
            formControlName="id"
            [options]="(subscriptionService.homeWorkspaceSubscriptionProductList$ | async)!"
            optionLabel="name"
            optionValue="id"
            placeholder="{{ 'system.form.dropdown-placeholder' | translate }}"
            appendTo="body"
        ></p-dropdown>
        <p-button (click)="onAddPlanConfirm()" label="{{ 'system.dialog.subscriptions.add-plan.confirm-button' | translate }}"></p-button>
    </div>
</p-dialog>
