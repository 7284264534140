import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '@ta/app/shared/services/toast.service';
import { ApiError, BusinessRuleError, translateKeysApiHttpResponse } from '@ta/app/shared/models/api-response.model';
import { ToastSeverity } from '@ta/app/shared/models/toast.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';

/**
 * Token that allows specific APIs to disable this interceptor
 */
export const IS_ERROR_HANDLER_DISABLED = new HttpContextToken<boolean>(() => false);

/**
 * Intercepts API responses to globally handle API errors
 */
@Injectable({
    providedIn: 'root'
})
export class ApiErrorInterceptor implements HttpInterceptor {
    /**
     * Translation keys
     */
    private readonly TRANSLATION_KEY_ERROR = 'system.toast.error.summary';

    /**
     * Store a local reference to the translate service
     */
    private _translateService!: TranslateService;

    constructor(private readonly _injector: Injector, private readonly _toastService: ToastService, private readonly _linkedFormService: LinkedFormService) {}

    /**
     * Intercept HTTP responses (middleware)
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // If the error handler is explicitly disabled, pass to the next middleware
        if (req.context.get(IS_ERROR_HANDLER_DISABLED) === true) {
            return next.handle(req);
        }

        // Here we load the TranslateService from the injector directly, when we need it.
        // This avoids a circular dependency between HttpClientModule and TranslateService.
        try {
            this._translateService = this._injector.get(TranslateService);
            return next.handle(req).pipe(
                // Catch errors, display toasts
                catchError((error: any) => {
                    // Re-Enable previously disabled buttons if there is an error.
                    this._linkedFormService.setFormElementsEnabled();

                    this.createToasts(error);
                    return throwError(error);
                })
            );
        } catch {
            // If the TranslationService fails to load, just forward the request to the next middleware
            return next.handle(req);
        }
    }

    /**
     * Create toasts from BusinessRuleErrors
     */
    createToasts(httpError: HttpErrorResponse): void {
        // Map error response to internal API error object
        // Use API defined errors first, fall back to generic errors based on Http response codes
        // tslint:disable-next-line: max-line-length
        const mappedErrors: Array<BusinessRuleError> = (httpError?.error as ApiError)?.errors
            ? (httpError?.error as ApiError).errors
            : [{ key: 'Api Error', value: translateKeysApiHttpResponse.get(httpError.status as number) } as BusinessRuleError];
        // Translate error messages and display toasts
        mappedErrors.forEach((error: BusinessRuleError) =>
            this._translateService.get([this.TRANSLATION_KEY_ERROR, error.value]).subscribe((translated: any) =>
                this._toastService.addToast({
                    severity: ToastSeverity.ERROR,
                    summary: translated[this.TRANSLATION_KEY_ERROR],
                    detail: translated[error.value]
                })
            )
        );
    }
}
