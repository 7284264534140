<p-card class="card">
    <div class="card-content">
        <!-- Left -->
        <div class="left">
            <div class="left__heading">
                <span class="left__heading__label">{{ subscription.productName }}</span>
                <p-tag
                    value="{{ SubscriptionStatusLabel[subscription.statusId] | translate | uppercase }}"
                    [severity]="SubscriptionStatusSeverity[subscription.statusId]"
                    [class]="SubscriptionStatusSeverity[subscription.statusId]"
                ></p-tag>
            </div>
            <div class="left__date">
                <span class="left__date__label">{{ 'system.subscriptions.activated' | translate }}</span>
                <span class="left__date__date">{{ subscription.activatedDate | date: 'dd MMM YYYY' }}</span>
            </div>
            <div class="left__date" *ngIf="subscription.statusId === SubscriptionStatus.CANCELLED">
                <span class="left__date__label">{{ 'system.subscriptions.cancelled' | translate }}</span>
                <span class="left__date__date">{{ subscription.cancelledDate | date: 'dd MMM YYYY' }}</span>
            </div>
        </div>
        <!-- Center -->
        <div class="center">
            <div class="center__unit">
                <span class="center__unit__label">{{ 'system.subscriptions.total-units' | translate }}</span>
                <span class="center__unit__count">{{ subscription.allocatedTotalUnits }}</span>
            </div>
            <div class="center__unit">
                <span class="center__unit__label">{{ 'system.subscriptions.active-units' | translate }}</span>
                <span class="center__unit__count">{{ subscription.allocatedActiveUnits }}</span>
            </div>
            <div class="center__unit">
                <span class="center__unit__label">{{ 'system.subscriptions.storage' | translate }}</span>
                <span class="center__unit__count">{{ subscription.allocatedStorageBytes ? allocatedStorageString : '-' }}</span>
            </div>
        </div>
        <!-- Right -->
        <div class="right">
            <div class="right__heading">
                <!-- Cost -->
                <span class="right__heading__cost" [class.right__heading__cost--redacted]="restricted">{{ subscription.amount ? (subscription.amount / 100 | currency) : '-' }}</span>
                <span class="right__heading__frequency">/ {{ SubscriptionFrequencyLabel[subscription.frequencyTypeId] | translate }}</span>

                <!-- Menu -->
                <p-button
                    *ngIf="subscriptionMenu.length > 0 && !restricted"
                    icon="ta ta-more-vertical"
                    class="right__heading__menu-button"
                    styleClass="p-button-text p-button-plain p-button-md"
                    (onClick)="menu.toggle($event)"
                ></p-button>
                <p-menu class="right__heading__menu" #menu [popup]="true" [model]="subscriptionMenu" appendTo="body"></p-menu>
            </div>
            <div class="right__detail">
                <span class="right__detail__label">{{ 'system.subscriptions.next-payment' | translate }}</span>
                <span *ngIf="subscription.statusId === SubscriptionStatus.ACTIVE; else cancelled" class="right__detail__value" [class.right__detail__value--redacted]="restricted">{{
                    subscription.nextPaymentDueDate | date: 'dd MMM YYYY'
                }}</span>
                <ng-template #cancelled>
                    <span class="right__detail__value right__detail__value--cancelled" [class.right__detail__value--redacted]="restricted">{{ 'system.subscriptions.cancelled' | translate }}</span>
                </ng-template>
            </div>
            <div class="right__detail">
                <span class="right__detail__label">{{ 'system.subscriptions.paid-by' | translate }}</span>
                <span class="right__detail__value truncate" [class.right__detail__value--redacted]="restricted">{{ subscription.paidBy }}</span>
            </div>
        </div>
    </div>
</p-card>
