import { Component } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { AccountRoutes, BladeQueryParamValues } from '@ta/app/shared/models/routes.model';
import { BladeService } from '@ta/app/shared/services/blade.service';
import { UserService } from '@ta/app/shared/services/user.service';

@Component({
    selector: 'ta-navbar-top-menu-account',
    templateUrl: './navbar-top-menu-account.component.html',
    styleUrls: ['./navbar-top-menu-account.component.scss']
})
export class NavbarMenuAccountComponent {
    constructor(private readonly _bladeService: BladeService, private readonly _auth0Service: Auth0Service, readonly userService: UserService) {}

    /**
     * Handle account settings click
     */
    onSettingsClick(event: any): void {
        this._bladeService.setBladeAndMenu(null, BladeQueryParamValues.ACCOUNT, null, AccountRoutes.ACCOUNT);

        // Prevent the global click handler from closing the menu
        event.stopPropagation();
    }

    /**
     * Handle logout click
     */
    onLogoutClick(event: any): void {
        // Prevent the global click handler from closing the menu
        event.stopPropagation();
        // Log out
        this._auth0Service.logout({ returnTo: window.location.origin });
    }
}
