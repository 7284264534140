/**
 * form validation values
 */
export enum FormStatus {
    VALID = 'VALID',
    INVALID = 'INVALID',
    PENDING = 'PENDING',
    DISABLED = 'DISABLED'
}

/**
 * form state values
 */
export enum FormState {
    READ,
    EDIT
}

/**
 * form names
 */
export enum FormName {
    FINALISE_ACCOUNT = 'FINALISE_ACCOUNT',

    PEOPLE_HEADER = 'PEOPLE_HEADER',
    USER_DETAILS = 'USER_DETAILS',
    SITES_HEADER = 'SITES_HEADER',
    PERSON_DETAILS = 'PERSON_DETAILS',
    PERSON_TAGS = 'PERSON_TAGS',
    PERSON_RELATIONSHIPS = 'PERSON_RELATIONSHIPS',
    JOBS_TAGS = 'JOBS_TAGS',
    JOBS_HEADER = 'JOBS_HEADER',
    JOB_DETAILS = 'JOB_DETAILS',
    JOB_RELATIONSHIPS = 'JOB_RELATIONSHIPS',
    SITE_DETAILS = 'SITE_DETAILS',
    SITE_TAGS_FORM = 'SITE_TAGS_FORM',
    SITE_RELATIONSHIPS = 'SITE_RELATIONSHIPS',
    STANDARD_WORKSPACE_FORM = 'STANDARD_WORKSPACE_FORM',
    PREMIUM_WORKSPACE_REQUEST = 'PREMIUM_WORKSPACE_REQUEST',
    ADMIN_PROFILE = 'ADMIN_PROFILE',
    WORKSPACE_SETTINGS = 'WORKSPACE_SETTINGS',
    WORKSPACE_USER_PERMISSIONS = 'WORKSPACE_USER_PERMISSIONS',
    OBJECT_PERMISSIONS = 'OBJECT_PERMSSIONS',

    ASSESSMENT = 'ASSESSMENT',
    ASSESSMENT_HEADER = 'ASSESSMENT_HEADER',
    ASSESSMENT_DETAIL = 'ASSESSMENT_DETAIL',
    ASSESSMENT_CREATE_OUTCOME_SELECTION = 'ASSESSMENT_CREATE_OUTCOME_SELECTION',
    ASSESSMENT_DETAILS_EDIT_PERSON_DATA = 'ASSESSMENT_DETAILS_EDIT_PERSON_DATA',
    ASSESSMENT_DETAILS_EDIT_PERSON = 'ASSESSMENT_DETAILS_EDIT_PERSON',
    ASSESSMENT_DETAILS_EDIT_JOB_DATA = 'ASSESSMENT_DETAILS_EDIT_JOB_DATA',
    ASSESSMENT_DETAILS_EDIT_JOB = 'ASSESSMENT_DETAILS_EDIT_JOB',
    ASSESSMENT_TAGS_FORM = 'ASSESSMENT_TAGS_FORM',
    ASSESSMENT_CASE_ID_FORM = 'ASSESSMENT_CASE_ID_FORM',
    ASSESSMENT_COMMENT_FORM = 'ASSESSMENT_COMMENT_FORM',
    ASSESSMENT_EDIT_COMMENT_FORM = 'ASSESSMENT_EDIT_COMMENT_FORM',

    JOB_DEFINITION = 'JOB_DEFINITION',
    JOB_DEFINITION_DECLARATION_FORM = 'JOB_DEFINITION_DECLARATION_FORM',

    PERSON_MOBILITY_FORM = 'PERSON_MOBILITY_FORM',
    PERSON_MOBILITY_INITIAL_DECLARATION_FORM = 'PERSON_MOBILITY_INITIAL_DECLARATION_FORM',
    PERSON_MOBILITY_FINAL_DECLARATION_FORM = 'PERSON_MOBILITY_FINAL_DECLARATION_FORM',

    PERSON_CAPACITY_FORM = 'PERSON_CAPACITY_FORM',
    PERSON_CAPACITY_INITIAL_DECLARATION_FORM = 'PERSON_CAPACITY_INITIAL_DECLARATION_FORM',
    PERSON_CAPACITY_FINAL_DECLARATION_FORM = 'PERSON_CAPACITY_FINAL_DECLARATION_FORM',

    JOB_MOBILITY_MAIN_FORM = 'JOB_MOBILITY_MAIN_FORM',
    JOB_MOBILITY_REVIEW_FORM = 'JOB_MOBILITY_REVIEW_FORM',
    JOB_MOBILITY_WELCOME_FORM = 'JOB_MOBILITY_WELCOME_FORM',

    DATA_CONTROLLER_EMIAL = 'DATA_CONTROLLER_EMAIL'
}
