import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'ta-usage-bar',
    templateUrl: './usage-bar.component.html',
    styleUrls: ['./usage-bar.component.scss']
})
export class UsageBarComponent implements OnChanges {
    /**
     * The number of used Total Units (also used storage)
     */
    @Input()
    totalUsed!: number;

    /**
     * The cap of Total Units (also storage cap)
     */
    @Input()
    totalCap!: number;

    /**
     * The label to be displayed next to the total cap
     */
    @Input()
    totalCapLabel!: string;

    /**
     * The string representation of the total cap
     * Useful where units need to be displayed alongside the total cap (e.g. 10 GB)
     */
    @Input()
    totalCapString?: string;

    /**
     * The number of used Active Units
     */
    @Input()
    activeUsed = 0;

    /**
     * The cap of Active Units
     */
    @Input()
    activeCap = 0;

    /**
     * Whether the usage bar should display only total and used (simple = true)
     * or factor in the active/archived units as well (simple = false)
     */
    @Input()
    simple = true;

    /**
     * Derived values used to construct the bar graph.
     */
    // Percentage of used Total Units (also used storage) - Capped at 100%
    totalUsedPercentage!: number;
    // Percentage of used Total Units (also used storage) - Uncapped
    totalUsedPercentageUncapped!: number;
    // Total Cap percentage
    totalCapPercentage = 100;
    // Percentage of used Active Units - Capped at 100%
    activeUsedPercentage?: number;
    // Percentage of used Active Units - Uncapped
    activeUsedPercentageUncapped?: number;
    // Percentage of Active Units in Total Units
    activeCapPercentage?: number;
    // The width of the Active Cap bar, which is the space to the right of the active cap divider
    activeCapPercentageInverted?: number;
    // Whether the active or total caps are exceeded
    isTotalCapExceeded = false;
    isActiveCapExceeded = false;

    constructor() {}

    ngOnChanges(): void {
        // Handle the case where there is no subscription allocation (total cap = 0)
        // The calculations in the else statement won't work due to divide-by-zero errors
        if (this.totalCap <= 0) {
            // Set the bars to 100%, as there are no available units
            this.totalUsedPercentage = 100;
            this.activeUsedPercentage = 100;
            this.activeCapPercentage = 100;
            this.activeCapPercentageInverted = 0;
            // Handle the case where there are used units, but no allocated units (subscription may have expired)
            // The bar should be red, so uncapped percentage must exceed 100
            this.totalUsedPercentageUncapped = this.totalUsed > 0 ? 101 : 100;
            this.activeUsedPercentageUncapped = this.activeUsed > 0 ? 101 : 100;
        } else {
            // Calculate actual, uncapped usage percentages (may exceed 100%)
            this.activeUsedPercentageUncapped = (this.activeUsed / this.totalCap) * 100;
            this.totalUsedPercentageUncapped = (this.totalUsed / this.totalCap) * 100;
            // Cap the Total and Active usage bars so they don't exceed to the total bar length
            this.totalUsedPercentage = Math.min(this.totalUsedPercentageUncapped, this.totalCapPercentage);
            this.activeUsedPercentage = Math.min(this.activeUsedPercentageUncapped, this.totalCapPercentage);
            // Calculate active cap as a percentage of the total cap (for the active cap bar)
            this.activeCapPercentage = (this.activeCap / this.totalCap) * 100;
            this.activeCapPercentageInverted = 100 - this.activeCapPercentage;
        }

        // Set whether caps are exceeded
        this.isTotalCapExceeded = this.totalUsedPercentageUncapped! > this.totalCapPercentage!;
        this.isActiveCapExceeded = this.activeUsedPercentageUncapped! > this.activeCapPercentage!;
    }
}
