<div class="content-dropdown">
    <div class="content-dropdown__title" (click)="toggleExpanded()">
        <span>{{ title }}</span>
        <i class="ta ta-chevron-down" *ngIf="!expanded"></i>
        <i class="ta ta-chevron-up" *ngIf="expanded"></i>
    </div>
    <div *ngIf="!expanded" class="content-dropdown__subtitle" (click)="toggleExpanded()">
        <ng-container *ngFor="let subtitle of subtitles">
            <span>{{ subtitle }}</span>
        </ng-container>
    </div>
    <div class="content-dropdown" *ngIf="expanded"><ng-content></ng-content></div>
</div>
