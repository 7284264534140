import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QueryOptions } from '@ta/app/shared/models/query-options.model';
import { ValidateQueryOptions } from '@ta/app/shared/utils/validate-query-options';
import { FilterMetadata } from 'primeng/api';

@Component({
    selector: 'ta-search-autocomplete[filterField]',
    templateUrl: './search-autocomplete.component.html',
    styleUrls: ['./search-autocomplete.component.scss']
})
export class SearchAutoCompleteComponent<T> implements OnInit {
    @Input()
    filteredItems = new Array<T>();

    @Input()
    filterField!: string;

    @Input()
    placeholder = 'system.form.global-search-placeholder';

    @Output()
    filter: EventEmitter<QueryOptions> = new EventEmitter();

    @Output()
    selectItem: EventEmitter<T> = new EventEmitter();

    selectedItem?: T;

    /**
     * The general filter for name
     */
    meta: FilterMetadata = {
        matchMode: 'contains',
        operator: 'and',
        value: ''
    };

    /**
     * The filters
     */
    filters: { [s: string]: Array<FilterMetadata> } = {};

    /**
     * The query options to filter people/jobs by for display in autocompletes
     */
    options: QueryOptions = {
        first: 0,
        rows: 10,
        filters: this.filters
    };

    constructor() {}

    ngOnInit(): void {}

    private _setSelectedItem(selected: T | undefined): void {
        this.selectItem.emit(selected);
    }

    /**
     * Handles the emmitted keyUp event for the autocomplete field.
     * @param event Browser event provided by the autocomplete field
     */
    handleKeyUp(event: any): void {
        if (!!event.key && event.key === 'Enter') {
            if (!!this.filteredItems[0]) {
                this._setSelectedItem(this.filteredItems[0]);
                this.filteredItems = [];
            }
        }
    }

    /**
     * Fliters the list displayed in the autocomplete dropdown
     * @param event The filter event provided by the autocomplete field
     */
    filterItems(event: any): void {
        this.meta.value = event.query;

        // make sure to include custom filter
        const options = ValidateQueryOptions(this.options, [], { [this.filterField]: this.meta });
        this.filter.emit(options);
    }

    onSelectItem(): void {
        if (!!this.filteredItems[0]) {
            this._setSelectedItem(this.selectedItem);
            this.selectedItem = undefined;
        }
    }
}
