import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { Country } from '@ta/app/shared/models/country.model';
import { CountryService } from '@ta/app/shared/services/country.service';
import { User } from '@ta/app/shared/models/user.model';
import { UserService } from '@ta/app/shared/services/user.service';
import { FormName } from '@ta/app/shared/models/form.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';

const DEFAULT_COUNTRY_CODE = 13; // Australia

@Component({
    selector: 'ta-auth-finalise-account',
    templateUrl: './auth-finalise-account.component.html',
    styleUrls: ['./auth-finalise-account.component.scss']
})
export class AuthFinaliseAccountComponent implements OnInit, OnDestroy {
    countries: Country[] = [];
    finaliseAccountForm: FormGroup = new FormGroup({});
    finaliseAccountFormName: FormName = FormName.FINALISE_ACCOUNT;
    user?: User;

    /**
     * Subject to end subscriptions when the user has completed the finalise account flow
     */
    hasCompletedFlow: Subject<boolean> = new Subject<boolean>();

    constructor(
        private readonly _userService: UserService,
        private readonly _auth0Service: Auth0Service,
        private readonly _countryService: CountryService,
        public _linkedFormService: LinkedFormService,
        private readonly router: Router
    ) {}

    ngOnInit(): void {
        // Create a new form so we can disable the Go button if the form is invalid
        this.finaliseAccountForm = new FormGroup({
            name: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
            country: new FormControl(DEFAULT_COUNTRY_CODE, Validators.required)
        });

        this._linkedFormService.addForm(this.finaliseAccountForm, this.finaliseAccountFormName);

        // Get a list of countries from the API and use them to populate our country drop down
        // (This could be moved to a resolver in future if need be...)
        this._countryService
            .getCountries()
            .pipe(take(1))
            .subscribe((countries) => {
                this.countries = countries;
            });

        // Listen for updated on the user stream
        this._userService.user$.pipe(takeUntil(this.hasCompletedFlow)).subscribe((user) => {
            // Orchestrate the flow
            if (!!user && user?.isUserFinalised) {
                // Finalise the flow
                this.handleCompleteFinaliseAccount();
            } else {
                // The user has either not been created (self-register flow)
                // Or not been finalised (invitation flow)
                this.user = user;
            }
        });
    }

    ngOnDestroy(): void {
        this._linkedFormService.removeForm(this.finaliseAccountFormName);
        // End this all subscriptions
        this.hasCompletedFlow.next(true);

        this._linkedFormService.setFormElementsEnabled();
    }

    /**
     * Handle account settings click
     */
    onLogoutClick(): void {
        this._auth0Service.logout({ returnTo: window.location.origin });
    }

    // Create the user record
    createUser(): void {
        // First, disable the submit button
        this._linkedFormService.setFormElementsDisabled();

        // If the form is invalid, return
        if (!this._linkedFormService.isValid(this.finaliseAccountFormName)) {
            this.finaliseAccountForm.get('name')?.markAsDirty();
            this.finaliseAccountForm.get('country')?.markAsDirty();
            this._linkedFormService.setFormElementsEnabled();
            return;
        }

        // Create user pushed the updated user onto the user observable, which will cause the subscription above to run again
        this._userService
            .finaliseUser(this.finaliseAccountForm.get('name')?.value ?? '', this.finaliseAccountForm.get('country')?.value ?? 0)
            .pipe(take(1))
            .subscribe({
                // If this errors, run through the guards again, as it's likely the user has already been created
                error: (error) => this.handleCompleteFinaliseAccount()
            });
    }

    /**
     * Call this function after a user account has been finalised
     */
    handleCompleteFinaliseAccount(): void {
        // This is the exit point of the flow
        // End this all subscriptions (to prevent infinite loops with the Workspace Guard)
        this.hasCompletedFlow.next(true);
        // The user is created and finalised
        // Then route them to the root of the app.
        // Let the workspace guard send them to the correct destination.
        this.router.navigate(['/']);
    }

    /**
     * Get text for error message if the Full Name given by the user is too long. Cannot use the getErrors() function in linkedFormService as this service is not part
     * @returns One or more errors, as a string
     */
    getErrors = (formControlName: string, friendlyControlName: string): string => this._linkedFormService.getErrors(this.finaliseAccountFormName, formControlName, friendlyControlName);
}
