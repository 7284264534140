export enum RelationshipType {
    PERSON_CLIENT,
    PERSON_EMPLOYEE,
    PERSON_ALL,
    JOB_POSITION,
    JOB_TASK,
    JOB_ALL,
    SITE_WORKPLACE,
    SITE_SPECIFIC_LOCATION,
    SITE_ALL
}
