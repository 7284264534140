<div class="menu-item menu-item__user">
    <i class="menu-item__user__icon ta-person-circle"></i>
    <div class="menu-item__user__details">
        <span class="menu-item__user__details__name truncate">{{ (this.userService.user$ | async)?.name }}</span>
        <span class="menu-item__user__details__email truncate">{{ (this.userService.user$ | async)?.email }}</span>
    </div>
</div>

<div class="menu-item menu-item--link" (click)="onSettingsClick($event)">
    <i class="ta-settings menu-item__icon"></i>
    <span class="menu-item__label">{{ 'system.nav.settings' | translate }}</span>
</div>

<div class="menu-item menu-item--link" (click)="onLogoutClick($event)">
    <i class="menu-item__icon ta-sign-out"></i>
    <span class="menu-item__label">{{ 'system.nav.logout' | translate }}</span>
</div>
