/**
 * API Error model
 */
export interface ApiError {
    errors: Array<BusinessRuleError>;
    errorCode: ErrorCodes;
    errorData: any;
}

/**
 * Business Rule Error model
 */
export interface BusinessRuleError {
    key: string;
    value: string;
}

/**
 * HTTP status code of api responses
 */
export enum ApiHttpResponseCodes {
    UNKNOWN = 9999,
    SERVER_UNREACHABLE = 0,
    OK = 200,
    BAD_REQUEST = 400, // ValidationException
    UNAUTHORIZED = 401, // SecurityHandlerException
    NOT_FOUND = 404,
    UNPROCESSABLE = 422, // BusinessRuleException
    INTERNAL_SERVER_ERROR = 500,
    NOT_IMPLEMENTED = 501
}

/**
 * System error translate key prefix
 */
const PREFIX_SYSTEM_ERROR = 'system.error';

/**
 * translate keys for api responses
 */
export const translateKeysApiResponse: { [key: string]: string } = {
    UNKNOWN: `${PREFIX_SYSTEM_ERROR}.unknown`,
    SERVER_UNREACHABLE: `${PREFIX_SYSTEM_ERROR}.serverunreachable`,
    OK: `${PREFIX_SYSTEM_ERROR}.ok`,
    UNAUTHORIZED: `${PREFIX_SYSTEM_ERROR}.unauthorized`,
    NOT_FOUND: `${PREFIX_SYSTEM_ERROR}.not-found`,
    UNPROCESSABLE: `${PREFIX_SYSTEM_ERROR}.unprocessable`,
    INTERNAL_SERVER_ERROR: `${PREFIX_SYSTEM_ERROR}.internalserver`,
    NOT_IMPLEMENTED: `${PREFIX_SYSTEM_ERROR}.notimplemented`,
    TOKEN_EXPIRED: `${PREFIX_SYSTEM_ERROR}.tokenexpired`
};

/**
 * Translate keys mapped to http response code errors
 */
export const translateKeysApiHttpResponse: Map<ApiHttpResponseCodes, string> = new Map([
    [ApiHttpResponseCodes.UNKNOWN, translateKeysApiResponse.UNKNOWN],
    [ApiHttpResponseCodes.SERVER_UNREACHABLE, translateKeysApiResponse.UNKNOWN],
    [ApiHttpResponseCodes.OK, translateKeysApiResponse.OK],
    [ApiHttpResponseCodes.UNAUTHORIZED, translateKeysApiResponse.UNAUTHORIZED],
    [ApiHttpResponseCodes.NOT_FOUND, translateKeysApiResponse.NOT_FOUND],
    [ApiHttpResponseCodes.UNPROCESSABLE, translateKeysApiResponse.UNPROCESSABLE],
    [ApiHttpResponseCodes.INTERNAL_SERVER_ERROR, translateKeysApiResponse.INTERNAL_SERVER_ERROR],
    [ApiHttpResponseCodes.NOT_IMPLEMENTED, translateKeysApiResponse.NOT_IMPLEMENTED]
]);

/**
 * Custom error codes for specific app states
 */
export enum ErrorCodes {
    GENERAL_ERROR = 100,
    DATA_INTEGRITY_ERROR = 200,
    CLIENT_EMAIL_VERIFIED_GUARD_GET_USER_FAILED_AUTH0 = 301,
    CLIENT_EMAIL_NOT_VERIFIED_GUARD_GET_USER_FAILED_AUTH0 = 302,
    CLIENT_USER_FINALISED_GUARD_CREATE_USER_FAILED_API = 303,
    CLIENT_USER_FINALISED_GUARD_REGISTER_AUTHPROVIDERID_FAILED_API = 304,
    CLIENT_USER_NOT_FINALISED_GUARD_CREATE_USER_FAILED_API = 305,
    CLIENT_USER_NOT_FINALISED_GUARD_REGISTER_AUTHPROVIDERID_FAILED_API = 306,
    QUESTION_MODULE_PESSIMISTIC_SAVE_FAILURE = 1001,
    QUESTION_MODULE_CLOSING_DECLARATIONS_PRESENT = 1002,
    INVITE_FLOW_USER_CREATED_WITHOUT_WORKSPACE_ACCESS = 2001,
    INVITE_FLOW_USER_ALREADY_FINALISED = 2002,
    INVITE_FLOW_INVITATION_HAS_EXPIRED = 2003,
    INVITE_FLOW_INVITATION_NOT_VALID = 2004,
    INVITE_FLOW_INVITATION_ISSUED_TO_DIFFERENT_EMAIL = 2005,
    USER_DELETE_IS_ADMIN_OF_WORKSPACES = 3001,
    COPY_ASSESSMENT_OUTCOME_CAN_BE_MIGRATED = 4001,
    COPY_ASSESSMENT_OUTCOME_MUST_BE_MIGRATED = 4002
}
