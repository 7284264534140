import { Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'ta-assessment-detail-activity-segment-item',
    templateUrl: './assessment-detail-activity-segment-item.component.html',
    styleUrls: ['./assessment-detail-activity-segment-item.component.scss']
})
export class AssessmentDetailActivitySegmentItemComponent {
    @Input() icons?: TemplateRef<any>;

    @Input() bottom?: TemplateRef<any>;

    @Input() leftIcon = 'ta ta-person-circle';

    @Input() itemDate!: Date;

    @Input() edited = false;

    constructor() {}
}
