import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormState, FormStatus } from '@ta/app/shared/models/form.model';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * Service used to centralise the management of forms.
 * Allows forms to be managed across multiple components, and to centrally manage form state.
 */
@Injectable({
    providedIn: 'root'
})
export class LinkedFormService {
    /**
     * Stores forms
     */
    private formArray: FormArray = new FormArray([]);

    /**
     * Key map of formarray indexes and form names.
     */
    private formArrayKeyMap: { [key: string]: number } = {};

    /**
     * Reversed version of keymap -> handy for when we need the keymap form names.
     */
    private formArrayReversedKeyMap: { [key: number]: string } = {};

    /**
     * Tracks any linked forms that are presented in a navigation blade.
     */
    private bladeFormArray: Array<string> = [];

    /**
     * Observables to notify blades of navigation away from a dirty blade.
     */
    private _navigateFromDirtyBlade = new Subject<[string | null, string | null]>();
    readonly navigateFromDirtyBlade$ = this._navigateFromDirtyBlade.asObservable();

    /**
     * Observables to notify app of navigation away from a dirty component.
     */
    private _navigateFromDirtyComponent = new Subject<string | null>();
    readonly navigateFromDirtyComponent$ = this._navigateFromDirtyComponent.asObservable();

    /**
     * Tracks form state (read-mode, edit-mode etc)
     */
    private _formState: BehaviorSubject<FormState> = new BehaviorSubject(FormState.READ as FormState);
    readonly formState$ = this._formState.asObservable();

    /**
     * Tracks whether the form header's Save/Update button and Cancel button should currently be disabled (for example when a save/update is being performed)
     */
    private _disabledState = new BehaviorSubject<boolean>(false);
    readonly disabledState$ = this._disabledState.asObservable();

    constructor(private _translateService: TranslateService) {}

    /**
     * Add a form to the linked forms array.
     * @param formGroup Form to add to linked form service
     * @param key Globally unique form name
     * @param isBladeForm Whether the form is displayed in a navigation blade
     */
    addForm(formGroup: FormGroup, key: string, isBlade?: boolean): void {
        // Don't add a form if it already exist
        if (this.formArrayKeyMap[key] !== undefined) return;
        // Track the key
        if (key) {
            this.formArrayKeyMap[key] = this.formArray.length;
            this.formArrayReversedKeyMap[this.formArray.length] = key;
        }
        this.formArray.push(formGroup);
        // Track forms in navigation blades
        if (isBlade) this.bladeFormArray.push(key);
    }

    /**
     * Remove a form to the linked forms array.
     */
    removeForm(key: string): void {
        // Don't remove a form if it doesn't exist
        if (this.formArrayKeyMap[key] === undefined) return;
        // Remove form from formArray
        this.formArray.controls.splice(this.formArrayKeyMap[key], 1);
        // Adjust index of remaining controls
        Object.keys(this.formArrayKeyMap).forEach((k: string) => {
            if (this.formArrayKeyMap[k] > this.formArrayKeyMap[key]) {
                this.formArrayKeyMap[k]--;
            }
        });
        // delete the key for the removed form
        // tslint:disable-next-line: no-dynamic-delete
        delete this.formArrayKeyMap[key];
        // rejig formArray reversed keymaps (easier to just copy whatever logic is occurring above)
        this.formArrayReversedKeyMap = this.reverseKeyMap(this.formArrayKeyMap);
        // Remove form from navigation blade tracking
        this.bladeFormArray = this.bladeFormArray.filter((k) => k !== key);
    }

    /**
     * Remove all forms from the linked forms array.
     */
    removeAllForms(): void {
        // reset all tracking objects
        this.formArray = new FormArray([]);
        this.formArrayKeyMap = {};
        this.formArrayReversedKeyMap = {};
        this.bladeFormArray = [];
    }

    /**
     * Check if all forms in array are valid.
     * Optionally, check if an individual form is valid using its provided key.
     */
    isValid(key?: string): boolean {
        if (!key) return this.formArray.valid;

        if (this.formArrayKeyMap.hasOwnProperty(key) && this.formArray.at(this.formArrayKeyMap[key])) {
            return this.formArray.at(this.formArrayKeyMap[key]).valid;
        } else {
            return true;
        }
    }

    /**
     * Check if any forms in array are dirty.
     * Optionally, check if an individual form is dirty using its provided key.
     */
    isDirty(key?: string): boolean {
        if (key && this.formArray.at(this.formArrayKeyMap[key])) return this.formArray.at(this.formArrayKeyMap[key]).dirty;
        else return !!this.formArray.controls.find((form) => form.dirty);
    }

    /**
     * Check if any navigation blade forms are dirty
     */
    isBladeDirty(): boolean {
        return !this.bladeFormArray.findIndex((key) => this.isDirty(key));
    }

    /**
     * Mark all forms in the array as clean.
     * Optionally, mark an individual form as clean using its provided key.
     */
    markClean(key?: string): void {
        if (key && this.formArray.at(this.formArrayKeyMap[key])) this.formArray.at(this.formArrayKeyMap[key]).markAsPristine();
        else this.formArray.markAsPristine();
    }

    /**
     * Mark all forms in the array as dirty.
     * Optionally, mark an individual form as dirty using its provided key.
     */
    markDirty(key?: string): void {
        if (key && this.formArray.at(this.formArrayKeyMap[key])) this.formArray.at(this.formArrayKeyMap[key]).markAsDirty();
        else this.formArray.markAsDirty();
    }

    /**
     * Get an individual form using its provided key.
     */
    getForm(key: string): FormGroup {
        if (key && this.formArray.at(this.formArrayKeyMap[key])) {
            return this.formArray.at(this.formArrayKeyMap[key]) as FormGroup;
        }
        // TODO: Centralise error handling
        throw new Error();
    }

    /**
     * Check the existence of an individual form using its provided key.
     */
    hasForm(key: string): boolean {
        if (key && this.formArray.at(this.formArrayKeyMap[key])) {
            return true;
        }
        return false;
    }

    /**
     * Get the value of all forms as a single object.
     * Optionally, get an individual form using its provided key.
     */
    getValue(key?: string): any {
        if (key && this.formArray.at(this.formArrayKeyMap[key])) return this.formArray.at(this.formArrayKeyMap[key]).value;
        else if (this.formArray.getRawValue().length) return this.formArray.getRawValue().reduce((previousForm: FormGroup, currentForm: FormGroup) => ({ ...previousForm, ...currentForm }));
    }

    /**
     * Reset all forms validators etc.
     * Optionally, reset an individual form using its provided key.
     */
    reset(key?: string): void {
        if (key && this.formArray.at(this.formArrayKeyMap[key])) this.formArray.at(this.formArrayKeyMap[key]).reset();
        else this.formArray.reset();
    }

    /**
     * Notify blades that have dirty forms.
     * Allows each blade to handle navigation away from a dirty form.
     */
    handleNavigateFromDirtyBlade(navigateTo: string | null): void {
        this.bladeFormArray.forEach((key) => {
            if (this.isDirty(key)) this._navigateFromDirtyBlade.next([navigateTo, key]);
        });
    }

    /**
     * Notify blades that have dirty forms.
     * Allows each blade to handle navigation away from a dirty form.
     */
    handleDirtyComponent(navigateTo: string | null): void {
        this._navigateFromDirtyComponent.next(navigateTo);
    }

    /**
     * Hiding some gross logic to get a reversed keymap.
     */
    private reverseKeyMap(keyMap: { [key: string]: number } = {}): { [key: number]: string } {
        const keyMapKeys: Array<{ [key: number]: string }> = Object.keys(keyMap).map((keyMapKey: string) => ({ [keyMap[keyMapKey]]: keyMapKey }));
        const keyMapReversed: { [key: number]: string } = keyMapKeys.reduce((prevValue: any, currentValue: any) => ({ ...prevValue, ...currentValue }), {});
        return keyMapReversed;
    }

    /**
     * Set form state
     */
    setFormState(formState: FormState): void {
        this._formState.next(formState);
    }

    /**
     * Gets the previous value of form state.
     * @returns the last value of form state
     */
    getFormStateLastValue(): FormState {
        return this._formState.getValue();
    }

    /**
     * Set the Save/Update and Cancel header buttons disabled (ie emit true to the disabledState$ observable)
     */
    setFormElementsDisabled(): void {
        this._disabledState.next(true);
    }

    /**
     * Set the Save/Update and Cancel header buttons enabled (ie emit false to the disabledState$ observable)
     */
    setFormElementsEnabled(): void {
        this._disabledState.next(false);
    }

    /**
     * Returns whether the header Save/Update and Cancel buttons are currently disabled (ie gets the last value emitted by the disabledState$
     * observable)
     */
    getDisabledStateLastValue(): boolean {
        return this._disabledState.getValue();
    }

    /**
     * get form array
     */
    getFormArray(): FormArray {
        return this.formArray;
    }

    /**
     * get form array validation status
     */
    getFormArrayStatuses<T = { [key: string]: FormStatus }>(): T {
        // our form array status object
        let formArrayStatuses: { [key: string]: FormStatus } = {};
        // use the keymap keys to form an object with current form statuses
        Object.keys(this.formArrayKeyMap).forEach((formArrayKey: string) => {
            formArrayStatuses = { ...formArrayStatuses, [formArrayKey]: this.getForm(formArrayKey).status as FormStatus };
        });
        // bit annoying that we have generics.... however need this for proper typing of return object keys
        return formArrayStatuses as any;
    }

    /**
     * get form array dirty status
     */
    getFormArrayIsDirty<T = { [key: string]: boolean }>(): T {
        // our form array status object
        let formArrayDirty: { [key: string]: boolean } = {};
        // use the keymap keys to form an object with current form dirty status
        Object.keys(this.formArrayKeyMap).forEach((formArrayKey: string) => {
            formArrayDirty = { ...formArrayDirty, [formArrayKey]: this.getForm(formArrayKey).dirty };
        });
        // bit annoying that we have generics.... however need this for proper typing of return object keys
        return formArrayDirty as any;
    }

    /**
     * get form array keymap
     */
    getFormArrayKeyMap(): { [key: string]: number } {
        return this.formArrayKeyMap;
    }

    /**
     * get form array keymap
     */
    getFormArrayReversedKeyMap(): { [key: number]: string } {
        return this.formArrayReversedKeyMap;
    }

    /**
     * This function generates error messages to display in the UI for the common types of form nesting we have in TaskAlyser. If for some reason you have a form that does not follow the
     * common structure (Person Mobility for example) you can instead use getErrorsByFormControl and pass in a FormControl directly.
     * @param key Form name
     * @param field Form field name
     * @param friendlyFieldName User friendly formcontrol name
     * @param arrayName FormArray the field is stored within
     * @param arrayIndex Index within the FormArray the field is stored within
     * @returns Returns one or more errors as a string.
     */
    getErrors(key: string, field: string, friendlyFieldName: string, arrayName?: string, arrayIndex?: number): string {
        // Handle form not found
        if (!(key && this.formArray.at(this.formArrayKeyMap[key]))) {
            return '';
        }

        // Get errors from forms
        const errors: any =
            !!arrayName && !!arrayIndex
                ? (this.formArray.at(this.formArrayKeyMap[key]).get(arrayName) as FormGroup).controls[arrayIndex].get(field)?.errors
                : this.formArray.at(this.formArrayKeyMap[key]).get(field)?.errors;

        return errors ? this.generateErrorMessageString(errors, friendlyFieldName) : '';
    }

    /**
     * Given a particular FormControl, grabs its errors and displays them in a format suitable for display to the user.
     * @param formControl FormControl to grab errors from
     * @param friendlyFieldName Name of the field that has error/s to display to the user. For example "Please enter a <friendlyFieldName>"
     * @returns a string containing the error/s to display to the user
     */
    getErrorsByFormControl(formControl: AbstractControl | null, friendlyFieldName: string): string {
        if (!formControl) {
            return '';
        }

        return formControl.errors ? this.generateErrorMessageString(formControl.errors, friendlyFieldName) : '';
    }

    /**
     * Common behaviour between getErrors() and getErrorsByFormControl(). Takes a ValidationErrors object and for each error concatenates its
     * corresponding user-friendly error message
     * @param errors Errors to convert to a format suitable for display to the user
     * @param friendlyFieldName Name of the field that has error/s to display to the user. For example "Please enter a <friendlyFieldName>"
     * @returns a string containing the error/s to display to the user
     */
    generateErrorMessageString(errors: ValidationErrors, friendlyFieldName: string): string {
        let errorMessage = '';

        Object.keys(errors).forEach((errorKey: string, index: number) => {
            // If there are multiple errors, separate them with a space.
            if (index > 0) {
                errorMessage += ' ';
            }

            if (errorKey === 'required') {
                this._translateService.get('system.error.required', { fieldName: friendlyFieldName }).subscribe((value) => {
                    errorMessage += value;
                });
            }
            if (errorKey === 'maxlength') {
                this._translateService.get('system.error.maxlength', { fieldName: friendlyFieldName }).subscribe((value) => {
                    errorMessage += value;
                });
            }
            if (errorKey === 'pattern') {
                this._translateService.get('system.error.pattern', { fieldName: friendlyFieldName }).subscribe((value) => {
                    errorMessage += value;
                });
            }
            if (errorKey === 'futuredate') {
                this._translateService.get('system.error.futuredate', { fieldName: friendlyFieldName }).subscribe((value) => {
                    errorMessage += value;
                });
            }
        });

        return errorMessage;
    }
}
