import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormName } from '@ta/app/shared/models/form.model';
import { FormArrayAction } from '@ta/app/shared/models/form-array-action.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';
import { TagService } from '@ta/app/shared/services/tag.service';
import { StructuredTagSet } from '@ta/app/shared/models/tag/structured-tag-set.model';
import { FormArrayLabel } from '@ta/app/shared/models/form-array-label.model';
import { Tag } from '@ta/app/shared/models/tag/tag.model';

@Component({
    selector: 'ta-tags-segment',
    templateUrl: './tags-segment.component.html',
    styleUrls: ['./tags-segment.component.scss']
})
export class TagsSegmentComponent implements OnInit, OnDestroy {
    TagActions: any = FormArrayAction;
    FormArrayLabel: any = FormArrayLabel;

    /**
     * Whether the page is in edit mode or not
     */
    @Input()
    edit = false;

    /**
     * The form of tags
     */
    @Input()
    tagsForm!: FormGroup;

    /**
     * The name of the tags form for the linked form service
     */
    @Input()
    tagsFormName!: FormName;

    /**
     * A list of sets of tags for displaying
     */
    @Input()
    setsOfTags?: StructuredTagSet[];

    /**
     * Whether or not the person is active
     */
    @Input()
    active = true;

    /**
     * Whether the compnonent is expanded or not
     */
    expanded = false;

    /**
     * The currently selected tag in the autocomplete field
     */
    selectedTag?: Tag;

    /**
     * The list of tags in the autocomplete dropdown
     */
    filteredTags = new Array<Tag>();

    tags!: FormArray;

    private _subscription?: Subscription;

    constructor(private tagService: TagService, private linkedFormService: LinkedFormService) {}

    ngOnInit(): void {
        this.tags = this.tagsForm.get('tags') as FormArray;
    }

    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    /**
     * Toggles the expansion of the component
     */
    onToggleDetails(): void {
        this.expanded = !this.expanded;
    }

    /**
     * Removes a tag from the tag list
     */
    onRemoveTag(tagId: number): void {
        this.tags.controls.forEach((tagAction: AbstractControl) => {
            if (tagAction.get(FormArrayLabel.TAG)?.value.id === tagId) {
                tagAction.get(FormArrayLabel.ACTION)?.setValue(this.TagActions.DELETE);
            }
        });

        this.tagsForm.markAsTouched();
        this.tagsForm.markAsDirty();
    }

    /**
     * Adds a tag to the tag list
     */
    onAddTag(): void {
        const tag = this.tags.controls.find((t: AbstractControl) => t.get(FormArrayLabel.TAG)?.value.id === this.selectedTag?.id);

        if (!!tag) {
            tag.get(FormArrayLabel.ACTION)?.setValue(this.TagActions.ADD);
        } else {
            (this.tagsForm.get('tags') as FormArray).push(
                new FormGroup({
                    tag: new FormControl(this.selectedTag),
                    action: new FormControl(this.TagActions.ADD)
                })
            );
        }

        this.tagsForm.markAsTouched();
        this.tagsForm.markAsDirty();
        this.selectedTag = undefined;
    }

    /**
     * Handles the emmitted keyUp event for the autocomplete field.
     * @param event Browser event provided by the autocomplete field
     */
    handleKeyUp(event: any): void {
        if (!!event.key && event.key === 'Enter') {
            if (!!this.filteredTags[0]) {
                this.selectedTag = this.filteredTags[0];
                this.onAddTag();
                this.filteredTags = [];
            }
        }
    }

    /**
     * Fliters the list displayed in the autocomplete dropdown
     * @param event The filter event provided by the autocomplete field
     */
    filter(event: any): void {
        this._subscription = this.tagService.searchTags(event.query).subscribe((result) => {
            if (!result) {
                return;
            }

            const tags: Map<number, Tag> = new Map();

            (this.tags.value as Array<any>)
                .map((fa) => fa.tag as Tag)
                .forEach((tag) => {
                    tags.set(tag.id, tag);
                });

            this.filteredTags = result?.filter((t) => !tags.get(t.id));
        });
    }

    /**
     * Checks whether the currently selected tag exists in the list of initial tags
     * @returns Whether or not the tag exists
     */
    tagExists(): boolean {
        if (!this.selectedTag) {
            return false;
        }
        return this.filteredTags?.includes(this.selectedTag) ?? false;
    }

    /**
     * Get errors relating to a specific form control
     * @returns One or more errors, as a string
     */
    getErrors = (formControName: string): string => this.linkedFormService.getErrors(this.tagsFormName, formControName, formControName);
}
