import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { interval, Subscription, switchMap } from 'rxjs';
import { isDevMode } from '@angular/core';
import { EnvironmentService } from './shared/services/environment.service';

@Component({
    selector: 'ta-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    /**
     * State of the global loading spinner
     */
    isLoading = true;

    /**
     * Automatically check over a set interval for an expired access token.
     */
    refreshAccessToken!: Subscription;

    constructor(private router: Router, private readonly _auth0Service: Auth0Service, private readonly _envService: EnvironmentService) {
        // The first time the app successfully navigates to a route, hide the spinner
        const subscription = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.isLoading = false;
                subscription.unsubscribe();
            }
        });
    }

    ngOnInit(): void {
        this.refreshAccessToken = interval(this._envService.config.ACCESS_TOKEN_POLLING_INTERVAL_MS)
            .pipe(switchMap((_) => this._auth0Service.getAccessTokenSilently()))
            .subscribe({
                error: (err) => {
                    if (isDevMode()) {
                        console.error('An error occurred when attempting to automatically refresh the access token via automatic polling. Full error:');
                        console.error(err);
                    }
                },
                next: () => {
                    if (isDevMode()) {
                        console.log('Access token has been checked for expiry, and was refreshed if needed via automatic polling.');
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.refreshAccessToken.unsubscribe();
    }
}
