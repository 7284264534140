import { Injectable } from '@angular/core';
import { FeatureFlag } from '@ta/app/shared-modules/toggle/models/feature-flags.model';
import { Toggle } from '@ta/app/shared-modules/toggle/models/toggle.model';
import { ObjectPermission } from '@ta/app/shared/models/permissions/object-permissions.model';
import { hasPermission, PermissionLevel } from '@ta/app/shared/models/permissions/permission-access-levels.model';
import { SubscriptionService } from '@ta/app/shared/services/subscription.service';
import { WorkspaceUserPermissionService } from '@ta/app/shared/services/workspace-user-permission.service';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';
import { FeatureFlagService } from './feature-flag.service';

// file deepcode ignore DuplicateCaseBody: To be explicit for each toggle case
@Injectable({
    providedIn: 'root'
})
export class ToggleService {
    constructor(
        private readonly _featureFlagService: FeatureFlagService,
        private readonly _subscriptionService: SubscriptionService,
        private readonly _workspaceService: WorkspaceService,
        private readonly _workspaceUserPermissionService: WorkspaceUserPermissionService
    ) {}

    isOn(toggle?: Toggle, permissionLevel?: ObjectPermission): boolean {
        // Toggle behaviour is enabled by default
        // If no toggle is provided for whatever reason, enable the toggle
        if (!toggle) return true;

        switch (toggle) {
            case Toggle.ADMIN: {
                return this._workspaceService.workspace?.userId == null && this._hasUserPermission(PermissionLevel.Administer);
            }
            case Toggle.POWER_USER: {
                return this._workspaceService.workspace?.userId == null && this._hasUserPermission(PermissionLevel.PowerUser);
            }
            case Toggle.ASSESSMENT_COMMENT_SUPRESS: {
                return this._hasUserPermission(PermissionLevel.PowerUser);
            }
            case Toggle.ASSESSMENT_COMPLETE: {
                return this._hasUserPermission(PermissionLevel.PowerUser);
            }
            case Toggle.ASSESSMENT_EDIT: {
                return this._hasUserPermission(PermissionLevel.EditAssessments) || this._canUserEdit(permissionLevel);
            }
            case Toggle.ASSESSMENT_DUPLICATE: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_ASSESSMENT_DUPLICATE);
            }
            case Toggle.ASSESSMENT_FILE_ADD: {
                return this._hasUserPermission(PermissionLevel.EditAssessments) || this._canUserEdit(permissionLevel);
            }
            case Toggle.ASSESSMENT_FILE_DELETE: {
                return this._hasUserPermission(PermissionLevel.EditAssessments) || this._canUserEdit(permissionLevel);
            }
            case Toggle.ASSESSMENT_FILE_DOWNLOAD: {
                return this._hasUserPermission(PermissionLevel.PowerUser);
            }
            case Toggle.ASSESSMENT_FILE_RENAME: {
                return this._hasUserPermission(PermissionLevel.EditAssessments) || this._canUserEdit(permissionLevel);
            }
            case Toggle.ASSESSMENT_PERMANENTLY_DELETE: {
                return this._hasUserPermission(PermissionLevel.PowerUser);
            }
            case Toggle.ASSESSMENT_SHARE: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_ASSESSMENT_SHARE);
            }
            case Toggle.COMPARISONS: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_COMPARISONS);
            }
            case Toggle.COMPLETED_ASSESSMENT_EDIT: {
                return this._hasUserPermission(PermissionLevel.PowerUser);
            }
            case Toggle.DASHBOARD_RECENT_ACTIVITY: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_DASHBOARD_RECENT_ACTIVITY);
            }
            case Toggle.DIRECTORIES: {
                return this.isOn(Toggle.JOBS) || this.isOn(Toggle.SITES) || this.isOn(Toggle.PEOPLE);
            }
            case Toggle.MY_DATA_DOWNLOAD: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_MY_DATA_DOWNLOAD);
            }
            case Toggle.FEATURE_ASSESSMENT_REPORT: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_ASSESSMENT_REPORT);
            }
            case Toggle.FEATURE_SUBSCRIPTION_MANAGEMENT: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_SUBSCRIPTION_MANAGEMENT);
            }
            case Toggle.HELP: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_HELP);
            }
            case Toggle.JOBS: {
                return !!this._workspaceService.workspace?.isJobsEnabled;
            }
            case Toggle.JOBS_PERMANENTLY_DELETE: {
                return this._hasUserPermission(PermissionLevel.PowerUser);
            }
            case Toggle.JOB_EDIT: {
                return this._hasUserPermission(PermissionLevel.EditJobs) || this._canUserEdit(permissionLevel);
            }
            case Toggle.NOTIFICATION: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_NOTIFICATIONS);
            }
            case Toggle.OBJECT_LEVEL_PERMISSION_EDIT: {
                return this._hasUserPermission(PermissionLevel.PowerUser);
            }
            case Toggle.PEOPLE: {
                return !!this._workspaceService.workspace?.isPeopleEnabled;
            }
            case Toggle.PEOPLE_PERMANENTLY_DELETE: {
                return this._hasUserPermission(PermissionLevel.PowerUser);
            }
            case Toggle.PERSON_ASSESSMENT: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_PERSON_ASSESSMENT);
            }
            case Toggle.PERSON_EDIT: {
                return this._hasUserPermission(PermissionLevel.EditPeople) || this._canUserEdit(permissionLevel);
            }
            case Toggle.REPORTING: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_REPORTING);
            }
            case Toggle.SEARCH: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_SEARCH);
            }
            case Toggle.SITES: {
                return !!this._workspaceService.workspace?.isSitesEnabled;
            }
            case Toggle.SITES_PERMANENTLY_DELETE: {
                return this._hasUserPermission(PermissionLevel.PowerUser);
            }
            case Toggle.SITE_EDIT: {
                return this._hasUserPermission(PermissionLevel.EditSites) || this._canUserEdit(permissionLevel);
            }
            case Toggle.SUBSCRIPTION_ASSESSMENT_COMPLETE: {
                return this._subscriptionService.subscriptionSummary!.remainingActiveUnits > 0;
            }
            case Toggle.SUBSCRIPTION_ASSESSMENT_CREATE: {
                return this._subscriptionService.subscriptionSummary!.remainingTotalUnits > 0;
            }
            case Toggle.SUBSCRIPTION_ASSESSMENT_FILE_UPLOAD: {
                return this._subscriptionService.subscriptionSummary!.remainingStorageBytes > 0;
            }
            case Toggle.SUBSCRIPTION_ASSESSMENT_RESTORE: {
                return this._subscriptionService.subscriptionSummary!.remainingActiveUnits > 0;
            }
            case Toggle.SUBSCRIPTIONS_MANAGE: {
                return this._hasUserPermission(PermissionLevel.Administer);
            }
            case Toggle.TAG_MANAGE: {
                return this._featureFlagService.isOn(FeatureFlag.FEATURE_TAG_MANAGEMENT);
            }
            case Toggle.USERS_MANAGE: {
                return this._hasUserPermission(PermissionLevel.PowerUser);
            }
            case Toggle.WORKSPACE_DELETE: {
                return this._hasUserPermission(PermissionLevel.Administer);
            }
            case Toggle.WORKSPACE_SETTINGS_MANAGE: {
                return this._hasUserPermission(PermissionLevel.Administer);
            }
            default:
                return true;
        }
    }

    private _hasUserPermission(permission?: PermissionLevel): boolean {
        return hasPermission(this._workspaceUserPermissionService.myWorkspaceUserPermissions, permission);
    }

    private _canUserEdit(objectPermission?: ObjectPermission): boolean {
        return !!objectPermission && (objectPermission === ObjectPermission.EDIT || objectPermission === ObjectPermission.READ_AND_EDIT);
    }

    private _canUserRead(objectPermission?: ObjectPermission): boolean {
        return !!objectPermission && (objectPermission === ObjectPermission.READ || objectPermission === ObjectPermission.READ_AND_EDIT);
    }
}
