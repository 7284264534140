import { AssessmentStatusFilters } from '@ta/app/shared/models/assessment/assessment-status-filters.model';

/**
 * ROUTES
 */
export enum AppRoutes {
    ROOT = '/',
    LOGIN = 'login',
    INVITE = 'invite',
    FINALISE = 'finalise-account',
    WORKSPACE = '',
    PRIVACY = 'privacy-and-data',
    TERMS = 'terms',
    TERMS_AND_POLICY_UPDATES = 'terms-and-policy-updates',
    VERIFY = 'verify-your-email',
    OOPS = 'oops'
}

export enum WorkspaceRoutes {
    ADMIN = 'admin',
    ASSESSMENTS = 'assessments',
    COMPARISONS = 'comparisons',
    DASHBOARD = 'dashboard',
    JOBS = 'jobs',
    PEOPLE = 'people',
    REPORTING = 'reporting',
    SITES = 'sites',
    OOPS = 'oops'
}

export enum AccountRoutes {
    ACCOUNT = 'account',
    MY_DATA = 'my-data',
    SUBSCRIPTIONS = 'subscriptions'
}

export enum AdminRoutes {
    PROFILE_SETTINGS = 'profile-settings',
    PERMISSIONS = 'permissions',
    BILLING = 'billing',
    TAG_MANAGEMENT = 'tag-management',
    ABOUT_YOUR_DATA = 'about-your-data'
}

export enum PeopleRoutes {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

export enum JobsRoutes {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

export enum JobRoutes {
    DETAILS = 'details',
    EVALUATION_HISTORY = 'evaluation-history',
    PERMISSIONS = 'permissions',
    AUDIT = 'audit',
    CREATE = 'new'
}

export enum PersonRoutes {
    ASSESSMENTS = 'assessments',
    GALLERY = 'gallery',
    INSIGHTS = 'insights',
    NOTES = 'notes',
    DETAILS = 'details',
    EVALUATION_HISTORY = 'evaluation-history',
    CREATE = 'new',
    AUDIT = 'audit',
    PERMISSIONS = 'permissions'
}

export enum SiteRoutes {
    DETAILS = 'details',
    AUDIT = 'audit',
    PERMISSIONS = 'permissions',
    CREATE = 'new'
}

export enum SitesRoutes {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

/**
 * Query Parameters
 */
export enum AppQueryParams {
    BLADE = 'blade',
    MENU = 'menu',
    TAB = 'tab',
    INSIGHT = 'insight',
    ASSESSMENT_TYPE = 'assessmentType',
    ASSESSMENT_SEARCH = 'assessmentSearch',
    ASSESSMENT_STATUS = 'assessmentStatus',
    ERROR = 'error'
}

export enum BladeQueryParamValues {
    HIDDEN = '',
    ACCOUNT = 'account',
    HELP = 'help',
    NOTIFICATIONS = 'notifications',
    SEARCH = 'search',
    WORKSPACE_SWITCHER = 'workspace-switcher'
}

export enum MenuQueryParamValues {
    ACCOUNT = 'account'
}

export enum TabQueryParamValues {
    ALL = 'all',
    FAVOURITES = 'favourites'
}

export enum InvitationParam {
    INVITATION_GUID = 'invitationGuid',
    WORKSPACE_GUID = 'workspaceGuid'
}

export enum AssessmentGalleryQueryParams {
    ACCESSING_FROM_WORKSPACE_GUID = 'accessingFromWorkspaceGuid',
    ACCESSING_FROM_GLOBAL_ASSESSMENT_ID = 'accessingFromGlobalAssessmentId'
}

export enum AssessmentsRoutes {
    RECEIVED = 'received',
    ACTIVE = 'active',
    ARCHIVED = 'archived'
}

export enum AssessmentRoutes {
    DETAILS = 'details',
    QUESTIONS = 'questions',
    INSIGHTS = 'insights',
    GALLERY = 'gallery',
    PERMISSIONS = 'permissions',
    FULL_REPORT = 'full-report'
}

export enum AssessmentQuestionsRoutes {
    QUESTION = 'question',
    NEW = 'new',
    SELECT = 'select',
    DETAIL = 'detail'
}

export enum AssessmentInsightsQueryParamValues {
    OVERVIEW = 'overview',

    PERSON_FUNCTIONAL_CAPACITY = 'person-functional-capacity',
    PERSON_FUNCTIONAL_MOBILITY = 'person-mobility-tests',
    PERSON_RANGE_OF_MOTION = 'person-range-of-motion',
    PERSON_CAPACITY_FORECAST = 'person-capacity-forecast',
    PERSON_RANGE_OF_MOTION_FORECAST = 'person-range-of-motion-forecast',

    PERSON_MOVEMENT_FREQUENCY = 'person-movement-frequency',
    PERSON_MOVEMENT_FREQUENCY_FORECAST = 'person-movement-frequency-forecast',

    JOB_MOVEMENT_EFFORT = 'job-movement-effort',
    JOB_MOVEMENT_FREQUENCY = 'job-movement-frequency',
    JOB_PHYSICAL_REQUIREMENTS = 'job-physical-requirements',
    JOB_POSITION_MOVEMENT = 'job-position-movement',
    JOB_RANGE_OF_MOTION = 'job-range-of-motion'
}

export enum AssessmentDirectoryTypeQueryParamValues {
    PERSON = 'person',
    JOB = 'job'
}

export enum AssessmentDirectoryStatusQueryParamValues {
    DRAFT = AssessmentStatusFilters.DRAFT,
    ALL = AssessmentStatusFilters.ALL,
    CURRENT = AssessmentStatusFilters.CURRENT,
    EXPIRED = AssessmentStatusFilters.EXPIRED
}
