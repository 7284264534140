import { Component, Input, OnChanges } from '@angular/core';
import { getFriendlyFileSize } from '@ta/app/assessments/utils/file.utils';

@Component({
    selector: 'ta-subscription-storage-data-display',
    templateUrl: './subscription-storage-data-display.component.html',
    styleUrls: ['./subscription-storage-data-display.component.scss']
})
export class SubscriptionStorageDataDisplayComponent implements OnChanges {
    /**
     * Total subscription storage allocation, in bytes
     */
    @Input() set total(totalBytes: number) {
        this._total = totalBytes;
        this.totalFriendlyString = getFriendlyFileSize(this._total, 0);
    }
    get total(): number {
        return this._total;
    }
    _total!: number;

    /**
     * Used subscription storage allocation, in bytes
     */
    @Input() set used(usedBytes: number) {
        this._used = usedBytes;
        this.usedFriendlyString = getFriendlyFileSize(this._used, 0);
    }
    get used(): number {
        return this._used;
    }
    _used!: number;

    /**
     * Whether to force the legend to align with the Assessment Data legend
     */
    @Input() forceLegendAlignment = false;

    /**
     * Friendly string representation of storage (e.g. 10 GB)
     */
    totalFriendlyString!: string;
    usedFriendlyString!: string;
    remainingFriendlyString!: string;

    constructor() {}

    ngOnChanges(): void {
        /**
         * Recalculate remaining string any time either used or total inputs change
         */
        this.remainingFriendlyString = getFriendlyFileSize(this._total - this._used, 0);
    }
}
