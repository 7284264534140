import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateLocaliser'
})
export class DateLocaliserPipe implements PipeTransform {
    transform(inDate: Date): Date {
        const date = new Date(inDate);
        const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        return newDate;
    }
}
