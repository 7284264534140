export enum PermissionItem {
    PEOPLE,
    JOBS,
    SITES,
    ASSESSMENTS,
    COMPARISONS,
    SETTINGS,
    PERMISSIONS,
    BILLING
}
