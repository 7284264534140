import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Toggle } from '@ta/app/shared-modules/toggle/models/toggle.model';
import { ToggleService } from '@ta/app/shared-modules/toggle/services/toggle.service';

@Component({
    selector: 'ta-assessment-restore-dialog',
    templateUrl: './assessment-restore-dialog.component.html',
    styleUrls: ['./assessment-restore-dialog.component.scss']
})
export class AssessmentRestoreDialogComponent {
    Toggle = Toggle;

    @Input()
    show = false;

    @Output()
    choice = new EventEmitter<boolean>();

    constructor(public toggleService: ToggleService) {}

    onRestoreConfirm(): void {
        this.choice.emit(true);
    }

    onRestoreCancel(): void {
        this.choice.emit(false);
    }
}
