import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastSeverity } from '@ta/app/shared/models/toast.model';
import { ToastService } from '@ta/app/shared/services/toast.service';
import { UserService } from '@ta/app/shared/services/user.service';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'ta-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
    /**
     * Single subscription to simplify clean-up on destroy
     */
    private _subscription?: Subscription;

    /**
     * Whether a verification email has been resent
     */
    public verificationEmailResent = false;

    /**
     * Controls whether the resend email button is disabled or not
     */
    public disableResendEmailButton = false;

    constructor(
        private readonly _auth0Service: Auth0Service,
        private readonly _userService: UserService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        private readonly _messageService: MessageService
    ) {}
    ngOnInit(): void {
        // Subscribe to new toasts
        this._subscription = this._toastService.toasts$.subscribe((messages: Array<Message>) => this._messageService.addAll(messages));
    }

    ngOnDestroy(): void {
        // Clean up subscriptions
        this._subscription?.unsubscribe();
    }

    /**
     * Call the user service to resend verification email and then handle the response and toasts
     */
    resendVerificationEmail(): void {
        this.disableResendEmailButton = true;
        this._userService
            .resendVerificationEmail()
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this._translateService.get('system.toast.success').subscribe((message) => {
                        this._toastService.addToast({ severity: ToastSeverity.SUCCESS, summary: message });
                        this.verificationEmailResent = true;
                    });
                },
                error: () => {
                    // Re-enable Resend Email button if there was an error of some sort.
                    this.disableResendEmailButton = false;
                }
            });
    }

    /**
     * log the user out using the Auth0 authservice.
     */
    onLogoutClick(): void {
        this._auth0Service.logout({ returnTo: window.location.origin });
    }

    /**
     * Handle close toasts
     */
    handleCloseToast(): void {
        this._messageService.clear();
    }

    /**
     * Handle toast click
     */
    onToastClick(event: any): void {
        // Stop propagation to prevent discard changes handler
        event.stopPropagation();
    }
}
