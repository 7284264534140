import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
// Implemented using parts from https://medium.com/@anup.bangale/showing-tooltip-only-when-text-overflow-ellipsis-active-angular8-bd5e9d7667a9 and
// https://stackoverflow.com/questions/32438642/clientwidth-and-clientheight-report-zero-while-getboundingclientrect-is-correct
@Directive({
    selector: '[taAddTooltipIfTruncate]'
})
export class AddTooltipIfTruncateDirective implements AfterViewInit {
    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this.setToolTip();
    }

    @HostListener('window:resize', ['$event.target'])
    setToolTip(): void {
        // Note: right now this only works for elements with 'display:block'. If we want it to work with inline elements, we may have to use
        // getBoundingClientRect() as used here: https://stackoverflow.com/questions/32438642/clientwidth-and-clientheight-report-zero-while-getboundingclientrect-is-correct
        this.el.nativeElement.clientWidth < this.el.nativeElement.scrollWidth
            ? this.renderer.setAttribute(this.el.nativeElement, 'title', this.el.nativeElement.textContent)
            : this.renderer.removeAttribute(this.el.nativeElement, 'title');
    }
}
