import { LOCATION_INITIALIZED } from '@angular/common';
import { APP_INITIALIZER, Injector, Provider } from '@angular/core';
import { AuthClientConfig, HttpMethod } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@ta/app/shared/services/environment.service';

// The initializer function which returns a function that returns a promise as per all the documentation I have read.
// I believe this is a factory that gets an instance of the DI'ed service and then returns a function which initializes it...
// See: https://juristr.com/blog/2018/01/ng-app-runtime-config/
export function appInitialiser(environmentService: EnvironmentService, authClientConfig: AuthClientConfig, translate: TranslateService, injector: Injector): () => Promise<void> {
    return async () => {
        // Load our runtime environment variables.
        await environmentService.loadEnvironment();

        // Pull out the relevant auth settings.
        const authSettings = environmentService.config.AUTH;

        // Configure the auth client. This only happens the FIRST time auth is initialized and can't be changed again at runtime as per the docs.
        authClientConfig.set({
            domain: authSettings.DOMAIN,
            clientId: authSettings.CLIENT_ID,
            audience: authSettings.AUDIENCE,
            redirectUri: authSettings.REDIRECT_URI,
            httpInterceptor: {
                allowedList: [
                    // Configure any public APIs to not require an authenticated token
                    {
                        uri: `${environmentService.config.ORCHESTRATION_API_URL}/Invitation/*`,
                        httpMethod: HttpMethod.Get,
                        allowAnonymous: true
                    },
                    {
                        uri: `${environmentService.config.ORCHESTRATION_API_URL}/Invitation/*`,
                        httpMethod: HttpMethod.Post,
                        allowAnonymous: true
                    },
                    // Configure authenticated APIs
                    ...authSettings.INTERCEPTOR_URLS.map((uri) => ({ uri }))
                ]
            }
        });

        // Load language
        await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        const defaultLang = 'en';
        translate.addLangs(['en']);
        translate.setDefaultLang(defaultLang);
        try {
            await translate.use(defaultLang).toPromise();
        } catch (err) {
            console.log(err);
        }
        console.log(`Successfully initialized ${defaultLang} language.`);
    };
}
/**
 * provides a global, lazy-loaded environment object
 */
export const AppInitialisationProvider: Provider = {
    provide: APP_INITIALIZER,
    useFactory: appInitialiser,
    deps: [EnvironmentService, AuthClientConfig, TranslateService, Injector],
    multi: true
};
