<div class="filter-dropdown">
    <p-overlayPanel #op [dismissable]="true" styleClass="filter">
        <ng-template pTemplate>
            <div class="filter-dropdown__filter">
                <p-listbox #list [options]="filterItems" [(ngModel)]="selectedChild" [multiple]="true" [checkbox]="true" [showToggleAll]="false" (onChange)="filter.emit()">
                    <ng-template let-filter pTemplate="item">
                        {{ filter.label | translate | titlecase }}
                    </ng-template>
                </p-listbox>
                <p-button class="filter-dropdown__filter__clear" (onClick)="clearFilter.emit()" [label]="'system.button.clear' | translate" styleClass="p-button-outlined"></p-button>
            </div>
        </ng-template>
    </p-overlayPanel>
    <p-button label="{{ 'system.button.filter' | translate }}" icon="ta ta-chevron-down" iconPos="right" [styleClass]="'p-button-secondary p-button-text'" (onClick)="op.toggle($event)">
        <i class="ta ta-filter filter-dropdown__button-icon"></i>
    </p-button>
</div>
