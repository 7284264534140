<div class="content">
    <div class="content__header" (click)="toggleExpanded()">
        <div class="content__header__left">
            <i class="{{ icon }} content__header__left__icon"></i>
            <h4 class="content__header__left__title">{{ title }}</h4>
        </div>
        <i class="ta ta-chevron-down content__header__icon" *ngIf="!expanded"></i>
        <i class="ta ta-chevron-up content__header__icon" *ngIf="expanded"></i>
    </div>
    <div *ngIf="expanded" class="content__main">
        <ng-content></ng-content>
        <ng-container *ngFor="let relationship of relationships.controls">
            <div *ngIf="!(relationship.get(FormArrayLabel.ACTION)?.value === RelationshipActions.DELETE)" class="content__main__relationship">
                <div class="content__main__relationship__left">
                    <i class="{{ icon }} content__main__relationship__left__icon"></i>
                    <span class="content__main__relationship__left__title truncate">{{ relationship.get(FormArrayLabel.RELATION)?.value.name }}</span>
                    <span class="content__main__relationship__left__status">{{ relationship.get('status')?.value | translate }}</span>
                </div>
                <div class="content__main__relationship__right">
                    <p-button
                        icon="ta ta-delete content__main__relationship__right__button"
                        (onClick)="onRemove(relationship.get(FormArrayLabel.RELATION)?.value.id)"
                        styleClass="p-button-text p-button-danger"
                    ></p-button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
