import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FormArrayAction } from '@ta/app/shared/models/form-array-action.model';
import { FormArrayLabel } from '@ta/app/shared/models/form-array-label.model';

@Component({
    selector: 'ta-relationships-section-edit',
    templateUrl: './relationships-section-edit.component.html',
    styleUrls: ['./relationships-section-edit.component.scss']
})
export class RelationshipsSegmentSectionEditComponent implements OnInit {
    RelationshipActions: any = FormArrayAction;
    FormArrayLabel: any = FormArrayLabel;

    /**
     * The relationships to be displayed and edited.
     */
    @Input()
    relationships!: FormArray;

    /**
     * The icon to display next to each relationship
     */
    @Input()
    icon!: string;

    /**
     * The title to display above each set of relationships
     */
    @Input()
    title!: string;

    /**
     * Whether the relationships (beneath the title) are expanded (visible) or collapsed (hidden)
     */
    @Input()
    expanded = true;

    /**
     * Emits the id of a realtionship that is removed by clicking the delete button.
     */
    @Output()
    removeEvent: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    /**
     * Handles the removal of relationships
     * @param id the relationships id
     */
    onRemove(id: number): void {
        this.removeEvent.emit(id);
    }

    /**
     * Toggles the expansion of the component.
     */
    toggleExpanded(): void {
        this.expanded = !this.expanded;
    }
}
