import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormName, FormState } from '@ta/app/shared/models/form.model';
import { FormArrayAction } from '@ta/app/shared/models/form-array-action.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';
import { PersonService } from '@ta/app/shared/services/person.service';
import { SiteService } from '@ta/app/shared/services/site.service';
import { JobService } from '@ta/app/shared/services/job.service';
import { FilterMetadata } from 'primeng/api';
import { ValidateQueryOptions } from '@ta/app/shared/utils/validate-query-options';
import { QueryOptions } from '@ta/app/shared/models/query-options.model';
import { AutoComplete } from 'primeng/autocomplete';
import { RelationshipObjectType } from '@ta/app/shared/models/relationship/relationship-object-type.model';
import { FormArrayLabel } from '@ta/app/shared/models/form-array-label.model';
import { Person } from '@ta/app/shared/models/person/person.model';
import { RelationshipTense } from '@ta/app/shared/models/relationship/relationship-tense.model';
import { RelationshipType } from '@ta/app/shared/models/relationship/relationship-type.model';
import { StructuredRelationships } from '@ta/app/shared/models/relationship/relationships.model';
import { Site } from '@ta/app/shared/models/site/site.model';
import { Job } from '@ta/app/shared/models/job/job.model';
import { Toggle } from '@ta/app/shared-modules/toggle/models/toggle.model';
import { ToggleService } from '@ta/app/shared-modules/toggle/services/toggle.service';

@Component({
    selector: 'ta-relationships-segment',
    templateUrl: './relationships-segment.component.html',
    styleUrls: ['./relationships-segment.component.scss']
})
export class RelationshipsSegmentComponent implements OnInit, OnDestroy {
    RelationshipActions = FormArrayAction;
    RelationshipType = RelationshipType;
    RelationshipTense = RelationshipTense;
    ObjectType = RelationshipObjectType;
    Toggle = Toggle;

    /**
     * Whether the page is in edit mode or not
     */
    @Input()
    edit = false;

    /**
     * The form of relationships
     */
    @Input()
    relationshipsForm!: FormGroup;

    /**
     * The name of the relationships form for the linked form service
     */
    @Input()
    relationshipsFormName!: FormName;

    /**
     * A list of sets of tags for displaying
     */
    @Input()
    structuredRelationships?: StructuredRelationships;

    /**
     * Whether or not the person is active
     */
    @Input()
    active = true;

    /**
     * Whether the relationships are being displayed for people.
     */
    displayPeople?: boolean;

    /**
     * The type of relationship that the viewing context and viewing type represent.
     */
    @Input()
    relationshipType?: RelationshipType;

    /**
     * Reference to search people input
     */
    @ViewChild('searchPeople') searchPeopleElement!: AutoComplete;

    /**
     * Reference to search sites input
     */
    @ViewChild('searchSites') searchSitesElement!: AutoComplete;

    /**
     * Reference to search jobs input
     */
    @ViewChild('searchJobs') searchJobsElement!: AutoComplete;
    /**
     * Whether or not the add relationship inputs should be displayed
     */
    siteRelationships!: FormArray;
    jobRelationships!: FormArray;
    personRelationships!: FormArray;

    /**
     * Filtered lists of people, jobs, and sites.
     */
    filteredPeople = new Array<Person>();
    filteredSites = new Array<Site>();
    filteredJobs = new Array<Job>();

    /**
     * Selected person, job, and site
     */
    selectedSite?: Site;
    selectedJob?: Job;
    selectedPerson?: Person;

    /**
     * Whether the compnonent is expanded or not
     */
    expanded = false;

    /**
     * Whether or not to show the add relationship fields
     */
    addSiteRelationship = false;
    addJobRelationship = false;
    addPersonRelationship = false;

    /**
     * The form for editing relationships
     */
    relationships!: FormArray;

    /**
     * The general filter for name
     */
    meta: FilterMetadata = {
        matchMode: 'contains',
        operator: 'and',
        value: ''
    };

    /**
     * The filters
     */
    filters: { [s: string]: Array<FilterMetadata> } = {};

    /**
     * The query options to filter people/jobs/sites by for display in autocompletes
     */
    options: QueryOptions = {
        first: 0,
        rows: 10,
        filters: this.filters
    };

    site?: Site;
    job?: Job;

    private _subscription = new Subscription();

    // Job service does not exist, is placeholder here
    constructor(
        private _personService: PersonService,
        private _jobService: JobService,
        private _siteService: SiteService,
        private _linkedFormService: LinkedFormService,
        public toggleService: ToggleService
    ) {}

    ngOnInit(): void {
        this.siteRelationships = this.relationshipsForm.get('sites') as FormArray;
        this.personRelationships = this.relationshipsForm.get('people') as FormArray;
        this.jobRelationships = this.relationshipsForm.get('jobs') as FormArray;

        this._linkedFormService.formState$.subscribe((state) => {
            this.expanded = state === FormState.EDIT;
        });

        this._subscription.add(
            this._siteService.site$.subscribe((site) => {
                this.site = site;
            })
        );

        this._subscription.add(
            this._jobService.job$.subscribe((job) => {
                this.job = job;
            })
        );
    }

    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    /**
     * Toggles the expansion of the component
     */
    onToggleDetails(): void {
        this.expanded = !this.expanded;
    }

    /**
     * Handle focus for the site autocomplete field
     */
    focusSearchSites(event: any): void {
        this.addSiteRelationship = true;
        this.searchSitesElement.focusInput();
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Handle focus for the job autocomplete field
     */
    focusSearchJobs(event: any): void {
        this.addJobRelationship = true;
        this.searchJobsElement.focusInput();
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Handle focus for the person autocomplete field
     */
    focusSearchPeople(event: any): void {
        this.addPersonRelationship = true;
        this.searchPeopleElement.focusInput();
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Handle hiding the site autocomplete field
     */
    hideSearchSites(): void {
        if (this.searchSitesElement.inputEL.nativeElement.value === '') {
            this.addSiteRelationship = false;
        }
    }

    /**
     * Handle hiding the job autocomplete field
     */
    hideSearchJobs(): void {
        if (this.searchJobsElement.inputEL.nativeElement.value === '') {
            this.addJobRelationship = false;
        }
    }

    /**
     * Handle hiding the person autocomplete field
     */
    hideSearchPeople(): void {
        if (this.searchPeopleElement.inputEL.nativeElement.value === '') {
            this.addPersonRelationship = false;
        }
    }

    /**
     * Handle the removal of sites
     * @param id the id of the item to be removed
     */
    removeSiteHandler(id: number): void {
        let site: AbstractControl | undefined;
        this.siteRelationships.controls.forEach((siteRelationshipControl: AbstractControl) => {
            if (siteRelationshipControl.get(FormArrayLabel.RELATION)?.value.id === id) {
                if (siteRelationshipControl.get(FormArrayLabel.ACTION)?.value === this.RelationshipActions.ADD) site = siteRelationshipControl;
                else siteRelationshipControl.get(FormArrayLabel.ACTION)?.setValue(this.RelationshipActions.DELETE);
            }
        });

        // if the site was in the add state, it has been added in this edit session
        // which means it should just be removed rather than set to delete.
        if (!!site) this.siteRelationships.removeAt(this.siteRelationships.controls.indexOf(site));

        this.touchedAndDirty();
    }

    /**
     * Handle the removal of jobs
     * @param id the id of the item to be removed
     */
    removeJobHandler(id: number): void {
        let job: AbstractControl | undefined;
        this.jobRelationships.controls.forEach((jobRelationshipControl: AbstractControl) => {
            if (jobRelationshipControl.get(FormArrayLabel.RELATION)?.value.id === id) {
                if (jobRelationshipControl.get(FormArrayLabel.ACTION)?.value === this.RelationshipActions.ADD) job = jobRelationshipControl;
                else jobRelationshipControl.get(FormArrayLabel.ACTION)?.setValue(this.RelationshipActions.DELETE);
            }
        });

        // if the job was in the add state, it has been added in this edit session
        // which means it should just be removed rather than set to delete.
        if (!!job) this.jobRelationships.removeAt(this.jobRelationships.controls.indexOf(job));

        this.touchedAndDirty();
    }

    /**
     * Handle the removal of people
     * @param id the id of the item to be removed
     */
    removePersonHandler(id: number): void {
        let person: AbstractControl | undefined;
        this.personRelationships.controls.forEach((personRelationshipControl: AbstractControl) => {
            if (personRelationshipControl.get(FormArrayLabel.RELATION)?.value.id === id) {
                if (personRelationshipControl.get(FormArrayLabel.ACTION)?.value === this.RelationshipActions.ADD) person = personRelationshipControl;
                else personRelationshipControl.get(FormArrayLabel.ACTION)?.setValue(this.RelationshipActions.DELETE);
            }
        });

        // if the person was in the add state, they have been added in this edit session
        // which means they should just be removed rather than set to delete.
        if (!!person) this.personRelationships.removeAt(this.personRelationships.controls.indexOf(person));

        this.touchedAndDirty();
    }

    /**
     * Handle adding the currently selected peorson to the form
     */
    onAddPerson(): void {
        const person = this.personRelationships.controls.find((t: AbstractControl) => t.get(FormArrayLabel.RELATION)?.value.id === this.selectedPerson?.id);

        if (!!person) {
            if (person.get(FormArrayLabel.ACTION)?.value === this.RelationshipActions.DELETE) person.get(FormArrayLabel.ACTION)?.setValue(this.RelationshipActions.NO_ACTION);
            else person.get(FormArrayLabel.ACTION)?.setValue(this.RelationshipActions.ADD);
        } else {
            this.personRelationships.push(
                new FormGroup({
                    relation: new FormControl(this.selectedPerson),
                    action: new FormControl(this.RelationshipActions.ADD),
                    status: new FormControl('system.relationships.new')
                })
            );
        }

        this.selectedPerson = undefined;
        this.touchedAndDirty();
    }

    /**
     * Handle adding the currently selected job to the form
     */
    onAddJob(): void {
        const job = this.jobRelationships.controls.find((t: AbstractControl) => t.get(FormArrayLabel.RELATION)?.value.id === this.selectedJob?.id);
        if (!!job) {
            if (job.get(FormArrayLabel.ACTION)?.value === this.RelationshipActions.DELETE) job.get(FormArrayLabel.ACTION)?.setValue(this.RelationshipActions.NO_ACTION);
            else job.get(FormArrayLabel.ACTION)?.setValue(this.RelationshipActions.ADD);
        } else {
            this.jobRelationships.push(
                new FormGroup({
                    relation: new FormControl(this.selectedJob),
                    action: new FormControl(this.RelationshipActions.ADD),
                    status: new FormControl('system.relationships.new')
                })
            );
        }
        this.selectedJob = undefined;
        this.touchedAndDirty();
    }

    /**
     * Handle adding the currently selected site to the form
     */
    onAddSite(): void {
        const site = this.siteRelationships.controls.find((t: AbstractControl) => t.get(FormArrayLabel.RELATION)?.value.id === this.selectedSite?.id);

        if (!!site) {
            if (site.get(FormArrayLabel.ACTION)?.value === this.RelationshipActions.DELETE) site.get(FormArrayLabel.ACTION)?.setValue(this.RelationshipActions.NO_ACTION);
            else site.get(FormArrayLabel.ACTION)?.setValue(this.RelationshipActions.ADD);
        } else {
            this.siteRelationships.push(
                new FormGroup({
                    relation: new FormControl(this.selectedSite),
                    action: new FormControl(this.RelationshipActions.ADD),
                    status: new FormControl('system.relationships.new')
                })
            );
        }

        this.selectedSite = undefined;
        this.touchedAndDirty();
    }

    /**
     * Set the relationships form to touched and dirty.
     */
    touchedAndDirty(): void {
        this.relationshipsForm.markAllAsTouched();
        this.relationshipsForm.markAsDirty();
    }

    /**
     * Handles the emmitted keyUp event for the autocomplete field.
     * @param event Browser event provided by the autocomplete field
     */
    handleKeyUp(event: any): void {
        if (!!event.key && event.key === 'Enter') {
            if (!!this.filteredJobs[0]) {
                this.selectedJob = this.filteredJobs[0];
                this.onAddJob();
                this.filteredJobs = [];
            }
            if (!!this.filteredPeople[0]) {
                this.selectedPerson = this.filteredPeople[0];
                this.onAddPerson();
                this.filteredPeople = [];
            }
            if (!!this.filteredSites[0]) {
                this.selectedSite = this.filteredSites[0];
                this.onAddSite();
                this.filteredSites = [];
            }
        }
    }

    /**
     * Fliters the list displayed in the autocomplete dropdown
     * @param event The filter event provided by the autocomplete field
     */
    filterJobs(event: any): void {
        this.meta.value = event.query;

        // make sure to include custom filter
        const options = ValidateQueryOptions(this.options, [], { 'job.Name': this.meta });
        this._subscription.add(
            this._jobService.getJobs(JSON.stringify(options)).subscribe((result) => {
                if (!result) {
                    return;
                }

                const jobRelationships: Map<number, Job> = new Map();

                (this.jobRelationships.value as Array<any>).forEach((job) => {
                    if (job.action !== this.RelationshipActions.DELETE) jobRelationships.set(job.relation.id, job.relation as Job);
                });

                // if job is not null, we are adding a job to a job, so ensure we are not adding the same job to the job
                if (!!this.job && this.relationshipsFormName === FormName.JOB_RELATIONSHIPS) {
                    this.filteredJobs = result?.jobs.filter((j) => !jobRelationships.get(j.id) && !!this.job && j.id !== this.job.id);
                }
                // otherwise, just ensure that the job hasn't been added before.
                else this.filteredJobs = result?.jobs.filter((j) => !jobRelationships.get(j.id));

                // truncate long names for dropdown
                this.filteredJobs.forEach((job) => {
                    if (job.name.length >= 25) {
                        job.name = job.name.substring(0, 25) + '...';
                    }
                });
            })
        );
    }

    /**
     * Fliters the list displayed in the autocomplete dropdown
     * @param event The filter event provided by the autocomplete field
     */
    filterPeople(event: any): void {
        this.meta.value = event.query;

        // make sure to include custom filter
        const options = ValidateQueryOptions(this.options, [], { 'person.Name': this.meta });
        this._subscription.add(
            this._personService.getPeople(JSON.stringify(options)).subscribe((result) => {
                if (!result) {
                    return;
                }

                const personRelationships: Map<number, Person> = new Map();

                (this.personRelationships.value as Array<any>).forEach((person) => {
                    if (person.action !== this.RelationshipActions.DELETE) personRelationships.set(person.relation.id, person.relation as Person);
                });

                this.filteredPeople = result?.people.filter((p) => !personRelationships.get(p.id));

                // truncate long names for dropdown
                this.filteredPeople.forEach((person) => {
                    if (person.name.length >= 25) {
                        person.name = person.name.substring(0, 25) + '...';
                    }
                });
            })
        );
    }

    /**
     * Fliters the list displayed in the autocomplete dropdown
     * @param event The filter event provided by the autocomplete field
     */
    filterSites(event: any): void {
        this.meta.value = event.query;

        // make sure to include custom filter
        const options = ValidateQueryOptions(this.options, [], { 'site.Name': this.meta });
        this._subscription.add(
            this._siteService.getSites(JSON.stringify(options)).subscribe((result) => {
                if (!result) {
                    return;
                }

                const siteRelationships: Map<number, Site> = new Map();

                (this.siteRelationships.value as Array<any>).forEach((site) => {
                    if (site.action !== this.RelationshipActions.DELETE) siteRelationships.set(site.relation.id, site.relation as Site);
                });

                // if site is not null, we are adding a site to a site, so ensure we are not adding the same site to the site
                if (!!this.site && this.relationshipsFormName === FormName.SITE_RELATIONSHIPS) {
                    this.filteredSites = result?.site.filter((s) => !siteRelationships.get(s.id) && !!this.site && s.id !== this.site.id);
                }
                // otherwise, just ensure that the site hasn't been added before.
                else this.filteredSites = result?.site.filter((s) => !siteRelationships.get(s.id));

                // truncate long names for dropdown
                this.filteredSites.forEach((site) => {
                    if (site.name.length >= 25) {
                        site.name = site.name.substring(0, 25) + '...';
                    }
                });
            })
        );
    }

    /**
     * determines if the relationships to display are people relationships
     * @returns a boolean determining whether people relationships should be displayed
     */
    displayPersonRelationships(): boolean {
        return [RelationshipType.PERSON_CLIENT, RelationshipType.PERSON_EMPLOYEE, RelationshipType.PERSON_ALL].includes(this.relationshipType ?? -1);
    }

    /**
     * determines if the relationships to display are job relationships
     * @returns a boolean determining whether job relationships should be displayed
     */
    displayJobRelationships(): boolean {
        return [RelationshipType.JOB_POSITION, RelationshipType.JOB_TASK, RelationshipType.JOB_ALL].includes(this.relationshipType ?? -1);
    }

    /**
     * determines if the relationships to display are site relationships
     * @returns a boolean determining whether site relationships should be displayed
     */
    displaySiteRelationships(): boolean {
        return [RelationshipType.SITE_SPECIFIC_LOCATION, RelationshipType.SITE_WORKPLACE, RelationshipType.SITE_ALL].includes(this.relationshipType ?? -1);
    }

    /**
     * Get errors relating to a specific form control
     * @returns One or more errors, as a string
     */
    getErrors = (formControName: string): string => this._linkedFormService.getErrors(this.relationshipsFormName, formControName, formControName);
}
