import { Component, Input } from '@angular/core';

@Component({
    selector: 'ta-layout-scroll',
    templateUrl: './layout-scroll.component.html',
    styleUrls: ['./layout-scroll.component.scss']
})
export class LayoutScrollComponent {
    @Input() contentStyle?: string;

    constructor() {}
}
