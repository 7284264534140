import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { User } from '@ta/app/shared/models/user.model';
import { EnvironmentService } from '@ta/app/shared/services/environment.service';
import { ApiResponseService } from '@ta/app/shared/services/api-response.service';
import { ApiAction } from '@ta/app/shared/models/api-action.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private readonly _http: HttpClient, private readonly _envService: EnvironmentService, private readonly _apiResponseService: ApiResponseService) {}

    private readonly USER_ROUTE = 'User';
    private readonly USER_FINALISE_ROUTE = 'finalise';
    private readonly USER_AUTH_PROVIDER_ROUTE = 'UserAuthProvider';

    private readonly _user = new BehaviorSubject<User | undefined>(undefined);
    readonly user$ = this._user.asObservable();

    get user(): User | undefined {
        return this._user.getValue();
    }

    getUser(): Observable<User | undefined> {
        return this._http.get<User>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.USER_ROUTE}`).pipe(
            tap((user) => {
                this._user.next(user);
            }),
            switchMap(() => this.user$)
        );
    }

    createUser(): Observable<User | undefined> {
        return this._http.post<User>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.USER_ROUTE}`, null).pipe(
            tap((result) => {
                this._user.next(result);
            }),
            switchMap(() => this.user$)
        );
    }

    finaliseUser(name: string, countryId: number): Observable<User | undefined> {
        return this._http.post<User>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.USER_ROUTE}/${this.USER_FINALISE_ROUTE}`, { name, countryId }).pipe(
            tap((result) => {
                this._user.next(result);
            })
        );
    }

    updateUser(name: string): Observable<User | undefined> {
        return this._http.put<User>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.USER_ROUTE}`, { name }).pipe(
            this._apiResponseService.showToastOnSuccess(ApiAction.SAVE),
            tap((result) => {
                this._user.next(result);
            })
        );
    }

    changePassword(): void {
        this._http.post<User>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.USER_ROUTE}/password-reset`, {}).pipe(take(1)).subscribe();
    }

    resendVerificationEmail(): Observable<object | undefined> {
        return this._http.post<User>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.USER_ROUTE}/resend-verification`, {});
    }

    deleteUser(): Observable<User | undefined> {
        return this._http.delete<User>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.USER_ROUTE}`, {}).pipe(
            this._apiResponseService.showToastOnSuccess(ApiAction.DELETE),
            tap((result) => {
                this._user.next(undefined);
            })
        );
    }

    registerAuthProviderId(): Observable<User | undefined> {
        return this._http.post<User>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.USER_AUTH_PROVIDER_ROUTE}`, null).pipe(
            tap((result) => {
                this._user.next(result);
            }),
            switchMap(() => this.user$)
        );
    }

    resetMfa(): Observable<object | undefined> {
        return this._http.delete(`${this._envService.config.ORCHESTRATION_API_URL}/${this.USER_ROUTE}/mfa`, {});
    }
}
