<div class="workspace-create-standard-container">
    <div [formGroup]="standardWorkspaceForm">
        <h5>{{ 'system.workspace.modal.business-name' | translate }}</h5>
        <input class="input-width" pInputText formControlName="businessName" />
        <p-message
            *ngIf="standardWorkspaceForm.get('businessName')?.dirty && standardWorkspaceForm.get('businessName')?.invalid"
            severity="error"
            [text]="getErrors('businessName', 'system.form.workspace.business-name' | translate)"
        ></p-message>
        <h5>{{ 'system.workspace.modal.legal-name' | translate }}</h5>
        <input class="input-width" pInputText formControlName="legalName" />
        <p-message
            *ngIf="standardWorkspaceForm.get('legalName')?.dirty && standardWorkspaceForm.get('legalName')?.invalid"
            severity="error"
            [text]="getErrors('legalName', 'system.form.workspace.legal-name' | translate)"
        ></p-message>
        <h5>{{ 'system.workspace.modal.country' | translate }}</h5>
        <div *ngIf="!isFinalisationOfPremiumWorkspace; else displayCountry" class="country-standard">
            <span class="taskalyser-override--medium">
                <p-dropdown
                    [dropdownIcon]="'ta ta-chevron-down'"
                    formControlName="country"
                    [options]="countries!"
                    optionLabel="name"
                    placeholder="{{ 'system.form.dropdown-placeholder-country' | translate }}"
                    (onChange)="getBusinessIdentifier()"
                ></p-dropdown>
            </span>
            <p-message
                *ngIf="standardWorkspaceForm.get('country')?.dirty && standardWorkspaceForm.get('country')?.invalid"
                severity="error"
                [text]="getErrors('country', 'system.form.workspace.country' | translate)"
            ></p-message>
        </div>
        <ng-template #displayCountry>
            <p>{{ standardWorkspaceForm.get('country')?.value.name }}</p>
        </ng-template>

        <div *ngIf="standardWorkspaceForm.get('country')?.value !== undefined && standardWorkspaceForm.get('country')?.value !== null && businessIdentifierTypes.length > 0">
            <span class="business-identifier business-identifier--optional">
                <h5>{{ 'system.workspace.modal.business-identifier' | translate }}</h5>
                <p *ngIf="!isFinalisationOfPremiumWorkspace">{{ 'system.site.optional' | translate }}</p>
            </span>
            <div class="business-identifier">
                <span class="taskalyser-override--small">
                    <p-dropdown
                        [dropdownIcon]="'ta ta-chevron-down'"
                        formControlName="businessIdentifierType"
                        [options]="businessIdentifierTypes!"
                        optionLabel="typeName"
                        placeholder="{{ 'system.form.dropdown-placeholder' | translate }}"
                        (onChange)="getBusinessIdentifierRegexMask()"
                    ></p-dropdown>
                </span>
                <div class="business-identifier business-identifier--mask">
                    <p-inputMask
                        formControlName="businessIdentifier"
                        mask="{{ identifierMask }}?"
                        [autoClear]="false"
                        (onBlur)="inputActive = false"
                        (onFocus)="onMaskFocus()"
                        [disabled]="!standardWorkspaceForm.get('businessIdentifierType')?.value"
                    ></p-inputMask>
                </div>
                <span *ngIf="standardWorkspaceForm.get('businessIdentifierType')?.value != null" class="business-identifier--mask">
                    <button pButton class="p-button-plain p-button-text" (click)="clearBusinessIdentifier()" label="{{ 'system.button.clear' | translate }}"></button>
                </span>
                <p-message
                    *ngIf="!inputActive && initialTouch && !standardWorkspaceForm.get('businessIdentifier')?.valid"
                    severity="error"
                    text="{{ getErrors('businessIdentifier', 'Business Identifier') }}"
                ></p-message>
            </div>
            <p class="data-placement">{{ 'system.workspace.modal.data' | translate }} {{ standardWorkspaceForm.get('country')?.value.name }}</p>
        </div>
    </div>
</div>
