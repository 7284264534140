<!-- Breadcrumbs -->
<div class="breadcrumbs">
    <ng-container *ngIf="crumbs.length">
        <div *ngFor="let crumb of crumbs; index as i">
            <a class="breadcrumbs__crumb truncate" [routerLink]="crumb.route">{{ crumb.name | translate }}</a>
            <i class="breadcrumbs__chevron ta ta-chevron-right"></i>
        </div>
        <p class="breadcrumbs__crumb breadcrumbs__crumb--bold truncate">{{ title?.value }}</p>
    </ng-container>
</div>

<!-- Page Heading + Actions -->
<div class="main">
    <!-- Page Heading -->
    <div class="main__title truncate">
        <h1 *ngIf="!edit; else titleInput" class="truncate" [class.inactive]="!active">{{ title?.value }}</h1>
        <ng-template #titleInput>
            <div class="main__title__edit">
                <h4 *ngIf="edit">{{ titleEdit }}</h4>
                <input
                    [formControl]="title"
                    aria-describedby="title-invalid"
                    placeholder="{{ 'system.form.person.name-placeholder' | translate }}"
                    type="text"
                    class="main__title__input-wrapper__input"
                    pInputText
                />
                <p-message *ngIf="title.dirty && title.invalid" severity="error" [text]="getErrors('name', 'system.person.name' | translate)"></p-message>
            </div>
        </ng-template>
    </div>

    <!-- Page Actions -->
    <div *ngIf="active; else inactiveActions" class="main__actions">
        <ng-content *ngIf="!edit; else nativeActions" class="main__projected-actions"></ng-content>
        <ng-template #nativeActions>
            <p-button
                class="main__actions__button"
                (onClick)="triggerCancel()"
                [disabled]="(this.linkedFormService.disabledState$ | async) === true"
                label="{{ 'system.button.cancel' | translate }}"
                icon="ta ta-dismiss"
                iconPos="left"
                styleClass="p-button-outlined p-button-secondary"
            ></p-button>
            <p-button
                *ngIf="!isCreate; else create"
                class="main__actions__button"
                (onClick)="triggerSave()"
                [disabled]="!areFormsDirty()"
                [loading]="(this.linkedFormService.disabledState$ | async) === true"
                label="{{ 'system.button.save-changes' | translate }}"
                icon="ta ta-save"
                iconPos="left"
            ></p-button>
            <ng-template #create>
                <p-button
                    class="main__actions__button"
                    (onClick)="triggerSave()"
                    [disabled]="!areFormsDirty()"
                    [loading]="(this.linkedFormService.disabledState$ | async) === true"
                    label="{{ 'system.action.save-create' | translate }}"
                    icon="ta ta-save"
                    iconPos="left"
                ></p-button>
            </ng-template>
        </ng-template>
    </div>
    <ng-template #inactiveActions>
        <div class="main__actions">
            <p-button
                label="{{ 'system.button.restore' | translate }}"
                class="main__actions__button"
                styleClass=" p-button-outlined p-button-secondary"
                icon="ta ta-arrow-undo"
                (onClick)="triggerRestore()"
            ></p-button>
            <p-button
                label="{{ 'system.button.delete-permanently' | translate }}"
                class="main__actions__button"
                styleClass="p-button-danger"
                icon="ta ta-delete"
                (onClick)="triggerDelete()"
            ></p-button>
        </div>
    </ng-template>
</div>

<div class="deleteTag">
    <p-tag *ngIf="!active" severity="danger" value="{{ 'system.button.archived' | translate | uppercase }}"></p-tag>
</div>

<!-- Tabs -->
<ta-tabs [tabs]="tabs" (onTabClick)="handleTabClick($event)"></ta-tabs>
