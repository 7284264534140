import { Component, Input } from '@angular/core';

@Component({
    selector: 'ta-subscription-assessment-data-display',
    templateUrl: './subscription-assessment-data-display.component.html',
    styleUrls: ['./subscription-assessment-data-display.component.scss']
})
export class SubscriptionAssessmentDataDisplayComponent {
    /**
     * The number of used Total Units (also used storage)
     */
    @Input() totalUsed = 0;

    /**
     * The cap of Total Units (also storage cap)
     */
    @Input() totalCap = 0;

    /**
     * The number of used Active Units
     */
    @Input() activeUsed = 0;

    /**
     * The cap of Active Units
     */
    @Input() activeCap = 0;

    constructor() {}
}
