import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Environment } from 'src/app/shared/models/environment.model';
import { buildConfig } from 'src/config/build-config';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {
    private readonly http: HttpClient;

    constructor(private readonly handler: HttpBackend) {
        // We are using some global http interceptors which aren't loaded
        // when we initialise the app. We don't need to run them for this call
        // so create a new backend and ignore the interceptor chain.
        this.http = new HttpClient(handler);
    }

    private _config?: Environment;
    private isLoaded = false;

    get config(): Environment {
        // Return the dynamic runtime environment object
        return !!this._config ? this._config : ({} as Environment);
    }

    loadEnvironment(): Promise<void> {
        // Warn if loaded more than once
        if (this.isLoaded && !buildConfig.PRODUCTION) {
            console.error('Environment variables are loaded on app init. There is no need to call loadEnvironment again!');
        }

        return this.http
            .get<Environment>('/assets/env.json')
            .toPromise()
            .then((result: any) => {
                // If we are not in production then merge local overrides for testing
                if (!buildConfig.PRODUCTION) {
                    let processEnv = {};

                    try {
                        // Ignore the warning about the file not existing below we don't need it
                        // We could import this but require is a little safer because it is a runtime check
                        // We don't need to care if the file is there...
                        // @ts-ignore
                        const developmentEnv = require('../../../../.env.json');

                        if (!!developmentEnv) {
                            processEnv = developmentEnv;
                        }
                    } catch {
                        // Do nothing the file probably just doesn't exist...
                    }

                    result = {
                        ...result,
                        ...processEnv
                    };
                }

                // Make sure we don't try and load this again
                this.isLoaded = true;

                // save result
                this._config = result;
            });
    }
}
