import { Routes } from '@angular/router';
import { AppRoutes } from '@ta/app/shared/models/routes.model';
import { AuthFinaliseAccountComponent } from './core/components/auth-finalise-account/auth-finalise-account.component';
import { AuthInvitationComponent } from './core/components/auth-invitation/auth-invitation.component';
import { VerifyEmailComponent } from './core/components/verify-email/verify-email.component';
import { InvitationGuard } from './core/guards/invitation.guard';
import { InvitationResolver } from './core/resolvers/invitation.resolver';
import { PrivacyComponent } from './core/components/privacy/privacy.component';
import { TermsConditionsComponent } from './core/components/terms-conditions/terms-conditions.component';
import { UserNotFinalisedGuard } from './shared/guards/user-not-finalised.guard';
import { TermsAndPolicyUpdatesComponent } from './core/components/terms-and-policy-updates/terms-and-policy-updates.component';
import { AuthLoginComponent } from './core/components/auth-login/auth-login.component';
import { EmailNotVerifiedGuard } from './shared/guards/email-not-verified.guard';
import { UnauthenticatedGuard } from './shared/guards/unauthenticated.guard';
import { UserFinalisedGuard } from './shared/guards/user-finalised.guard';
import { OopsComponent } from './core/components/oops/oops.component';

export const routes: Routes = [
    // Unauthenticated routes
    { path: AppRoutes.OOPS, component: OopsComponent },
    { path: AppRoutes.PRIVACY, component: PrivacyComponent },
    { path: AppRoutes.TERMS, component: TermsConditionsComponent },
    { path: AppRoutes.TERMS_AND_POLICY_UPDATES, component: TermsAndPolicyUpdatesComponent },
    // Authentication flow routes
    { path: AppRoutes.LOGIN, canActivate: [UnauthenticatedGuard], component: AuthLoginComponent },
    { path: AppRoutes.INVITE, canActivate: [InvitationGuard], resolve: { invite: InvitationResolver }, component: AuthInvitationComponent },
    { path: AppRoutes.VERIFY, canActivate: [EmailNotVerifiedGuard], component: VerifyEmailComponent },
    { path: AppRoutes.FINALISE, canActivate: [UserNotFinalisedGuard], component: AuthFinaliseAccountComponent },
    // The main app
    { path: AppRoutes.WORKSPACE, canActivateChild: [UserFinalisedGuard], loadChildren: () => import('./workspace/workspace.module').then((m) => m.WorkspaceModule) },
    { path: '**', redirectTo: AppRoutes.WORKSPACE, pathMatch: 'full' }
];
