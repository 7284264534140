<div class="privacy-and-data-component">
    <div class="section">
        <div class="section__header">
            <h2 class="section__header__heading">{{ 'system.privacy-and-data.title' | translate }}</h2>
        </div>
        <div class="section__content">
            <p>{{ 'system.privacy-and-data.paragraph1' | translate }}</p>
            <p>
                {{ 'system.privacy-and-data.paragraph2-1' | translate }}
                <a [routerLink]="'/' + AppRoutes.TERMS">{{ 'system.privacy-and-data.terms-of-service' | translate }}</a>
                {{ 'system.privacy-and-data.and' | translate }}
                <a [routerLink]="'/' + AppRoutes.PRIVACY">{{ 'system.privacy-and-data.privacy-and-data-policy' | translate }}</a
                >,
                {{ 'system.privacy-and-data.paragraph2-2' | translate }}
            </p>
        </div>
    </div>
</div>
