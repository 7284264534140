import { Component } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DynamicFormElementSchema } from 'src/app/shared/models/dynamic-form-element-schema.model';

@Component({
    selector: 'ta-form-element-base',
    templateUrl: './form-element-base.component.html',
    styleUrls: ['./form-element-base.component.scss']
})
export abstract class FormElementBaseComponent {
    // We expect the following to be set in the function below...
    // If they aren't set the form will blow up, so let's just assert them to not null
    // to save checking everywhere else.....
    schema!: DynamicFormElementSchema;
    form!: FormGroup;

    setElementSchema(schema: DynamicFormElementSchema, form: FormGroup, initialData?: any): void {
        this.schema = schema;
        this.form = form;
    }

    abstract getFormControl(): AbstractControl;
}
