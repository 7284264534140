import { Component, OnInit } from '@angular/core';
import { InvitationService } from '@ta/app/core/services/invitation.service';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { EnvironmentService } from '@ta/app/shared/services/environment.service';
import { initiliseSocialProvders, SocialProvider } from '@ta/app/core/models/social-login.model';

@Component({
    selector: 'ta-auth-login',
    templateUrl: './auth-login.component.html',
    styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent implements OnInit {
    /**
     * The set of supported social providers
     */
    socialProviders: Array<SocialProvider> = [];

    constructor(private readonly _environmentService: EnvironmentService, readonly invitationService: InvitationService, private readonly _auth0Service: Auth0Service) {}

    ngOnInit(): void {
        // Set up social providers
        this.socialProviders = initiliseSocialProvders(this._environmentService.config);
    }

    /**
     * Handle continue click
     */
    onContinue(): void {
        // Send the user to the Auth0 log in page
        this._auth0Service.loginWithRedirect({ connection: this._environmentService.config.AUTH.CONNECTION_DATABASE });
    }

    /**
     * Handle sign up click
     */
    onSignUp(): void {
        // Send the user to the Auth0 sign up page
        this._auth0Service.loginWithRedirect({ screen_hint: 'signup' });
    }
}
