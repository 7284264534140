<ta-auth class="auth">
    <!-- Welcome heading -->
    <h1 class="auth__heading">{{ 'system.auth.finalise-account.heading' | translate }}</h1>

    <!-- Message -->
    <p class="auth__message">{{ 'system.auth.finalise-account.message' | translate }}</p>

    <!-- Form -->
    <form [formGroup]="finaliseAccountForm" class="auth__form">
        <div class="auth__form__input">
            <span class="p-float-label">
                <input formControlName="name" id="float-input" pInputText type="text" class="name" />
                <label for="float-input">{{ 'system.login-name' | translate }}</label>
            </span>
            <p-message
                class="newline"
                *ngIf="finaliseAccountForm.get('name')?.invalid && finaliseAccountForm.get('name')?.dirty"
                severity="error"
                [text]="getErrors('name', 'system.person.name' | translate)"
            ></p-message>
        </div>

        <div class="auth__form__input">
            <p-dropdown
                [dropdownIcon]="'ta ta-chevron-down'"
                formControlName="country"
                [options]="countries"
                id="float-input2"
                optionLabel="name"
                placeholder="{{ 'system.login.select-country' | translate }}"
                optionValue="id"
                class="country"
                [style]="{ width: '100%' }"
            ></p-dropdown>
            <p-message
                class="newline"
                *ngIf="finaliseAccountForm.get('country')?.invalid && finaliseAccountForm.get('country')?.dirty"
                severity="error"
                [text]="getErrors('country', 'system.person.country' | translate)"
            ></p-message>
        </div>

        <p>{{ 'system.auth.finalise-account.data' | translate }}</p>

        <!-- Continue -->
        <a class="auth__action">
            <p-button
                class="button"
                styleClass="p-button-lg"
                (onClick)="createUser()"
                [disabled]="!_linkedFormService.isValid(finaliseAccountFormName)"
                [loading]="(_linkedFormService.disabledState$ | async) === true"
                label="{{ 'system.auth.finalise-account.button' | translate }}"
            >
            </p-button>
        </a>
    </form>
</ta-auth>
