import { AfterContentInit, AfterViewInit, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ToggleService } from '@ta/app/shared-modules/toggle/services/toggle.service';
import { Toggle } from '@ta/app/shared-modules/toggle/models/toggle.model';
import { ObjectPermission } from '@ta/app/shared/models/permissions/object-permissions.model';

@Directive({
    selector: '[taIfToggle]'
})
export class IfToggleDirective implements OnInit {
    /**
     * OPTIONAL
     * Permission level to check
     */
    @Input()
    set taIfTogglePermissionLevel(taIfTogglePermissionLevel: ObjectPermission | undefined) {
        this._permissionLevel = taIfTogglePermissionLevel;
    }
    _permissionLevel?: ObjectPermission;

    /**
     * REQUIRED
     * Toggle to check
     */
    @Input()
    set taIfToggle(toggle: Toggle | undefined) {
        this._toggle = toggle;
    }
    _toggle!: Toggle | undefined;

    constructor(private readonly templateRef: TemplateRef<any>, private readonly viewContainer: ViewContainerRef, private readonly _toggleService: ToggleService) {}

    evaluateToggle(): void {
        // Toggle behaviour is enabled by default
        // If no toggle is provided for whatever reason, enable the toggle
        if (!this._toggle || this._toggleService.isOn(this._toggle, this._permissionLevel)) this.viewContainer.createEmbeddedView(this.templateRef);
        else this.viewContainer.clear();
    }

    ngOnInit(): void {
        this.evaluateToggle();
    }
}
