import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppQueryParams, BladeQueryParamValues, TabQueryParamValues, WorkspaceRoutes } from '@ta/app/shared/models/routes.model';
import { Tab } from '@ta/app/shared/models/tab.model';
import { BladeService } from '@ta/app/shared/services/blade.service';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { WorkspaceSwitcherItem } from '@ta/app/core/models/workspace-switcher-item.model';
import { FormName } from '@ta/app/shared/models/form.model';
import { CreateWorkspaceRequest } from '@ta/app/shared/models/workspace/create-workspace-request.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';
import { FinalisePremiumWorkspace } from '@ta/app/shared/models/workspace/finalise-premium-workspace.model';
import { PremiumWorkspaceStaticData } from '@ta/app/shared/models/workspace/premium-workspace-static-data.model';
import { Constants } from '@ta/app/shared/models/constants.model';
import { PremiumRequest } from '@ta/app/shared/models/workspace/premium-workspace-request.model';
import { WorkspaceCreateModelSteps } from '@ta/app/core/models/workspace-create-model-steps.model';

const DEFAULT_COUNTRY_CODE = 13; // Australia

@Component({
    selector: 'ta-workspace-switcher',
    templateUrl: './workspace-switcher.component.html',
    styleUrls: ['./workspace-switcher.component.scss']
})
export class WorkspaceSwitcherComponent implements OnInit, OnDestroy {
    TabQueryParamValues: any = TabQueryParamValues;
    ModelSteps: any = WorkspaceCreateModelSteps;

    /**
     * Whether to show the workspace switcher
     */
    showWorkspaceSwitcher = false;

    /**
     * Store currently selected tab
     */
    currentTab: string = TabQueryParamValues.ALL;

    /**
     * Tabs to filter workspaces
     */
    tabs: Tab[] = new Array<Tab>(
        { name: 'system.nav.workspace-switcher.tab.favourites', relativeRoute: true, queryParams: { [AppQueryParams.TAB]: TabQueryParamValues.FAVOURITES } },
        { name: 'system.nav.workspace-switcher.tab.all', relativeRoute: true, queryParams: { [AppQueryParams.TAB]: TabQueryParamValues.ALL } }
    );

    /**
     * Locally store a list of workspaces
     */
    workspaceList: Array<WorkspaceSwitcherItem> = [];
    filteredWorkspaceList: Array<WorkspaceSwitcherItem> = [];

    /**
     * Filter state
     */
    search: FormControl = new FormControl();

    /**
     * Single subscription to simplify clean-up on destroy
     */
    private _subscription?: Subscription;

    createNewWorkspaceModal = false;

    /**
     * If the user has a finalise premium workspace ticket
     */
    isFinalisePremiumWorkspace = false;

    /**
     * If the premium workspace form is cancelled
     */
    premiumIncomplete = false;

    /**
     * Premium Workspace invite ticket
     */
    premiumWorkspaceTicket!: string;

    /**
     * Premium workspace data
     */
    premiumWorkspaceData?: PremiumWorkspaceStaticData;

    /**
     * Form that facilitates the creation of a workspace
     */
    standardWorkspaceForm!: FormGroup;

    /**
     * Form that facilitates the request of a premium workspace
     */
    premiumWorkspaceRequestForm!: FormGroup;

    /**
     * Name of the form used in this component
     */
    standardWorkspaceFormName: FormName = FormName.STANDARD_WORKSPACE_FORM;
    premiumWorkspaceRequestFormName: FormName = FormName.PREMIUM_WORKSPACE_REQUEST;

    /**
     * If the form has been saved
     */
    hasClickedSave = false;

    /**
     * if the modal for a premium request confirmation is shown
     */
    requestSent = false;

    initialStep: WorkspaceCreateModelSteps = WorkspaceCreateModelSteps.START;

    constructor(
        private readonly _bladeService: BladeService,
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _workspaceService: WorkspaceService,
        private readonly _linkedFormService: LinkedFormService
    ) {}

    ngOnInit(): void {
        // Handle query parameters
        this._subscription = this._route.queryParams.subscribe((queryParams: Params) => {
            // Set the blade to open / closed based on query params
            this.showWorkspaceSwitcher = queryParams[AppQueryParams.BLADE] === BladeQueryParamValues.WORKSPACE_SWITCHER;
            // Navigate to a tab, if none is set
            // Default to favourites if there are any, else default to all
            if (!queryParams[AppQueryParams.TAB] && this.showWorkspaceSwitcher) {
                const defaultTab = this.workspaceList.find((workspace) => workspace.isFavourite) ? TabQueryParamValues.FAVOURITES : TabQueryParamValues.ALL;
                this._router.navigate([], { queryParams: { [AppQueryParams.TAB]: defaultTab }, queryParamsHandling: 'merge' });
                return;
            }
            // Store the current tab
            this.currentTab = queryParams[AppQueryParams.TAB];
            // Reset filters
            this.search.reset();
            this.updateWorkspaceFilter();
        });

        this.updateWorkspaceList();

        // Take premium ticket from route
        if (this._route.snapshot.queryParams.inviteTicket) {
            this.premiumWorkspaceTicket = this._route.snapshot.queryParams.inviteTicket;

            // Get finalise premium workspace data if it exists
            // This currently throws an error if the invite ticket is incorrect
            this._subscription = this._workspaceService
                .getPremiumWorkspaceFinaliseData(this.premiumWorkspaceTicket)
                .pipe(filter((workspaceData) => !!workspaceData))
                .subscribe((workspaceData) => {
                    this.premiumWorkspaceData = workspaceData;

                    if (!!this.premiumWorkspaceData) {
                        // make sure to set the country in the form
                        this.standardWorkspaceForm.get('country')?.setValue(this.premiumWorkspaceData.country);
                        this.isFinalisePremiumWorkspace = true;
                        this.initialStep = WorkspaceCreateModelSteps.FINALISE_PREMIUM;
                    }
                });
        }

        // Update filters on search input
        this._subscription = this.search.valueChanges.pipe(debounceTime(Constants.DEBOUNCE_TIME_DURATION)).subscribe(() => this.updateWorkspaceFilter());

        // Declare formgroup for standard workspace
        this.standardWorkspaceForm = new FormGroup({
            businessName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
            legalName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
            country: new FormControl('', Validators.required),
            businessIdentifierType: new FormControl(''),
            businessIdentifier: new FormControl('', Validators.maxLength(100)),
            dataControllerEmail: new FormControl('', [Validators.maxLength(100), Validators.pattern(Constants.EMAIL_REGEX)])
        });

        // Declare formgroup for premium workspace
        this.premiumWorkspaceRequestForm = new FormGroup({
            country: new FormControl(DEFAULT_COUNTRY_CODE, Validators.required),
            fullName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
            emailAddress: new FormControl('', [Validators.required, Validators.pattern(Constants.EMAIL_REGEX), Validators.maxLength(100)]),
            contact: new FormControl('', [Validators.required, Validators.pattern(Constants.PHONE_REGEX), Validators.maxLength(25)])
        });

        // Add forms to linked form service
        this._linkedFormService.addForm(this.standardWorkspaceForm, this.standardWorkspaceFormName);
        this._linkedFormService.addForm(this.premiumWorkspaceRequestForm, this.premiumWorkspaceRequestFormName);
    }

    ngOnDestroy(): void {
        // Clean up subscriptions
        this._subscription?.unsubscribe();
    }

    /**
     * Handle add workspace button
     */
    onAddWorkspace(): void {
        this.initialStep = this.isFinalisePremiumWorkspace ? WorkspaceCreateModelSteps.FINALISE_PREMIUM : WorkspaceCreateModelSteps.START;
        this.createNewWorkspaceModal = true;
    }

    /**
     * Create a standard workspace
     */
    createWorkspace(): void {
        this._linkedFormService.setFormElementsDisabled();

        // handle invalid form
        if (this.standardWorkspaceForm.invalid) {
            this._linkedFormService.setFormElementsEnabled();
            return;
        }

        const workspace: CreateWorkspaceRequest = {
            displayName: this.standardWorkspaceForm.get('businessName')?.value,
            legalName: this.standardWorkspaceForm.get('legalName')?.value,
            businessIdentifierTypeId: !!this.standardWorkspaceForm.get('businessIdentifier')?.value ? this.standardWorkspaceForm.get('businessIdentifierType')?.value.id : null,
            businessIdentifier: this.standardWorkspaceForm.get('businessIdentifier')?.value ?? null,
            countryId: this.standardWorkspaceForm.get('country')?.value.id
        };

        // create workspace
        this._workspaceService
            .createWorkspace(workspace)
            .pipe()
            .subscribe((result) => {
                this._linkedFormService.setFormElementsEnabled();

                this.updateWorkspaceList();
                this._router.navigate([result?.globalId, WorkspaceRoutes.DASHBOARD]);
                this.closeWorkspaceToggle();
            });
    }

    /**
     * Submit a premium request form
     */
    submitPremiumWorkspaceRequest(): void {
        this._linkedFormService.setFormElementsDisabled();

        // handle invalid form
        if (this.premiumWorkspaceRequestForm.invalid) {
            this._linkedFormService.setFormElementsEnabled();
            return;
        }

        const workspace: PremiumRequest = {
            contactEmail: this.premiumWorkspaceRequestForm.get('emailAddress')?.value,
            contactName: this.premiumWorkspaceRequestForm.get('fullName')?.value,
            contactPhone: this.premiumWorkspaceRequestForm.get('contact')?.value,
            countryId: this.premiumWorkspaceRequestForm.get('country')?.value
        };

        this._workspaceService
            .createPremiumWorkspaceRequest(workspace)
            .pipe()
            .subscribe((result) => {
                this._linkedFormService.setFormElementsEnabled();

                if (result === true) {
                    this.requestSent = true;
                }

                this.closeWorkspaceToggle();
            });
    }

    /**
     * Finalises a premium workspace using the startand workspace form.
     */
    finalisePremiumWorkspace(): void {
        this._linkedFormService.setFormElementsDisabled();

        if (this.standardWorkspaceForm.invalid) {
            this._linkedFormService.setFormElementsEnabled();
            return;
        }

        const workspace: CreateWorkspaceRequest = {
            displayName: this.standardWorkspaceForm.get('businessName')?.value,
            legalName: this.standardWorkspaceForm.get('legalName')?.value,
            businessIdentifierTypeId: this.standardWorkspaceForm.get('businessIdentifierType')?.value.id,
            businessIdentifier: this.standardWorkspaceForm.get('businessIdentifier')?.value,
            countryId: this.standardWorkspaceForm.get('country')?.value.id
        };

        const finalisedWorkspace: FinalisePremiumWorkspace = {
            inviteTicket: this.premiumWorkspaceTicket,
            workspace: workspace
        };

        this._workspaceService
            .finalisePremiumWorkspace(finalisedWorkspace)
            .pipe()
            .subscribe((result) => {
                this._linkedFormService.setFormElementsEnabled();
                this.updateWorkspaceList();
                this.hasClickedSave = true;
                this._router.navigate([result?.globalId, WorkspaceRoutes.DASHBOARD]);
                this.closeWorkspaceToggle();
            });
    }

    /**
     * Update the list of displayed workspaces, filtering the retrieved data.
     */
    updateWorkspaceList(): void {
        this._subscription = this._workspaceService
            .getWorkspaceList()
            .pipe(filter((workspaceList) => !!workspaceList))
            .subscribe((workspaceList) => {
                // Store a local copy of workspaces
                this.workspaceList = workspaceList!;
                // Update the filtered list
                this.updateWorkspaceFilter();
            });
    }

    /**
     * Close Workspace toggle and clean up variables
     */
    closeWorkspaceToggle(): void {
        // close the modal
        this.createNewWorkspaceModal = false;

        if (this.isFinalisePremiumWorkspace && !this.hasClickedSave) {
            this.premiumIncomplete = true;
        }

        // Close premium workspace modal if present
        this.isFinalisePremiumWorkspace = false;

        this._linkedFormService.markClean(this.standardWorkspaceFormName);
        this._linkedFormService.markClean(this.premiumWorkspaceRequestFormName);
        this._linkedFormService.reset(this.premiumWorkspaceRequestFormName);
        this._linkedFormService.reset(this.standardWorkspaceFormName);
        this._linkedFormService.setFormElementsEnabled();
    }

    /**
     * Handle click events on the workspace switcher blade panel
     */
    onBladeClick(event: any): void {
        // Prevent clicks on the blade panel from closing the blade
        event.stopPropagation();
    }

    /**
     * Handle when user clicks acrylic overlay
     */
    onOverlayClick(): void {
        this.closeBlade();
    }

    /**
     * Handle when user clicks close button
     */
    onCloseClick(): void {
        this.closeBlade();
    }

    /**
     * Close the workspace switcher blade
     */
    closeBlade(): void {
        // Route to current route and remove the blade query param
        this._bladeService.closeBladesAndMenus();
    }

    /**
     * Update the filtered list of workspaces
     */
    updateWorkspaceFilter(): void {
        // First, filter based on current tab
        switch (this.currentTab) {
            case TabQueryParamValues.ALL: {
                this.filteredWorkspaceList = this.workspaceList;
                break;
            }
            case TabQueryParamValues.FAVOURITES: {
                this.filteredWorkspaceList = this.workspaceList.filter((workspace) => workspace.isFavourite);
                break;
            }
            default: {
                this.filteredWorkspaceList = this.workspaceList;
            }
        }

        // Then, filter by search term
        if (!!this.search.value) {
            // Search for matches in legalname and displayname
            // Convert to lowercase to make search case insensitive
            this.filteredWorkspaceList = this.filteredWorkspaceList.filter(
                (workspace) => workspace.displayName?.toLowerCase().includes(this.search.value.toLowerCase()) || workspace.legalName?.toLowerCase().includes(this.search.value.toLowerCase())
            );
        }
    }
}
