export enum ObjectPermission {
    READ = 1,
    EDIT = 2,
    READ_AND_EDIT = 3
}

export enum ObjectPermissions {
    'system.permission-level.read' = ObjectPermission.READ,
    'system.permission-level.edit' = ObjectPermission.READ_AND_EDIT
}
