import { Environment } from '@ta/app/shared/models/environment.model';

export interface SocialProvider {
    label: string;
    caption?: string;
    icon: string;
    connection: string;
}

// Set up social providers
export function initiliseSocialProvders(environment: Environment): Array<SocialProvider> {
    return [
        {
            label: 'system.auth.social-provider.google',
            icon: 'assets/images/google.svg',
            connection: environment.AUTH.CONNECTION_GOOGLE
        },
        {
            label: 'system.auth.social-provider.microsoft-work',
            caption: 'system.auth.social-provider.microsoft-work.caption',
            icon: 'assets/images/microsoft.svg',
            connection: environment.AUTH.CONNECTION_MICROSOFT_WORK
        },
        {
            label: 'system.auth.social-provider.microsoft-personal',
            caption: 'system.auth.social-provider.microsoft-personal.caption',
            icon: 'assets/images/microsoft.svg',
            connection: environment.AUTH.CONNECTION_MICROSOFT_PERSONAL
        }
    ];
}
