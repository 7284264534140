<ng-content select="[dynamic-form-header]"></ng-content>
<ng-template taDynamicForm></ng-template>
<ng-content select="[dynamic-form-footer]"></ng-content>
<div *ngIf="!hideControls" class="controls">
    <ng-container *ngIf="!!schema?.pages && schema!.pages.length > 1">
        <p-button label="Previous" [disabled]="pagination.isOnFirstPage" (onClick)="onPreviousClicked()"></p-button>
        <p-button label="Next" [disabled]="pagination.isOnLastPage" (onClick)="onNextClicked()"></p-button>
    </ng-container>
    <ng-content select="[dynamic-form-controls]"></ng-content>
</div>
