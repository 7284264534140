import { fromEvent, map, Observable, take } from 'rxjs';

export enum FileCategory {
    Document,
    Image,
    Video,
    Unsupported
}

/**
 * Documents
 */
export const acceptedDocumentFileExtensions: Array<string> = ['.doc', '.docx', '.pdf', '.txt', '.xls', '.xlsx'];

/**
 *  Images
 */
export const acceptedImageFileExtensions: Array<string> = ['.jpeg', '.jpg', '.png'];

/**
 * Videos
 */
export const acceptedVideoFileExtensions: Array<string> = ['.mp4', '.mov'];

/**
 * Accepted file extensions
 */
export const acceptedFileExtensions: Array<string> = [...acceptedDocumentFileExtensions, ...acceptedImageFileExtensions, ...acceptedVideoFileExtensions];

/**
 * A helper to convert file size into a human-friendly string
 * @param bytes The file size in bytes
 * @returns A friendly string e.g. 20MB
 */
export function getFriendlyFileSize(bytes: number, decimalPoints: number): string {
    // Handle no bytes
    if (bytes === 0) return '0 B';
    // Handle negative bytes
    let prefix = '';
    if (bytes < 0) {
        prefix = '-';
        bytes *= -1;
    }
    // Handle positive bytes
    const i: number = Math.floor(Math.log(bytes) / Math.log(1024));
    return prefix + (bytes / Math.pow(1024, i)).toFixed(decimalPoints) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

/**
 * Converts an image  from a blob into a data URL for display in an image src tag
 * @param image The image blob to convert
 * @returns The image as a data url
 */
export function createImageUrlFromBlob(image: Blob): Observable<string> {
    // Create a file reader
    const reader: FileReader = new FileReader();
    // Turn the lad event into an obervable
    const fileReader$ = fromEvent(reader, 'load').pipe(
        map((e) => (e as any).target.result),
        take(1)
    );
    // Read the blob into a data URL
    if (image) reader.readAsDataURL(image);
    // Return the observable
    return fileReader$;
}

/**
 * Whether a file is a document
 * @param extension File extension
 * @returns True if file extension is a supported document extension
 */
export function isDocument(extension: string): boolean {
    return acceptedDocumentFileExtensions.includes(extension);
}

/**
 * Whether a file is an image
 * @param extension File extension
 * @returns True if file extension is a supported image extension
 */
export function isImage(extension: string): boolean {
    return acceptedImageFileExtensions.includes(extension);
}

/**
 * Whether a file is a video
 * @param extension File extension
 * @returns True if file extension is a supported video extension
 */
export function isVideo(extension: string): boolean {
    return acceptedVideoFileExtensions.includes(extension);
}

/**
 * Determine the file category (e.g. image, document)
 * @param extension The file's extension
 * @returns The file's FileCategory. Returns FileCategory.Unsupported if no match.
 */
export function getFileCategory(extension: string): FileCategory {
    if (acceptedDocumentFileExtensions.includes(extension)) return FileCategory.Document;
    if (acceptedImageFileExtensions.includes(extension)) return FileCategory.Image;
    if (acceptedVideoFileExtensions.includes(extension)) return FileCategory.Video;
    return FileCategory.Unsupported;
}
