import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { ApiAction } from '@ta/app/shared/models/api-action.model';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';
import { StructuredTagSet } from '@ta/app/shared/models/tag/structured-tag-set.model';
import { IObjectPermissionRequest } from '@ta/app/shared/models/permissions/object-permission-request.model';
import { Router } from '@angular/router';
import { PersonList } from '@ta/app/shared/models/person/person-list.model';
import { UpdatePersonRequest } from '@ta/app/shared/models/person/update-person-request.model';
import { ApiResponseService } from '@ta/app/shared/services/api-response.service';
import { Tag } from 'primeng/tag';
import { Audit } from '@ta/app/shared/models/audit/audit.model';
import { CreatePersonRequest } from '@ta/app/shared/models/person/create-person-request.model';
import { PersonStatus } from '@ta/app/shared/models/person/person-status.model';
import { PersonType } from '@ta/app/shared/models/person/person-type.model';
import { Person } from '@ta/app/shared/models/person/person.model';
import { RelationshipRequest, StructuredRelationships } from '@ta/app/shared/models/relationship/relationships.model';

@Injectable({
    providedIn: 'root'
})
export class PersonService {
    constructor(private readonly _http: HttpClient, private readonly _apiResponseService: ApiResponseService, private readonly _workspaceService: WorkspaceService, private readonly _router: Router) {}

    private readonly _PERSON_ROUTE = 'person';
    private readonly _PERMISSION_ROUTE = 'objectpermissions';
    private readonly _PERSON_TYPES_ROUTE = 'person/list/types';
    private readonly _PERSON_STATUSES_ROUTE = 'person/list/statuses';
    private readonly _LIST_ROUTE = 'list';
    private readonly _PERSON_ARCHIVE_ROUTE = 'person/archive';
    private readonly _PERSON_UNARCHIVE_ROUTE = 'person/unarchive';
    private readonly _TAGS_ROUTE = 'tags';
    private readonly _TAGS_STRUCTURED_ROUTE = 'tags/structured';
    private readonly _RELATIONSHIPS_ROUTE = 'relationships';
    private readonly _REMOVE_RELATIONSHIPS_ROUTE = 'remove';
    private readonly _ADD_RELATIONSHIPS_ROUTE = 'add';
    private readonly _RELATIONSHIPS_STRUCTURED_ROUTE = 'relationships/structured';
    private readonly _PERSON_AUDIT_ROUTE = 'audit/person';

    private readonly _person = new BehaviorSubject<Person | undefined>(undefined);
    readonly person$ = this._person.asObservable();

    private readonly _personTypes = new BehaviorSubject<PersonType[] | undefined>(undefined);
    readonly personTypes$ = this._personTypes.asObservable();

    private readonly _personStatuses = new BehaviorSubject<PersonStatus[] | undefined>(undefined);
    readonly personStatuses$ = this._personStatuses.asObservable();

    private readonly _peopleList = new BehaviorSubject<PersonList | undefined>(undefined);
    readonly peopleList$ = this._peopleList.asObservable();

    private readonly _personTags = new BehaviorSubject<Tag[] | undefined>(undefined);
    readonly personTags$ = this._personTags.asObservable();

    private readonly _personTagsStructured = new BehaviorSubject<StructuredTagSet[] | undefined>(undefined);
    readonly personTagsStructured$ = this._personTagsStructured.asObservable();

    private readonly _personRelationships = new BehaviorSubject<RelationshipRequest | undefined>(undefined);
    readonly personRelationships$ = this._personRelationships.asObservable();

    private readonly _personStructuredRelationships = new BehaviorSubject<StructuredRelationships | undefined>(undefined);
    readonly personStructuredRelationships$ = this._personStructuredRelationships.asObservable();

    private readonly _auditList = new BehaviorSubject<Array<Audit> | undefined>(undefined);
    readonly auditList$ = this._auditList.asObservable();

    private readonly _objectLevelPermissions = new BehaviorSubject<Array<IObjectPermissionRequest> | undefined>(undefined);
    readonly objectLevelPermissions$ = this._objectLevelPermissions.asObservable();

    getPerson(id: number): Observable<Person | undefined> {
        return this._http.get<Person>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}/${id}`).pipe(
            tap((person) => {
                this._person.next(person);
            }),
            switchMap(() => this.person$)
        );
    }

    createPerson(personRequest: CreatePersonRequest): Observable<Person | undefined> {
        return this._http.post<Person>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}`, personRequest).pipe(
            this._apiResponseService.showToastOnSuccess(ApiAction.CREATE),
            tap((person) => {
                this._person.next(person);
            }),
            switchMap(() => this.person$)
        );
    }

    updatePerson(personRequest: UpdatePersonRequest): Observable<Person | undefined> {
        return this._http.put<Person>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}`, personRequest).pipe(
            this._apiResponseService.showToastOnSuccess(ApiAction.UPDATE),
            tap((person) => {
                this._person.next(person);
            }),
            switchMap(() => this.person$)
        );
    }

    /* LOOKUP TABLE ENDPOINTS */

    getPersonTypes(): Observable<PersonType[] | undefined> {
        return this._http.get<PersonType[]>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_TYPES_ROUTE}`).pipe(
            tap((personTypes) => {
                this._personTypes.next(personTypes);
            }),
            switchMap(() => this.personTypes$)
        );
    }

    getPersonStatuses(): Observable<PersonStatus[] | undefined> {
        return this._http.get<PersonStatus[]>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_STATUSES_ROUTE}`).pipe(
            tap((personStatuses) => {
                this._personStatuses.next(personStatuses);
            }),
            switchMap(() => this.personStatuses$)
        );
    }

    /* BULK ENDPOINTS */

    getPeople(params: any): Observable<PersonList | undefined> {
        return this._http.get<PersonList>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}/${this._LIST_ROUTE}`, { params: { queryOptions: params } }).pipe(
            tap((people) => {
                this._peopleList.next(people);
            }),
            switchMap(() => this.peopleList$)
        );
    }

    archivePeople(personIds: Array<number>): Observable<object> {
        return this._http.put(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ARCHIVE_ROUTE}`, personIds).pipe(this._apiResponseService.showToastOnSuccess(ApiAction.ARCHIVE));
    }

    unarchivePeople(personIds: Array<number>): Observable<object> {
        return this._http.put(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_UNARCHIVE_ROUTE}`, personIds).pipe(this._apiResponseService.showToastOnSuccess(ApiAction.UNARCHIVE));
    }

    deletePeople(personIds: Array<number>): Observable<object> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: personIds
        };

        return this._http.delete(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}`, options).pipe(this._apiResponseService.showToastOnSuccess(ApiAction.PERMANENTLY_DELETE));
    }

    /* TAG ENDPOINTS */

    getPersonTags(id: number): Observable<Tag[] | undefined> {
        return this._http.get<Tag[]>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}/${id}/${this._TAGS_ROUTE}`).pipe(
            tap((personTags) => {
                this._personTags.next(personTags);
                this.getPersonTagsStructured(id).pipe(take(1)).subscribe();
            }),
            switchMap(() => this.personTags$)
        );
    }

    getPersonTagsStructured(id: number): Observable<StructuredTagSet[] | undefined> {
        return this._http.get<StructuredTagSet[]>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}/${id}/${this._TAGS_STRUCTURED_ROUTE}`).pipe(
            tap((personTagsStructured) => {
                this._personTagsStructured.next(personTagsStructured);
            }),
            switchMap(() => this.personTagsStructured$)
        );
    }

    addPersonTags(tagIds: Array<number>, personId: number): Observable<object> {
        return this._http.post(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}/${personId}/${this._TAGS_ROUTE}`, tagIds).pipe(
            tap(() => {
                this.getPersonTagsStructured(personId).pipe(take(1)).subscribe();
            }),
            catchError(() => {
                this.getPersonTagsStructured(personId).pipe(take(1)).subscribe();
                return new Observable<any>();
            })
        );
    }

    deletePersonTags(tagIds: Array<number>, personId: number): Observable<object> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: tagIds
        };

        return this._http.delete(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}/${personId}/${this._TAGS_ROUTE}`, options).pipe(
            tap(() => {
                this.getPersonTagsStructured(personId).pipe(take(1)).subscribe();
            }),
            catchError(() => {
                this.getPersonTagsStructured(personId).pipe(take(1)).subscribe();
                return new Observable<any>();
            })
        );
    }

    /* RELATIONSHIP ENDPOINTS */

    getPersonRelationships(id: number): Observable<RelationshipRequest | undefined> {
        return this._http.get<RelationshipRequest>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}/${id}/${this._RELATIONSHIPS_ROUTE}`).pipe(
            tap((personRelationships) => {
                this._personRelationships.next(personRelationships);
            }),
            switchMap(() => this.personRelationships$)
        );
    }

    getPersonStructuredRelationships(id: number): Observable<StructuredRelationships | undefined> {
        return this._http.get<StructuredRelationships>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}/${id}/${this._RELATIONSHIPS_STRUCTURED_ROUTE}`).pipe(
            tap((personRelationships) => {
                this._personStructuredRelationships.next(personRelationships);
            }),
            switchMap(() => this.personStructuredRelationships$)
        );
    }

    addPersonRelationships(relationships: RelationshipRequest, personId: number): Observable<RelationshipRequest | undefined> {
        return this._http
            .post<RelationshipRequest>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}/${personId}/${this._RELATIONSHIPS_ROUTE}/${this._ADD_RELATIONSHIPS_ROUTE}`, relationships)
            .pipe(
                tap((personRelationships) => {
                    this._personRelationships.next(personRelationships);
                    this.getPersonStructuredRelationships(personId).pipe(take(1)).subscribe();
                }),
                catchError(() => {
                    this.getPersonStructuredRelationships(personId).pipe(take(1)).subscribe();
                    return new Observable<any>();
                }),
                switchMap(() => this.personRelationships$)
            );
    }

    deletePersonRelationships(relationships: RelationshipRequest, personId: number): Observable<RelationshipRequest | undefined> {
        return this._http
            .put<RelationshipRequest>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_ROUTE}/${personId}/${this._RELATIONSHIPS_ROUTE}/${this._REMOVE_RELATIONSHIPS_ROUTE}`, relationships)
            .pipe(
                tap((personRelationships) => {
                    this._personRelationships.next(personRelationships);
                    this.getPersonStructuredRelationships(personId).pipe(take(1)).subscribe();
                }),
                catchError(() => {
                    this.getPersonStructuredRelationships(personId).pipe(take(1)).subscribe();
                    return new Observable<any>();
                }),
                switchMap(() => this.personRelationships$)
            );
    }

    /* AUDIT ENDPOINTS */

    retrieveAudits(personId: number, actions: Array<number>): Observable<Array<Audit> | undefined> {
        return this._http.get<Array<Audit>>(`${this._workspaceService.workspaceApiUrl}/${this._PERSON_AUDIT_ROUTE}/${personId}`, { params: { eventTypes: '[' + actions.toString() + ']' } }).pipe(
            tap((audits) => {
                this._auditList.next(audits);
            }),
            switchMap(() => this.auditList$)
        );
    }

    /* OBJECT LEVEL PERMISSIONS ENDPOINTS */

    listObjectLevelPermissions(objectId: number): Observable<Array<IObjectPermissionRequest> | undefined> {
        return this._http.get<Array<IObjectPermissionRequest>>(`${this._workspaceService.workspaceApiUrl}/${this._PERMISSION_ROUTE}/${this._PERSON_ROUTE}/${objectId}/${this._LIST_ROUTE}`).pipe(
            tap((permissionResults) => {
                this._objectLevelPermissions.next(permissionResults);
            }),
            switchMap(() => this.objectLevelPermissions$)
        );
    }

    createObjectLevelPermission(objectId: number, objectLevelPermission: IObjectPermissionRequest): Observable<object | undefined> {
        return this._http.post<Array<IObjectPermissionRequest>>(`${this._workspaceService.workspaceApiUrl}/${this._PERMISSION_ROUTE}/${this._PERSON_ROUTE}/${objectId}`, objectLevelPermission).pipe(
            tap(() => {
                this.listObjectLevelPermissions(objectId).pipe(take(1)).subscribe();
            })
        );
    }

    updateObjectLevelPermission(objectId: number, objectLevelPermission: IObjectPermissionRequest): Observable<object | undefined> {
        return this._http.put<Array<IObjectPermissionRequest>>(`${this._workspaceService.workspaceApiUrl}/${this._PERMISSION_ROUTE}/${this._PERSON_ROUTE}/${objectId}`, objectLevelPermission).pipe(
            tap(() => {
                this.listObjectLevelPermissions(objectId).pipe(take(1)).subscribe();
            })
        );
    }

    deleteObjectLevelPermission(objectId: number, userId: number): Observable<object | undefined> {
        return this._http
            .delete<Array<IObjectPermissionRequest>>(`${this._workspaceService.workspaceApiUrl}/${this._PERMISSION_ROUTE}/${this._PERSON_ROUTE}/${objectId}`, { params: { userId: userId.toString() } })
            .pipe(
                tap(() => {
                    this.listObjectLevelPermissions(objectId).pipe(take(1)).subscribe();
                })
            );
    }
}
