import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AccountRoutes, AppQueryParams } from '@ta/app/shared/models/routes.model';
import { Tab } from '@ta/app/shared/models/tab.model';

@Component({
    selector: 'ta-blade-account',
    templateUrl: './blade-account.component.html',
    styleUrls: ['./blade-account.component.scss']
})
export class BladeAccountComponent implements OnInit {
    /**
     * Tabs to filter workspaces
     */
    tabs: Tab[] = new Array<Tab>(
        { name: 'system.account-blade.nav.profile', relativeRoute: true, queryParams: { [AppQueryParams.TAB]: AccountRoutes.ACCOUNT } },
        { name: 'system.account-blade.nav.subscriptions', relativeRoute: true, queryParams: { [AppQueryParams.TAB]: AccountRoutes.SUBSCRIPTIONS } },
        { name: 'system.account-blade.nav.my-data', relativeRoute: true, queryParams: { [AppQueryParams.TAB]: AccountRoutes.MY_DATA } }
    );

    /**
     * The current tab to show
     * If null, the blade overlay is hidden
     */
    currentTab: string | null = null;

    /**
     * Make the tab names accessible to the template
     */
    AccountRoutes: any = AccountRoutes;

    constructor(private _route: ActivatedRoute) {}

    ngOnInit(): void {
        this._route.queryParams.subscribe((queryParams: Params) => {
            // Set the current blade, if the queryparam matches a blade on the right of the screen
            this.currentTab = queryParams[AppQueryParams.TAB] ? queryParams[AppQueryParams.TAB] : AccountRoutes.ACCOUNT;
        });
    }
}
