import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToggleService } from '@ta/app/shared-modules/toggle/services/toggle.service';
import { Tab } from '@ta/app/shared/models/tab.model';

@Component({
    selector: 'ta-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
    /**
     * Set of tabs to display (may be null)
     */
    @Input() set tabs(value: Tab[] | undefined) {
        // Where relative route is set, set the route to []
        // This forces the routerLink to make the route relative.
        value?.forEach((tab) => (tab.route = tab.relativeRoute ? [] : tab.route));
        this._tabs = value;
    }
    get tabs(): Tab[] | undefined {
        return this._tabs;
    }
    _tabs?: Tab[];

    @Input()
    rightAlign = false;

    // tslint:disable-next-line: no-output-on-prefix
    @Output() onTabClick: EventEmitter<Tab> = new EventEmitter();

    constructor(private readonly _router: Router, public toggleService: ToggleService) {}

    /**
     * Handles the click event on a tab, emitting the object repersenting the tab that was clicked.
     */
    handleTabClick(event: Tab): void {
        if (event && event.route && !this._router.url.toString().includes(event.route.toString())) {
            this.onTabClick.emit(event);
        }
    }
}
