import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkspaceCreateModelSteps } from '@ta/app/core/models/workspace-create-model-steps.model';
import { FormName } from '@ta/app/shared/models/form.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';

@Component({
    selector: 'ta-workspace-create-model',
    templateUrl: './workspace-create-model.component.html',
    styleUrls: ['./workspace-create-model.component.scss']
})
export class WorkspaceCreateModelComponent implements OnInit {
    ModelSteps: any = WorkspaceCreateModelSteps;

    /**
     * The current step in the model
     */
    @Input()
    currentStep = WorkspaceCreateModelSteps.START;

    @Output()
    closeModel: EventEmitter<any> = new EventEmitter();

    @Output()
    requestStandardWorkspace: EventEmitter<any> = new EventEmitter();

    @Output()
    requestPremiumWorkspace: EventEmitter<any> = new EventEmitter();

    @Output()
    finalisePremiumWorkspaceRequest: EventEmitter<any> = new EventEmitter();

    /**
     * The next step the model will take
     */
    nextStep?: WorkspaceCreateModelSteps;

    showCancelConfirmation = false;

    /**
     * Name of the form used in this component
     */
    standardWorkspaceFormName: FormName = FormName.STANDARD_WORKSPACE_FORM;
    premiumWorkspaceRequestFormName: FormName = FormName.PREMIUM_WORKSPACE_REQUEST;

    constructor(public linkedFormService: LinkedFormService) {}

    ngOnInit(): void {}

    /**
     * Handles the close model click event.
     */
    onCloseModel(): void {
        this.onCancel();
    }

    /**
     * on next | back to step event handler
     */
    goTo(nextStep: WorkspaceCreateModelSteps): void {
        this.showCancelConfirmation = false;
        this.currentStep = nextStep;
    }

    /**
     * Handles the cancel button click event
     */
    onCancel(): void {
        if (this.linkedFormService.isDirty(this.standardWorkspaceFormName) || this.linkedFormService.isDirty(this.premiumWorkspaceRequestFormName)) {
            this.showCancelConfirmation = true;
        } else {
            this.handleCancel();
        }
    }

    /**
     * Handles the process of cancelling the model and resetting data
     */
    handleCancel(): void {
        this.closeModel.emit();
        this.showCancelConfirmation = false;
        this.resetModelSteps();
        this.linkedFormService.markClean(this.standardWorkspaceFormName);
        this.linkedFormService.markClean(this.premiumWorkspaceRequestFormName);
        this.linkedFormService.reset(this.standardWorkspaceFormName);
        this.linkedFormService.reset(this.premiumWorkspaceRequestFormName);
    }

    private resetModelSteps(): void {
        this.nextStep = undefined;
    }

    /**
     * Handles the cancel confirmation click event
     */
    onCancelConfirm(): void {
        this.handleCancel();
    }

    /**
     * Handles the cancel cancellation click event
     */
    onCancelCancel(): void {
        this.showCancelConfirmation = false;
    }

    /**
     * Handles the submission of the standard workspace form
     */
    handleSubmitStandardRequest(): void {
        this.requestStandardWorkspace.emit();
        this.resetModelSteps();
    }

    /**
     * Handles the submission of the premium workspace request form
     */
    handleSubmitPremiumRequest(): void {
        this.requestPremiumWorkspace.emit();
        this.resetModelSteps();
    }

    /**
     * Handles the submission of the finalise premium workspace request
     *
     * Note: this actually submits the standard workspace form.
     */
    handleSubmitFinalisePremiumRequest(): void {
        this.finalisePremiumWorkspaceRequest.emit();
        this.resetModelSteps();
    }

    /**
     * Checks whether a form is Dirty AND Invalid.
     * @param formName the name of the form to check
     * @returns Whether the provided form is Dirty AND Invalid
     */
    isDirtyAndValid(formName: FormName): boolean {
        const form = this.linkedFormService.getForm(formName);
        return form.dirty && form.valid;
    }
}
