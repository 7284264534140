import { Component, ComponentFactoryResolver, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DynamicFormElementSchema } from 'src/app/shared/models/dynamic-form-element-schema.model';
import { DynamicFormDirective } from '@ta/app/dynamic-form/directives/dynamic-form.directive';
import { FormElementBaseComponent } from '@ta/app/dynamic-form/form-element-base/form-element-base.component';
import { FormElementPanelComponent } from '@ta/app/dynamic-form/form-element-panel/form-element-panel.component';

@Component({
    selector: 'ta-form-element-panel-dynamic',
    templateUrl: './form-element-panel-dynamic.component.html',
    styleUrls: ['./form-element-panel-dynamic.component.scss']
})
export class FormElementPanelDynamicComponent extends FormElementBaseComponent implements OnDestroy {
    @ViewChild(DynamicFormDirective, { static: true }) formHost!: DynamicFormDirective;

    private _formArray: FormArray = new FormArray([]);
    private _unsubscribe$: Subject<boolean> = new Subject();

    constructor(private readonly componentFactoryResolover: ComponentFactoryResolver) {
        super();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next(false);
        this._unsubscribe$.complete();
    }

    setElementSchema(schema: DynamicFormElementSchema, form: FormGroup, initialData?: any): void {
        const { templateElements, ...rest } = schema;

        // Rename templateElements to elements so that it matches the panel schema model....
        schema = {
            ...rest,
            elements: templateElements
        };

        super.setElementSchema(schema, form, initialData);

        // Make sure that we have enough forms prefilled so that when the parent patches in the form we get the appropriate data...
        // If it's null just iterate over an empty object for 0 prefilled items....
        const length = Object.keys(initialData ?? {}).length;
        for (let i = 0; i < length; i++) {
            this.onAddForm(i);
        }
    }

    getFormControl(): AbstractControl {
        return this._formArray;
    }

    onAddForm(index?: number): void {
        index = index === undefined ? this._formArray.length : index;

        // Create the panel and set its data
        const component = this.formHost.viewContainerRef.createComponent(this.componentFactoryResolover.resolveComponentFactory(FormElementPanelComponent));
        component.instance.setElementSchema(this.schema, this.form);
        // Make the panel removable
        component.instance.removable = true;
        component.instance.removableIndex = index;

        // When the emitter fires grab the index of the item and clean it up
        // Take until should ensure we always clean up if the emitter doesn't fire
        component.instance.emitter.pipe(takeUntil(this._unsubscribe$), take(1)).subscribe((i) => {
            this.onRemoveForm(i);
        });

        // Add it to the list as it may never
        this._formArray.push(component.instance.getFormControl());
    }

    onRemoveForm(index: number): void {
        this.formHost.viewContainerRef.remove(index);
        this._formArray.removeAt(index);
    }
}
