import { Component, Input } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { SocialProvider } from '@ta/app/core/models/social-login.model';
import { AuthMode } from '@ta/app/core/models/auth-mode.model';

@Component({
    selector: 'ta-auth-social-provider',
    templateUrl: './auth-social-provider.component.html',
    styleUrls: ['./auth-social-provider.component.scss']
})
export class AuthSocialProviderComponent {
    /**
     * The social provider to display
     */
    @Input() socialProvider!: SocialProvider;

    /**
     * The user's email address
     */
    @Input() email?: string;

    /**
     * Whether the button will log in or sign up
     * Defaults to login
     */
    @Input() mode: AuthMode = AuthMode.LOGIN;

    constructor(private readonly _auth0Service: Auth0Service) {}

    /**
     * Handle button click
     */
    onClick(): void {
        this._auth0Service.loginWithRedirect({
            screen_hint: this.mode === AuthMode.SIGNUP ? 'signup' : undefined,
            login_hint: this.email,
            connection: this.socialProvider.connection
        });
    }
}
