import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortObject'
})
export class SortObjectPipe implements PipeTransform {
    /**
     * sort object by specific property. e.g: array | sortObject:'id'
     * @param arr array
     * @param args property of an object to compare
     * @returns sorted array
     */
    transform(arr: any[], args: any): any[] {
        return arr.sort((a, b) => {
            if (a[args] > b[args]) {
                return 1;
            } else if (a[args] > b[args]) {
                return -1;
            }
            return 0;
        });
    }
}
