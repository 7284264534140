import { Component, EventEmitter, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { DynamicFormElementSchema } from 'src/app/shared/models/dynamic-form-element-schema.model';
import { DynamicFormSchema } from 'src/app/shared/models/dynamic-form-schema.model';
import { DynamicFormComponent } from '@ta/app/dynamic-form/dynamic-form/dynamic-form.component';
import { FormElementBaseComponent } from '@ta/app/dynamic-form/form-element-base/form-element-base.component';

@Component({
    selector: 'ta-form-element-panel',
    templateUrl: './form-element-panel.component.html',
    styleUrls: ['./form-element-panel.component.scss']
})
export class FormElementPanelComponent extends FormElementBaseComponent {
    @ViewChild('dynamicForm', { static: true }) formHost!: DynamicFormComponent;

    removable = false;
    removableIndex?: number;
    emitter = new EventEmitter<number>();

    // While the form group is technically null here setElementSchema is always called
    // so just assert this to true here to save checking later.
    // If setElementSchema is not called we have bigger problems.....
    private _formControl!: FormGroup;
    private _validators: (ValidatorFn | null)[] = [];
    private _panelSchema: DynamicFormSchema = { pages: [{ name: 'page0', elements: [] }] };

    setElementSchema(schema: DynamicFormElementSchema, form: FormGroup, initialData?: any): void {
        super.setElementSchema(schema, form, initialData);

        // Take the schema of the panel and massage it into a one-page form so that we can re-use a nested dynamic form element
        this._panelSchema = {
            pages: [
                {
                    name: 'page0',
                    elements: schema.elements ?? []
                }
            ]
        };

        // Set the schema for the nested dynamic form
        const formGroup = this.formHost.generateFormBeforeRender(this._panelSchema);
        // Remove the arbitrary first page nesting and just get the page level controls this will make our
        // answer data model a bit nicer to work with
        this._formControl = formGroup.get('page0') as FormGroup;

        // Cache the validators on the nested form as we are going to manipulate these at runtime based on visibility
        this.getFormControlValidators();

        // Set the initial validator state
        this.setFormControlValidators(schema.visible);
    }

    getFormControl(): AbstractControl {
        return this._formControl;
    }

    onRemove(): void {
        if (this.removable && this.removableIndex !== undefined) {
            this.emitter.emit(this.removableIndex);
        }
    }

    /**
     * Iterates through the form controls for the panel extracting any validator functions.
     * These functions can then be cached and reapplied at a later date based on visibility requirements.
     */
    private getFormControlValidators(): void {
        // We don't want to accidentally override the initial validator cache
        // so only grab them once.
        if (this._validators.length > 0) {
            return;
        }

        Object.keys(this._formControl.controls).forEach((key) => {
            const control = this._formControl.get(key);

            if (!!control) {
                this._validators.push(control.validator);
            }
        });
    }

    /**
     * This function applies a list of cached validators or nothing depending on whether the panel is visible
     * @param visible whether the panel is visible or not (anything NOT false is assumed to be true, including undefined)
     */
    private setFormControlValidators(visible?: boolean): void {
        const keys = Object.keys(this._formControl.controls);

        for (let i = 0; i < keys.length; i++) {
            const control = this._formControl.get(keys[i]);
            const cachedValidator = this._validators[i];

            control?.setValidators(visible !== false ? cachedValidator : null);
            control?.updateValueAndValidity();
        }
    }
}
