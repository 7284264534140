import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { LinkedFormService } from '../../services/linked-form.service';

@Component({
    selector: 'ta-model-form-controls',
    templateUrl: './model-form-controls.component.html',
    styleUrls: ['./model-form-controls.component.scss']
})
export class ModelFormControlsComponent implements OnInit {
    /**
     * Whether the form the controls are related to is dirty, e.g.: should save be disabled
     */
    @Input() dirty = false;

    /**
     * Whether to show the discard changes prompt
     */
    @Input() showDiscardChangesPrompt: Subject<boolean> = new Subject<boolean>();

    /**
     * Optional text to be displayed on the save button, defaults to 'Save Changes'
     */
    @Input() saveButtonText = 'system.button.save-changes';

    /**
     * Button event emitters
     */
    @Output() save: EventEmitter<any> = new EventEmitter();
    @Output() discard: EventEmitter<any> = new EventEmitter();

    constructor(public _linkedFormService: LinkedFormService) {}

    ngOnInit(): void {}

    /**
     * Handle cancel button click
     */
    onCancel(): void {
        this.dirty ? this.showDiscardChangesPrompt.next(true) : this.discardChanges();
    }

    /**
     * Handle save button click
     */
    onSave(): void {
        this.save.emit();
    }

    /**
     * Handle discard cancel click
     */
    onDiscardChangesCancel(): void {
        this.showDiscardChangesPrompt.next(false);
    }

    /**
     * Handle discard confirm click
     */
    onDiscardChangesConfirm(): void {
        this.discardChanges();
    }

    /**
     * Discard changes
     */
    discardChanges(): void {
        this.showDiscardChangesPrompt.next(false);
        this.discard.emit();
    }
}
