import { Component, OnInit } from '@angular/core';
import { AppRoutes } from '@ta/app/shared/models/routes.model';
import { Tab } from '@ta/app/shared/models/tab.model';

@Component({
    selector: 'ta-terms-and-policy-updates',
    templateUrl: './terms-and-policy-updates.component.html',
    styleUrls: ['./terms-and-policy-updates.component.scss']
})
export class TermsAndPolicyUpdatesComponent implements OnInit {
    AppRoutes = AppRoutes;

    /**
     * Tabs
     */
    tabs: Tab[] = new Array<Tab>(
        { name: 'Terms of Service', route: '../' + AppRoutes.TERMS },
        { name: 'Privacy & Data Policy', route: '../' + AppRoutes.PRIVACY },
        { name: 'Updates', route: '../' + AppRoutes.TERMS_AND_POLICY_UPDATES }
    );

    constructor() {}

    ngOnInit(): void {}
}
