import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { buildConfig } from '../config/build-config';
import { AppComponent } from './app.component';
import { AppInitialisationProvider } from './shared/providers/initialisation.provider';
import { CoreModule } from './core/core.module';
import { DynamicFormModule } from './dynamic-form/dynamic-form.module';
import { EnvironmentService } from './shared/services/environment.service';
import { routes } from './app.routes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { UserNotFinalisedGuard } from './shared/guards/user-not-finalised.guard';
import { AuthenticatedGuard } from './shared/guards/authenticated.guard';
import { UnauthenticatedGuard } from './shared/guards/unauthenticated.guard';
import { UserFinalisedGuard } from './shared/guards/user-finalised.guard';
import { EmailNotVerifiedGuard } from './shared/guards/email-not-verified.guard';
import { EmailVerifiedGuard } from './shared/guards/email-verified.guard';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AuthModule.forRoot(),
        RouterModule.forRoot(routes, { enableTracing: !buildConfig.PRODUCTION }),
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        DynamicFormModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CoreModule,
        SharedModule
    ],
    providers: [
        EnvironmentService,
        AppInitialisationProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        UnauthenticatedGuard,
        AuthenticatedGuard,
        UserNotFinalisedGuard,
        UserFinalisedGuard,
        EmailNotVerifiedGuard,
        EmailVerifiedGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
