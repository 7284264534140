import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from '@ta/app/shared/models/routes.model';
import { Tab } from '@ta/app/shared/models/tab.model';
import { Glossary } from '../../models/glossary.model';

@Component({
    selector: 'ta-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
    AppRoutes = AppRoutes;

    /**
     * Tabs for navigation
     */
    tabs: Tab[] = new Array<Tab>(
        { name: 'Terms of Service', route: '../' + AppRoutes.TERMS },
        { name: 'Privacy & Data Policy', route: '../' + AppRoutes.PRIVACY },
        { name: 'Updates', route: '../' + AppRoutes.TERMS_AND_POLICY_UPDATES }
    );

    constructor() {}

    ngOnInit(): void {}
}
