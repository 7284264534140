import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Crumb } from '@ta/app/shared/models/crumb.model';
import { AssessmentRoutes } from '@ta/app/shared/models/routes.model';

@Component({
    selector: 'ta-assessment-header',
    templateUrl: './assessment-header.component.html',
    styleUrls: ['./assessment-header.component.scss']
})
export class AssessmentHeaderComponent {
    /**
     * The assessment header for the questionaire
     */
    @Input()
    assessmentHeader?: string;

    /**
     * The assessment sub header for the questionaire
     */
    @Input()
    assessmentSubHeading?: string;

    /**
     * Set of breadcrumbs to display
     */
    @Input() crumbs: Array<Crumb> = [];

    constructor(private _router: Router, private _route: ActivatedRoute) {}

    /**
     * Navigate to previous page
     */
    onNavigateBack(): void {
        this._router.navigate(['../../' + AssessmentRoutes.QUESTIONS], { relativeTo: this._route });
    }
}
