import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ta-layout-static',
    templateUrl: './layout-static.component.html',
    styleUrls: ['./layout-static.component.scss']
})
export class LayoutStaticComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
