import { Component, Input } from '@angular/core';
import { ChartLegendItem } from '../../models/insights/chart-legend-item.model';

@Component({
    selector: 'ta-chart-legend',
    templateUrl: './chart-legend.component.html',
    styleUrls: ['./chart-legend.component.scss']
})
export class ChartLegendComponent {
    /**
     * List of ChartLegendItems to display
     */
    @Input()
    legendItems!: Array<ChartLegendItem>;

    /**
     * Translation key for text to be displayed as title
     */
    @Input()
    title!: string;

    @Input()
    wide = false;

    constructor() {}
}
