import { Component, Input, OnDestroy } from '@angular/core';
import { WorkspaceRoutes } from '@ta/app/shared/models/routes.model';
import { NavbarLeftMenuItem } from '@ta/app/core/models/navbar-left-menu-item.model';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';
import { filter, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ToggleService } from '@ta/app/shared-modules/toggle/services/toggle.service';
import { Toggle } from '@ta/app/shared-modules/toggle/models/toggle.model';

@Component({
    selector: 'ta-navbar-left',
    templateUrl: './navbar-left.component.html',
    styleUrls: ['./navbar-left.component.scss']
})
export class NavBarLeftComponent implements OnDestroy {
    /**
     * Whether we are in small form-factor mode
     */
    @Input() set belowResponsiveBreakpoint(value: boolean) {
        // Set the value of the private variable
        this._belowResponsiveBreakpoint = value;
        // Also set compact mode
        this.compactMode = this._belowResponsiveBreakpoint;
        // Set to expanded floating mode when below the breakpoint and also expanded
        this.expandedFloatingMode = !this.compactMode && this._belowResponsiveBreakpoint;
        // Reset the isToggleDirty state
        this.isToggleDirty = false;
    }
    get belowResponsiveBreakpoint(): boolean {
        return this._belowResponsiveBreakpoint;
    }
    _belowResponsiveBreakpoint = false;

    /**
     * Define sidebar routes
     */
    aboveDividerMenus: Array<NavbarLeftMenuItem> = [];
    belowDividerMenus: Array<NavbarLeftMenuItem> = [];

    /**
     * UI state
     */
    compactMode = false;

    /**
     * Whether the sidenav toggle has been touched by the user
     */
    isToggleDirty = false;

    /**
     * Whether The side nav is expanded and floating over the UI
     * This occurs when we are in small form-factor mode and the sidenav is manually expanded
     */
    expandedFloatingMode = false;

    /**
     * Single subscription to simplify clean-up on destroy
     */
    private _subscription?: Subscription;

    constructor(private readonly _translateService: TranslateService, private readonly _toggleService: ToggleService, private readonly _workspaceService: WorkspaceService) {
        // Fetch workspace details
        this._subscription = _workspaceService.workspace$
            .pipe(
                filter((workspace) => !!workspace),
                tap((workspace) => {
                    // Dynamically generate sidenav
                    this.aboveDividerMenus = [
                        {
                            icon: 'ta-home',
                            label: this._translateService.instant('system.nav.sidenav.dashboard'),
                            path: [workspace!.globalId, WorkspaceRoutes.DASHBOARD]
                        },
                        {
                            icon: 'ta-folder-briefcase',
                            label: this._translateService.instant('system.nav.sidenav.directories'),
                            path: [''],
                            toggle: Toggle.DIRECTORIES,
                            subMenus: [
                                {
                                    icon: 'ta-briefcase',
                                    label: this._translateService.instant('system.nav.sidenav.jobs'),
                                    path: [workspace!.globalId, WorkspaceRoutes.JOBS],
                                    toggle: Toggle.JOBS
                                },
                                {
                                    icon: 'ta-location',
                                    label: this._translateService.instant('system.nav.sidenav.sites'),
                                    path: [workspace!.globalId, WorkspaceRoutes.SITES],
                                    toggle: Toggle.SITES
                                },
                                {
                                    icon: 'ta-people-community',
                                    label: this._translateService.instant('system.nav.sidenav.people'),
                                    path: [workspace!.globalId, WorkspaceRoutes.PEOPLE],
                                    toggle: Toggle.PEOPLE
                                }
                            ]
                        },
                        {
                            icon: 'ta-clipboard-task',
                            label: this._translateService.instant('system.nav.sidenav.evaluations'),
                            path: [''],
                            subMenus: [
                                {
                                    icon: 'ta-clipboard-text',
                                    label: this._translateService.instant('system.nav.sidenav.assessments'),
                                    path: [workspace!.globalId, WorkspaceRoutes.ASSESSMENTS]
                                },
                                {
                                    icon: 'ta-scales',
                                    label: this._translateService.instant('system.nav.sidenav.comparisons'),
                                    path: [workspace!.globalId, WorkspaceRoutes.COMPARISONS],
                                    disabled: Toggle.COMPARISONS
                                }
                            ]
                        },
                        {
                            icon: 'ta-data-pie',
                            label: this._translateService.instant('system.nav.sidenav.reporting'),
                            path: [workspace!.globalId, WorkspaceRoutes.REPORTING],
                            toggle: Toggle.REPORTING
                        }
                    ];

                    this.belowDividerMenus = [
                        {
                            icon: 'ta-settings',
                            label: this._translateService.instant('system.nav.sidenav.admin'),
                            path: [workspace!.globalId, WorkspaceRoutes.ADMIN],
                            toggle: Toggle.POWER_USER
                        }
                    ];
                })
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        // Clean up subscriptions
        this._subscription?.unsubscribe();
    }

    /**
     * Handle toggle button
     */
    toggleCompact(): void {
        // Set the toggle as dirty
        this.isToggleDirty = true;
        // Toggle compact mode
        this.compactMode = !this.compactMode;
        // Set to expanded floating mode when below the breakpoint and also expanded
        this.expandedFloatingMode = !this.compactMode && this._belowResponsiveBreakpoint;
    }

    /**
     * Handle menu or submenu clicked
     */
    handleMenuItemClick(): void {
        if (this._belowResponsiveBreakpoint) this.compactMode = true;
    }
}
