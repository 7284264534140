import { Component, ComponentRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DynamicFormSchema } from 'src/app/shared/models/dynamic-form-schema.model';
import { DynamicFormStateChange } from 'src/app/shared/models/dynamic-form-state-change.model';
import { defaultPagination, Pagination } from 'src/app/shared/models/pagination.model';
import { DynamicFormDirective } from '@ta/app/dynamic-form/directives/dynamic-form.directive';
import { FormElementWrapperComponent } from '@ta/app/dynamic-form/form-element-wrapper/form-element-wrapper.component';
import { DynamicElementService } from '@ta/app/dynamic-form/services/dynamic-element.service';

@Component({
    selector: 'ta-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnDestroy, OnChanges {
    // Visibility (do we include numbering or not, if so how do we handle numbering)
    // TODO: I think there is a pagination bug (re pages vs pagination object)

    // Readonly for components
    // Iterations....
    // LOGIC >:(

    @Input()
    hideControls = false;

    @Input()
    schema?: DynamicFormSchema;

    @Input()
    initialData?: any;

    @Input()
    page = 0;

    @Output()
    stateChange = new EventEmitter<DynamicFormStateChange>();

    @ViewChild(DynamicFormDirective, { static: true }) formHost!: DynamicFormDirective;

    pagination: Pagination = defaultPagination;

    private _form: FormGroup = new FormGroup({});
    private _formInitialized = false;
    private _components?: Array<Array<ComponentRef<FormElementWrapperComponent>>> = [];
    private _unsubscribe$: Subject<boolean> = new Subject();

    constructor(private readonly dynamicElementService: DynamicElementService) { }

    ngOnInit(): void {
        this._form.valueChanges.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
            this.stateChange.emit({
                form: this._form,
                pagination: this.pagination
            });
        });
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next(false);
        this._unsubscribe$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Only try to generate a form once
        if (!this._formInitialized) {
            this.generateDynamicForm();
        }

        // Try and get the current page number (it might not be an input)
        let page = changes.page?.currentValue;

        // If page isn't supplied or isn't valid just assume we want page 0.
        // This should only fire when we actually change pages
        // which means that having it set to NaN won't override page changes as the form
        // alters its own internal state.
        if (isNaN(page)) {
            page = 0;
        }

        // Clamp between 0 and page count
        page = Math.max(0, Math.min(page, this.pagination.pageCount - 1));
        this.onPageChange(page);
    }

    onNextClicked(): void {
        this.onPageChange(this.pagination.page + 1);
    }

    onPreviousClicked(): void {
        this.onPageChange(this.pagination.page - 1);
    }

    /**
     * This is an escape hatch for generating sub-forms or setting other data
     * before the component gets a chance to render. Use this in conjunction with a ViewChild.
     * See the form-element-panel for an example.
     * @param schema A dynamic form schema as per SurveyJS specifications
     */
    generateFormBeforeRender(schema: DynamicFormSchema, initialData?: any): FormGroup {
        this.schema = schema;
        this.initialData = initialData;
        this.generateDynamicForm();
        this.onPageChange(0);
        return this._form;
    }

    /**
     * This is an escape hatch for reusing this component when the schema changes but the component doesn't.
     * To manage form control bindings a schema can only be initialized on this component once.
     * If you MUST keep this component but need to reset it, create a ViewChild to access this component,
     * reset it manually and then update it's schema accordingly...
     */
    resetForm(): void {
        this.schema = undefined;
        this.initialData = undefined;
        this.page = 0;
        this._form = new FormGroup({});
        this._formInitialized = false;
    }

    private onPageChange(page: number): void {
        this.pagination = {
            page,
            pageCount: this.schema?.pages.length ?? 0,
            isOnFirstPage: page === 0,
            isOnLastPage: page === (this.schema?.pages.length ?? 1) - 1
        };

        this.setPaginatedElements();
    }

    private generateDynamicForm(): void {
        if (!this.schema) {
            return;
        }

        // Clear the current ng-container as we want to rebuild the entire form
        const viewContainerRef = this.formHost.viewContainerRef;
        viewContainerRef.clear();

        this._components = this.dynamicElementService.createAndBindDynamicFormElements(this.schema, this._form, this.initialData);

        if (this.initialData) {
            this._form.patchValue(this.initialData);
        }

        this._formInitialized = true;
    }

    private setPaginatedElements(): void {
        if (!this._components) {
            return;
        }

        const viewContainerRef = this.formHost.viewContainerRef;

        // Detach any current elements without deleting them so we can use them later
        while (viewContainerRef.length > 0) {
            viewContainerRef.detach(0);
        }

        // We can get into a case where a schema was not properly defined and no components
        // generated. Just ignore pagination if the page is invalid by returning...
        const components = this._components[this.pagination.page];

        if (!components) {
            return;
        }

        // Attach only the elements from the current page
        for (const component of components) {
            viewContainerRef.insert(component.hostView);
        }

        this.stateChange.emit({
            form: this._form,
            pagination: this.pagination
        });
    }
}
