import { Component, OnInit } from '@angular/core';
import { AppRoutes } from '@ta/app/shared/models/routes.model';
import { Tab } from '@ta/app/shared/models/tab.model';
import { Glossary } from '../../models/glossary.model';

@Component({
    selector: 'ta-terms-conditions',
    templateUrl: './terms-conditions.component.html',
    styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
    AppRoutes = AppRoutes;

    /**
     * Tabs
     */
    tabs: Tab[] = new Array<Tab>(
        { name: 'Terms of Service', route: '../' + AppRoutes.TERMS },
        { name: 'Privacy & Data Policy', route: '../' + AppRoutes.PRIVACY },
        { name: 'Updates', route: '../' + AppRoutes.TERMS_AND_POLICY_UPDATES }
    );

    /**
     * Substitles for dropdowns
     */
    usingTaskAlyserSubtitles: Array<string> = [
        'Who can use our service? ',
        'Our license to you',
        'Commercial use of TaskAlyser',
        'Users as administrators and data controllers',
        'Our relationship with you '
    ];
    yourInformationSubtitles: Array<string> = ['Information you and others capture', 'Information responsibilities for data controllers'];

    /**
     * Glossary of terms
     */
    glossary: Glossary[] = [
        {
            meaning: 'This consists of all services we provide now, and in the future, including but not limited to the TaskAlyser website, application, apps and all APIs.',
            term: ['TaskAlyser', 'Service', 'Platform']
        },
        {
            meaning: 'Terms of service, privacy and data policy.',
            term: ['Terms']
        },
        {
            meaning: 'TaskAlyser Pty Ltd (Australia) /n ABN: 90 641 821 927',
            term: ['We', 'Us', 'Our']
        },
        {
            meaning: 'A user of TaskAlyser ',
            term: ['You', 'Your', 'Yourself', 'Individual', 'User']
        },
        {
            meaning: 'Any legal entity which controls a business workspace in TaskAlyser. ',
            term: ['Business']
        },
        {
            meaning: 'Businesses and individuals. ',
            term: ['Parties', 'Party']
        },
        {
            meaning:
                'Businesses and individuals that have a commercial, medical or legal reason to interact with a party for the purpose of maximising health, injury prevention, and recovery outcomes. ',
            term: ['Collaborating Parties']
        },
        {
            meaning:
                'Is a natural person, a legal entity, a business, an agency, a public authority, or any other body who, alone, or when joined with others, determines the capture, maintenance, and purpose of data as well as the means of processing it. ',
            term: ['Data Controller', 'Controller', 'Controls']
        },
        {
            meaning: 'Is a natural person, a legal entity, a business, an agency, a public authority, or any other body who processes personal data on behalf of a data controller. ',
            term: ['Data Processor', 'Processor', 'Processes']
        }
    ];

    constructor() {}

    ngOnInit(): void {}
}
