<div class="privacy-component-container">
    <div class="header">
        <a routerLink="/workspace/"><i class="ta ta-logo-word"></i></a>
    </div>

    <div class="tabs">
        <!-- Tabs -->
        <ta-tabs [tabs]="tabs" [rightAlign]="true"></ta-tabs>
    </div>
    <p-scrollPanel #scroll [style]="{ width: '100%' }" class="scroll">
        <div class="privacy-container">
            <div class="privacy-container__body">
                <div class="privacy-container__body__header">
                    <h1>Privacy & Data Policy</h1>
                </div>
                <h5>Welcome and thank you for using TaskAlyser</h5>
                <p>We define TaskAlyser as the set of all services we provide now, and in the future, including, but not limited to, the TaskAlyser website, web application, apps, and all APIs.</p>
                <p>By accessing or using TaskAlyser, you agree to be bound by our <a [routerLink]="'/' + AppRoutes.TERMS">Terms of Service</a> and Privacy & Data Policy.</p>
                <p>These terms of service govern your access to and use of TaskAlyser. Please read these Terms carefully, and contact us if you have any questions.</p>

                <h5>Updates</h5>
                <p>
                    We keep a <a [routerLink]="'/' + AppRoutes.TERMS_AND_POLICY_UPDATES">record of updates</a> to this document, which lists each change we have made since our initial release in April
                    2022.
                </p>

                <!--     Your Privacy  -->
                <ta-content-dropdown [title]="'    Your Privacy '">
                    <!-- simply put -->
                    <div class="privacy-container__body__divide">
                        <div class="privacy-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    We take your privacy very seriously. We will only use information for the purpose it was collected. We’ll only release information, when necessary, only in defined
                                    ways, and for specific reasons.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="privacy-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                Our service is required to comply with the Australian Privacy Act 1988 (Cth), including the Australian Privacy Principles (APPs) when handling personal information. We
                                also seek to comply with the European Union General Data Protection Regulation (GDPR).
                                <br /><br />
                                In some cases, we may be required to handle personal information in accordance with legislation and requirements.
                                <br /><br />
                                All information you provide will be collected, stored and used in accordance with our terms of use and this privacy and data policy.
                                <br /><br />
                                If you provide us with personal information about a third party, we collect it on the basis that you have that person’s consent for us to collect and handle their
                                personal information in accordance with this policy. If you are an organisation or other legal entity, you will also be required to maintain a data policy contact email
                                address which will be shared with relevant persons on our site.
                                <br /><br />
                                When collecting your personal information, we will take reasonable steps to provide you with certain information as required under the APPs, including the purposes of
                                collection, the types of organisations or people to whom we may disclose your personal information, any law that requires or authorises us to collect the information
                                and the main consequences if we do not collect all of the personal information we require. If we collect your personal information from another source, we will take
                                reasonable steps to ensure you are aware of the fact and the circumstances of that collection.
                                <br /><br />
                                We will use and disclose your personal information for the purposes for which we collected it, for other related purposes that you would reasonably expect, and as
                                otherwise permitted or required by law.
                                <br /><br />
                                We may disclose your personal information to our agents and contractors, for the purposes set out above, and for the purposes of those parties providing services to us
                                or performing business services or functions on our behalf. When doing so, we will require all agents and contractors to uphold the standards of the APPs (and other
                                relevant applicable privacy schemes).
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     Privacy choices  -->
                <ta-content-dropdown [title]="'    Privacy choices '">
                    <!-- simply put -->
                    <div class="privacy-container__body__divide">
                        <div class="privacy-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    If those you collaborate with on our service are happy for you to use a pseudonym, then we are too. If you want to know what our service knows about you, then you
                                    can ask and we will inform you.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="privacy-container__body__divide--right">
                            <!-- explanation -->
                            <div class="explanation">
                                <p>We allow users several choices relating to their privacy:</p>
                                <ul>
                                    <li>
                                        We allow you to use a pseudonym in place of your real name. However, you should consult the administrators of each workspace that you are a member of to
                                        determine if they permit pseudonyms for data under their control.
                                    </li>
                                    <li>
                                        We offer users the right to know what our service knows about you. On request we can provide a report on what our service knows about you. Please refer to the
                                        Privacy Policy for further information.
                                    </li>
                                    <li>
                                        We provide users the right to be forgotten. You can invoke this right at any time by deleting your account with us. This is achieved by accessing your profile
                                        settings page and selecting to delete your account. We will delete your credentials, personal workspace, and other unnecessary data relating to your account.
                                        However, for compliance purposes our service is required to maintain audit trails in relation to data access, editing and sharing. This means we cannot forget
                                        you entirely. Actions you perform within a business workspace may appear in audit trails within that workspace for as long as the data continues to exist.
                                    </li>
                                    <li>
                                        We provide users with contact information about data controllers for workspaces they have access to. This allows users to seek further privacy control
                                        information from those data controllers.
                                    </li>
                                    <li>
                                        We provide users with information on where the data for their workspace is physically located. For personal workspaces, this information is available in your
                                        user profile. For business workspaces, this information is available in the Administration section of your workspace.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     We collect information in a few different ways  -->
                <ta-content-dropdown [title]="'    We collect information in a few different ways '">
                    <!-- simply put -->
                    <div class="privacy-container__body__divide">
                        <div class="privacy-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    We only collect what we need in order to provide you and others the most informative and meaningful service possible. We collect technical information to support
                                    and improve our site, so we can give you the best experience possible. Your browser also stores some technical information, so we can keep your session secure and
                                    give you a great experience.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="privacy-container__body__divide--right">
                            <!-- explanation -->
                            <div class="explanation">
                                <p>We collect information in several ways, some of it personal, some not.</p>

                                <ul>
                                    <li>
                                        <b>When you or others give it to us</b>: Our service allows users to provide a range of information relating to workforce health, workplace injury prevention
                                        and injury recovery. Some of this is personal, some is not.
                                    </li>
                                    <li>
                                        <b>Log Data</b>: When you use our service, our servers record information provided by your device, such as your browser, operating system (Windows, iOS,
                                        Android, etc.) and Internet Protocol address (IP address). Our service also logs the pages you visit, any errors which may have occurred, and so on.
                                    </li>
                                    <li>
                                        <b>Cookie Data</b>: We also use cookies. These are small text files held by your browser and sent each time you visit and use our site. We use session cookies
                                        (which last until you close your browser) and persistent cookies (which last until your browser deletes them).
                                    </li>
                                    <li>
                                        <b>Browser Storage</b>: We use browser storage. This is like short term memory for your browser which helps us keep your session secure and performing well. We
                                        use session storage (which lasts until you close your browser) and local storage (which lasts until your browser deletes it).
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     The types of information we collect  -->
                <ta-content-dropdown [title]="'    The types of information we collect '">
                    <!-- simply put -->
                    <div class="privacy-container__body__divide">
                        <div class="privacy-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    We collect a range of information, all of it aligned to our goal of helping the world capture, understand, manage and appropriately distribute information relating
                                    to workforce health, workplace injury prevention, injury recovery and returning worker outcomes.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="privacy-container__body__divide--right">
                            <!-- explanation -->
                            <div class="explanation">
                                <p>We collect several types of information, some of it personal, some not.</p>

                                <p><b>Technical Information</b>:</p>
                                <p>
                                    <b>Personal Information</b>: In order to establish your account, we require you to voluntarily provide some basic information. This helps us and others collaborate
                                    and communicate with you in the workspaces you are part of. Personal information includes, but may not be not limited to:
                                </p>
                                <ul>
                                    <li>Email address.</li>
                                    <li>Full name (or pseudonym).</li>
                                </ul>

                                <p>
                                    <b>Legal Entity Information</b>: In order to establish a business workspace, we require you to voluntarily provide information about the legal entity your business
                                    workspace will represent. This includes, but may not be limited to:
                                </p>

                                <ul>
                                    <li>Business name.</li>
                                    <li>Legal name.</li>
                                    <li>Country of business.</li>
                                    <li>A unique identifier for your business.</li>
                                </ul>

                                <p>
                                    <b>Job, Person and Site Directory Information</b>: This allows controllers and their delegates to define aspects of the legal entity their business workspace
                                    represents. This may contain business and personal information including, but may not be limited to:
                                </p>

                                <ul>
                                    <li>Names/titles.</li>
                                    <li>Descriptions.</li>
                                    <li>Contact information (people only).</li>
                                    <li>Geospatial information (sites only).</li>
                                    <li>Relationships.</li>
                                    <li>Tags and other metadata.</li>
                                </ul>

                                <p>
                                    <b>Assessment Information</b>: This allows controllers and their delegates to assess a person, job or site relating to the legal entity their business workspace
                                    represents, or in the case of collaborating parties, the legal entity they provide services to. This may contain business and personal information including, but
                                    may not be limited to:
                                </p>

                                <ul>
                                    <li>Names/titles.</li>
                                    <li>Descriptions.</li>
                                    <li>Dates.</li>
                                    <li>Physical assessments.</li>
                                    <li>Medical and general health assessments.</li>
                                    <li>Psychosocial assessments.</li>
                                    <li>Risk assessments.</li>
                                    <li>Unstructured content (gallery).</li>
                                    <li>Tags and other metadata.</li>
                                </ul>

                                <p>
                                    <b>Comparative Information</b>: This allows controllers and their delegates to reapply assessment data already collected, to compare assessments of people, jobs and
                                    sites against each other, and determine suitability and fit.
                                </p>
                                <p>
                                    <b>Reporting Information</b>: This allows controllers and their delegates to reapply collected data to produce a variety of exportable and interactive reporting and
                                    insights.
                                </p>
                                <p>
                                    <b>Audit Trail Information</b>: This information is collected whenever a user interacts with key information in a workspace. This allows controllers and their
                                    delegates to meet compliance, proof and traceability requirements.
                                </p>
                                <p>
                                    <b>Communication and Notification Information</b>: This allows users to receive information and notification of events occurring on our service and within
                                    workspaces they have access to.
                                </p>
                                <p>
                                    <b>Derived and Statistical Information</b>: As part of our processing of your data, we may produce derived data that allows us to improve, develop and protect our
                                    services, create new services, communicate with you about our service, and send you information we think may be of interest to you. We may also produce anonymised
                                    statistical datasets, including through aggregation processes. We own any anonymised statistical datasets we produce, and may use it for our own purposes. This may
                                    include providing you and others with trend or comparative data at local, national, regional and global levels.
                                </p>
                            </div>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     Collection consent   -->
                <ta-content-dropdown [title]="'    Collection consent  '">
                    <!-- simply put -->
                    <div class="privacy-container__body__divide">
                        <div class="privacy-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    At key stages in an assessment’s lifecycle, controllers or their delegates are required to confirm they have obtained the necessary consent from the subject of that
                                    assessment. This control helps ensure controllers are accountable for any data they collect.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="privacy-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                At the time of completion of an assessment, and for each instance of its sharing with another workspace, controllers or their delegates are required to confirm they
                                have obtained the necessary consent from the subject of that assessment.
                                <br /><br />
                                As processors, we are not in control of data collection consent, nor do we have full visibility of the relationship between parties that would allow us to do so.
                                Instead, we implement this feature to defend your right to privacy and to ensure that an identifiable individual (either a controller or one of their delegates) claims
                                accountability for obtaining this consent.
                                <br /><br />
                                If you believe that data has been collected without your consent, please get in contact with us and we will investigate and if necessary, take action within our power
                                to remedy the situation.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     What we do with your information  -->
                <ta-content-dropdown [title]="'    What we do with your information '">
                    <!-- simply put -->
                    <div class="privacy-container__body__divide">
                        <div class="privacy-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    Your data is your own, we only process it. But in the act of processing your data we may create derived anonymised statistical datasets, and this data is ours.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="privacy-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                We don’t own the data you or others capture on our service, but you grant us a license to process that data.
                                <br /><br />
                                As part of this processing, we may produce derived data that allows us to improve, develop and protect our services, create new services, communicate with you about our
                                service, send you information we think may be of interest to you.
                                <br /><br />
                                We may also produce anonymised statistical datasets, including through aggregation processes. We own any anonymised statistical datasets we produce, and may use it for
                                our own purposes. This may include providing you and others with trend or comparative data at local, national, regional and global levels.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     Where we store your information  -->
                <ta-content-dropdown [title]="'    Where we store your information '">
                    <!-- simply put -->
                    <div class="privacy-container__body__divide">
                        <div class="privacy-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">If we have a datacentre in your country, we will put your data there. If we don’t, we’ll try to get it as close to you as we can.</p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="privacy-container__body__divide--right">
                            <!-- explanation -->
                            <div class="explanation">
                                Our service is designed as a distributed global service. We store our data in one of two ways:

                                <p>
                                    <b>Global Data</b>: Data that provides shape, form and structure to our service. This form of data changes infrequently, but needs to be available in all regions
                                    around the world so we can provide you a scalable service. We replicate this type of data across all datacentres we operate. When you access global data, you access
                                    it from our datacentre closest to you. <br /><br />
                                    Examples of global data include:
                                </p>

                                <ul>
                                    <li>Core User Information.</li>
                                    <li>Core Workspace Definition Data.</li>
                                    <li>Permission Data.</li>
                                    <li>Cross workspace sharing references.</li>
                                </ul>

                                <p>
                                    <b>Workspace Data</b>: Data in a user or business workspace that you, or others control and own. We store workspace data in a single geographical region, based on
                                    your country of origin. If we have services in your country, we will place your data in your country. If we do not, we will pick a datacentre location on the basis
                                    of trying to maximise your data sovereignty outcomes. Regardless of where you access our service from, workspace data will always retrieve it from where it has been
                                    stored. <br /><br />
                                    Examples of workspace data include:
                                </p>

                                <ul>
                                    <li>Job, site, and person directory information.</li>
                                    <li>Assessment and comparison information.</li>
                                    <li>Insights and reporting information.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     How long we keep your information  -->
                <ta-content-dropdown [title]="'    How long we keep your information '">
                    <!-- simply put -->
                    <div class="privacy-container__body__divide">
                        <div class="privacy-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    We keep user information until it is deleted by the last user who needed that information, and for a period after that to ensure a reliable service to you.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="privacy-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                We retain user information for as long as it is still needed by users. In the cases where controllers have long term or permanent retention requirements, this means we
                                may not be able to delete data for an indeterminant length of time.
                                <br /><br />
                                When a user permanently deletes information, we permanently delete our active copy of that data in that user’s workspace. However, we are also required to maintain
                                backups and logs so we can provide a reliable service to you. This means we may still need to hold deleted data in inactive forms for a period following its permanent
                                deletion by you.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!-- What happens when you or others share information -->
                <ta-content-dropdown [title]="'What happens when you or others share information'">
                    <!-- simply put -->
                    <div class="privacy-container__body__divide">
                        <div class="privacy-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    When you share information you are giving a copy of your data to the recipient’s workspace. The controllers of that workspace then become the owner of the copy you
                                    have given them.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="privacy-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                When structured information is shared between workspaces, such as assessment data, we copy the data into the recipient’s workspace. The controllers of that workspace
                                then become the owner of the copy you have given them. This type of sharing cannot be withdrawn.
                                <br /><br />
                                When unstructured information is shared between workspaces, such as gallery content, we copy a reference to your data, which the recipient can access. This type of
                                sharing can be withdrawn by making the content private again. However, while content is shared it can be copied by the recipient’s workspace, granting them a permanent
                                copy which they own. We therefore cannot ensure the privacy of any data once you, or others, in your workspace have shared it.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     Information about children  -->
                <ta-content-dropdown [title]="'    Information about children '">
                    <!-- simply put -->
                    <div class="privacy-container__body__divide">
                        <div class="privacy-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    We do not allow data to be collected for people under the age of 13. We take data collection about minors very seriously. Do no collect this type of data unless you
                                    have cause, comply with all applicable laws and our terms.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="privacy-container__body__divide--right">
                            <!-- explanation -->
                            <div class="explanation">
                                <p>
                                    Children under 16 are not allowed to use our service. Users must not capture data about children under the age of 13. Data Controllers and their delegates are
                                    responsible for any breeches of this and we will take action to remove this data if we become aware of its existence. <br /><br />
                                    Data controllers and their delegates must make themselves aware of any laws of your country relating to collection of data about minors, and comply with all
                                    applicable laws.
                                    <br /><br />
                                    Data controllers and their delegates can only collect data with purpose. This extends to data about minors. Even if all applicable laws have been met by
                                    controllers, we restrict valid purposes to:
                                </p>
                                <ul>
                                    <li>The workspace’s legal entity employs the minor’s whose data is being collected.</li>
                                    <li>A collaborating party is providing a commercial service to a legal entity who employs the minor’s whose data is being collected.</li>
                                    <li>The workspace is owned by the parent or legal guardian of the minor whose data is being collected.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     Feedback you provide  -->
                <ta-content-dropdown [title]="'    Feedback you provide '">
                    <!-- simply put -->
                    <div class="privacy-container__body__divide">
                        <div class="privacy-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">We can use your feedback to make our service better.</p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="privacy-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                We value hearing from our users, and are always interested in learning about ways we can make our service better. If you provide feedback, you agree that we are free to
                                use what you provide freely without any restriction or compensation to you. By accepting your feedback, we retain any rights to use similar or related feedback
                                previously known to us, or obtained from sources other than you.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <h5>Contact Us</h5>
                <p>You can get in contact with us at the following email addresses:</p>
                <ul>
                    <li><a href="mailto:feedback@taskalyser.com">feedback@taskalyser.com</a></li>
                    <li><a href="mailto:support@taskalyser.com">support@taskalyser.com</a></li>
                    <li><a href="mailto:sales@taskalyser.com">sales@taskalyser.com</a></li>
                </ul>
            </div>
        </div>
    </p-scrollPanel>
</div>
