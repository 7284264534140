import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppQueryParams, BladeQueryParamValues } from '@ta/app/shared/models/routes.model';
import { BladeService } from '@ta/app/shared/services/blade.service';

@Component({
    selector: 'ta-blade',
    templateUrl: './blade.component.html',
    styleUrls: ['./blade.component.scss']
})
export class BladeComponent implements OnInit {
    /**
     * Make the blade names accessible to the template
     */
    BladeQueryParamValues: any = BladeQueryParamValues;

    /**
     * The current blade to show
     * If null, the blade overlay is hidden
     */
    currentBlade: string | null = null;

    /**
     * A list of blades that show on the right of the screen
     */
    rightBlades: Array<string> = [BladeQueryParamValues.ACCOUNT, BladeQueryParamValues.HELP, BladeQueryParamValues.NOTIFICATIONS];

    /**
     * Prevent click propagation to prevent interference with the click handler
     * that closes open blades. See app.component.ts
     */
    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        event.stopPropagation();
    }

    constructor(private _bladeService: BladeService, private _router: Router, private _route: ActivatedRoute) {}

    ngOnInit(): void {
        this._route.queryParams.subscribe((queryParams: Params) => {
            // Set the current blade, if the queryparam matches a blade on the right of the screen
            this.currentBlade = this.rightBlades.includes(queryParams[AppQueryParams.BLADE]) ? queryParams[AppQueryParams.BLADE] : null;
        });
    }

    /**
     * Handle when user clicks acrylic overlay
     */
    onOverlayClick(): void {
        this._bladeService.closeBladesAndMenus();
    }
}
