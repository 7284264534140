<div class="admin-header-container">
    <div class="admin-header-container__top-filler"></div>
    <div class="admin-header-container__content">
        <div class="admin-header-container__content__title">
            <h1>{{ 'system.nav.admin' | translate }}</h1>
        </div>
        <ng-content *ngIf="!edit; else editActions" class="main__projected-actions"></ng-content>
        <ng-template #editActions>
            <div class="admin-header-container__content__actions">
                <p-button
                    class="admin-header-container__content__actions__button"
                    (onClick)="triggerCancel()"
                    label="{{ 'system.button.cancel' | translate }}"
                    icon="ta ta-dismiss"
                    iconPos="left"
                    styleClass="p-button-outlined p-button-secondary"
                ></p-button>
                <p-button
                    class="admin-header-container__content__actions__button"
                    (onClick)="triggerSave()"
                    [disabled]="!areFormsDirty()"
                    label="{{ 'system.button.save-changes' | translate }}"
                    icon="ta ta-save"
                    iconPos="left"
                ></p-button>
            </div>
        </ng-template>
    </div>
    <div class="admin-header-container__bottom-filler"></div>
    <div class="admin-header-container__tabs">
        <ta-tabs [tabs]="tabs" (onTabClick)="logTab($event)"></ta-tabs>
    </div>
</div>
