<div class="object-permissions-directory-component-container ta-table-wrapper">
    <p-table
        #permissionsTable
        [value]="permissionsList ? permissionsList : []"
        responsiveLayout="scroll"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{ 'system.directory.results-info.showing' | translate }} {first} {{ 'system.directory.results-info.to' | translate }} {last} {{
            'system.directory.results-info.of' | translate
        }} {{ permissionsList?.length }} {{ 'system.directory.results-info.results' | translate }}"
        [rowsPerPageOptions]="[10, 25, 50]"
        [paginator]="true"
        paginatorDropdownAppendTo="body"
        [scrollable]="true"
        scrollHeight="flex"
        scrollWidth="100%"
        [rows]="10"
        [globalFilterFields]="['userId', 'name', 'emailAddress']"
    >
        <!-- Table 'header' with buttons and filters (search) -->

        <ng-template pTemplate="caption">
            <div class="table-actions">
                <div class="table-actions__left">
                    <p-button
                        label="{{ 'system.button.reset-all' | translate }}"
                        class="table-actions__left__button"
                        styleClass="p-button-outlined p-button-secondary"
                        icon="ta ta-reset"
                        (onClick)="onResetAll()"
                        [disabled]="permissionsExceptions && permissionsExceptions.size < 1 ? true : false || currentlyEditing"
                    ></p-button>
                </div>
                <div class="table-actions__right">
                    <ta-search-input [disabled]="currentlyEditing" class="table-actions__right__search" [input]="search" [maxLength]="100"></ta-search-input>
                    <span class="table-actions__right__separator"></span>
                    <p-selectButton
                        class="table-actions__right__select-button"
                        [disabled]="currentlyEditing"
                        [options]="filterOptions"
                        [(ngModel)]="selectedFilter"
                        (onChange)="permissionsTable.filterGlobal($event.value, filterMatchMode)"
                        optionValue="filter"
                    >
                        <ng-template let-item>
                            {{ item.label | translate }}
                        </ng-template>
                    </p-selectButton>
                </div>
            </div>
        </ng-template>

        <!-- Table header row with column titles -->
        <ng-template pTemplate="header">
            <tr class="table-header">
                <th class="table-header__item table-header__item__user">
                    {{ 'system.object-permissions.user-permission' | translate | uppercase }}
                </th>
                <th class="table-header__item table-header__item__exception">
                    <span>{{ 'system.object-permissions.exception-access-level' | translate | uppercase }}</span>
                </th>
                <th class="table-header__item table-header__item__access">
                    <span>{{ 'system.object-permissions.this-record-access-level' | translate | uppercase }}</span>
                </th>
                <th class="table-header__item table-header__item__actions table-header__item--horizontally-centered">
                    {{ 'system.actions' | translate | uppercase }}
                </th>
            </tr>
        </ng-template>

        <!-- Table body with row definition -->
        <ng-template pTemplate="body" let-userPermission>
            <tr class="table-body">
                <!-- User -->
                <td class="table-body__item table-body__item__user table__body__item--top truncate">
                    <div class="user truncate">
                        <div class="user__icon">
                            <i *ngIf="userPermission.name; else invite" class="ta ta-person"></i>
                            <ng-template #invite>
                                <i class="ta ta-person-mail"></i>
                            </ng-template>
                        </div>
                        <div class="user__text truncate">
                            <p class="text--obvious text--green truncate" [class.text--italic]="!userPermission.name" [class.text--primaryGrey]="!userPermission.name">
                                <span [class.text--lightGrey70]="isUserDisabled(userPermission.permissionLevel)">{{
                                    userPermission.name ? userPermission.name : ('system.permissions.invite-pending' | translate)
                                }}</span>
                            </p>
                            <p class="text--subtle text--xxsmall-m-t truncate">{{ userPermission.emailAddress }}</p>
                            <p class="text--obvious text--small-m-t" [class.text--red]="isUserDisabled(userPermission.permissionLevel)">
                                {{ getPermissionLevel(userPermission.permissionLevel) | translate }}
                            </p>
                        </div>
                    </div>
                </td>

                <!-- Object Level Permission -->
                <td class="table-body__item table-body__item__exception">
                    <div *ngIf="!formDisplayDict.get(userPermission.userId); else editInput">
                        {{ getException(userPermission.userId) | translate }}
                    </div>
                    <ng-template #editInput>
                        <p-dropdown
                            [dropdownIcon]="'ta ta-chevron-down'"
                            [appendTo]="'body'"
                            class="dropdown"
                            [options]="permissionDropdownOptions"
                            [(ngModel)]="selectedPermissionType"
                            (onChange)="onDropdownChange()"
                        >
                            <ng-template pTemplate="selectedItem">
                                <ng-container *ngIf="selectedPermissionType">
                                    {{ ObjectPermissions[selectedPermissionType] | translate }}
                                </ng-container>
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                                {{ ObjectPermissions[item] | translate }}
                            </ng-template>
                        </p-dropdown>
                    </ng-template>
                </td>

                <!-- Overriding Permission for this record -->
                <td
                    class="table-body__item table-body__item__access"
                    [class.text--obvious]="!isWorkspacePermissionGreater(userPermission.permissionLevel, permissionsExceptions?.get(userPermission.userId))"
                >
                    {{
                        (isWorkspacePermissionGreater(userPermission.permissionLevel, permissionsExceptions?.get(userPermission.userId))
                            ? isUserDisabled(userPermission.permissionLevel) || getAccessLevel(userPermission.permissionLevel, permissionItem) === AccessLevel.BY_EXCEPTION
                                ? '-'
                                : getAccessLevel(userPermission.permissionLevel, permissionItem)
                            : getException(userPermission.userId)
                        ) | translate
                    }}
                </td>

                <!-- Action controls -->
                <td class="table-body__item table-body__item__actions">
                    <div *ngIf="!formDisplayDict.get(userPermission.userId); else editButtons" class="table-body__item__content">
                        <p-button class="" *ngIf="!currentlyEditing" styleClass="p-button-text p-button-secondary" icon="ta ta-edit" (onClick)="onEdit(userPermission.userId)"></p-button>

                        <p-button
                            *ngIf="!currentlyEditing"
                            [disabled]="permissionsExceptions?.has(userPermission.userId) ? false : true"
                            styleClass="p-button-text p-button-danger"
                            icon="ta ta-reset"
                            (onClick)="onReset(userPermission.userId)"
                        ></p-button>
                    </div>
                    <ng-template #editButtons>
                        <div class="table-body__item__content">
                            <p-button
                                (onClick)="onUpdateOrCreate(userPermission.userId, selectedPermissionType)"
                                styleClass="p-button-text p-button-primary"
                                [disabled]="!objectPermissionsForm.dirty"
                                icon="ta ta-checkmark"
                            >
                            </p-button>
                            <p-button (onClick)="onCancelUpdateOrCreate()" styleClass="p-button-text p-button-danger" icon="ta ta-dismiss"></p-button>
                        </div>
                    </ng-template>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">{{ 'system.directory.no-content' | translate }}</td>
            </tr>
        </ng-template>
    </p-table>

    <!-- Discard changes dialog -->

    <p-dialog [draggable]="false" [modal]="true" header="{{ 'system.dialog.unsaved-changed.heading' | translate }}" [(visible)]="showDiscardChangesPrompt">
        <i class="ta ta-exclamation-triangle"></i>
        <p>{{ 'system.dialog.unsaved-changed.content' | translate }}</p>
        <ng-template pTemplate="footer">
            <p-button styleClass="p-button-outlined p-button-secondary" (onClick)="onDiscardChangesCancel()" label="{{ 'system.button.discard-cancel' | translate }}"></p-button>
            <p-button styleClass="p-button-danger" (onClick)="onDiscardChangesConfirm()" label="{{ 'system.button.discard' | translate }}"></p-button>
        </ng-template>
    </p-dialog>

    <!-- Reset single permission dialog -->

    <p-dialog [draggable]="false" [modal]="true" header="{{ 'system.dialog.reset-object-level-permission.heading' | translate }}" [(visible)]="showResetPermissionDialog">
        <i class="ta ta-exclamation-triangle"></i>
        <p>{{ 'system.dialog.reset-object-level-permission.content' | translate }}</p>
        <ng-template pTemplate="footer">
            <p-button styleClass="p-button-outlined p-button-secondary" (onClick)="onResetCancel()" label="{{ 'system.button.delete-cancel' | translate }}"></p-button>
            <p-button styleClass="p-button-danger" (onClick)="onResetConfirm()" label="{{ 'system.button.reset' | translate }}"></p-button>
        </ng-template>
    </p-dialog>

    <!-- Reset all permissions dialog -->

    <p-dialog [draggable]="false" [modal]="true" header="{{ 'system.dialog.reset-all-object-level-permission.heading' | translate }}" [(visible)]="showResetAllPermissionsDialog">
        <i class="ta ta-exclamation-triangle"></i>
        <p>{{ 'system.dialog.reset-all-object-level-permission.content' | translate }}</p>
        <ng-template pTemplate="footer">
            <p-button styleClass="p-button-outlined p-button-secondary" (onClick)="onResetAllCancel()" label="{{ 'system.button.delete-cancel' | translate }}"></p-button>
            <p-button styleClass="p-button-danger" (onClick)="onResetAllConfirm()" label="{{ 'system.button.reset-all' | translate }}"></p-button>
        </ng-template>
    </p-dialog>
</div>
