import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'ta-model-wrapper',
    templateUrl: './model-wrapper.component.html',
    styleUrls: ['./model-wrapper.component.scss']
})
export class ModelWrapperComponent implements OnInit {
    /**
     * Template for header, default is empty with close button.
     */
    @Input() header?: TemplateRef<any>;

    /**
     * Template for body, default is empty.
     */
    @Input() body?: TemplateRef<any>;

    /**
     * Template for footer, default is text close button.
     */
    @Input() footer?: TemplateRef<any>;

    /**
     * Optional style for close button if default footer is used
     */
    @Input() closeButtonStyle?: string;

    /**
     * Whether or not the modal is visible
     */
    @Input() visible = false;

    /**
     * The width of the model in pixles, e.g.: 400
     */
    _defaultWidth = 900;
    _width = this._defaultWidth;
    @Input() set width(width: number) {
        this._defaultWidth = width;
        this._width = width;
    }
    get width(): number {
        if (this._width > window.innerWidth) {
            this._height = window.innerHeight;
            return window.innerWidth;
        } else {
            this._height = this._defaultHeight;
            return this._width;
        }
    }

    /**
     * The height of the model in pixles, e.g.: 400
     */
    _defaultHeight = 780;
    _height = this._defaultHeight;
    @Input() set height(height: number) {
        this._defaultHeight = height;
        this._height = height;
    }
    get height(): number {
        if (this._height > window.innerHeight) {
            return window.innerHeight;
        } else {
            return this._height;
        }
    }

    @Input()
    headerHeight = 100;

    /**
     * Close event.
     */
    @Output() closeModel: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}
}
