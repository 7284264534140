export enum SubscriptionStatus {
    ACTIVE = 1,
    CANCELLED = 2
}

export enum SubscriptionStatusLabel {
    'system.subscription.status.active' = SubscriptionStatus.ACTIVE,
    'system.subscription.status.cancelled' = SubscriptionStatus.CANCELLED
}

export enum SubscriptionStatusSeverity {
    'success' = SubscriptionStatus.ACTIVE,
    'custom-grey' = SubscriptionStatus.CANCELLED
}
