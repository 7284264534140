import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from '@ta/app/shared/models/subscription/subscription.model';
import { SubscriptionStatus, SubscriptionStatusLabel, SubscriptionStatusSeverity } from '@ta/app/shared/models/subscription/subscription-status.model';
import { SubscriptionFrequencyLabel } from '@ta/app/shared/models/subscription/subscription-frequency.model';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { getFriendlyFileSize } from '@ta/app/assessments/utils/file.utils';

@Component({
    selector: 'ta-subscription-list-item',
    templateUrl: './subscription-list-item.component.html',
    styleUrls: ['./subscription-list-item.component.scss']
})
export class SubscriptionListItemComponent {
    /**
     * The subscription to display
     */
    @Input() set subscription(subscription: Subscription) {
        this._subscription = subscription;
        this.allocatedStorageString = getFriendlyFileSize(this.subscription.allocatedStorageBytes, 0);
    }
    get subscription(): Subscription {
        return this._subscription;
    }
    _subscription!: Subscription;

    /**
     * Whether the subscription is displayed in restricted mode
     * In restricted mode, subscriptions cannot be edited and some data is obfuscated
     */
    @Input() restricted = false;

    /**
     * Emit events when the edit button is clicked
     */
    @Output() edit: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Emit events when subscription cancel button is clicked
     */
    @Output() cancelSubscription: EventEmitter<any> = new EventEmitter();

    /**
     * Menu items shown when clicking the menu button
     */
    subscriptionMenu: MenuItem[] = [];

    /**
     * Make enums available to the template
     */
    SubscriptionStatus: any = SubscriptionStatus;
    SubscriptionStatusLabel: any = SubscriptionStatusLabel;
    SubscriptionStatusSeverity: any = SubscriptionStatusSeverity;
    SubscriptionFrequencyLabel: any = SubscriptionFrequencyLabel;

    /**
     * Presents number of bytes into human-friendly string
     */
    allocatedStorageString = '';

    constructor(private readonly _translateService: TranslateService) {
        // Set up the subscription menu
        this.subscriptionMenu = [
            {
                label: _translateService.instant('system.button.edit'),
                command: () => {
                    this.onEditSubscription();
                }
            },
            {
                label: _translateService.instant('system.button.cancel-subscription'),
                command: () => {
                    this.onCancelSubscription();
                }
            }
        ];
    }

    /**
     * Handle edit subscription click
     */
    onEditSubscription(): void {
        this.edit.emit();
    }

    /**
     * Handle cancel subscription click
     */
    onCancelSubscription(): void {
        this.cancelSubscription.emit();
    }
}
