<div class="blade-account-data-container">
    <p-scrollPanel [style]="{ width: '100%' }" class="scroll">
        <div class="content">
            <!-- Privacy & Data block-->
            <div>
                <p>
                    {{ 'system.account-blade.my-data.privacy-&-data-1' | translate }}
                </p>
                <p>
                    {{ 'system.account-blade.my-data.privacy-&-data-2' | translate }}
                    <a [routerLink]="['/', AppRoutes.TERMS]">{{ 'system.account-blade.my-data.terms-of-service' | translate }}</a>
                    {{ 'system.account-blade.my-data.privacy-&-data-3' | translate }}
                    <a [routerLink]="['/', AppRoutes.PRIVACY]">{{ 'system.account-blade.my-data.privacy-&-data-policy' | translate }}</a>
                </p>
            </div>

            <!-- Your workspaces block-->
            <div>
                <h3>{{ 'system.account-blade.my-data.your-workspaces' | translate }}</h3>
                <p>
                    {{ 'system.account-blade.my-data.your-workspaces-1' | translate }}
                </p>
                <table>
                    <tr>
                        <th>{{ 'system.workspace' | translate }}</th>
                        <th>{{ 'system.account-blade.data-controller-email' | translate }}</th>
                    </tr>
                    <tr *ngFor="let workspace of workspaces">
                        <td class="bold">{{ workspace.displayName }}</td>
                        <td>{{ workspace.dataControllerEmail ? workspace.dataControllerEmail : ('system.none' | translate) }}</td>
                    </tr>
                </table>
            </div>

            <!-- download my data block-->
            <div *taIfToggle="Toggle.MY_DATA_DOWNLOAD">
                <h3>{{ 'system.account-blade.my-data.can-i-download-my-data' | translate }}</h3>
                <p>
                    {{ 'system.account-blade.my-data.download-my-data-1' | translate }}
                </p>
                <p-button styleClass="p-button-secondary p-button-outlined" icon="ta ta-arrow-download" [label]="'system.button.download-data' | translate"></p-button>
                <p>
                    {{ 'system.account-blade.my-data.download-my-data-2' | translate }}
                    <a href="mailto:{{ environmentService.config.TASKALYSER.SUPPORT_EMAIL }}">{{ environmentService.config.TASKALYSER.SUPPORT_EMAIL }}</a>
                    {{ 'system.account-blade.my-data.download-my-data-3' | translate }}
                    <a [routerLink]="['/', AppRoutes.PRIVACY]">{{ 'system.account-blade.my-data.privacy-&-data-policy' | translate }}</a>
                </p>
            </div>

            <!-- Delete account block -->
            <div>
                <h3>{{ 'system.account-blade.my-data.can-i-delete-my-account' | translate }}</h3>
                <p>
                    {{ 'system.account-blade.my-data.delete-my-account-1' | translate }}
                </p>
                <p>
                    {{ 'system.account-blade.my-data.delete-my-account-2' | translate }}
                </p>
                <p>
                    {{ 'system.account-blade.my-data.delete-my-account-3' | translate }}
                </p>
                <h4>{{ 'system.account-blade.account.delete-account.heading' | translate }}</h4>
                <p>{{ 'system.account-blade.account.delete-account.content' | translate }}</p>
                <p-dialog [draggable]="false" [modal]="true" header="{{ 'system.dialog.delete-account.heading' | translate }}" [(visible)]="showDeleteAccountModal">
                    <i class="ta ta-exclamation-triangle"></i> {{ 'system.dialog.delete-account.content' | translate }}
                    <ng-template pTemplate="footer">
                        <p-button styleClass="p-button-secondary p-button-outlined" (onClick)="onDeleteAccountCancel()" label="{{ 'system.button.cancel' | translate }}"></p-button>
                        <p-button styleClass="p-button-danger" (onClick)="onDeleteAccountConfirm()" label="{{ 'system.button.delete-account' | translate }}"></p-button>
                    </ng-template>
                </p-dialog>
                <p-button styleClass="p-button-outlined p-button-danger" (onClick)="onDeleteAccount()" icon="pi ta-delete" label="{{ 'system.button.delete-account' | translate }}"></p-button>
            </div>

            <!-- feedback block-->
            <div>
                <h3>{{ 'system.account-blade.my-data.feedback' | translate }}</h3>
                <p>
                    {{ 'system.account-blade.my-data.feedback-text' | translate }}
                    <a href="mailto:{{ environmentService.config.TASKALYSER.FEEDBACK_EMAIL }}">{{ environmentService.config.TASKALYSER.FEEDBACK_EMAIL }}</a>
                </p>
            </div>
        </div>
    </p-scrollPanel>
</div>
