import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ErrorCodes } from '@ta/app/shared/models/api-response.model';
import { errorCodeMessageMap, ErrorMessage } from '@ta/app/shared/models/error-message.model';
import { AppQueryParams } from '@ta/app/shared/models/routes.model';
import { take } from 'rxjs';
import { AppRoutes } from '@ta/app/shared/models/routes.model';

@Component({
    selector: 'ta-oops',
    templateUrl: './oops.component.html',
    styleUrls: ['./oops.component.scss']
})
export class OopsComponent implements OnInit {
    errorMessage?: ErrorMessage = errorCodeMessageMap.get(ErrorCodes.GENERAL_ERROR);
    redirectLocation = '/';

    constructor(private readonly _route: ActivatedRoute) {}

    ngOnInit(): void {
        this._route.queryParams.pipe(take(1)).subscribe((queryParams: Params) => {
            // Get the error code from the query param
            const errorCode = Number(queryParams[AppQueryParams.ERROR]);
            // Set the error message from the map
            if (!!errorCode) this.errorMessage = errorCodeMessageMap.get(errorCode);
            // If the error code was not found, set to default
            if (!this.errorMessage) this.errorMessage = errorCodeMessageMap.get(ErrorCodes.GENERAL_ERROR);

            // If error message is related to the user auth/email verified/user finalised guards, redirect to login page.
            // If we find in the future that for other errors we need to redirect to other places, we can make the redirect location a property in ErrorMessage.
            if (!!errorCode && errorCode > 300 && errorCode <= 306) {
                this.redirectLocation = `/${AppRoutes.LOGIN}`;
            }
        });
    }
}
