import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WorkspaceSummary } from '@ta/app/shared/models/workspace/workspace-summary.model';
import { EnvironmentService } from '@ta/app/shared/services/environment.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { WorkspaceSwitcherItem } from '@ta/app/core/models/workspace-switcher-item.model';
import { CreateWorkspaceRequest } from '@ta/app/shared/models/workspace/create-workspace-request.model';
import { ApiResponseService } from '@ta/app/shared/services/api-response.service';
import { ApiAction } from '@ta/app/shared/models/api-action.model';
import { BusinessIdentifierType } from '@ta/app/shared/models/workspace/business-Identifier-type.model';
import { Country } from '@ta/app/shared/models/country.model';
import { FinalisePremiumWorkspace } from '@ta/app/shared/models/workspace/finalise-premium-workspace.model';
import { PremiumWorkspaceStaticData } from '@ta/app/shared/models/workspace/premium-workspace-static-data.model';
import { PremiumRequest } from '@ta/app/shared/models/workspace/premium-workspace-request.model';
import { User } from '@ta/app/shared/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceService {
    /**
     * Routes
     */
    private readonly WORKSPACE_ROUTE = 'Workspace';
    private readonly PREMIUM_ROUTE = 'premium';
    private readonly PREMIUM_FINALISE_ROUTE = 'premium/finalise';
    private readonly WORKSPACE_LIST_ROUTE = 'Workspace/Switcher';
    private readonly WORKSPACE_FAVOURITE_ROUTE = 'WorkspaceFavourites';
    private readonly BUSINESS_IDENTIFIER_ROUTE = 'Business/list';
    private readonly COUNTRY_LIST_ROUTE = 'Country/list';

    /**
     * State
     */
    private readonly _workspace = new BehaviorSubject<WorkspaceSummary | undefined>(undefined);
    readonly workspace$ = this._workspace.asObservable();

    private readonly _homeWorkspace = new BehaviorSubject<WorkspaceSummary | undefined>(undefined);
    readonly homeWorkspace$ = this._homeWorkspace.asObservable();

    private readonly _premiumRequestResponse = new BehaviorSubject<any | undefined>(undefined);
    readonly _premiumRequestResponse$ = this._premiumRequestResponse.asObservable();

    private readonly _premiumWorkspaceStaticData = new BehaviorSubject<PremiumWorkspaceStaticData | undefined>(undefined);
    readonly _premiumWorkspaceStaticData$ = this._premiumWorkspaceStaticData.asObservable();

    private readonly _workspaceList = new BehaviorSubject<Array<WorkspaceSwitcherItem> | undefined>(undefined);
    readonly workspaceList$ = this._workspaceList.asObservable();

    private readonly _businessIdentifierList = new BehaviorSubject<BusinessIdentifierType[] | undefined>(undefined);
    readonly businessIdentifierList$ = this._businessIdentifierList.asObservable();

    private readonly countryList = new BehaviorSubject<Country[] | undefined>(undefined);
    readonly countryList$ = this.countryList.asObservable();

    /**
     * A static getter for services to use to construct API requests
     * This should not be used for anything else, use the Observable instead
     */
    get workspace(): WorkspaceSummary | undefined {
        return this._workspace.getValue();
    }
    get workspaceApiUrl(): string {
        return `${this._workspace.getValue()?.apiUrl}/${this._workspace.getValue()?.globalId}`;
    }

    get homeWorkspace(): WorkspaceSummary | undefined {
        return this._homeWorkspace.getValue();
    }
    get homeWorkspaceApiUrl(): string {
        return `${this._homeWorkspace.getValue()?.apiUrl}/${this._homeWorkspace.getValue()?.globalId}`;
    }

    constructor(private readonly _apiResponseService: ApiResponseService, private readonly _envService: EnvironmentService, private readonly _http: HttpClient) {}

    getWorkspace(workspaceGuid: string): Observable<WorkspaceSummary | undefined> {
        return this._http.get<WorkspaceSummary>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.WORKSPACE_ROUTE}/${workspaceGuid}`).pipe(
            tap((workspace) => {
                this._workspace.next(workspace);
            }),
            switchMap(() => this.workspace$)
        );
    }

    getHomeWorkspace(user: User): Observable<WorkspaceSummary | undefined> {
        return this._http.get<WorkspaceSummary>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.WORKSPACE_ROUTE}/${user.homeWorkspace.globalId}`).pipe(
            tap((workspace) => {
                this._homeWorkspace.next(workspace);
            }),
            switchMap(() => this.homeWorkspace$)
        );
    }

    getWorkspaceList(): Observable<Array<WorkspaceSwitcherItem> | undefined> {
        return this._http.get<Array<WorkspaceSwitcherItem>>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.WORKSPACE_LIST_ROUTE}`).pipe(
            tap((workspaceList) => {
                this._workspaceList.next(workspaceList);
            }),
            switchMap(() => this.workspaceList$)
        );
    }

    addWorkspaceFavourite(workspaceGuid: string): Observable<Array<WorkspaceSwitcherItem> | undefined> {
        return this._http.post<Array<WorkspaceSwitcherItem>>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.WORKSPACE_FAVOURITE_ROUTE}/${workspaceGuid}`, null);
    }

    removeWorkspaceFavourite(workspaceGuid: string): Observable<Array<WorkspaceSwitcherItem> | undefined> {
        return this._http.delete<Array<WorkspaceSwitcherItem>>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.WORKSPACE_FAVOURITE_ROUTE}/${workspaceGuid}`);
    }

    createWorkspace(createWorkspaceRequest: CreateWorkspaceRequest): Observable<WorkspaceSummary | undefined> {
        return this._http.post<WorkspaceSummary>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.WORKSPACE_ROUTE}`, createWorkspaceRequest).pipe(
            this._apiResponseService.showToastOnSuccess(ApiAction.CREATE),
            tap((workspace) => {
                this._workspace.next(workspace);
            }),
            switchMap(() => this.workspace$)
        );
    }

    createPremiumWorkspaceRequest(premiumWorkspaceRequest: PremiumRequest): Observable<any> {
        return this._http.post(`${this._envService.config.ORCHESTRATION_API_URL}/${this.WORKSPACE_ROUTE}/${this.PREMIUM_ROUTE}`, premiumWorkspaceRequest).pipe(
            tap((premiumRequest) => {
                this._premiumRequestResponse.next(premiumRequest);
            }),
            switchMap(() => this._premiumRequestResponse$)
        );
    }

    finalisePremiumWorkspace(finalisePremiumWorkspace: FinalisePremiumWorkspace): Observable<WorkspaceSummary | undefined> {
        return this._http.post<WorkspaceSummary>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.WORKSPACE_ROUTE}/${this.PREMIUM_FINALISE_ROUTE}`, finalisePremiumWorkspace).pipe(
            this._apiResponseService.showToastOnSuccess(ApiAction.CREATE),
            tap((premiumWorkspace) => {
                this._workspace.next(premiumWorkspace);
            }),
            switchMap(() => this.workspace$)
        );
    }

    getPremiumWorkspaceFinaliseData(inviteTicket: string): Observable<PremiumWorkspaceStaticData | undefined> {
        const options = {
            params: {
                inviteTicket
            },

            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this._http.get<PremiumWorkspaceStaticData>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.WORKSPACE_ROUTE}/${this.PREMIUM_ROUTE}`, options).pipe(
            tap((workspaceStaticData) => {
                this._premiumWorkspaceStaticData.next(workspaceStaticData);
            }),
            switchMap(() => this._premiumWorkspaceStaticData$)
        );
    }

    listBusinessIdentifierTypes(countryId: number): Observable<BusinessIdentifierType[] | undefined> {
        return this._http.get<BusinessIdentifierType[]>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.BUSINESS_IDENTIFIER_ROUTE}/${countryId}`).pipe(
            tap((businessIdentifierType) => {
                this._businessIdentifierList.next(businessIdentifierType);
            }),
            switchMap(() => this.businessIdentifierList$)
        );
    }

    listCountries(): Observable<Country[] | undefined> {
        return this._http.get<Country[]>(`${this._envService.config.ORCHESTRATION_API_URL}/${this.COUNTRY_LIST_ROUTE}`).pipe(
            tap((country) => {
                this.countryList.next(country);
            }),
            switchMap(() => this.countryList$)
        );
    }

    deleteWorkspace(workspaceId: string): Observable<object> {
        return this._http.delete(`${this._envService.config.ORCHESTRATION_API_URL}/${this.WORKSPACE_ROUTE}/${workspaceId}`).pipe(
            this._apiResponseService.showToastOnSuccess(ApiAction.PERMANENTLY_DELETE),
            tap(() => {
                this.getWorkspaceList().pipe(take(1)).subscribe();
            })
        );
    }
}
