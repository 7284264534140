export enum AuditAction {
    AUDIT_ACTION_CREATED = 1,
    AUDIT_ACITON_VIEWED = 2,
    AUDIT_ACTION_EDITED = 3,
    AUDIT_ACTION_DELETED = 4,
    AUDIT_ACTION_ARCHIVED = 5,
    AUDIT_ACTION_UNARCHIVED = 6,
    AUDIT_ACTION_ADD_TAG = 7,
    AUDIT_ACTION_REMOVE_TAG = 8,
    AUDIT_ACTION_ADD_RELATIONSHIP = 9,
    AUDIT_ACTION_REMOVE_RELATIONSHIP = 10,
    AUDIT_ACTION_SET_PROCESSING = 11,
    AUDIT_ACTION_COMPLETE = 12,
    AUDIT_ACTION_VIEW_GALLERY = 13,
    AUDIT_ACTION_UPLOAD_FILE = 14,
    AUDIT_ACTION_UPDATE_FILE = 15,
    AUDIT_ACTION_DELETE_FILE = 16,
    AUDIT_ACTION_DUPLICATED = 17
}

export enum AuditActionTranslation {
    'system.audit.action.created' = AuditAction.AUDIT_ACTION_CREATED,
    'system.audit.action.viewed' = AuditAction.AUDIT_ACITON_VIEWED,
    'system.audit.action.edited' = AuditAction.AUDIT_ACTION_EDITED,
    'system.audit.action.deleted' = AuditAction.AUDIT_ACTION_DELETED,
    'system.audit.action.archived' = AuditAction.AUDIT_ACTION_ARCHIVED,
    'system.audit.action.unarchived' = AuditAction.AUDIT_ACTION_UNARCHIVED,
    'system.audit.action.add-tag' = AuditAction.AUDIT_ACTION_ADD_TAG,
    'system.audit.action.remove-tag' = AuditAction.AUDIT_ACTION_REMOVE_TAG,
    'system.audit.action.add-relationship' = AuditAction.AUDIT_ACTION_ADD_RELATIONSHIP,
    'system.audit.action.remove-relationship' = AuditAction.AUDIT_ACTION_REMOVE_RELATIONSHIP,
    'system.audit.action.duplicate' = AuditAction.AUDIT_ACTION_DUPLICATED
}

export class AuditActions {
    public static readonly AUDIT_VIEW_ACTIONS = [AuditAction.AUDIT_ACITON_VIEWED];

    public static readonly AUDIT_CHANGE_ACTIONS = [
        AuditAction.AUDIT_ACTION_CREATED,
        AuditAction.AUDIT_ACTION_EDITED,
        AuditAction.AUDIT_ACTION_DELETED,
        AuditAction.AUDIT_ACTION_ARCHIVED,
        AuditAction.AUDIT_ACTION_UNARCHIVED,
        AuditAction.AUDIT_ACTION_ADD_TAG,
        AuditAction.AUDIT_ACTION_REMOVE_TAG,
        AuditAction.AUDIT_ACTION_ADD_RELATIONSHIP,
        AuditAction.AUDIT_ACTION_REMOVE_RELATIONSHIP,
        AuditAction.AUDIT_ACTION_DUPLICATED
    ];

    public static readonly AUDIT_ALL_ACTIONS = [
        AuditAction.AUDIT_ACTION_CREATED,
        AuditAction.AUDIT_ACITON_VIEWED,
        AuditAction.AUDIT_ACTION_EDITED,
        AuditAction.AUDIT_ACTION_DELETED,
        AuditAction.AUDIT_ACTION_ARCHIVED,
        AuditAction.AUDIT_ACTION_UNARCHIVED,
        AuditAction.AUDIT_ACTION_ADD_TAG,
        AuditAction.AUDIT_ACTION_REMOVE_TAG,
        AuditAction.AUDIT_ACTION_ADD_RELATIONSHIP,
        AuditAction.AUDIT_ACTION_REMOVE_RELATIONSHIP,
        AuditAction.AUDIT_ACTION_DUPLICATED
    ];
}
