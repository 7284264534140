import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { WorkspaceUserPermission } from '@ta/app/shared/models/permissions/workspace-user-permission.model';
import { FilterService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Constants } from '@ta/app/shared/models/constants.model';
import { CustomTableFilters } from '@ta/app/shared/models/custom-table-filters.model';
import { IObjectPermissionRequest } from '@ta/app/shared/models/permissions/object-permission-request.model';
import { PermissionItem } from '@ta/app/shared/models/permissions/permission-item.model';
import { getAccessLevel } from '@ta/app/shared/utils/get-access-level';
import { FilterItem } from '@ta/app/shared/models/filter-item.model';
import { getPermissionLevel } from '@ta/app/shared/utils/get-permission-level';
import { AccessLevel } from '@ta/app/shared/models/permissions/access-level.model';
import { PageHeaderService } from '@ta/app/shared/services/page-header.service';
import { FormName } from '@ta/app/shared/models/form.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';
import { ObjectPermissions, ObjectPermission } from '@ta/app/shared/models/permissions/object-permissions.model';
import { PermissionLevel, PermissionLevels } from '@ta/app/shared/models/permissions/permission-access-levels.model';

@Component({
    selector: 'ta-object-permissions-directory',
    templateUrl: './object-permissions-directory.component.html',
    styleUrls: ['./object-permissions-directory.component.scss']
})
export class ObjectPermissionsDirectoryComponent implements OnInit, OnDestroy {
    ObjectPermissions: any = ObjectPermissions;
    AccessLevel: any = AccessLevel;
    PermissionLevel: any = PermissionLevel;

    /**
     * Reference to table
     */
    @ViewChild('permissionsTable') tableElement!: Table;

    /**
     * The total list of permissions to display
     */
    protected _permissionsList?: WorkspaceUserPermission[];
    @Input() set permissionsList(permissionsList: WorkspaceUserPermission[] | undefined) {
        this._permissionsList = permissionsList;

        // create a dictionary of booleans for displaying forms.
        permissionsList?.forEach((permission) => {
            this.formDisplayDict?.set(permission.userId, false);
        });
    }
    get permissionsList(): WorkspaceUserPermission[] | undefined {
        return this._permissionsList;
    }

    /**
     * The type of object the permissions relate to
     */
    @Input() permissionItem!: PermissionItem;

    /**
     * A map of permissions exceptions, object level permissions.
     */
    @Input() permissionsExceptions?: Map<number, ObjectPermission>;

    /**
     * Reset single permission event output
     */
    @Output() resetPermission: EventEmitter<number> = new EventEmitter();

    /**
     * Reset all permissions event output
     */
    @Output() resetAllPermissions: EventEmitter<any> = new EventEmitter();

    /**
     * Create object permission event output
     */
    @Output() createObjectPermission: EventEmitter<IObjectPermissionRequest> = new EventEmitter();

    /**
     * Update object permission event output
     */
    @Output() updateObjectPermission: EventEmitter<IObjectPermissionRequest> = new EventEmitter();

    /**
     * The currently selected permission type.
     */
    selectedPermissionType = ObjectPermission.READ;

    /**
     * User being edited.
     */
    userSelectedForEditing?: number;

    /**
     * Whether somethnig is currently being edited.
     */
    currentlyEditing = false;

    /**
     * Whether or not to show the dialogs on the page
     */
    showDiscardChangesPrompt = false;
    showResetPermissionDialog = false;
    showResetAllPermissionsDialog = false;

    /**
     * Filter match mode to be used to define the custom filter and its use.
     */
    filterMatchMode = CustomTableFilters.MAP_CONTAINS_KEY;

    /**
     * Filter match mode to be used to define the custom filter and its use.
     */
    searchFilterMatchMode = CustomTableFilters.CONTAINS_NO_NUMBERS;

    /**
     * Dictionary of booleans controlling display of individual forms in table
     */
    formDisplayDict: Map<number, boolean> = new Map();

    /**
     * The dropdown options for editing object permissions.
     */
    permissionDropdownOptions = [ObjectPermission.READ, ObjectPermission.READ_AND_EDIT];

    /**
     * The options for filtering the table
     */
    filterOptions: FilterItem[] = [
        { label: 'system.all-permissions', filter: false },
        { label: 'system.exceptions-only', filter: true }
    ];

    /**
     * This exists solely to set the default option on the filter.
     */
    selectedFilter = false;

    /**
     * Search input
     */
    search: FormControl = new FormControl();

    /**
     * Form for storing form state and dealing with route guard
     */
    objectPermissionsForm!: FormGroup;

    /**
     * name of object permissions form for forms service
     */
    objectPermissionsFormName: FormName = FormName.OBJECT_PERMISSIONS;

    private _subscription?: Subscription;

    constructor(private readonly _filterService: FilterService, private readonly _pageHeaderService: PageHeaderService, private readonly _linkedFormService: LinkedFormService) {}

    getAccessLevel = getAccessLevel;
    getPermissionLevel = getPermissionLevel;

    ngOnInit(): void {
        this.objectPermissionsForm = new FormGroup({});
        this._linkedFormService.addForm(this.objectPermissionsForm, this.objectPermissionsFormName);

        /**
         * This custom filter takes a filter value of a boolean indicating whether or not only exceptions should be displayed
         * It takes a value of user id to verify if an item contains an exception. It also ensures that the provided value
         * is not of string type, which could lead to strange issues.
         */
        this._filterService.register(CustomTableFilters.MAP_CONTAINS_KEY, (value: number, filter: boolean): boolean => {
            // check if filter and value are 'good' values.
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null || typeof value === 'string') {
                return false;
            }

            if (filter) {
                return this.permissionsExceptions?.has(value) ? true : false;
            }

            return true;
        });

        /**
         * This custom filter ensures that no numbers are being searched. This is necessary because both the search
         * and map filter are global filters, which mean search will 'search' the userIds provided to the map filter.
         * To prevent this, number values are excluded from filtering.
         */
        this._filterService.register(CustomTableFilters.CONTAINS_NO_NUMBERS, (value: any, filter: any): boolean => {
            // check if filter and value are 'good' values.
            if (filter === undefined || filter === null || filter.length < 1) {
                return true;
            }

            if (value === undefined || value === null || typeof value === 'number') {
                return false;
            }

            return (value as string).includes(filter as string);
        });

        // Handle search input
        this._subscription = this.search.valueChanges
            .pipe(debounceTime(Constants.DEBOUNCE_TIME_DURATION))
            .subscribe((searchTerm) => this.tableElement.filterGlobal(searchTerm, this.searchFilterMatchMode));
    }

    ngOnDestroy(): void {
        this._linkedFormService.removeForm(this.objectPermissionsFormName);
        this._subscription?.unsubscribe();
    }

    /**
     * gets the translate key for a permission exception
     * @param uscerId the id of the user to retrieve permission exception for
     * @returns translate key for a permission exception
     */
    getException(userId: number): string {
        const i = this.permissionsExceptions?.get(userId);
        if (i) return ObjectPermissions[i];
        return '-';
    }

    /**
     * Checks if a user is disabled.
     * @param permissionLevel the permission level of the user
     * @returns Whether the user is disabled
     */
    isUserDisabled(permissionLevel: number): boolean {
        return !((permissionLevel & PermissionLevel.WorkspaceAccess) === PermissionLevel.WorkspaceAccess);
    }

    /**
     * Determines if the given workspace level permisison is greater than (or equal to), the provided object level permission.
     * @param permissionLevel The users workspace level permission
     * @param objectLevelPermission The users object level permission, if it exists
     */
    isWorkspacePermissionGreater(permissionLevel: number, objectLevelPermission?: ObjectPermission): boolean {
        // if no object permission is provided or the user is disabled, then the workspace permission wins.
        if (!objectLevelPermission || this.isUserDisabled(permissionLevel)) return true;

        // first check if the user has administrator or power user access as these always override.
        if (getPermissionLevel(permissionLevel) === PermissionLevels[PermissionLevel.Administer] || getPermissionLevel(permissionLevel) === PermissionLevels[PermissionLevel.PowerUser]) return true;

        let read = false;
        let edit = false;

        switch (this.permissionItem) {
            case PermissionItem.PEOPLE:
                read = (permissionLevel & PermissionLevel.ReadPeople) === PermissionLevel.ReadPeople;
                edit = (permissionLevel & PermissionLevel.EditPeople) === PermissionLevel.EditPeople;
                break;
            case PermissionItem.JOBS:
                read = (permissionLevel & PermissionLevel.ReadJobs) === PermissionLevel.ReadJobs;
                edit = (permissionLevel & PermissionLevel.EditJobs) === PermissionLevel.EditJobs;
                break;
            case PermissionItem.SITES:
                read = (permissionLevel & PermissionLevel.ReadSites) === PermissionLevel.ReadSites;
                edit = (permissionLevel & PermissionLevel.EditSites) === PermissionLevel.EditSites;
                break;
            case PermissionItem.ASSESSMENTS:
                read = (permissionLevel & PermissionLevel.ReadAssessments) === PermissionLevel.ReadAssessments;
                edit = (permissionLevel & PermissionLevel.EditAssessments) === PermissionLevel.EditAssessments;
                break;
            // More to be added in future.
        }

        if ((edit && read) || edit) return true;
        if (read && objectLevelPermission !== ObjectPermission.READ_AND_EDIT && objectLevelPermission !== ObjectPermission.EDIT) return true;

        // otherwise,
        return false;
    }

    /**
     * Handles the resetting of a users object level permissions for a record
     * @param userId the id of the user to be reset
     */
    onReset(userId: number): void {
        this.userSelectedForEditing = userId;
        this.showResetPermissionDialog = true;
    }

    /**
     * Handles the confirmation of resetting a users object level permissions for a record
     */
    onResetConfirm(): void {
        this.showResetPermissionDialog = false;
        this.resetPermission.emit(this.userSelectedForEditing);
    }

    /**
     * Handles cancellation of reseting permissions
     */
    onResetCancel(): void {
        this.showResetPermissionDialog = false;
    }

    /**
     * Handles the resetting of all users permissions for a record
     */
    onResetAll(): void {
        this.showResetAllPermissionsDialog = true;
    }

    /**
     * Handles the confirmation of resetting all users permissions for a record
     */
    onResetAllConfirm(): void {
        this.showResetAllPermissionsDialog = false;
        this.resetAllPermissions.emit();
    }

    /**
     * Handles the cancellation of resetting all users permissions
     */
    onResetAllCancel(): void {
        this.showResetAllPermissionsDialog = false;
    }

    /**
     * Handles the edit action for a users object level permissions
     * @param userId The id of the user being edited
     */
    onEdit(userId: number): void {
        this._pageHeaderService.setChildPageBeingEdited();
        this.userSelectedForEditing = userId;
        this.formDisplayDict?.set(userId, true);
        this.selectedPermissionType = this.permissionsExceptions?.get(userId) ?? ObjectPermission.READ;
        this.currentlyEditing = true;
        if (!this.permissionsExceptions?.has(userId)) {
            this.onDropdownChange();
        }
    }

    /**
     * Handle changes to the edit dropdown
     */
    onDropdownChange(): void {
        this.objectPermissionsForm.markAsDirty();
    }

    /**
     * Handles processing either create or update actions for a users object level permissions for a record.
     * @param userId id of user being edited
     * @param selectedPermission the currently selected object level permission
     */
    onUpdateOrCreate(userId: number, selectedPermission: ObjectPermission): void {
        // if the key exists, it's an update action
        if (this.permissionsExceptions?.has(userId)) {
            // process update action

            this.updateObjectPermission.emit({
                userId: userId,
                permissionTypeId: selectedPermission
            });
        } else {
            // otherwise it's a create action

            this.createObjectPermission.emit({
                userId: userId,
                permissionTypeId: selectedPermission
            });
        }

        this.userSelectedForEditing = userId;
        this.onDiscard();
    }

    /**
     * Handles the cancellation of the update or create action.
     */
    onCancelUpdateOrCreate(): void {
        if (this.objectPermissionsForm.dirty) {
            this.showDiscardChangesPrompt = true;
        } else {
            this.onDiscard();
        }
    }

    /**
     * Handles the confirmation of discarding changes
     */
    onDiscardChangesConfirm(): void {
        this.showDiscardChangesPrompt = false;
        this.onDiscard();
    }

    /**
     * Handles the cancellation of discarding changes.
     */
    onDiscardChangesCancel(): void {
        this.showDiscardChangesPrompt = false;
    }

    /**
     * Discards changes. and sets page to default state.
     */
    onDiscard(): void {
        if (this.userSelectedForEditing) this.formDisplayDict?.set(this.userSelectedForEditing, false);
        this.userSelectedForEditing = undefined;
        this.currentlyEditing = false;
        this._linkedFormService.reset(this.objectPermissionsFormName);
        this._pageHeaderService.setChildPageNotBeingEdited();
    }
}
