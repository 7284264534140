import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WorkspaceRoutes } from '@ta/app/shared/models/routes.model';
import { WorkspaceSwitcherItem } from '@ta/app/core/models/workspace-switcher-item.model';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';
import { switchMap, take } from 'rxjs/operators';

@Component({
    selector: 'ta-workspace-switcher-card',
    templateUrl: './workspace-switcher-card.component.html',
    styleUrls: ['./workspace-switcher-card.component.scss']
})
export class WorkspaceSwitcherCardComponent implements OnInit {
    /**
     * Workspace
     */
    @Input() workspace!: WorkspaceSwitcherItem;

    /**
     * Dynamically set styling
     */
    avatarStyle: any;

    /**
     * Some styles need to be set programatically, because PrimeNG
     * Setting static styles to make styling clearer
     */
    COLOUR_PRIMARY = '#1b7e55';
    COLOUR_WHITE = '#ffffff';

    constructor(private readonly _router: Router, private readonly _workspaceService: WorkspaceService) {}

    ngOnInit(): void {
        // Programatically change avatar styling for personal vs business workspaces
        // This is necessary because of the way that PrimeNg handles style overrides
        this.avatarStyle = this.workspace.isHomeWorkspace
            ? { 'background-color': this.COLOUR_WHITE, color: this.COLOUR_PRIMARY, border: `1px solid ${this.COLOUR_PRIMARY}` }
            : { 'background-color': this.COLOUR_PRIMARY, color: this.COLOUR_WHITE };
    }

    /**
     * Handle click of card element
     */
    onCardClick(): void {
        this._router.navigate([this.workspace.globalId, WorkspaceRoutes.DASHBOARD]);
    }

    /**
     * Handle click of favourite button
     */
    onFavouriteClick(event: any): void {
        // Toggle locally for optimistic save
        this.workspace.isFavourite = !this.workspace.isFavourite;
        // Construct API call, based on favourite / unfavourite
        const apiAction = this.workspace.isFavourite ? this._workspaceService.addWorkspaceFavourite(this.workspace.globalId) : this._workspaceService.removeWorkspaceFavourite(this.workspace.globalId);
        // Submit and handle API call (call API then refresh workspaces list)
        apiAction
            .pipe(
                take(1),
                switchMap(() => this._workspaceService.getWorkspaceList())
            )
            .subscribe();
        // Prevent event from triggering onClick above
        event.stopPropagation();
    }

    /**
     * Handle click of more button
     */
    onMoreClick(event: any): void {
        // Prevent event from triggering onClick above
        event.stopPropagation();
    }
}
