<ta-auth class="auth">
    <!-- Welcome heading -->
    <h1 class="auth__heading">{{ 'system.auth.login.heading' | translate }}</h1>

    <!-- Message -->
    <p class="auth__message">{{ (invitationService.invitation$ | async)?.workspaceName }} {{ 'system.auth.login.message.line1' | translate }}</p>

    <!-- Create account -->
    <a class="auth__action">
        <p-button class="button" styleClass="p-button-lg" (onClick)="onContinue()" label="{{ 'system.auth.login.button' | translate }}"> </p-button>
    </a>

    <!-- Divider -->
    <div class="auth__divider">
        <hr />
        <p>OR</p>
        <hr />
    </div>

    <!-- Social providers -->
    <div class="auth__social-providers">
        <ta-auth-social-provider *ngFor="let socialProvider of socialProviders" [socialProvider]="socialProvider"></ta-auth-social-provider>
    </div>

    <!-- Login -->
    <p class="auth__message">
        {{ 'system.auth.login.message.line2' | translate }} <a (click)="onSignUp()" class="auth__message--link auth__message--emphasis">{{ 'system.auth.login.message.sign-up' | translate }}</a>
    </p>
</ta-auth>
