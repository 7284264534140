<!-- for readability purposes this file is not being auto formatted with prittier as is the project default -->
<!-- prettier-ignore -->
<div class="content" [class.content--edit]="edit">
    <!-- Heading and expand/collapse button -->
    <div class="content__top">
        <div class="content__top__title">
            <h4>{{ 'system.related-items' | translate }}</h4>
        </div>
        <!-- hide the expand button if both sites and jobs directories are disabled -->
        <ng-container *ngIf="(!toggleService.isOn(Toggle.JOBS) && !toggleService.isOn(Toggle.SITES)) === false">
            <p-button *ngIf="
                !expanded &&
                structuredRelationships &&
                ((structuredRelationships.people && structuredRelationships.people.length > 0) ||
                    (structuredRelationships.sites && structuredRelationships.sites.length > 0) ||
                    (structuredRelationships.jobs && structuredRelationships.jobs.length > 0)) || edit && !expanded
            " label="{{ 'system.button.expand-all' | translate }}" class="content__top__button" styleClass=" p-button-text p-button-secondary" icon="ta ta-chevron-double-down" [iconPos]="'right'" (onClick)="onToggleDetails()"></p-button>
        </ng-container>
        <p-button *ngIf="expanded" label="{{ 'system.button.collapse-all' | translate }}" class="content__top__button" styleClass=" p-button-text p-button-secondary" icon="ta ta-chevron-double-up" [iconPos]="'right'" (onClick)="onToggleDetails()"></p-button>
    </div>

    <!-- Edit content -->
    <div *ngIf="edit; else view" class="content__main">
        <!-- Edit Job Relationships -->
        <hr class="content__main__hr content__main__hr--first" />
        <ng-container *taIfToggle="Toggle.JOBS">
            <ta-relationships-section-edit [relationships]="jobRelationships" [icon]="'ta ta-briefcase'" [title]="'system.nav.jobs' | translate" [expanded]="expanded" (removeEvent)="removeJobHandler($event)">
                <div class="content__main__projected">
                    <p-button class="content__main__projected__button" styleClass="p-button-text p-button-secondary" label="Add new" icon="ta ta-add" iconPos="left" (mousedown)="focusSearchJobs($event)"></p-button>
                    <p-autoComplete #searchJobs [class.content__main__projected__input--visible]="addJobRelationship" class="content__main__projected__input p-fluid" [(ngModel)]="selectedJob" (onKeyUp)="handleKeyUp($event)" (onSelect)="onAddJob()" [suggestions]="filteredJobs" field="name" (completeMethod)="filterJobs($event)" placeholder="{{ 'system.form.relationship-input-placeholder' | translate }}" (onBlur)="hideSearchJobs()" [maxlength]=200></p-autoComplete>
                </div>
            </ta-relationships-section-edit>
        </ng-container>

        <!-- Edit Site Relationships -->
        <ng-container *taIfToggle="Toggle.SITES">
            <ta-relationships-section-edit [relationships]="siteRelationships" [icon]="'ta ta-location'" [title]="'system.nav.sites' | translate" [expanded]="expanded" (removeEvent)="removeSiteHandler($event)">
                <div class="content__main__projected">
                    <p-button class="content__main__projected__button" styleClass="p-button-text p-button-secondary" label="Add new" icon="ta ta-add" iconPos="left" (mousedown)="focusSearchSites($event)"></p-button>
                    <p-autoComplete #searchSites [class.content__main__projected__input--visible]="addSiteRelationship" class="content__main__projected__input p-fluid" [(ngModel)]="selectedSite" (onKeyUp)="handleKeyUp($event)" (onSelect)="onAddSite()" [suggestions]="filteredSites" field="name" (completeMethod)="filterSites($event)" placeholder="{{ 'system.form.relationship-input-placeholder' | translate }}" (onBlur)="hideSearchSites()" [maxlength]=200></p-autoComplete>
                </div>
            </ta-relationships-section-edit>
        </ng-container>

        <!-- Edit Person Relationships -->
        <ng-container *taIfToggle="Toggle.PEOPLE">
            <ta-relationships-section-edit *ngIf="!displayPersonRelationships()" [relationships]="personRelationships" [icon]="'ta ta-people-community'" [title]="'system.nav.people' | translate" [expanded]="expanded" (removeEvent)="removePersonHandler($event)">
                <div class="content__main__projected">
                    <p-button class="content__main__projected__button" styleClass="p-button-text p-button-secondary" label="Add new" icon="ta ta-add" iconPos="left" (mousedown)="focusSearchPeople($event)"></p-button>
                    <p-autoComplete #searchPeople [class.content__main__projected__input--visible]="addPersonRelationship" class="content__main__projected__input p-fluid" [(ngModel)]="selectedPerson" (onKeyUp)="handleKeyUp($event)" (onSelect)="onAddPerson()" [suggestions]="filteredPeople" field="name" (completeMethod)="filterPeople($event)" placeholder="{{ 'system.form.relationship-input-placeholder' | translate }}" (onBlur)="hideSearchPeople()" [maxlength]=200></p-autoComplete>
                </div>
            </ta-relationships-section-edit> 
        </ng-container>
    </div>               


    <!-- View content -->
    <ng-template #view>
        <div *ngIf="structuredRelationships?.jobs?.length !== 0 || structuredRelationships?.sites?.length !== 0 || structuredRelationships?.people?.length !== 0; else none" class="content__main">
            <!-- Job Relationships -->
            <div *ngIf="displayJobRelationships()">
                <!-- Job Job Relationships -->
                <hr *ngIf="structuredRelationships?.jobs?.length !== 0" class="content__main__hr content__main__hr--first" />
                <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.JOB_POSITION" [displayTense]="RelationshipTense.PRESENT" [icon]="'ta ta-briefcase'" [relationships]="structuredRelationships?.jobs" [active]="active" [expanded]="expanded"></ta-relationships-division>
                <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.JOB_TASK" [displayTense]="RelationshipTense.PRESENT" [icon]="'ta ta-briefcase'" [relationships]="structuredRelationships?.jobs" [active]="active" [expanded]="expanded"></ta-relationships-division>
                <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.JOB_POSITION" [displayTense]="RelationshipTense.FUTURE" [icon]="'ta ta-briefcase'" [relationships]="structuredRelationships?.jobs" [active]="active" [expanded]="expanded"></ta-relationships-division>
                <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.JOB_TASK" [displayTense]="RelationshipTense.FUTURE" [icon]="'ta ta-briefcase'" [relationships]="structuredRelationships?.jobs" [active]="active" [expanded]="expanded"></ta-relationships-division>
                <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.JOB_POSITION" [displayTense]="RelationshipTense.PAST" [icon]="'ta ta-briefcase'" [relationships]="structuredRelationships?.jobs" [active]="active" [expanded]="expanded"></ta-relationships-division>
                <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.JOB_TASK" [displayTense]="RelationshipTense.PAST" [icon]="'ta ta-briefcase'" [relationships]="structuredRelationships?.jobs" [active]="active" [expanded]="expanded"></ta-relationships-division>
                <!-- Job Site Relationships -->
                <ng-container *taIfToggle="Toggle.SITES">
                    <hr *ngIf="structuredRelationships?.sites?.length !== 0" class="content__main__hr" [class.content__main__hr--first]="structuredRelationships?.jobs?.length === 0" />
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.SITE_ALL" [displayTense]="RelationshipTense.PRESENT" [icon]="'ta ta-location'" [relationships]="structuredRelationships?.sites" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.SITE_ALL" [displayTense]="RelationshipTense.FUTURE" [icon]="'ta ta-location'" [relationships]="structuredRelationships?.sites" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.SITE_ALL" [displayTense]="RelationshipTense.PAST" [icon]="'ta ta-location'" [relationships]="structuredRelationships?.sites" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                </ng-container> <!-- Job Person Relationships -->
                <ng-container *taIfToggle="Toggle.PEOPLE">
                    <hr *ngIf="structuredRelationships?.people?.length !== 0" class="content__main__hr" [class.content__main__hr--first]="structuredRelationships?.jobs?.length === 0 && structuredRelationships?.sites?.length === 0" />
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.PERSON_EMPLOYEE" [displayTense]="RelationshipTense.PRESENT" [icon]="'ta ta-people-community'" [relationships]="structuredRelationships?.people" [active]="active" [expanded]="expanded"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.PERSON_CLIENT" [displayTense]="RelationshipTense.PRESENT" [icon]="'ta ta-people-community'" [relationships]="structuredRelationships?.people" [active]="active" [expanded]="expanded"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.PERSON_EMPLOYEE" [displayTense]="RelationshipTense.FUTURE" [icon]="'ta ta-people-community'" [relationships]="structuredRelationships?.people" [active]="active" [expanded]="expanded"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.PERSON_CLIENT" [displayTense]="RelationshipTense.FUTURE" [icon]="'ta ta-people-community'" [relationships]="structuredRelationships?.people" [active]="active" [expanded]="expanded"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.PERSON_EMPLOYEE" [displayTense]="RelationshipTense.PAST" [icon]="'ta ta-people-community'" [relationships]="structuredRelationships?.people" [active]="active" [expanded]="expanded"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.PERSON_CLIENT" [displayTense]="RelationshipTense.PAST" [icon]="'ta ta-people-community'" [relationships]="structuredRelationships?.people" [active]="active" [expanded]="expanded"></ta-relationships-division>
                </ng-container>
            </div>
            <!-- Site Relationships -->
            <div *ngIf="displaySiteRelationships()">
                <!-- Site Job Relationships -->
                <ng-container *taIfToggle="Toggle.JOBS">
                    <hr *ngIf="structuredRelationships?.jobs?.length !== 0" class="content__main__hr content__main__hr--first" />
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.JOB_ALL" [displayTense]="RelationshipTense.PRESENT" [icon]="'ta ta-briefcase'" [relationships]="structuredRelationships?.jobs" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.JOB_ALL" [displayTense]="RelationshipTense.FUTURE" [icon]="'ta ta-briefcase'" [relationships]="structuredRelationships?.jobs" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.JOB_ALL" [displayTense]="RelationshipTense.PAST" [icon]="'ta ta-briefcase'" [relationships]="structuredRelationships?.jobs" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                </ng-container> <!-- Site Site Relationships -->
                <hr *ngIf="structuredRelationships?.sites?.length !== 0" class="content__main__hr" [class.content__main__hr--first]="structuredRelationships?.jobs?.length === 0" />
                <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.SITE_WORKPLACE" [displayTense]="RelationshipTense.PRESENT" [icon]="'ta ta-location'" [relationships]="structuredRelationships?.sites" [active]="active" [expanded]="expanded"></ta-relationships-division>
                <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.SITE_SPECIFIC_LOCATION" [displayTense]="RelationshipTense.PRESENT" [icon]="'ta ta-location'" [relationships]="structuredRelationships?.sites" [active]="active" [expanded]="expanded"></ta-relationships-division>
                <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.SITE_WORKPLACE" [displayTense]="RelationshipTense.FUTURE" [icon]="'ta ta-location'" [relationships]="structuredRelationships?.sites" [active]="active" [expanded]="expanded"></ta-relationships-division>
                <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.SITE_SPECIFIC_LOCATION" [displayTense]="RelationshipTense.FUTURE" [icon]="'ta ta-location'" [relationships]="structuredRelationships?.sites" [active]="active" [expanded]="expanded"></ta-relationships-division>
                <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.SITE_WORKPLACE" [displayTense]="RelationshipTense.PAST" [icon]="'ta ta-location'" [relationships]="structuredRelationships?.sites" [active]="active" [expanded]="expanded"></ta-relationships-division>
                <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.SITE_SPECIFIC_LOCATION" [displayTense]="RelationshipTense.PAST" [icon]="'ta ta-location'" [relationships]="structuredRelationships?.sites" [active]="active" [expanded]="expanded"></ta-relationships-division>
                <!-- Site Person Relationships -->
                <ng-container *taIfToggle="Toggle.PEOPLE">
                    <hr *ngIf="structuredRelationships?.people?.length !== 0" class="content__main__hr" [class.content__main__hr--first]="structuredRelationships?.jobs?.length === 0 && structuredRelationships?.sites?.length === 0" />
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.PERSON_ALL" [displayTense]="RelationshipTense.PRESENT" [icon]="'ta ta-people-community'" [relationships]="structuredRelationships?.people" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.PERSON_ALL" [displayTense]="RelationshipTense.FUTURE" [icon]="'ta ta-people-community'" [relationships]="structuredRelationships?.people" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.PERSON_ALL" [displayTense]="RelationshipTense.PAST" [icon]="'ta ta-people-community'" [relationships]="structuredRelationships?.people" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                </ng-container>
            </div>
            <!-- Person Relationships -->
            <div *ngIf="displayPersonRelationships()">
                <!-- Person Job Relationships -->
                <ng-container *taIfToggle="Toggle.JOBS">
                    <hr *ngIf="structuredRelationships?.jobs?.length !== 0" class="content__main__hr content__main__hr--first" />
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.JOB_ALL" [displayTense]="RelationshipTense.PRESENT" [icon]="'ta ta-briefcase'" [relationships]="structuredRelationships?.jobs" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.JOB_ALL" [displayTense]="RelationshipTense.FUTURE" [icon]="'ta ta-briefcase'" [relationships]="structuredRelationships?.jobs" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.JOB_ALL" [displayTense]="RelationshipTense.PAST" [icon]="'ta ta-briefcase'" [relationships]="structuredRelationships?.jobs" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                </ng-container> <!-- Person Site Relationships -->
                <ng-container *taIfToggle="Toggle.SITES">
                    <hr *ngIf="structuredRelationships?.sites?.length !== 0" class="content__main__hr" [class.content__main__hr--first]="structuredRelationships?.jobs?.length === 0" />
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.SITE_ALL" [displayTense]="RelationshipTense.PRESENT" [icon]="'ta ta-location'" [relationships]="structuredRelationships?.sites" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.SITE_ALL" [displayTense]="RelationshipTense.FUTURE" [icon]="'ta ta-location'" [relationships]="structuredRelationships?.sites" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                    <ta-relationships-division [viewingContext]="relationshipType" [displayContext]="RelationshipType.SITE_ALL" [displayTense]="RelationshipTense.PAST" [icon]="'ta ta-location'" [relationships]="structuredRelationships?.sites" [active]="active" [expanded]="expanded" [overrideDisplayContext]="true"></ta-relationships-division>
                </ng-container>
                <ng-container *ngIf="!toggleService.isOn(Toggle.JOBS) && !toggleService.isOn(Toggle.SITES)">
                    <div class="content__main">
                        <p class="content__main--none">{{ 'system.relationships.none' | translate }}</p>
                    </div>
                </ng-container>
            </div>
        </div>
        <ng-template #none class="content__main">
            <p class="content__main--none">{{ 'system.relationships.none' | translate }}</p>
        </ng-template>
    </ng-template>
</div>
