import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { SharedModule } from '@ta/app/shared/shared.module';
import { DynamicFormDirective } from './directives/dynamic-form.directive';
import { DynamicFormElementDirective } from './directives/dynamic-form-element.directive';
import { FormElementInputTextComponent } from './form-element-input-text/form-element-input-text.component';
import { FormElementDropdownComponent } from './form-element-dropdown/form-element-dropdown.component';
import { FormElementWrapperComponent } from './form-element-wrapper/form-element-wrapper.component';
import { FormElementPanelComponent } from './form-element-panel/form-element-panel.component';
import { FormElementPanelDynamicComponent } from './form-element-panel-dynamic/form-element-panel-dynamic.component';

@NgModule({
    declarations: [
        DynamicFormComponent,
        DynamicFormDirective,
        DynamicFormElementDirective,
        FormElementInputTextComponent,
        FormElementDropdownComponent,
        FormElementWrapperComponent,
        FormElementPanelComponent,
        FormElementPanelDynamicComponent
    ],
    imports: [CommonModule, SharedModule],
    exports: [DynamicFormComponent]
})
export class DynamicFormModule { }
