import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from '@ta/app/shared/shared.module';
import { ApiErrorInterceptor } from '@ta/app/core/interceptors/api-error.interceptor';

import { BladeAccountComponent } from '@ta/app/core/components/blade-account/blade-account.component';
import { BladeAccountDetailsComponent } from '@ta/app/core/components/blade-account-details/blade-account-details';
import { BladeComponent } from '@ta/app/core/components/blade/blade.component';
import { BladeHeaderComponent } from '@ta/app/core/components/blade-header/blade-header.component';
import { BladeHelpComponent } from '@ta/app/core/components/blade-help/blade-help.component';
import { BladeNotificationsComponent } from '@ta/app/core/components/blade-notifications/blade-notifications.component';
import { AuthFinaliseAccountComponent } from '@ta/app/core/components/auth-finalise-account/auth-finalise-account.component';
import { NavBarLeftComponent } from '@ta/app/core/components/navbar-left/navbar-left.component';
import { NavbarMenuAccountComponent } from '@ta/app/core/components/navbar-top-menu-account/navbar-top-menu-account.component';
import { NavbarMenuComponent } from '@ta/app/core/components/navbar-top-menu/navbar-top-menu.component';
import { NavBarTopComponent } from '@ta/app/core/components/navbar-top/navbar-top.component';
import { WorkspaceSwitcherComponent } from '@ta/app/core/components/workspace-switcher/workspace-switcher.component';
import { NavbarLeftMenuComponent } from '@ta/app/core/components/navbar-left-menu/navbar-left-menu.component';
import { BladeControlsComponent } from '@ta/app/core/components/blade-controls/blade-controls.component';
import { NavbarLeftMenuCompactComponent } from '@ta/app/core/components/navbar-left-menu-compact/navbar-left-menu-compact.component';
import { WorkspaceSwitcherCardComponent } from '@ta/app/core/components/workspace-switcher-card/workspace-switcher-card.component';
import { AuthInvitationComponent } from '@ta/app/core/components/auth-invitation/auth-invitation.component';
import { AuthComponent } from '@ta/app/core/components/auth/auth.component';
import { PrivacyComponent } from '@ta/app/core/components/privacy/privacy.component';
import { TermsConditionsComponent } from '@ta/app/core/components/terms-conditions/terms-conditions.component';
import { BladeAccountDataComponent } from '@ta/app/core/components/blade-account-data/blade-account-data.component';
import { VerifyEmailComponent } from '@ta/app/core/components/verify-email/verify-email.component';
import { MessageService } from 'primeng/api';
import { WorkspaceCreateModelComponent } from '@ta/app/core/components/workspace-create-model/workspace-create-model.component';
import { WorkspaceCreateStandardComponent } from '@ta/app/core/components/workspace-create-standard/workspace-create-standard.component';
import { WorkspaceCreatePremiumComponent } from '@ta/app/core/components/workspace-create-premium/workspace-create-premium.component';
import { OopsComponent } from '@ta/app/core/components/oops/oops.component';
import { InsufficientPermissionsComponent } from '@ta/app/core/components/insufficient-permissions/insufficient-permissions.component';
import { TermsAndPolicyUpdatesComponent } from './components/terms-and-policy-updates/terms-and-policy-updates.component';
import { BladeAccountSubscriptionsComponent } from './components/blade-account-subscriptions/blade-account-subscriptions.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthSocialProviderComponent } from './components/auth-social-provider/auth-social-provider.component';
import { AuthLoginComponent } from '@ta/app/core/components/auth-login/auth-login.component';

const components = [
    BladeAccountComponent,
    BladeAccountDetailsComponent,
    BladeComponent,
    BladeControlsComponent,
    BladeHeaderComponent,
    BladeHelpComponent,
    BladeNotificationsComponent,
    AuthFinaliseAccountComponent,
    AuthInvitationComponent,
    AuthLoginComponent,
    NavBarLeftComponent,
    NavbarLeftMenuCompactComponent,
    NavbarLeftMenuComponent,
    NavbarMenuAccountComponent,
    NavbarMenuComponent,
    NavBarTopComponent,
    WorkspaceSwitcherComponent,
    WorkspaceSwitcherCardComponent,
    PrivacyComponent,
    TermsConditionsComponent,
    BladeAccountDataComponent,
    AuthSocialProviderComponent
];

@NgModule({
    imports: [CommonModule, RouterModule, SharedModule],
    declarations: [
        ...components,
        AuthComponent,
        VerifyEmailComponent,
        WorkspaceCreateModelComponent,
        WorkspaceCreateStandardComponent,
        WorkspaceCreatePremiumComponent,
        OopsComponent,
        InsufficientPermissionsComponent,
        TermsAndPolicyUpdatesComponent,
        BladeAccountSubscriptionsComponent,
        NotFoundComponent
    ],
    exports: [...components],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true }, MessageService]
})
export class CoreModule {}
