import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { InvitationService } from '@ta/app/core/services/invitation.service';
import { catchError, take } from 'rxjs/operators';
import { Invitation } from '@ta/app/core/models/invitation.model';

@Injectable({
    providedIn: 'root'
})
export class InvitationResolver implements Resolve<InvitationService | undefined> {
    constructor(private readonly _invitationService: InvitationService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return this._invitationService.getInvitation(route.queryParams.invitationGuid).pipe(
            take(1),
            catchError((invitation: Invitation) => {
                // Return the invalid invitation data to the component for handling
                // This occurs when the invitation has expired, so the component can handle accordingly
                return of(invitation);
            })
        );
    }
}
