import { ErrorCodes } from './api-response.model';

/**
 * The data model for the oops error page
 */
export interface ErrorMessage {
    title: string;
    subtitle: string;
    explanationTitle?: string;
    explanationMessages?: Array<string>;
    buttonMessage: string;
}

/**
 * Maps error codes to error page messages
 */
export const errorCodeMessageMap: Map<number, ErrorMessage> = new Map([
    [
        ErrorCodes.GENERAL_ERROR,
        {
            title: 'system.oops.title',
            subtitle: 'system.oops.subtitle',
            explanationTitle: 'system.oops.explanation.title',
            explanationMessages: ['system.oops.explanation.para-1', 'system.oops.explanation.para-2', 'system.oops.explanation.para-3'],
            buttonMessage: 'system.oops.return'
        }
    ],
    [
        ErrorCodes.INVITE_FLOW_INVITATION_NOT_VALID,
        {
            title: 'system.oops.invite-invalid.title',
            subtitle: 'system.oops.invite-invalid.subtitle',
            explanationTitle: 'system.oops.invite-invalid.explanation.title',
            explanationMessages: ['system.oops.invite-invalid.explanation.para-1'],
            buttonMessage: 'system.oops.invite-invalid.return'
        }
    ],
    [
        ErrorCodes.INVITE_FLOW_INVITATION_ISSUED_TO_DIFFERENT_EMAIL,
        {
            title: 'system.oops.invite-wrong-email.title',
            subtitle: 'system.oops.invite-wrong-email.subtitle',
            explanationTitle: 'system.oops.invite-wrong-email.explanation.title',
            explanationMessages: ['system.oops.invite-wrong-email.explanation.para-1', 'system.oops.invite-wrong-email.explanation.para-2'],
            buttonMessage: 'system.oops.invite-wrong-email.return'
        }
    ],
    [
        ErrorCodes.CLIENT_EMAIL_VERIFIED_GUARD_GET_USER_FAILED_AUTH0,
        {
            title: 'system.oops.client.email-verified-guard.get-user-failed.title',
            subtitle: 'system.oops.client.email-verified-guard.get-user-failed.subtitle',
            buttonMessage: 'system.oops.client.email-verified-guard.get-user-failed.return'
        }
    ],
    [
        ErrorCodes.CLIENT_EMAIL_NOT_VERIFIED_GUARD_GET_USER_FAILED_AUTH0,
        {
            title: 'system.oops.client.email-not-verified-guard.get-user-failed.title',
            subtitle: 'system.oops.client.email-not-verified-guard.get-user-failed.subtitle',
            buttonMessage: 'system.oops.client.email-not-verified-guard.get-user-failed.return'
        }
    ],
    [
        ErrorCodes.CLIENT_USER_FINALISED_GUARD_CREATE_USER_FAILED_API,
        {
            title: 'system.oops.client.user-finalised-guard.create-user-failed.title',
            subtitle: 'system.oops.client.user-finalised-guard.create-user-failed.subtitle',
            buttonMessage: 'system.oops.client.user-finalised-guard.create-user-failed.return'
        }
    ],
    [
        ErrorCodes.CLIENT_USER_FINALISED_GUARD_REGISTER_AUTHPROVIDERID_FAILED_API,
        {
            title: 'system.oops.client.user-finalised-guard.register-auth-provider-id-failed.title',
            subtitle: 'system.oops.client.user-finalised-guard.register-auth-provider-id-failed.subtitle',
            buttonMessage: 'system.oops.client.user-finalised-guard.register-auth-provider-id-failed.return'
        }
    ],
    [
        ErrorCodes.CLIENT_USER_NOT_FINALISED_GUARD_CREATE_USER_FAILED_API,
        {
            title: 'system.oops.client.user-not-finalised-guard.create-user-failed.title',
            subtitle: 'system.oops.client.user-not-finalised-guard.create-user-failed.subtitle',
            buttonMessage: 'system.oops.client.user-not-finalised-guard.create-user-failed.return'
        }
    ],
    [
        ErrorCodes.CLIENT_USER_NOT_FINALISED_GUARD_REGISTER_AUTHPROVIDERID_FAILED_API,
        {
            title: 'system.oops.client.user-not-finalised-guard.register-auth-provider-id-failed.title',
            subtitle: 'system.oops.client.user-not-finalised-guard.register-auth-provider-id-failed.subtitle',
            buttonMessage: 'system.oops.client.user-not-finalised-guard.register-auth-provider-id-failed.return'
        }
    ]
]);
