import { Injectable } from '@angular/core';
import { OperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiAction, translateKeysApiActionComplete } from '@ta/app/shared/models/api-action.model';
import { ToastSeverity } from '@ta/app/shared/models/toast.model';
import { ToastService } from '@ta/app/shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ApiResponseService {
    constructor(private readonly _toastService: ToastService, readonly _translateService: TranslateService) {}

    /**
     * Taps into an API response observable and displays a success toast.
     * Only add this to API request pipe if you want a success toast to be shown to the user.
     * @param apiAction Generates success message based on ApiAction type.
     * @returns tap() rxjs function, so observable continues as usual
     */
    showToastOnSuccess<T>(apiAction?: string): OperatorFunction<T, T> {
        // Set generic message when no ApiAction is set
        const apiActionKey: string = apiAction ? apiAction : ApiAction.GENERIC;
        // By returning a tap, to original observable continues as usual
        return tap(() => {
            // Translate the relevant kay, then display a toast
            this._translateService.get(translateKeysApiActionComplete[apiActionKey]).subscribe((summary: any) => this._toastService.addToast({ severity: ToastSeverity.SUCCESS, summary }));
        });
    }

    showToastOnGoogleMapsGeocodingError<T>(): OperatorFunction<T, T> {
        return tap(() => {
            this._translateService.get('system.googlemaps.geocoding.error').subscribe((summary: any) => this._toastService.addToast({ severity: ToastSeverity.ERROR, summary }));
        });
    }
}
