import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Tag } from 'primeng/tag';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, switchMap, take, catchError } from 'rxjs/operators';
import { ApiAction } from '@ta/app/shared/models/api-action.model';
import { Audit } from '@ta/app/shared/models/audit/audit.model';
import { IObjectPermissionRequest } from '@ta/app/shared/models/permissions/object-permission-request.model';
import { RelationshipRequest, StructuredRelationships } from '@ta/app/shared/models/relationship/relationships.model';
import { CreateSiteRequest } from '@ta/app/shared/models/site/create-site-request';
import { SiteList } from '@ta/app/shared/models/site/site-list.model';
import { Site } from '@ta/app/shared/models/site/site.model';
import { SiteStatus } from '@ta/app/shared/models/site/siteStatus.model';
import { SiteType } from '@ta/app/shared/models/site/siteType.model';
import { UpdateSiteRequest } from '@ta/app/shared/models/site/update-site-request';
import { StructuredTagSet } from '@ta/app/shared/models/tag/structured-tag-set.model';
import { ApiResponseService } from '@ta/app/shared/services/api-response.service';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';

@Injectable({
    providedIn: 'root'
})
export class SiteService {
    constructor(private readonly _http: HttpClient, private readonly _workspaceService: WorkspaceService, private readonly _apiResponseService: ApiResponseService, private readonly _router: Router) {}

    siteId!: number;

    private readonly _SITE_ROUTE = 'Site';
    private readonly _PERMISSION_ROUTE = 'objectpermissions';
    private readonly _SITE_TYPES_ROUTES = 'Site/list/types';
    private readonly _SITE_STATUSES_ROUTES = 'Site/list/statuses';
    private readonly _LIST_ROUTE = 'list';
    private readonly _ARCHIVE_SITES_ROUTE = 'site/archive';
    private readonly _UNARCHIVE_SITES_ROUTE = 'site/unarchive';
    private readonly _DELETE_SITES_ROUTE = 'site';
    private readonly _TAGS_ROUTE = 'tags';
    private readonly _TAGS_STRUCTURED_ROUTE = 'tags/structured';
    private readonly _AUDIT_LIST_ROUTE = 'Audit/Site';
    private readonly _RELATIONSHIPS_ROUTE = 'relationships';
    private readonly _REMOVE_RELATIONSHIPS_ROUTE = 'remove';
    private readonly _ADD_RELATIONSHIPS_ROUTE = 'add';
    private readonly _RELATIONSHIPS_STRUCTURED_ROUTE = 'relationships/structured';

    private readonly _site = new BehaviorSubject<Site | undefined>(undefined);
    readonly site$ = this._site.asObservable();

    private readonly _siteTypes = new BehaviorSubject<SiteType[] | undefined>(undefined);
    readonly siteTypes$ = this._siteTypes.asObservable();

    private readonly _siteStatuses = new BehaviorSubject<SiteStatus[] | undefined>(undefined);
    readonly siteStatuses$ = this._siteStatuses.asObservable();

    private readonly _siteList = new BehaviorSubject<SiteList | undefined>(undefined);
    readonly siteList$ = this._siteList.asObservable();

    private readonly _siteTags = new BehaviorSubject<Tag[] | undefined>(undefined);
    readonly siteTags$ = this._siteTags.asObservable();
    private readonly _auditList = new BehaviorSubject<Array<Audit> | undefined>(undefined);
    readonly auditList$ = this._auditList.asObservable();

    private readonly _siteTagsStructured = new BehaviorSubject<StructuredTagSet[] | undefined>(undefined);
    readonly siteTagsStructured$ = this._siteTagsStructured.asObservable();

    private readonly _siteRelationships = new BehaviorSubject<RelationshipRequest | undefined>(undefined);
    readonly siteRelationships$ = this._siteRelationships.asObservable();

    private readonly _siteStructuredRelationships = new BehaviorSubject<StructuredRelationships | undefined>(undefined);
    readonly siteStructuredRelationships$ = this._siteStructuredRelationships.asObservable();

    private readonly _objectLevelPermissions = new BehaviorSubject<Array<IObjectPermissionRequest> | undefined>(undefined);
    readonly objectLevelPermissions$ = this._objectLevelPermissions.asObservable();

    getSite(siteId: number): Observable<Site | undefined> {
        return this._http.get<Site>(`${this._workspaceService.workspaceApiUrl}/${this._SITE_ROUTE}/${siteId}`).pipe(
            tap((site) => {
                this._site.next(site);
            }),
            switchMap(() => this.site$)
        );
    }

    clearSite(): void {
        this._site.next(undefined);
    }

    getSiteStatus(): Observable<SiteStatus[] | undefined> {
        return this._http.get<SiteStatus[]>(`${this._workspaceService.workspaceApiUrl}/${this._SITE_STATUSES_ROUTES}`).pipe(
            tap((siteStatuses) => {
                this._siteStatuses.next(siteStatuses);
            }),
            switchMap(() => this.siteStatuses$)
        );
    }

    getSiteType(): Observable<SiteType[] | undefined> {
        return this._http.get<SiteType[]>(`${this._workspaceService.workspaceApiUrl}/${this._SITE_TYPES_ROUTES}`).pipe(
            tap((siteTypes) => {
                this._siteTypes.next(siteTypes);
            }),
            switchMap(() => this.siteTypes$)
        );
    }

    getSites(params: any): Observable<SiteList | undefined> {
        return this._http.get<SiteList>(`${this._workspaceService.workspaceApiUrl}/${this._SITE_ROUTE}/${this._LIST_ROUTE}`, { params: { queryOptions: params } }).pipe(
            tap((sites) => {
                this._siteList.next(sites);
            }),
            switchMap(() => this.siteList$)
        );
    }

    archiveSites(siteIds: Array<number>): Observable<object> {
        return this._http.put(`${this._workspaceService.workspaceApiUrl}/${this._ARCHIVE_SITES_ROUTE}`, siteIds).pipe(this._apiResponseService.showToastOnSuccess(ApiAction.ARCHIVE));
    }

    unarchiveSites(siteIds: Array<number>): Observable<object> {
        return this._http.put(`${this._workspaceService.workspaceApiUrl}/${this._UNARCHIVE_SITES_ROUTE}`, siteIds).pipe(this._apiResponseService.showToastOnSuccess(ApiAction.UNARCHIVE));
    }

    deleteSites(siteIds: Array<number>): Observable<object> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: siteIds
        };

        return this._http.delete(`${this._workspaceService.workspaceApiUrl}/${this._DELETE_SITES_ROUTE}`, options).pipe(this._apiResponseService.showToastOnSuccess(ApiAction.PERMANENTLY_DELETE));
    }

    updateSite(updateSite: UpdateSiteRequest): Observable<Site | undefined> {
        return this._http.put<Site>(`${this._workspaceService.workspaceApiUrl}/${this._SITE_ROUTE}`, updateSite).pipe(
            this._apiResponseService.showToastOnSuccess(ApiAction.UPDATE),
            tap((site) => {
                this._site.next(site);
            }),
            switchMap(() => this.site$)
        );
    }

    createSite(site: CreateSiteRequest): Observable<Site | undefined> {
        return this._http.post<Site>(`${this._workspaceService.workspaceApiUrl}/${this._SITE_ROUTE}`, site).pipe(
            this._apiResponseService.showToastOnSuccess(ApiAction.CREATE),
            tap((result) => {
                this._site.next(result);
            }),
            switchMap(() => this.site$)
        );
    }

    /* TAG ENDPOINTS */

    getSiteTags(id: number): Observable<Tag[] | undefined> {
        return this._http.get<Tag[]>(`${this._workspaceService.workspaceApiUrl}/${this._SITE_ROUTE}/${id}/${this._TAGS_ROUTE}`).pipe(
            tap((siteTags) => {
                this._siteTags.next(siteTags);
                this.getSiteTagsStructured(id).pipe(take(1)).subscribe();
            }),
            switchMap(() => this.siteTags$)
        );
    }

    getSiteTagsStructured(id: number): Observable<StructuredTagSet[] | undefined> {
        return this._http.get<StructuredTagSet[]>(`${this._workspaceService.workspaceApiUrl}/${this._SITE_ROUTE}/${id}/${this._TAGS_STRUCTURED_ROUTE}`).pipe(
            tap((siteTagsStructured) => {
                this._siteTagsStructured.next(siteTagsStructured);
            }),
            switchMap(() => this.siteTagsStructured$)
        );
    }

    addSiteTags(tagIds: Array<number>, siteId: number): Observable<object> {
        return this._http.post(`${this._workspaceService.workspaceApiUrl}/${this._SITE_ROUTE}/${siteId}/${this._TAGS_ROUTE}`, tagIds).pipe(
            tap(() => {
                this.getSiteTagsStructured(siteId).pipe(take(1)).subscribe();
            }),
            catchError(() => {
                this.getSiteTagsStructured(siteId).pipe(take(1)).subscribe();
                return new Observable<any>();
            })
        );
    }

    deleteSiteTags(tagIds: Array<number>, siteId: number): Observable<object> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: tagIds
        };

        return this._http.delete(`${this._workspaceService.workspaceApiUrl}/${this._SITE_ROUTE}/${siteId}/${this._TAGS_ROUTE}`, options).pipe(
            tap(() => {
                this.getSiteTagsStructured(siteId).pipe(take(1)).subscribe();
            }),
            catchError(() => {
                this.getSiteTagsStructured(siteId).pipe(take(1)).subscribe();
                return new Observable<any>();
            })
        );
    }

    // Audit endpoints

    retrieveAudits(siteId: number, actions: Array<number>): Observable<Array<Audit> | undefined> {
        return this._http.get<Array<Audit>>(`${this._workspaceService.workspaceApiUrl}/${this._AUDIT_LIST_ROUTE}/${siteId}`, { params: { eventTypes: '[' + actions.toString() + ']' } }).pipe(
            tap((audits) => {
                this._auditList.next(audits);
            }),
            switchMap(() => this.auditList$)
        );
    }

    /* RELATIONSHIP ENDPOINTS */

    getSiteRelationships(siteId: number): Observable<RelationshipRequest | undefined> {
        return this._http.get<RelationshipRequest>(`${this._workspaceService.workspaceApiUrl}/${this._SITE_ROUTE}/${siteId}/${this._RELATIONSHIPS_ROUTE}`).pipe(
            tap((siteRelationships) => {
                this._siteRelationships.next(siteRelationships);
            }),
            switchMap(() => this.siteRelationships$)
        );
    }

    getSiteStructuredRelationships(siteId: number): Observable<StructuredRelationships | undefined> {
        return this._http.get<StructuredRelationships>(`${this._workspaceService.workspaceApiUrl}/${this._SITE_ROUTE}/${siteId}/${this._RELATIONSHIPS_STRUCTURED_ROUTE}`).pipe(
            tap((siteRelationships) => {
                this._siteStructuredRelationships.next(siteRelationships);
            }),
            switchMap(() => this.siteStructuredRelationships$)
        );
    }

    addSiteRelationships(relationships: RelationshipRequest, siteId: number): Observable<RelationshipRequest | undefined> {
        return this._http
            .post<RelationshipRequest>(`${this._workspaceService.workspaceApiUrl}/${this._SITE_ROUTE}/${siteId}/${this._RELATIONSHIPS_ROUTE}/${this._ADD_RELATIONSHIPS_ROUTE}`, relationships)
            .pipe(
                tap((siteRelationships) => {
                    this._siteRelationships.next(siteRelationships);
                    this.getSiteStructuredRelationships(siteId).pipe(take(1)).subscribe();
                }),
                catchError(() => {
                    this.getSiteStructuredRelationships(siteId).pipe(take(1)).subscribe();
                    return new Observable<any>();
                }),
                switchMap(() => this.siteRelationships$)
            );
    }

    deleteSiteRelationships(relationships: RelationshipRequest, siteId: number): Observable<RelationshipRequest | undefined> {
        return this._http
            .put<RelationshipRequest>(`${this._workspaceService.workspaceApiUrl}/${this._SITE_ROUTE}/${siteId}/${this._RELATIONSHIPS_ROUTE}/${this._REMOVE_RELATIONSHIPS_ROUTE}`, relationships)
            .pipe(
                tap((siteRelationships) => {
                    this._siteRelationships.next(siteRelationships);
                    this.getSiteStructuredRelationships(siteId).pipe(take(1)).subscribe();
                }),
                catchError(() => {
                    this.getSiteStructuredRelationships(siteId).pipe(take(1)).subscribe();
                    return new Observable<any>();
                }),
                switchMap(() => this.siteRelationships$)
            );
    }

    /* OBJECT LEVEL PERMISSIONS ENDPOINTS */

    listObjectLevelPermissions(objectId: number): Observable<Array<IObjectPermissionRequest> | undefined> {
        return this._http.get<Array<IObjectPermissionRequest>>(`${this._workspaceService.workspaceApiUrl}/${this._PERMISSION_ROUTE}/${this._SITE_ROUTE}/${objectId}/${this._LIST_ROUTE}`).pipe(
            tap((permissionResults) => {
                this._objectLevelPermissions.next(permissionResults);
            }),
            switchMap(() => this.objectLevelPermissions$)
        );
    }

    createObjectLevelPermission(objectId: number, objectLevelPermission: IObjectPermissionRequest): Observable<object | undefined> {
        return this._http.post<Array<IObjectPermissionRequest>>(`${this._workspaceService.workspaceApiUrl}/${this._PERMISSION_ROUTE}/${this._SITE_ROUTE}/${objectId}`, objectLevelPermission).pipe(
            tap(() => {
                this.listObjectLevelPermissions(objectId).pipe(take(1)).subscribe();
            })
        );
    }

    updateObjectLevelPermission(objectId: number, objectLevelPermission: IObjectPermissionRequest): Observable<object | undefined> {
        return this._http.put<Array<IObjectPermissionRequest>>(`${this._workspaceService.workspaceApiUrl}/${this._PERMISSION_ROUTE}/${this._SITE_ROUTE}/${objectId}`, objectLevelPermission).pipe(
            tap(() => {
                this.listObjectLevelPermissions(objectId).pipe(take(1)).subscribe();
            })
        );
    }

    deleteObjectLevelPermission(objectId: number, userId: number): Observable<object | undefined> {
        return this._http
            .delete<Array<IObjectPermissionRequest>>(`${this._workspaceService.workspaceApiUrl}/${this._PERMISSION_ROUTE}/${this._SITE_ROUTE}/${objectId}`, { params: { userId: userId.toString() } })
            .pipe(
                tap(() => {
                    this.listObjectLevelPermissions(objectId).pipe(take(1)).subscribe();
                })
            );
    }
}
