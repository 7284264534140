import { Injectable, isDevMode } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { firstValueFrom } from 'rxjs';
import { AppRoutes } from '@ta/app/shared/models/routes.model';

@Injectable()
export class AuthenticatedGuard implements CanActivate, CanActivateChild {
    constructor(private readonly _router: Router, private _auth0Service: Auth0Service) {}

    canActivate(): Promise<true | UrlTree> {
        return this.checkUserIsAuthenticated();
    }

    canActivateChild(): Promise<true | UrlTree> {
        return this.checkUserIsAuthenticated();
    }

    private async checkUserIsAuthenticated(): Promise<true | UrlTree> {
        //////////////////////////////////////
        // Is the User logged in? Note that this observable tracks whether the user is authenticated with Auth0, and not if the user's access token is currently valid.
        //////////////////////////////////////
        const isAuthenticated = await firstValueFrom(this._auth0Service.isAuthenticated$);

        // If the user is not authenticated, send them to login
        if (!isAuthenticated) {
            return this._router.createUrlTree([AppRoutes.LOGIN]);
        }

        // If the user's access token has expired, the value of getUser() will be undefined, so we are using this here as a check whether the user's access token has expired.
        const user = await firstValueFrom(this._auth0Service.getUser());

        if (!user) {
            try {
                const accessToken = await firstValueFrom(this._auth0Service.getAccessTokenSilently());
                if (isDevMode()) console.log('Access token has been refreshed via the authenticated guard');
            } catch (err) {
                if (isDevMode()) {
                    console.error('An error occurred when attempting to automatically refresh the access token via the authenticated guard. Full error:');
                    console.error(err);
                }
                // If we can't refresh the token silently, go back to login.
                return this._router.createUrlTree([AppRoutes.LOGIN]);
            }
        }

        return true;
    }
}
