<div class="assessment-detail-activity-segment-item">
    <div class="top">
        <div class="top__left-icon">
            <i [class]="leftIcon"></i>
        </div>
        <div class="top__info">
            <ng-content></ng-content>
            <p>
                {{ itemDate | dateLocaliser | date: 'dd MMM YYYY' }} {{ 'system.at' | translate }} {{ itemDate | dateLocaliser | date: 'h:mm a' }}
                {{ edited ? '(' + ('system.assessment-details.activity.edited' | translate) + ')' : '' }}
            </p>
        </div>
        <div class="top__icons">
            <ng-template *ngTemplateOutlet="!!icons ? icons : defaultIcons"></ng-template>
            <ng-template #defaultIcons>
                <i class="ta ta-person-lock top__icons__icon"></i>
            </ng-template>
        </div>
    </div>
    <ng-template *ngTemplateOutlet="!!bottom ? bottom : null"> </ng-template>
</div>
