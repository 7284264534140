import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ta-content-dropdown-simply',
    templateUrl: './content-dropdown-simply.component.html',
    styleUrls: ['./content-dropdown-simply.component.scss']
})
export class ContentDropdownSimplyComponent implements OnInit {
    constructor() {}

    @Input()
    contentHeader?: string;

    ngOnInit(): void {}
}
