export class Constants {
    public static readonly DEBOUNCE_TIME_DURATION = 500;
    public static readonly EMAIL_REGEX = '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)+$';
    public static readonly PHONE_REGEX = '^^[0-9 ()-+]+$';
    public static readonly RESPONSIVE_BREAKPOINT = 1141;
    public static readonly GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
    public static readonly P_KNOB = {
        strokeWidth: 5,
        valueColor: '#36a172',
        rangeColor: '#e1e1e1'
    };
    public static readonly MAX_FILE_SIZE = 1024 * 1024 * 100; // 100 MB
}
