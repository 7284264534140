import { Component } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { FormElementBaseComponent } from '@ta/app/dynamic-form/form-element-base/form-element-base.component';
import { requiredWhenVisible } from '@ta/app/dynamic-form/validators/required-when-visible.validator';

@Component({
    selector: 'ta-form-element-dropdown',
    templateUrl: './form-element-dropdown.component.html',
    styleUrls: ['./form-element-dropdown.component.scss']
})
export class FormElementDropdownComponent extends FormElementBaseComponent {
    formControl?: FormControl;

    getFormControl(): AbstractControl {
        if (!this.formControl) {
            this.formControl = new FormControl('', this.schema?.isRequired ? requiredWhenVisible(this.schema) : undefined);
        }

        return this.formControl;
    }
}
