import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tab } from '@ta/app/shared/models/tab.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';

@Component({
    selector: 'ta-admin-header',
    templateUrl: './admin-header.component.html',
    styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {
    /**
     * Set of tabs to display (may be null)
     */
    @Input() tabs?: Tab[];

    /**
     * Whether the form is in edit mode
     */
    @Input() edit = false;

    /**
     * Whether the form is on settings tab
     */
    @Input() isSettings = true;

    /**
     * Whether the user has clicked save
     */
    hasClickedSave = false;

    /**
     * Emitters for standard header action events
     */
    @Output() save: EventEmitter<any> = new EventEmitter();
    @Output() cancel: EventEmitter<any> = new EventEmitter();
    @Output() tabSelect: EventEmitter<Tab> = new EventEmitter();

    constructor(private readonly _linkedFormService: LinkedFormService) {}

    ngOnInit(): void {}
    /**
     * Handles the cancel event and emits it
     */
    triggerCancel(): void {
        this.cancel.emit(false);
    }

    /**
     * Handles the save event and emits it
     */
    triggerSave(): void {
        // Handle invalid form
        if (!this._linkedFormService.isValid) {
            this.hasClickedSave = true;
        }

        // still emit the save event so that other pages forms can also validate.
        this.save.emit();
    }

    /**
     * Checks whether there are forms that are dirty
     * @returns A boolean indicating if forms are dirty
     */
    areFormsDirty(): boolean {
        return this._linkedFormService.isDirty();
    }

    logTab(event: any): void {
        this.tabSelect.emit(event);
    }
}
