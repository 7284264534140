import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterItem } from '@ta/app/shared/models/filter-item.model';
import { AuditActionTranslation, AuditActions } from '@ta/app/shared/models/audit/audit-action.model';
import { Audit } from '@ta/app/shared/models/audit/audit.model';

@Component({
    selector: 'ta-audit',
    templateUrl: './audit.component.html',
    styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
    AuditAction: any = AuditActionTranslation;

    private readonly FILTER_OPTION_VIEWED = 'VIEWED';
    private readonly FILTER_OPTION_CHANGED = 'CHANGED';

    /**
     * The audits to display
     */
    @Input()
    auditLog: Audit[] = new Array<Audit>();

    /**
     * The name of the object having it's audit log displayed
     */
    @Input()
    objectName?: string;

    /**
     * Handle the changes in filter and output them.
     */
    @Output()
    filterChanged: EventEmitter<any> = new EventEmitter();

    /**
     * The filters available
     */
    filterItems!: Array<FilterItem>;

    /**
     * The current filter for audit event options
     */
    filterOptions: number[] = AuditActions.AUDIT_ALL_ACTIONS;

    /**
     * The selected values of filterItems
     */
    selectedFilters: Array<FilterItem> = [];

    constructor() {}

    ngOnInit(): void {
        this.filterItems = [
            {
                label: 'system.viewed',
                filter: this.FILTER_OPTION_VIEWED
            },
            {
                label: 'system.changed',
                filter: this.FILTER_OPTION_CHANGED
            }
        ];
    }

    /**
     * Filters the audits
     */
    filter(): void {
        if (this.selectedFilters.find((x) => x.filter === this.FILTER_OPTION_VIEWED) && this.selectedFilters.find((x) => x.filter === this.FILTER_OPTION_CHANGED)) {
            this.filterOptions = AuditActions.AUDIT_ALL_ACTIONS;
        } else if (this.selectedFilters.find((x) => x.filter === this.FILTER_OPTION_CHANGED)) {
            this.filterOptions = AuditActions.AUDIT_CHANGE_ACTIONS;
        } else if (this.selectedFilters.find((x) => x.filter === this.FILTER_OPTION_VIEWED)) {
            this.filterOptions = AuditActions.AUDIT_VIEW_ACTIONS;
        } else {
            this.filterOptions = AuditActions.AUDIT_ALL_ACTIONS;
        }
        this.filterChanged.emit(this.filterOptions);
    }

    /**
     * Clears the audit log filter.
     */
    clearFilter(): void {
        this.selectedFilters = [];
        this.filterOptions = AuditActions.AUDIT_ALL_ACTIONS;
        this.filterChanged.emit(this.filterOptions);
    }
}
