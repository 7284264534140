import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BladeService } from '@ta/app/shared/services/blade.service';

@Component({
  selector: 'ta-blade-header',
  templateUrl: './blade-header.component.html',
  styleUrls: ['./blade-header.component.scss']
})
export class BladeHeaderComponent {
  /**
   * Blade heading
   */
  @Input() heading!: string;

  constructor(private readonly _bladeService: BladeService, private readonly _router: Router) { }

  /**
   * Close the workspace switcher blade
   */
  closeBlade(): void {
    this._bladeService.closeBladesAndMenus();
  }

}
