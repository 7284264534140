import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { DynamicFormElementDirective } from '@ta/app/dynamic-form/directives/dynamic-form-element.directive';

@Component({
    selector: 'ta-form-element-wrapper',
    templateUrl: './form-element-wrapper.component.html',
    styleUrls: ['./form-element-wrapper.component.scss']
})
export class FormElementWrapperComponent {
    @ViewChild(DynamicFormElementDirective, { static: true }) elementHost!: DynamicFormElementDirective;

    @Input()
    @HostBinding('class.hidden')
    hidden = false;

    title?: string;
    description?: string;

    setWrapperData(title?: string, description?: string): void {
        this.title = title;
        this.description = description;
    }
}
