import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ta-insufficient-permissions',
    templateUrl: './insufficient-permissions.component.html',
    styleUrls: ['./insufficient-permissions.component.scss']
})
export class InsufficientPermissionsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
