import { Component } from '@angular/core';
import { BladeQueryParamValues, MenuQueryParamValues, TabQueryParamValues } from '@ta/app/shared/models/routes.model';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';
import { Toggle } from '@ta/app/shared-modules/toggle/models/toggle.model';

@Component({
    selector: 'ta-navbar-top',
    templateUrl: './navbar-top.component.html',
    styleUrls: ['./navbar-top.component.scss']
})
export class NavBarTopComponent {
    BladeQueryParamValues: any = BladeQueryParamValues;
    MenuQueryParamValues: any = MenuQueryParamValues;
    TabQueryParamValues: any = TabQueryParamValues;
    Toggle: any = Toggle;

    constructor(readonly worspaceService: WorkspaceService) {}
}
