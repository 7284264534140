import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DynamicFormElementSchema } from 'src/app/shared/models/dynamic-form-element-schema.model';

export function requiredWhenVisible(schema?: DynamicFormElementSchema): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!schema) return null;

        // SurveyJS schemas set visible to undefined or false, not true or false
        // so we have to assume any value that is not explictly false is true.....
        if (schema.visible === false) return null;

        // Check if the control has a value. We might need a more robust solution here over time
        // for now I am going to see if the double bang generally determins truthy-ness for us
        // or if I should adapt when we get object reference types...
        return !!control.value ? null : { visibleAndRequired: { value: control.value } };
    };
}
