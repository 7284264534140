import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Tag } from 'primeng/tag';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, switchMap, take, catchError } from 'rxjs/operators';
import { ApiAction } from '@ta/app/shared/models/api-action.model';
import { Audit } from '@ta/app/shared/models/audit/audit.model';
import { CreateJobRequest } from '@ta/app/shared/models/job/create-job.request.model';
import { JobList } from '@ta/app/shared/models/job/job-list.model';
import { JobStatus } from '@ta/app/shared/models/job/job-status.model';
import { JobType } from '@ta/app/shared/models/job/job-type.model';
import { Job } from '@ta/app/shared/models/job/job.model';
import { UpdateJobRequest } from '@ta/app/shared/models/job/update-job-request.model';
import { IObjectPermissionRequest } from '@ta/app/shared/models/permissions/object-permission-request.model';
import { RelationshipRequest, StructuredRelationships } from '@ta/app/shared/models/relationship/relationships.model';
import { StructuredTagSet } from '@ta/app/shared/models/tag/structured-tag-set.model';
import { ApiResponseService } from '@ta/app/shared/services/api-response.service';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';

@Injectable({
    providedIn: 'root'
})
export class JobService {
    constructor(private readonly _http: HttpClient, private readonly _workspaceService: WorkspaceService, private readonly _apiResponseService: ApiResponseService, private _router: Router) {}

    private readonly _JOB_ROUTE = 'job';
    private readonly _PERMISSION_ROUTE = 'objectpermissions';
    private readonly _JOB_TYPES_ROUTE = 'job/list/types';
    private readonly _JOB_STATUSES_ROUTE = 'job/list/statuses';
    private readonly _LIST_ROUTE = 'list';
    private readonly _JOB_ARCHIVE_ROUTE = 'job/archive';
    private readonly _JOB_UNARCHIVE_ROUTE = 'job/unarchive';
    private readonly _TAGS_ROUTE = 'tags';
    private readonly _TAGS_STRUCTURED_ROUTE = 'tags/structured';
    private readonly _JOB_AUDIT_ROUTE = 'audit/job';
    private readonly _RELATIONSHIPS_ROUTE = 'relationships';
    private readonly _REMOVE_RELATIONSHIPS_ROUTE = 'remove';
    private readonly _ADD_RELATIONSHIPS_ROUTE = 'add';
    private readonly _RELATIONSHIPS_STRUCTURED_ROUTE = 'relationships/structured';

    private readonly _job = new BehaviorSubject<Job | undefined>(undefined);
    readonly job$ = this._job.asObservable();

    private readonly _jobTypes = new BehaviorSubject<JobType[] | undefined>(undefined);
    readonly jobTypes$ = this._jobTypes.asObservable();

    private readonly _jobStatuses = new BehaviorSubject<JobStatus[] | undefined>(undefined);
    readonly jobStatuses$ = this._jobStatuses.asObservable();

    private readonly _jobList = new BehaviorSubject<JobList | undefined>(undefined);
    readonly jobList$ = this._jobList.asObservable();

    private readonly _jobTags = new BehaviorSubject<Tag[] | undefined>(undefined);
    readonly jobTags$ = this._jobTags.asObservable();

    private readonly _jobTagsStructured = new BehaviorSubject<StructuredTagSet[] | undefined>(undefined);
    readonly jobTagsStructured$ = this._jobTagsStructured.asObservable();

    private readonly _auditList = new BehaviorSubject<Array<Audit> | undefined>(undefined);
    readonly auditList$ = this._auditList.asObservable();

    private readonly _jobRelationships = new BehaviorSubject<RelationshipRequest | undefined>(undefined);
    readonly jobRelationships$ = this._jobRelationships.asObservable();

    private readonly _jobStructuredRelationships = new BehaviorSubject<StructuredRelationships | undefined>(undefined);
    readonly jobStructuredRelationships$ = this._jobStructuredRelationships.asObservable();

    private readonly _objectLevelPermissions = new BehaviorSubject<Array<IObjectPermissionRequest> | undefined>(undefined);
    readonly objectLevelPermissions$ = this._objectLevelPermissions.asObservable();

    getJob(jobId: number): Observable<Job | undefined> {
        return this._http.get<Job>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}/${jobId}`).pipe(
            tap((job) => {
                this._job.next(job);
            }),
            switchMap(() => this.job$)
        );
    }

    clearJob(): void {
        this._job.next(undefined);
    }

    getJobTypes(): Observable<JobType[] | undefined> {
        return this._http.get<JobType[]>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_TYPES_ROUTE}`).pipe(
            tap((jobTypes) => {
                this._jobTypes.next(jobTypes);
            }),
            switchMap(() => this.jobTypes$)
        );
    }

    getJobStatuses(): Observable<JobStatus[] | undefined> {
        return this._http.get<JobStatus[]>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_STATUSES_ROUTE}`).pipe(
            tap((jobStatuses) => {
                this._jobStatuses.next(jobStatuses);
            }),
            switchMap(() => this.jobStatuses$)
        );
    }

    getJobs(params: any): Observable<JobList | undefined> {
        return this._http.get<JobList>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}/${this._LIST_ROUTE}`, { params: { queryOptions: params } }).pipe(
            tap((jobs) => {
                this._jobList.next(jobs);
            }),
            switchMap(() => this.jobList$)
        );
    }

    createJob(jobRequest: CreateJobRequest): Observable<Job | undefined> {
        return this._http.post<Job>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}`, jobRequest).pipe(
            this._apiResponseService.showToastOnSuccess(ApiAction.CREATE),
            tap((job) => {
                this._job.next(job);
            }),
            switchMap(() => this.job$)
        );
    }

    updateJob(jobRequest: UpdateJobRequest): Observable<Job | undefined> {
        return this._http.put<Job>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}`, jobRequest).pipe(
            this._apiResponseService.showToastOnSuccess(ApiAction.UPDATE),
            tap((job) => {
                this._job.next(job);
            }),
            switchMap(() => this.job$)
        );
    }

    archiveJobs(jobIds: Array<number>): Observable<object> {
        return this._http.put(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ARCHIVE_ROUTE}`, jobIds).pipe(this._apiResponseService.showToastOnSuccess(ApiAction.ARCHIVE));
    }

    unarchiveJobs(jobIds: Array<number>): Observable<object> {
        return this._http.put(`${this._workspaceService.workspaceApiUrl}/${this._JOB_UNARCHIVE_ROUTE}`, jobIds).pipe(this._apiResponseService.showToastOnSuccess(ApiAction.UNARCHIVE));
    }

    deleteJobs(jobIds: Array<number>): Observable<object> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: jobIds
        };

        return this._http.delete(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}`, options).pipe(this._apiResponseService.showToastOnSuccess(ApiAction.PERMANENTLY_DELETE));
    }

    /* TAG ENDPOINTS */

    getJobTags(id: number): Observable<Tag[] | undefined> {
        return this._http.get<Tag[]>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}/${id}/${this._TAGS_ROUTE}`).pipe(
            tap((jobTags) => {
                this._jobTags.next(jobTags);
                this.getJobTagsStructured(id).pipe(take(1)).subscribe();
            }),
            switchMap(() => this.jobTags$)
        );
    }

    getJobTagsStructured(id: number): Observable<StructuredTagSet[] | undefined> {
        return this._http.get<StructuredTagSet[]>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}/${id}/${this._TAGS_STRUCTURED_ROUTE}`).pipe(
            tap((jobTagsStructured) => {
                this._jobTagsStructured.next(jobTagsStructured);
            }),
            switchMap(() => this.jobTagsStructured$)
        );
    }

    addJobTags(tagIds: Array<number>, jobId: number): Observable<object> {
        return this._http.post(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}/${jobId}/${this._TAGS_ROUTE}`, tagIds).pipe(
            tap(() => {
                this.getJobTagsStructured(jobId).pipe(take(1)).subscribe();
            }),
            catchError(() => {
                this.getJobTagsStructured(jobId).pipe(take(1)).subscribe();
                return new Observable<any>();
            })
        );
    }

    deleteJobTags(tagIds: Array<number>, jobId: number): Observable<object> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: tagIds
        };

        return this._http.delete(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}/${jobId}/${this._TAGS_ROUTE}`, options).pipe(
            tap(() => {
                this.getJobTagsStructured(jobId).pipe(take(1)).subscribe();
            }),
            catchError(() => {
                this.getJobTagsStructured(jobId).pipe(take(1)).subscribe();
                return new Observable<any>();
            })
        );
    }

    /* AUDIT ENDPOINTS */

    retrieveAudits(jobId: number, actions: Array<number>): Observable<Array<Audit> | undefined> {
        return this._http.get<Array<Audit>>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_AUDIT_ROUTE}/${jobId}`, { params: { eventTypes: '[' + actions.toString() + ']' } }).pipe(
            tap((audits) => {
                this._auditList.next(audits);
            }),
            switchMap(() => this.auditList$)
        );
    }

    /* RELATIONSHIP ENDPOINTS */

    getJobRelationships(id: number): Observable<RelationshipRequest | undefined> {
        return this._http.get<RelationshipRequest>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}/${id}/${this._RELATIONSHIPS_ROUTE}`).pipe(
            tap((jobRelationships) => {
                this._jobRelationships.next(jobRelationships);
            }),
            switchMap(() => this.jobRelationships$)
        );
    }

    getJobStructuredRelationships(id: number): Observable<StructuredRelationships | undefined> {
        return this._http.get<StructuredRelationships>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}/${id}/${this._RELATIONSHIPS_STRUCTURED_ROUTE}`).pipe(
            tap((jobRelationships) => {
                this._jobStructuredRelationships.next(jobRelationships);
            }),
            switchMap(() => this.jobStructuredRelationships$)
        );
    }

    addJobRelationships(relationships: RelationshipRequest, jobId: number): Observable<RelationshipRequest | undefined> {
        return this._http
            .post<RelationshipRequest>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}/${jobId}/${this._RELATIONSHIPS_ROUTE}/${this._ADD_RELATIONSHIPS_ROUTE}`, relationships)
            .pipe(
                tap((jobRelationships) => {
                    this._jobRelationships.next(jobRelationships);
                    this.getJobStructuredRelationships(jobId).pipe(take(1)).subscribe();
                }),
                catchError(() => {
                    this.getJobStructuredRelationships(jobId).pipe(take(1)).subscribe();
                    return new Observable<any>();
                }),
                switchMap(() => this.jobRelationships$)
            );
    }

    deleteJobRelationships(relationships: RelationshipRequest, jobId: number): Observable<RelationshipRequest | undefined> {
        return this._http
            .put<RelationshipRequest>(`${this._workspaceService.workspaceApiUrl}/${this._JOB_ROUTE}/${jobId}/${this._RELATIONSHIPS_ROUTE}/${this._REMOVE_RELATIONSHIPS_ROUTE}`, relationships)
            .pipe(
                tap((jobRelationships) => {
                    this._jobRelationships.next(jobRelationships);
                    this.getJobStructuredRelationships(jobId).pipe(take(1)).subscribe();
                }),
                catchError(() => {
                    this.getJobStructuredRelationships(jobId).pipe(take(1)).subscribe();
                    return new Observable<any>();
                }),
                switchMap(() => this.jobRelationships$)
            );
    }

    /* OBJECT LEVEL PERMISSIONS ENDPOINTS */

    listObjectLevelPermissions(objectId: number): Observable<Array<IObjectPermissionRequest> | undefined> {
        return this._http.get<Array<IObjectPermissionRequest>>(`${this._workspaceService.workspaceApiUrl}/${this._PERMISSION_ROUTE}/${this._JOB_ROUTE}/${objectId}/${this._LIST_ROUTE}`).pipe(
            tap((permissionResults) => {
                this._objectLevelPermissions.next(permissionResults);
            }),
            switchMap(() => this.objectLevelPermissions$)
        );
    }

    createObjectLevelPermission(objectId: number, objectLevelPermission: IObjectPermissionRequest): Observable<object | undefined> {
        return this._http.post<Array<IObjectPermissionRequest>>(`${this._workspaceService.workspaceApiUrl}/${this._PERMISSION_ROUTE}/${this._JOB_ROUTE}/${objectId}`, objectLevelPermission).pipe(
            tap(() => {
                this.listObjectLevelPermissions(objectId).pipe(take(1)).subscribe();
            })
        );
    }

    updateObjectLevelPermission(objectId: number, objectLevelPermission: IObjectPermissionRequest): Observable<object | undefined> {
        return this._http.put<Array<IObjectPermissionRequest>>(`${this._workspaceService.workspaceApiUrl}/${this._PERMISSION_ROUTE}/${this._JOB_ROUTE}/${objectId}`, objectLevelPermission).pipe(
            tap(() => {
                this.listObjectLevelPermissions(objectId).pipe(take(1)).subscribe();
            })
        );
    }

    deleteObjectLevelPermission(objectId: number, userId: number): Observable<object | undefined> {
        return this._http
            .delete<Array<IObjectPermissionRequest>>(`${this._workspaceService.workspaceApiUrl}/${this._PERMISSION_ROUTE}/${this._JOB_ROUTE}/${objectId}`, { params: { userId: userId.toString() } })
            .pipe(
                tap(() => {
                    this.listObjectLevelPermissions(objectId).pipe(take(1)).subscribe();
                })
            );
    }
}
