import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarLeftMenuItem } from '@ta/app/core/models/navbar-left-menu-item.model';
import { Toggle } from '@ta/app/shared-modules/toggle/models/toggle.model';
import { ToggleService } from '@ta/app/shared-modules/toggle/services/toggle.service';

@Component({
    selector: 'ta-navbar-left-menu-compact',
    templateUrl: './navbar-left-menu-compact.component.html',
    styleUrls: ['./navbar-left-menu-compact.component.scss']
})
export class NavbarLeftMenuCompactComponent {
    /**
     * Menu to display
     */
    @Input() menu!: NavbarLeftMenuItem;
    /**
     * Whether the menu is expanded to show sub-menus
     */
    isMenuExpanded = false;

    /**
     * Subscribing to router to close expanded menu if any navigation occurs
     */
    constructor(private _router: Router, private eRef: ElementRef, private _toggleService: ToggleService) {
        _router.events.subscribe((_) => {
            this.isMenuExpanded = false;
        });
    }
    @HostListener('document:click', ['$event'])
    public docClick(event: any): void {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.isMenuExpanded = false;
        }
    }

    /**
     * Whether the menu has sub menus (and there has an expandable menu with submenus)
     */
    hasSubMenus = (): boolean => !!this.menu.subMenus;

    /**
     * Handle toggling menu open or closed
     */
    toggleMenuExpanded(): void {
        this.isMenuExpanded = !this.isMenuExpanded;
    }

    /**
     * Returns the current submenu if the active menu is part of this items submenus
     * Will return undefined if active menu is not part of this menu item
     */
    getActiveSubMenuFromThisMenu(): NavbarLeftMenuItem | undefined {
        return this.menu.subMenus?.find((subMenu) => {
            return this._router.isActive(subMenu.path.join('/'), false);
        });
    }

    /**
     * Used to set the icon for the menu if current active submenu is part of this menu item
     */
    subMenuIcon(): string {
        const currentMenuItem = this.getActiveSubMenuFromThisMenu();

        if (!!currentMenuItem) return currentMenuItem.icon;
        else return this.menu.icon;
    }

    /**
     * Used to set the current menu class to active if a submenu of this menu is active
     */
    inThisSubMenu(): string {
        if (!!this.getActiveSubMenuFromThisMenu()) return 'submenu__item--active';
        else return '';
    }

    isMenuItemDisabled(toggle: Toggle | undefined): boolean {
        // as disabled is option check that toggle has been provided and that isn't on
        return !!toggle && !this._toggleService.isOn(toggle);
    }
}
