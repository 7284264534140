import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { BusinessIdentifierType } from '@ta/app/shared/models/workspace/business-Identifier-type.model';
import { Country } from '@ta/app/shared/models/country.model';
import { FormName } from '@ta/app/shared/models/form.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

const DEFAULT_COUNTRY_CODE = 13; // Australia

@Component({
    selector: 'ta-workspace-create-standard',
    templateUrl: './workspace-create-standard.component.html',
    styleUrls: ['./workspace-create-standard.component.scss']
})
export class WorkspaceCreateStandardComponent implements OnInit, OnDestroy {
    @Input()
    isFinalisationOfPremiumWorkspace = false;

    /**
     * Form that facilitates the creation of a workspace
     */
    standardWorkspaceForm!: FormGroup;

    /**
     * Name of the form used in this component
     */
    standardWorkspaceFormName: FormName = FormName.STANDARD_WORKSPACE_FORM;

    /**
     * An array containing all countries availible for selection
     */
    countries: Country[] = new Array<Country>();

    /**
     * An array containing all business identifier types for each country
     */
    businessIdentifierTypes: BusinessIdentifierType[] = new Array<BusinessIdentifierType>();

    /**
     * Identifier mask for the selected business identifier
     */
    identifierMask?: string;

    /**
     * If the user is in the input
     */
    inputActive = false;

    /**
     * If the user has touched the input mask
     */
    initialTouch = false;

    /**
     * Single subscription to simplify clean-up on destroy
     */
    private _subscription?: Subscription;

    constructor(private _linkedFormService: LinkedFormService, private _workspaceService: WorkspaceService) {}

    ngOnInit(): void {
        this.standardWorkspaceForm = this._linkedFormService.getForm(this.standardWorkspaceFormName);

        if (this.isFinalisationOfPremiumWorkspace) {
            this.standardWorkspaceForm.controls.businessIdentifier.setValidators(Validators.required);
            this.standardWorkspaceForm.controls.businessIdentifier.updateValueAndValidity();
            this.getBusinessIdentifier();
        }

        if (!!this.standardWorkspaceForm.get('country')?.value) {
            this.getBusinessIdentifier();
        }

        // Populate country array
        this._workspaceService
            .listCountries()
            .pipe(take(1))
            .subscribe((result) => {
                this.countries = result ?? new Array<Country>();

                // Set default country
                const defaultCountry = this.countries.find((country) => country.id === DEFAULT_COUNTRY_CODE);
                if (!this.standardWorkspaceForm.get('country')?.value) this.standardWorkspaceForm.get('country')?.setValue(defaultCountry);
                this.getBusinessIdentifier();
            });
    }

    ngOnDestroy(): void {
        // Clean up subscriptions
        this._subscription?.unsubscribe();
    }

    /**
     * Obtain business identifiers for selected country
     */
    getBusinessIdentifier(): void {
        if (this.standardWorkspaceForm.get('country')?.value.id !== undefined) {
            this._workspaceService
                .listBusinessIdentifierTypes(this.standardWorkspaceForm.get('country')?.value.id)
                .pipe(take(1))
                .subscribe((result) => {
                    this.businessIdentifierTypes = result ?? new Array<BusinessIdentifierType>();
                });
        }

        this.clearBusinessIdentifier();
    }

    /**
     * Load regex mask for selected identifier type
     */
    getBusinessIdentifierRegexMask(): void {
        if (this.standardWorkspaceForm.get('businessIdentifierType')?.value !== undefined) {
            this.identifierMask = this.standardWorkspaceForm.get('businessIdentifierType')?.value.identifierMask;
            if (this.isFinalisationOfPremiumWorkspace) {
                this.setPatternAndRequired();
            } else {
                this.setPattern();
            }
        }
    }

    private setPattern(): void {
        this.standardWorkspaceForm.controls.businessIdentifier.setValidators(Validators.pattern(this.standardWorkspaceForm.get('businessIdentifierType')?.value.validationRegex));
        this.standardWorkspaceForm.controls.businessIdentifier.updateValueAndValidity();
    }

    private setPatternAndRequired(): void {
        this.standardWorkspaceForm.controls.businessIdentifier.setValidators([
            Validators.pattern(this.standardWorkspaceForm.get('businessIdentifierType')?.value.validationRegex),
            Validators.required
        ]);
        this.standardWorkspaceForm.controls.businessIdentifier.updateValueAndValidity();
    }

    /**
     * Clears and resets business identifier fields
     */
    clearBusinessIdentifier(): void {
        this.standardWorkspaceForm.get('businessIdentifierType')?.reset();
        this.standardWorkspaceForm.get('businessIdentifier')?.reset();
    }

    /**
     * Handles the focus event for the input mask input
     */
    onMaskFocus(): void {
        this.inputActive = true;
        this.initialTouch = true;
    }

    /**
     * Get errors relating to a standard workspace form control
     * @returns One or more errors, as a string
     */
    getErrors = (formControName: string, friendlyControlName: string): string => this._linkedFormService.getErrors(this.standardWorkspaceFormName, formControName, friendlyControlName);
}
