import { ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormElementSchema } from 'src/app/shared/models/dynamic-form-element-schema.model';
import { DynamicFormSchema } from 'src/app/shared/models/dynamic-form-schema.model';
import { FormElementWrapperComponent } from '@ta/app/dynamic-form/form-element-wrapper/form-element-wrapper.component';
import { getSchemaComponentMapping } from '@ta/app/dynamic-form/utils/schema-component-mapping.util';

@Injectable({
    providedIn: 'root'
})
export class DynamicElementService {
    constructor(private readonly componentFactoryResolover: ComponentFactoryResolver, private readonly injector: Injector) { }

    createAndBindDynamicFormElements(schema: DynamicFormSchema, form: FormGroup, initialData?: any): Array<Array<ComponentRef<FormElementWrapperComponent>>> | undefined {
        if (!schema || !form) return undefined;

        const components: Array<Array<ComponentRef<FormElementWrapperComponent>>> = [];

        for (let pageNum = 0; pageNum < schema.pages.length; pageNum++) {
            components[pageNum] = [];

            const formGroup = new FormGroup({});

            const pageName = schema.pages[pageNum].name;

            schema.pages[pageNum].elements?.forEach((element: DynamicFormElementSchema) => {
                // Get a component factory for our wrapper so we can render the question title and descrption
                const wrapperFactory = this.componentFactoryResolover.resolveComponentFactory(FormElementWrapperComponent);
                // Create a wrapper and then set it's data (do not inject this into the DOM though we can handle that manually later...)
                const wrapperElement = wrapperFactory.create(this.injector);
                wrapperElement.instance.setWrapperData(element.title, element.description);
                wrapperElement.instance.hidden = element.visible === undefined ? false : !element.visible;

                // Get the view container ref inside the wrapper element so we can inject our dymanic component below...
                const wrapperViewContainerRef = wrapperElement.instance.elementHost.viewContainerRef;

                // Get a component factory for the specific component we are after so that we can generate a new dynamic component
                const componentFactory = this.componentFactoryResolover.resolveComponentFactory(getSchemaComponentMapping(element.type));
                // Create the new dynamic component and then set it's data
                const componentRef = wrapperViewContainerRef.createComponent(componentFactory);
                componentRef.instance.setElementSchema(element, form, initialData?.[pageName]);

                // Attach the components form control so we can do form validation etc...
                formGroup.addControl(element.name, componentRef.instance.getFormControl());

                // Push these to the collection so we can iterate over them later
                components[pageNum].push(wrapperElement);
            });

            form.addControl(pageName, formGroup);
        }

        return components;
    }
}
