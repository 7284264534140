<div class="workspace-create-premium-container">
    <div [formGroup]="premiumWorkspaceRequestForm">
        <p>{{ 'system.workspace.modal.country-header-text' | translate }}</p>
        <h5>{{ 'system.workspace.modal.country' | translate }}</h5>
        <span class="taskalyser-override--medium">
            <p-dropdown
                [dropdownIcon]="'ta ta-chevron-down'"
                formControlName="country"
                [options]="countries!"
                optionLabel="name"
                optionValue="id"
                placeholder="{{ 'system.form.dropdown-placeholder' | translate }}"
            ></p-dropdown>
        </span>
        <p-message
            *ngIf="premiumWorkspaceRequestForm.get('country')?.dirty && premiumWorkspaceRequestForm.get('country')?.invalid"
            severity="error"
            [text]="getErrors('country', 'system.form.workspace.country' | translate)"
        ></p-message>

        <h5 class="hr">{{ 'system.workspace.modal.details-header' | translate }}</h5>

        <h5>{{ 'system.workspace.modal.full-name' | translate }}</h5>
        <p>{{ userDetails?.name }}</p>
        <h5>{{ 'system.workspace.modal.email' | translate }}</h5>
        <p>{{ userDetails?.email }}</p>

        <h5 class="hr">{{ 'system.workspace.modal.primary.contact-header' | translate }}</h5>

        <h5>{{ 'system.workspace.modal.full-name' | translate }}</h5>
        <input class="input-width" pInputText formControlName="fullName" />
        <p-message
            *ngIf="premiumWorkspaceRequestForm.get('fullName')?.dirty && premiumWorkspaceRequestForm.get('fullName')?.invalid"
            severity="error"
            [text]="getErrors('fullName', 'system.form.workspace.full-name' | translate)"
        ></p-message>

        <h5>{{ 'system.workspace.modal.email' | translate }}</h5>
        <div class="input-wrapper">
            <span class="p-input-icon-left">
                <i class="ta ta-mail"></i>
                <input class="input-width" pInputText formControlName="emailAddress" (focus)="emailInputActive = true" (focusout)="emailInputActive = false" />
            </span>
        </div>
        <p-message
            class="newline"
            *ngIf="!emailInputActive && premiumWorkspaceRequestForm.get('emailAddress')?.dirty && premiumWorkspaceRequestForm.get('emailAddress')?.invalid"
            severity="error"
            [text]="getErrors('emailAddress', 'system.form.workspace.email' | translate)"
        ></p-message>

        <h5>{{ 'system.workspace.modal.phone-number' | translate }}</h5>
        <div class="input-wrapper">
            <span class="p-input-icon-left">
                <i class="ta ta-call"></i>
                <input class="input-width" pInputText formControlName="contact" (focus)="contactNumberInputActive = true" (focusout)="contactNumberInputActive = false" />
            </span>
        </div>
        <p-message
            class="newline"
            *ngIf="!contactNumberInputActive && premiumWorkspaceRequestForm.get('contact')?.dirty && premiumWorkspaceRequestForm.get('contact')?.invalid"
            severity="error"
            [text]="getErrors('contact', 'system.form.workspace.contact' | translate)"
        ></p-message>
    </div>
</div>
