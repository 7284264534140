import { PermissionLevel, PermissionLevels } from '../models/permissions/permission-access-levels.model';

export function getPermissionLevel(permissionLevel: number): string {
    if (!((permissionLevel & PermissionLevel.WorkspaceAccess) === PermissionLevel.WorkspaceAccess)) {
        return PermissionLevels[PermissionLevel.Disabled];
    } else if ((permissionLevel & PermissionLevel.Administer) === PermissionLevel.Administer) {
        return PermissionLevels[PermissionLevel.Administer];
    } else if ((permissionLevel & PermissionLevel.PowerUser) === PermissionLevel.PowerUser) {
        return PermissionLevels[PermissionLevel.PowerUser];
    } else if ((permissionLevel & PermissionLevel.Edit) === PermissionLevel.Edit) {
        return PermissionLevels[PermissionLevel.Edit];
    } else if ((permissionLevel & PermissionLevel.Read) === PermissionLevel.Read) {
        // if any of the edit bits are set individually
        if (
            (permissionLevel & PermissionLevel.EditAssessments) === PermissionLevel.EditAssessments ||
            (permissionLevel & PermissionLevel.EditComparisons) === PermissionLevel.EditComparisons ||
            (permissionLevel & PermissionLevel.EditPeople) === PermissionLevel.EditPeople ||
            (permissionLevel & PermissionLevel.EditSites) === PermissionLevel.EditSites ||
            (permissionLevel & PermissionLevel.EditJobs) === PermissionLevel.EditJobs
        ) {
            return PermissionLevels[PermissionLevel.Custom];
        } else {
            return PermissionLevels[PermissionLevel.Read];
        }
    } else {
        return PermissionLevels[PermissionLevel.Custom];
    }
}
