<ng-container *ngIf="visible">
    <div class="acrylic-overlay"></div>
    <div
        class="model-wrapper-component-container"
        [style.width]="width + 'px'"
        [style.height]="height + 'px'"
        [style.margin-left]="'-' + width / 2 + 'px'"
        [style.margin-top]="'-' + height / 2 + 'px'"
        [style.min-width]="_defaultWidth"
        [style.min-height]="_defaultHeight"
    >
        <div class="model-wrapper-component-container__header" [style.height]="headerHeight + 'px'">
            <ng-container *ngTemplateOutlet="header ? header : defaultHeader"></ng-container>
            <ng-template #defaultHeader></ng-template>
            <div class="model-wrapper-component-container__header__close-button">
                <p-button icon="ta ta-dismiss" (onClick)="this.closeModel.emit()" styleClass="p-button-text p-button-secondary"></p-button>
            </div>
        </div>
        <p-scrollPanel [style]="{ width: '100%', height: height - headerHeight - 90 + 'px', 'min-width': _defaultWidth }">
            <div class="model-wrapper-component-container__body">
                <ng-container *ngTemplateOutlet="body ? body : defaultBody"></ng-container>
                <ng-template #defaultBody></ng-template>
            </div>
        </p-scrollPanel>
        <div class="model-wrapper-component-container__footer">
            <ng-container *ngTemplateOutlet="footer ? footer : defaultFooter"></ng-container>
            <ng-template #defaultFooter>
                <div class="model-wrapper-component-container__footer__default">
                    <p-button
                        label="{{ 'system.button.close' | translate }}"
                        (onClick)="this.closeModel.emit()"
                        styleClass="{{ closeButtonStyle ? closeButtonStyle : 'p-button-secondary p-button-text' }}"
                    ></p-button>
                </div>
            </ng-template>
        </div>
    </div>
</ng-container>
