<div class="terms-conditions-component-container">
    <div class="header">
        <a routerLink="/workspace/"><i class="ta ta-logo-word"></i></a>
    </div>
    <div class="tabs">
        <!-- Tabs -->
        <ta-tabs [tabs]="tabs" [rightAlign]="true"></ta-tabs>
    </div>
    <p-scrollPanel #scroll [style]="{ width: '100%' }" class="scroll">
        <div class="terms-container">
            <div class="terms-container__body">
                <div class="terms-container__body__header">
                    <h1>Terms of Service</h1>
                </div>
                <h5>Welcome and thank you for using TaskAlyser</h5>
                <p>We define TaskAlyser as the set of all services we provide now, and in the future, including, but not limited to, the TaskAlyser website, web application, apps, and all APIs.</p>
                <p>By accessing or using TaskAlyser, you agree to be bound by our Terms of Service and <a [routerLink]="'/' + AppRoutes.PRIVACY">Privacy & Data Policy</a>.</p>
                <p>These terms of service govern your access to and use of TaskAlyser. Please read these Terms carefully, and contact us if you have any questions.</p>

                <h5>Updates</h5>
                <p>
                    We keep a <a [routerLink]="'/' + AppRoutes.TERMS_AND_POLICY_UPDATES">record of updates</a> to this document, which lists each change we have made since our initial release in April
                    2022.
                </p>

                <h5>Glossary</h5>
                <div class="terms-container__body__glossary">
                    <p-table [value]="glossary">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Terms Used</th>
                                <th>Meaning</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-glossary>
                            <tr>
                                <td>
                                    <ng-container *ngFor="let term of glossary.term; let last = last"
                                        ><span class="text--green">{{ term }}</span
                                        ><ng-container *ngIf="!last"> or </ng-container></ng-container
                                    >
                                </td>
                                <td>{{ glossary.meaning }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

                <!-- our service -->
                <ta-content-dropdown [title]="'Our service'">
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    TaskAlyser helps individuals and businesses be informed, so they can keep you and others healthy and safe in the workplace. Because of this, we need to be able to
                                    identify and hold a range of information about individuals and businesses alike, then allow those businesses and individuals to access and interpret the data they
                                    need to see.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                TaskAlyser is a platform that helps the world capture, understand, manage, and appropriately distribute information relating to workforce health, workplace injury
                                prevention, injury recovery, and returning worker outcomes. In order for us to provide our service, we need to be able to identify you and the businesses you interact
                                with.
                                <br /><br />
                                From time to time, parties will need to capture, maintain and/or use information about themselves, a collaborating party or you, in order to maximise health, injury
                                prevention and recovery outcomes for these parties. <br /><br />
                                Similarly, collaborating parties may also be required to create, use and/or access this information to assist the parties achieve their outcomes.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!-- What we own -->
                <ta-content-dropdown [title]="'What we own'">
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">We respect the intellectual property rights of others and we expect users to do the same with our services.</p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                We own everything we’ve put into our services unless otherwise stated and excluding content owned by others. This includes rights to the design, compilation, behaviour,
                                look and feel of our services. It also includes rights to all copyrighted works, trademarks, designs, inventions, and other intellectual property. You agree not to
                                copy, distribute, modify or make derivative works of any of our content or use any of our intellectual property rights in any way not expressly permitted by us.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!-- disclaimers -->
                <ta-content-dropdown [title]="'Disclaimers'">
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    We provide services that support workplace injury prevention and recovery practices, but we are not a replacement for health professionals or the care they provide.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                Our service is provided on an 'as is' basis without warranty of any kind, whether express or implied. Although our service captures and presents health related
                                information, it is to support the service delivery of qualified professionals. Our service is not intended as a substitute for medical, physical, psychiatric or
                                psychological advice, and should not be used to diagnose, treat, cure or prevent any physical, medical, psychiatric or psychological conditions. Our service is not a
                                substitute for independent professional advice and should not be used as an alternative to professional health care. If you have a medical, physical, psychiatric or
                                psychological condition or concern, please consult a healthcare professional.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!-- using our service -->
                <ta-content-dropdown [title]="'Using TaskAlyser'" [subtitles]="usingTaskAlyserSubtitles">
                    <!-- who can use our service-->
                    <p class="terms-container__body--heading">Who can use our service?</p>
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    You can use TaskAlyser as long as you are a human, over the age of 16, can provide informed consent, and can comply with all of our terms and all applicable laws.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <div class="explanation">
                                <p>
                                    You may use TaskAlyser only if you can legally form a binding contract with TaskAlyser, have the capacity to provide informed consent under the laws of your
                                    country, and comply with our terms and all applicable laws.<br /><br />
                                    You cannot use TaskAlyser if it would be prohibited by Australian sanctions. Use or access by anyone under the age of 16 is not allowed. Accounts registered by bots
                                    or other automated methods are not permitted. Use of shared accounts or another person’s account is not permitted.<br /><br />
                                    Using TaskAlyser may include downloading software to your computer, phone, tablet, or other device. You agree that we may automatically update that software, and
                                    that our terms will apply to any updates.
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr class="rule" />

                    <!-- our licence to you -->
                    <p class="terms-container__body--heading">Our license to you</p>
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <!-- simply put -->
                            <div class="simply-put"></div>
                        </div>
                        <!-- explanation -->
                        <div class="terms-container__body__divide--right">
                            <p class="explanation">Subject to our Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to use our service.</p>
                        </div>
                    </div>

                    <hr class="rule" />

                    <!-- Commercial use of taskalyser -->
                    <p class="terms-container__body--heading">Commercial use of our site</p>
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    If you’re acting in a commercial capacity, you must use a business workspace. If you are an administrator of a business workspace, you must agree to our terms on
                                    behalf of the commercial entity it represents.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <div class="explanation">
                                <p>
                                    If you are using our service for commercial purposes such as; supporting the operational workplace injury prevention and recovery practices of a business, medical,
                                    psychological or allied health services, insurance and/or claim support services, you must do this in a business workspace. Administrators of a business workspace
                                    must provide and maintain accurate identifying information about the commercial entity they represent. Administrators of a business workspace must agree to our
                                    terms on the commercial entity’s behalf.
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr class="rule" />

                    <!-- Users as administrators and data collectors -->
                    <p class="terms-container__body--heading">Users as administrators and data controllers</p>
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">If you have administrative control over a workspace, you are its administrator and are a data controller for that workspace.</p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <div class="explanation">
                                <p>
                                    The administrator of a personal workspace is the user. The administrator of a business workspace is any user with administration rights to that business workspace.
                                    Any user who creates a business workspace becomes the first administrator of that workspace.<br /><br />
                                    Administrators and the legal entities those business workspaces represent carry the responsibilities of data controllers for any and all information captured and
                                    maintained within that workspace.
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr class="rule" />

                    <!-- Our relationship with you  -->
                    <p class="terms-container__body--heading">Our relationship with you</p>
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <div class="simply-put"></div>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <div class="explanation">
                                <p>
                                    Administrators and the legal entities their workspaces represent, are data controllers who grant a license to us (TaskAlyser Pty. Ltd.) as a processor to process
                                    business and personal data held in that workspace. Each party must comply with the obligations that apply to it under Applicable Data Protection Law.
                                </p>
                            </div>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!-- your information -->
                <ta-content-dropdown [title]="'Your Information'" [subtitles]="yourInformationSubtitles">
                    <!-- Information you and others capture -->
                    <p class="terms-container__body--heading">Information you and others capture</p>
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    We process a range of personal and business information to provide a service to others. Any information captured or maintained in a workspace is owned by the owners
                                    of that workspace.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <div class="explanation">
                                <p>
                                    TaskAlyser allows you and others to capture and maintain structured information on specific topics such as people, jobs, worksites, assessments and comparisons.
                                    This necessitates the capture of personal and/or sensitive data about people, places and/or the jobs, tasks and activities performed within or related to a
                                    business.
                                    <br /><br />
                                    TaskAlyser also allows you and others to capture unstructured information against these topics including, photos, videos, PDFs, and comments.
                                    <br /><br />
                                    All information captured and/or maintained in a workspace is owned by the legal entity that the workspace represents. In the case of a personal workspace, this will
                                    be the user. In the case of a business workspace, this will be the legal entity that the administrators of that workspace represent.
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr class="rule" />

                    <!-- our licence to you -->
                    <p class="terms-container__body--heading">Information responsibilities of data controllers</p>
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <!-- simply put -->
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">If you are a data controller, you are responsible for protecting the personal data of others.</p>
                            </ta-content-dropdown-simply>
                        </div>
                        <!-- explanation -->
                        <div class="terms-container__body__divide--right">
                            <p class="explanation">
                                Data controllers must ensure that all personal data within their workspaces is collected and maintained legally, collected with purpose, only used for the purposes in
                                which it was collected, is accurate, and is only retained as required. <br /><br />
                                Data controllers must ensure that the legal entity they represent implements appropriate processes and safeguards to care for and protect personal data under their
                                control.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     What we do with your information  -->
                <ta-content-dropdown [title]="'What we do with your information '">
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    Your data is your own, we only process it. But in the act of processing your data, we may create derived anonymised statistical datasets, and this data is ours.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                We don’t own the data you or others capture on our service, but you grant us a license to process that data.
                                <br /><br />
                                As part of this processing, we may produce derived data that allows us to improve, develop, and protect our services, create new services, communicate with you about
                                our service, and send you information we think may be of interest to you.
                                <br /><br />
                                We may also produce anonymised statistical datasets, including through aggregation processes. We own any anonymised statistical datasets we produce, and may use it for
                                our own purposes. This may include providing you and others with trend or comparative data at local, national, regional and global levels.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!-- Security -->
                <ta-content-dropdown [title]="'Security'">
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">Help us keep your data secure by keeping your password secure.</p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                We care about you and your data. We take all reasonable steps to protect the service from unauthorised and malicious access. Unfortunately, we can’t guarantee that
                                unauthorised third parties won’t be able to defeat our security measures. To help us protect your data, we ask that you keep your password secure and please notify us
                                immediately if you suspect unauthorised use of your account.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!-- Third Party links, sites, and services -->
                <ta-content-dropdown [title]="'Third Party links, sites and services'">
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    We use several reputable third-party providers to deliver our service to you. Sometimes we may include links to content not owned by us. We work to ensure that
                                    linked content is of high quality, but we’re not responsible when it’s not.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                We use several reputable third-party providers in order to deliver our service to you. This includes foundational platforms and services such as cloud compute hosting
                                and mail distribution. We take reasonable steps to ensure that these providers do not process your data or our data without cause and without our agreement. We also
                                take reasonable steps to ensure that only necessary data is released to these providers. We may, however, from time to time be required to grant these providers access
                                to portions of our service in the event of a service disruption or outage.
                                <br /><br />
                                Our site may also contain links to third party resources. We include these links for your convenience. We do not endorse or assume any responsibility for any
                                third-party sites or their information, materials, products, or services. If you access any third-party website or service from TaskAlyser, you do so at your own risk
                                and you agree that we are not liable for your use of that content. It is your responsibility to make your own informed decisions about the reliability of the
                                information provided by the third-party websites, services or content.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!-- Account deletion and termination  -->
                <ta-content-dropdown [title]="'Account deletion and termination '">
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">
                                    You have the right to be forgotten and we will do our best to forget whatever we can, without compromising the data and the service we provide to others. Please use
                                    our site responsibly. If you do not, we reserve the right to refuse service.
                                </p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <div class="explanation">
                                <p>
                                    You may choose to delete your account with us at any time by accessing the profile settings page and selecting to delete your account. We will delete your
                                    credentials, personal workspace and other unnecessary data relating to your account. However, for compliance purposes our service is required to maintain audit
                                    trails in relation to data access, editing and sharing. This means we cannot forget you entirely. Your last known name, email and actions you performed within a
                                    business workspace will continue to appear in audit trails within that workspace for as long as that data continues to exist.
                                    <br /><br />
                                    We may terminate or suspend your right to access or use our site for any reason on appropriate notice. We may delete your account immediately and without notice if
                                    we have a good reason, such as:
                                </p>
                                <ul>
                                    <li>Violating any of our Terms.</li>
                                    <li>Using our service in a manner that would cause us or data controllers’ legal liability.</li>
                                    <li>Causing disruption to other users of our service.</li>
                                </ul>

                                <p>We may terminate your account with advance notice if we have a good reason, such as:</p>
                                <ul>
                                    <li>Your account has not been accessed for 12 consecutive months.</li>
                                    <li>You have infringed copyright or other intellectual property rights.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     Governing law and jurisdiction  -->
                <ta-content-dropdown [title]="'Governing law and jurisdiction '">
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">South Australia is our home and where any legal problems will be settled.</p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">Any and all disputes that arises from using our site is subject to the laws of South Australia, Australia regardless of your location.</p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     Changes to our terms  -->
                <ta-content-dropdown [title]="'Changes to our terms '">
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">If we change our terms, we will let you know. If you don’t like the changes, you must discontinue your use of our service.</p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                We reserve the right to change our terms from time to time. We reserve the right to choose if a revision is significant and warrants your notification. We reserve the
                                right to determine the form and means of providing notifications to you. The most current version of these terms will always be published on our service. By continuing
                                to access or use our service after revisions come into effect, you agree to be bound by the new terms. If you don’t agree to the new terms, you must discontinue the use
                                of our service.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>

                <!--     Entire agreement and severability  -->
                <ta-content-dropdown [title]="'Entire agreement and severability '">
                    <!-- simply put -->
                    <div class="terms-container__body__divide">
                        <div class="terms-container__body__divide--left">
                            <ta-content-dropdown-simply class="simply-put" [contentHeader]="'Simply Put'">
                                <p class="no-margin-top">Our terms form the entirety of your agreement with us.</p>
                            </ta-content-dropdown-simply>
                        </div>
                        <div class="terms-container__body__divide--right">
                            <!-- explanation -->
                            <p class="explanation">
                                Our terms, any amendments, and any additional agreements you may enter into with us, will constitute the entire agreement between you and us concerning our site.
                                <br /><br />
                                If any provision of these terms is deemed invalid, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of
                                these terms will remain in full force and effect.
                            </p>
                        </div>
                    </div>
                </ta-content-dropdown>
            </div>
        </div>
    </p-scrollPanel>
</div>
