import { Component } from '@angular/core';

@Component({
    selector: 'ta-layout-wrapper',
    templateUrl: './layout-wrapper.component.html',
    styleUrls: ['./layout-wrapper.component.scss']
})
export class LayoutWrapperComponent {
    constructor() {}
}
