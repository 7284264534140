import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Crumb } from 'src/app/shared/models/crumb.model';
import { Tab } from 'src/app/shared/models/tab.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ta-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {
    /**
     * Set of breadcrumbs to display
     */
    @Input() crumbs: Array<Crumb> = [];

    /**
     * Set of tabs to display (may be null)
     */
    @Input() tabs?: Tab[];

    /**
     * If the page is in a create state;
     */
    @Input() isCreate?: boolean;

    /**
     * Form to bind to the header
     */
    @Input() form!: FormGroup;

    /**
     * Form name used to bind to linked form service
     */
    @Input() formName!: string;

    /**
     * Whether the form is in edit mode
     */
    @Input() edit = false;

    /**
     * Header for the object name in edit mode
     */
    @Input() titleEdit!: string;

    /**
     * Whether the item is active
     */
    @Input() active = true;

    /**
     * Emitters for standard header action events
     */
    @Output() save: EventEmitter<any> = new EventEmitter();
    @Output() cancel: EventEmitter<any> = new EventEmitter();

    @Output() delete: EventEmitter<any> = new EventEmitter();
    @Output() restore: EventEmitter<any> = new EventEmitter();

    @Output() tabClick: EventEmitter<Tab> = new EventEmitter();

    /**
     * Store a reference to form controls
     */
    title!: FormControl;

    /**
     * Store a local copy of the title for the breadcrumbs
     */
    titleStr?: string;

    private _subscription?: Subscription;

    constructor(readonly linkedFormService: LinkedFormService) {}

    ngOnInit(): void {
        // Store title state
        this.title = this.form.get('name') as FormControl;
        this.titleStr = this.title?.value;
    }

    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    /**
     * Handles the save event and emits it
     */
    triggerSave(): void {
        if (this.form.invalid) {
            this.form.get('name')?.markAsDirty();
        }
        this.save.emit();
    }

    /**
     * Handles the cancel event and emits it
     */
    triggerCancel(): void {
        this.cancel.emit(false);
    }

    /**
     * Handles the delete event and emits it
     */
    triggerDelete(): void {
        this.delete.emit(true);
    }

    /**
     * Handles the restore event and emits it
     */
    triggerRestore(): void {
        this.restore.emit(true);
    }

    /**
     * Handle the click event on tabs.
     * @param event the event emitted by tab click
     */
    handleTabClick(event: Tab): void {
        this.tabClick.emit(event);
    }

    /**
     * Checks whether there are forms that are dirty
     * @returns A boolean indicating if forms are dirty
     */
    areFormsDirty(): boolean {
        return this.linkedFormService.isDirty();
    }

    /**
     * Get errors relating to a specific form control
     * @returns One or more errors, as a string
     */
    getErrors = (formControName: string, friendlyControlName: string): string => this.linkedFormService.getErrors(this.formName, formControName, friendlyControlName);
}
