import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ta-blade-help',
    templateUrl: './blade-help.component.html',
    styleUrls: ['./blade-help.component.scss']
})
export class BladeHelpComponent implements OnInit {
    // TODO: Refactor into i81n
    heading = 'Help';

    constructor() {}

    ngOnInit(): void {}
}
