export enum BodyPartJointMovementTypeV1 {
    ALL = 2,

    SPINE = 3,
    SPINE_ALL = 4,
    SPINE_CERVICAL = 5,
    SPINE_CERVICAL_ALL = 6,
    SPINE_CERVICAL_FORWARD_FLEXION = 7,
    SPINE_CERVICAL_EXTENSION = 8,
    SPINE_CERVICAL_LEFT_LATERAL_FLEXION = 9,
    SPINE_CERVICAL_RIGHT_LATERAL_FLEXION = 10,
    SPINE_CERVICAL_LEFT_ROTATION = 11,
    SPINE_CERVICAL_RIGHT_ROTATION = 12,

    SPINE_THORACIC = 13,
    SPINE_THORACIC_ALL = 14,
    SPINE_THORACIC_FLEXION = 15,
    SPINE_THORACIC_EXTENSION = 16,

    SPINE_LUMBAR = 17,
    SPINE_LUMBAR_FORWARD_ALL = 18,
    SPINE_LUMBAR_FORWARD_FLEXION = 19,
    SPINE_LUMBAR_EXTENSION = 20,
    SPINE_LUMBAR_LEFT_FLEXION = 21,
    SPINE_LUMBAR_RIGHT_FLEXION = 22,

    UPPER_LIMBS = 23,
    UPPER_LIMBS_ALL = 24,
    UPPER_LIMBS_LEFT_SHOULDER = 25,
    UPPER_LIMBS_LEFT_SHOULDER_ALL = 26,
    UPPER_LIMBS_LEFT_SHOULDER_FLEXION = 27,
    UPPER_LIMBS_LEFT_SHOULDER_EXTENSION = 28,
    UPPER_LIMBS_LEFT_SHOULDER_ABDUCTION = 29,
    UPPER_LIMBS_LEFT_SHOULDER_EXTERNAL_ROTATION = 30,
    UPPER_LIMBS_LEFT_SHOULDER_INTERNAL_ROTATION = 31,

    UPPER_LIMBS_RIGHT_SHOULDER = 32,
    UPPER_LIMBS_RIGHT_SHOULDER_ALL = 33,
    UPPER_LIMBS_RIGHT_SHOULDER_FLEXION = 34,
    UPPER_LIMBS_RIGHT_SHOULDER_EXTENSION = 35,
    UPPER_LIMBS_RIGHT_SHOULDER_ABDUCTION = 36,
    UPPER_LIMBS_RIGHT_SHOULDER_EXTERNAL_ROTATION = 37,
    UPPER_LIMBS_RIGHT_SHOULDER_INTERNAL_ROTATION = 38,

    UPPER_LIMBS_LEFT_ELBOW = 39,
    UPPER_LIMBS_LEFT_ELBOW_ALL = 40,
    UPPER_LIMBS_LEFT_ELBOW_FLEXION = 41,
    UPPER_LIMBS_LEFT_ELBOW_EXTENSION = 42,

    UPPER_LIMBS_RIGHT_ELBOW = 43,
    UPPER_LIMBS_RIGHT_ELBOW_ALL = 44,
    UPPER_LIMBS_RIGHT_ELBOW_FLEXION = 45,
    UPPER_LIMBS_RIGHT_ELBOW_EXTENSION = 46,

    UPPER_LIMBS_LEFT_FOREARM = 47,
    UPPER_LIMBS_LEFT_FOREARM_ALL = 48,
    UPPER_LIMBS_LEFT_FOREARM_SUPINATION = 49,
    UPPER_LIMBS_LEFT_FOREARM_PRONATION = 50,

    UPPER_LIMBS_RIGHT_FOREARM = 51,
    UPPER_LIMBS_RIGHT_FOREARM_ALL = 52,
    UPPER_LIMBS_RIGHT_FOREARM_SUPINATION = 53,
    UPPER_LIMBS_RIGHT_FOREARM_PRONATION = 54,

    UPPER_LIMBS_LEFT_WRIST = 55,
    UPPER_LIMBS_LEFT_WRIST_ALL = 56,
    UPPER_LIMBS_LEFT_WRIST_FLEXION = 57,
    UPPER_LIMBS_LEFT_WRIST_EXTENSION = 58,
    UPPER_LIMBS_LEFT_WRIST_RADIAL_DEVIATION = 59,
    UPPER_LIMBS_LEFT_WRIST_ULNAR_DEVIATION = 60,

    UPPER_LIMBS_RIGHT_WRIST = 61,
    UPPER_LIMBS_RIGHT_WRIST_ALL = 62,
    UPPER_LIMBS_RIGHT_WRIST_FLEXION = 63,
    UPPER_LIMBS_RIGHT_WRIST_EXTENSION = 64,
    UPPER_LIMBS_RIGHT_WRIST_RADIAL_DEVIATION = 65,
    UPPER_LIMBS_RIGHT_WRIST_ULNAR_DEVIATION = 66,

    LOWER_LIMBS = 67,
    LOWER_LIMBS_ALL = 68,
    LOWER_LIMBS_LEFT_HIP = 69,
    LOWER_LIMBS_LEFT_HIP_ALL = 70,
    LOWER_LIMBS_LEFT_HIP_FLEXION = 71,
    LOWER_LIMBS_LEFT_HIP_EXTENSION = 72,
    LOWER_LIMBS_LEFT_HIP_ABDUCTION = 73,
    LOWER_LIMBS_LEFT_HIP_ADDUCTION = 74,
    LOWER_LIMBS_LEFT_HIP_EXTERNAL_ROTATION = 75,
    LOWER_LIMBS_LEFT_HIP_INTERNAL_ROTATION = 76,

    LOWER_LIMBS_RIGHT_HIP = 77,
    LOWER_LIMBS_RIGHT_HIP_ALL = 78,
    LOWER_LIMBS_RIGHT_HIP_FLEXION = 79,
    LOWER_LIMBS_RIGHT_HIP_EXTENSION = 80,
    LOWER_LIMBS_RIGHT_HIP_ABDUCTION = 81,
    LOWER_LIMBS_RIGHT_HIP_ADDUCTION = 82,
    LOWER_LIMBS_RIGHT_HIP_EXTERNAL_ROTATION = 83,
    LOWER_LIMBS_RIGHT_HIP_INTERNAL_ROTATION = 84,

    LOWER_LIMBS_LEFT_KNEE = 85,
    LOWER_LIMBS_LEFT_KNEE_ALL = 86,
    LOWER_LIMBS_LEFT_KNEE_FLEXION = 87,
    LOWER_LIMBS_LEFT_KNEE_EXTENSION = 88,

    LOWER_LIMBS_RIGHT_KNEE = 89,
    LOWER_LIMBS_RIGHT_KNEE_ALL = 90,
    LOWER_LIMBS_RIGHT_KNEE_FLEXION = 91,
    LOWER_LIMBS_RIGHT_KNEE_EXTENSION = 92,

    LOWER_LIMBS_LEFT_ANKLE = 93,
    LOWER_LIMBS_LEFT_ANKLE_ALL = 94,
    LOWER_LIMBS_LEFT_ANKLE_DORSIFLEXION = 95,
    LOWER_LIMBS_LEFT_ANKLE_PLANTAR_FLEXION = 96,
    LOWER_LIMBS_LEFT_ANKLE_EVERSION = 97,
    LOWER_LIMBS_LEFT_ANKLE_INVERSION = 98,

    LOWER_LIMBS_RIGHT_ANKLE = 99,
    LOWER_LIMBS_RIGHT_ANKLE_ALL = 100,
    LOWER_LIMBS_RIGHT_ANKLE_DORSIFLEXION = 101,
    LOWER_LIMBS_RIGHT_ANKLE_PLANTAR_FLEXION = 102,
    LOWER_LIMBS_RIGHT_ANKLE_EVERSION = 103,
    LOWER_LIMBS_RIGHT_ANKLE_INVERSION = 104,

    UPPER_LIMBS_BEST_SHOULDER = 105,
    UPPER_LIMBS_BEST_SHOULDER_ALL = 106,
    UPPER_LIMBS_BEST_SHOULDER_FLEXION = 107,
    UPPER_LIMBS_BEST_SHOULDER_EXTENSION = 108,
    UPPER_LIMBS_BEST_SHOULDER_ABDUCTION = 109,
    UPPER_LIMBS_BEST_SHOULDER_EXTERNAL_ROTATION = 110,
    UPPER_LIMBS_BEST_SHOULDER_INTERNAL_ROTATION = 111,

    UPPER_LIMBS_BEST_ELBOW = 112,
    UPPER_LIMBS_BEST_ELBOW_ALL = 113,
    UPPER_LIMBS_BEST_ELBOW_FLEXION = 114,
    UPPER_LIMBS_BEST_ELBOW_EXTENSION = 115,

    UPPER_LIMBS_BEST_FOREARM = 116,
    UPPER_LIMBS_BEST_FOREARM_ALL = 117,
    UPPER_LIMBS_BEST_FOREARM_SUPINATION = 118,
    UPPER_LIMBS_BEST_FOREARM_PRONATION = 119,

    UPPER_LIMBS_BEST_WRIST = 120,
    UPPER_LIMBS_BEST_WRIST_ALL = 121,
    UPPER_LIMBS_BEST_WRIST_FLEXION = 122,
    UPPER_LIMBS_BEST_WRIST_EXTENSION = 123,
    UPPER_LIMBS_BEST_WRIST_RADIAL_DEVIATION = 124,
    UPPER_LIMBS_BEST_WRIST_ULNAR_DEVIATION = 125,

    LOWER_LIMBS_BEST_HIP = 126,
    LOWER_LIMBS_BEST_HIP_ALL = 127,
    LOWER_LIMBS_BEST_HIP_FLEXION = 128,
    LOWER_LIMBS_BEST_HIP_EXTENSION = 129,
    LOWER_LIMBS_BEST_HIP_ABDUCTION = 130,
    LOWER_LIMBS_BEST_HIP_ADDUCTION = 131,
    LOWER_LIMBS_BEST_HIP_EXTERNAL_ROTATION = 132,
    LOWER_LIMBS_BEST_HIP_INTERNAL_ROTATION = 133,

    LOWER_LIMBS_BEST_KNEE = 134,
    LOWER_LIMBS_BEST_KNEE_ALL = 135,
    LOWER_LIMBS_BEST_KNEE_FLEXION = 136,
    LOWER_LIMBS_BEST_KNEE_EXTENSION = 137,

    LOWER_LIMBS_BEST_ANKLE = 138,
    LOWER_LIMBS_BEST_ANKLE_ALL = 139,
    LOWER_LIMBS_BEST_ANKLE_DORSIFLEXION = 140,
    LOWER_LIMBS_BEST_ANKLE_PLANTAR_FLEXION = 141,
    LOWER_LIMBS_BEST_ANKLE_EVERSION = 142,
    LOWER_LIMBS_BEST_ANKLE_INVERSION = 143
}

export const RoMDetailTitleTranslations: Map<BodyPartJointMovementTypeV1, string> = new Map([
    [BodyPartJointMovementTypeV1.SPINE_CERVICAL, 'system-assessment.lookups.body-parts.cervical-spine'],
    [BodyPartJointMovementTypeV1.SPINE_CERVICAL_ALL, 'system-assessment.lookups.body-parts.cervical-spine'],
    [BodyPartJointMovementTypeV1.SPINE_CERVICAL_EXTENSION, 'system-assessment.lookups.body-parts.cervical-spine'],
    [BodyPartJointMovementTypeV1.SPINE_CERVICAL_FORWARD_FLEXION, 'system-assessment.lookups.body-parts.cervical-spine'],
    [BodyPartJointMovementTypeV1.SPINE_CERVICAL_LEFT_LATERAL_FLEXION, 'system-assessment.lookups.body-parts.cervical-spine'],
    [BodyPartJointMovementTypeV1.SPINE_CERVICAL_RIGHT_LATERAL_FLEXION, 'system-assessment.lookups.body-parts.cervical-spine'],
    [BodyPartJointMovementTypeV1.SPINE_CERVICAL_RIGHT_ROTATION, 'system-assessment.lookups.body-parts.cervical-spine'],
    [BodyPartJointMovementTypeV1.SPINE_CERVICAL_LEFT_ROTATION, 'system-assessment.lookups.body-parts.cervical-spine'],

    [BodyPartJointMovementTypeV1.SPINE_THORACIC, 'system-assessment.lookups.body-parts.thoracic-spine'],
    [BodyPartJointMovementTypeV1.SPINE_THORACIC_ALL, 'system-assessment.lookups.body-parts.thoracic-spine'],
    [BodyPartJointMovementTypeV1.SPINE_THORACIC_EXTENSION, 'system-assessment.lookups.body-parts.thoracic-spine'],
    [BodyPartJointMovementTypeV1.SPINE_THORACIC_FLEXION, 'system-assessment.lookups.body-parts.thoracic-spine'],

    [BodyPartJointMovementTypeV1.SPINE_LUMBAR, 'system-assessment.lookups.body-parts.lumbar-spine'],
    [BodyPartJointMovementTypeV1.SPINE_LUMBAR_EXTENSION, 'system-assessment.lookups.body-parts.lumbar-spine'],
    [BodyPartJointMovementTypeV1.SPINE_LUMBAR_FORWARD_ALL, 'system-assessment.lookups.body-parts.lumbar-spine'],
    [BodyPartJointMovementTypeV1.SPINE_LUMBAR_FORWARD_FLEXION, 'system-assessment.lookups.body-parts.lumbar-spine'],
    [BodyPartJointMovementTypeV1.SPINE_LUMBAR_LEFT_FLEXION, 'system-assessment.lookups.body-parts.lumbar-spine'],
    [BodyPartJointMovementTypeV1.SPINE_LUMBAR_RIGHT_FLEXION, 'system-assessment.lookups.body-parts.lumbar-spine'],

    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_SHOULDER, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_SHOULDER, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_SHOULDER, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_SHOULDER_ABDUCTION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_SHOULDER_ALL, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_SHOULDER_EXTENSION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_SHOULDER_FLEXION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_SHOULDER_ABDUCTION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_SHOULDER_ALL, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_SHOULDER_EXTENSION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_SHOULDER_FLEXION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_SHOULDER_ABDUCTION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_SHOULDER_ALL, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_SHOULDER_EXTENSION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_SHOULDER_FLEXION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_SHOULDER_EXTERNAL_ROTATION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_SHOULDER_EXTERNAL_ROTATION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_SHOULDER_EXTERNAL_ROTATION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_SHOULDER_INTERNAL_ROTATION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_SHOULDER_INTERNAL_ROTATION, 'system-assessment.lookups.body-parts.shoulder'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_SHOULDER_INTERNAL_ROTATION, 'system-assessment.lookups.body-parts.shoulder'],

    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_ELBOW, 'system-assessment.lookups.body-parts.elbow'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_ELBOW, 'system-assessment.lookups.body-parts.elbow'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_ELBOW, 'system-assessment.lookups.body-parts.elbow'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_ELBOW_ALL, 'system-assessment.lookups.body-parts.elbow'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_ELBOW_EXTENSION, 'system-assessment.lookups.body-parts.elbow'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_ELBOW_FLEXION, 'system-assessment.lookups.body-parts.elbow'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_ELBOW_ALL, 'system-assessment.lookups.body-parts.elbow'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_ELBOW_EXTENSION, 'system-assessment.lookups.body-parts.elbow'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_ELBOW_FLEXION, 'system-assessment.lookups.body-parts.elbow'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_ELBOW_ALL, 'system-assessment.lookups.body-parts.elbow'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_ELBOW_EXTENSION, 'system-assessment.lookups.body-parts.elbow'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_ELBOW_FLEXION, 'system-assessment.lookups.body-parts.elbow'],

    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_FOREARM, 'system-assessment.lookups.body-parts.forearm'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_FOREARM, 'system-assessment.lookups.body-parts.forearm'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_FOREARM, 'system-assessment.lookups.body-parts.forearm'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_FOREARM_PRONATION, 'system-assessment.lookups.body-parts.forearm'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_FOREARM_SUPINATION, 'system-assessment.lookups.body-parts.forearm'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_FOREARM_PRONATION, 'system-assessment.lookups.body-parts.forearm'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_FOREARM_SUPINATION, 'system-assessment.lookups.body-parts.forearm'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_FOREARM_ALL, 'system-assessment.lookups.body-parts.forearm'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_FOREARM_ALL, 'system-assessment.lookups.body-parts.forearm'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_FOREARM_PRONATION, 'system-assessment.lookups.body-parts.forearm'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_FOREARM_SUPINATION, 'system-assessment.lookups.body-parts.forearm'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_FOREARM_ALL, 'system-assessment.lookups.body-parts.forearm'],

    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_WRIST, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_WRIST, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_WRIST, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_WRIST_ALL, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_WRIST_EXTENSION, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_WRIST_FLEXION, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_WRIST_ULNAR_DEVIATION, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_WRIST_ALL, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_WRIST_EXTENSION, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_WRIST_FLEXION, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_WRIST_ULNAR_DEVIATION, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_WRIST_RADIAL_DEVIATION, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_WRIST_RADIAL_DEVIATION, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_WRIST_ALL, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_WRIST_EXTENSION, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_WRIST_FLEXION, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_WRIST_ULNAR_DEVIATION, 'system-assessment.lookups.body-parts.wrist'],
    [BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_WRIST_RADIAL_DEVIATION, 'system-assessment.lookups.body-parts.wrist'],

    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_HIP, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_HIP, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_HIP, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_HIP_ABDUCTION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_HIP_ADDUCTION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_HIP_ALL, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_HIP_EXTENSION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_HIP_EXTERNAL_ROTATION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_HIP_FLEXION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_HIP_INTERNAL_ROTATION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_HIP_ABDUCTION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_HIP_ADDUCTION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_HIP_ALL, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_HIP_EXTENSION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_HIP_EXTERNAL_ROTATION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_HIP_FLEXION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_HIP_INTERNAL_ROTATION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_HIP_ABDUCTION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_HIP_ADDUCTION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_HIP_ALL, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_HIP_EXTENSION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_HIP_EXTERNAL_ROTATION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_HIP_FLEXION, 'system-assessment.lookups.body-parts.hip'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_HIP_INTERNAL_ROTATION, 'system-assessment.lookups.body-parts.hip'],

    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_KNEE, 'system-assessment.lookups.body-parts.knee'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_KNEE, 'system-assessment.lookups.body-parts.knee'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_KNEE_EXTENSION, 'system-assessment.lookups.body-parts.knee'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_KNEE_EXTENSION, 'system-assessment.lookups.body-parts.knee'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_KNEE, 'system-assessment.lookups.body-parts.knee'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_KNEE_ALL, 'system-assessment.lookups.body-parts.knee'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_KNEE_FLEXION, 'system-assessment.lookups.body-parts.knee'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_KNEE_ALL, 'system-assessment.lookups.body-parts.knee'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_KNEE_FLEXION, 'system-assessment.lookups.body-parts.knee'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_KNEE_EXTENSION, 'system-assessment.lookups.body-parts.knee'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_KNEE_ALL, 'system-assessment.lookups.body-parts.knee'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_KNEE_FLEXION, 'system-assessment.lookups.body-parts.knee'],

    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_ANKLE, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_ANKLE, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_ANKLE_EVERSION, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_ANKLE_EVERSION, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_ANKLE, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_ANKLE_ALL, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_ANKLE_DORSIFLEXION, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_ANKLE_INVERSION, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_BEST_ANKLE_PLANTAR_FLEXION, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_ANKLE_ALL, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_ANKLE_DORSIFLEXION, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_ANKLE_INVERSION, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_LEFT_ANKLE_PLANTAR_FLEXION, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_ANKLE_EVERSION, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_ANKLE_ALL, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_ANKLE_DORSIFLEXION, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_ANKLE_INVERSION, 'system-assessment.lookups.body-parts.ankle'],
    [BodyPartJointMovementTypeV1.LOWER_LIMBS_RIGHT_ANKLE_PLANTAR_FLEXION, 'system-assessment.lookups.body-parts.ankle']
]);
