<!-- Tabs -->
<div class="tabs" [class.tabs--right-align]="rightAlign">
    <ng-container *ngFor="let tab of tabs; let last = last">
        <a
            *taIfToggle="tab.toggle"
            class="tabs__tab"
            [class.tabs__tab--last]="last"
            [routerLinkActive]="'tabs__tab--active'"
            [class.tabs__tab--disabled]="toggleService.isOn(tab.disabledToggle) === false"
            [routerLink]="toggleService.isOn(tab.disabledToggle) === true ? tab.route : null"
            pTooltip="{{ 'system.future-feature' | translate }}"
            [tooltipDisabled]="toggleService.isOn(tab.disabledToggle) === true"
            [queryParams]="tab.queryParams"
            queryParamsHandling="merge"
            (click)="handleTabClick(tab)"
            >{{ tab.name | translate }}</a
        ></ng-container
    >
</div>
