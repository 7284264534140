<div class="not-found-container">
    <div class="not-found-container__body">
        <h1>{{ 'system.not-found-title' | translate }}</h1>
        <p class="not-found-container__body__subtitle">{{ 'system.not-found-subtitle' | translate }}</p>
        <p>
            <strong>{{ 'system.not-found-explanation-title' | translate }}</strong>
        </p>
        <div class="not-found-container__body__error-explanation">
            <p>{{ 'system.not-found-explanation-para-1' | translate }}</p>
            <p>{{ 'system.not-found-explanation-para-2' | translate }}</p>
            <p>{{ 'system.not-found-explanation-para-3' | translate }}</p>
        </div>
        <p-button routerLink="/" class="not-found-container__body__button" styleClass="p-button-lg" label="{{ 'system.not-found-return-to-dashboard-button-text' | translate }}"></p-button>
    </div>
    <div class="not-found-container__logo">
        <img src="assets/images/logo__shattered__gradient.svg" />
    </div>
</div>
