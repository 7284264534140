import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationService } from '@ta/app/core/services/invitation.service';
import { LocalStorageKey } from '@ta/app/shared/models/local-storage.model';
import { AppRoutes, InvitationParam } from '@ta/app/shared/models/routes.model';
import { Invitation } from '@ta/app/core/models/invitation.model';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { EnvironmentService } from '@ta/app/shared/services/environment.service';
import { initiliseSocialProvders, SocialProvider } from '@ta/app/core/models/social-login.model';
import { AuthMode } from '@ta/app/core/models/auth-mode.model';
import { take } from 'rxjs';

@Component({
    selector: 'ta-auth-invitation',
    templateUrl: './auth-invitation.component.html',
    styleUrls: ['./auth-invitation.component.scss']
})
export class AuthInvitationComponent implements OnInit {
    /**
     * The invitation GUID, included in the invitation email URL
     */
    invitationGuid: string | null = null;

    /**
     * The invitation to TaskAlyser
     */
    invitation: Invitation | null = null;

    /**
     * The set of supported social providers
     */
    socialProviders: Array<SocialProvider> = [];

    /**
     * Make enum available in template
     */
    AuthMode = AuthMode;

    constructor(
        private readonly _environmentService: EnvironmentService,
        readonly invitationService: InvitationService,
        private readonly _auth0Service: Auth0Service,
        private _route: ActivatedRoute,
        private _router: Router
    ) {}

    ngOnInit(): void {
        // Get the invitationGuid from the URL in the invite email
        this.invitationGuid = this._route.snapshot.queryParams[InvitationParam.INVITATION_GUID];

        // Get the invite that was retrieved by the resolver
        this.invitation = this._route.snapshot.data.invite;

        // If the invitation has expired, send the user to the sign up screen
        if (this.invitation?.isExpired) {
            this._auth0Service.loginWithRedirect({ screen_hint: 'signup', login_hint: this.invitation?.email, connection: this._environmentService.config.AUTH.CONNECTION_DATABASE });
        }

        if (this.invitation?.isUserFinalised) {
            // If the user is already finalised, we don't need to trigger a password reset
            // Just navigate to the workspace
            this._router.navigate([AppRoutes.WORKSPACE, this.invitation.workspaceGuid]);
        } else {
            // Otherwise, store the workspace guid for later retrieval, after password reset
            if (!!this.invitation?.workspaceGuid) localStorage.setItem(LocalStorageKey.INVITE_WORKSPACE_GUID, this.invitation.workspaceGuid);
        }

        // Set up social providers
        this.socialProviders = initiliseSocialProvders(this._environmentService.config);
    }

    /**
     * Handle create account click
     */
    onCreateAccount(): void {
        // Send the user to the Auth0 sign up page
        this.invitationService
            .acceptInvitation(this.invitationGuid!)
            .pipe(take(1))
            .subscribe((acceptInvitationResponse) => {
                // Go to password reset
                window.location.href = acceptInvitationResponse!.passwordResetLink;
            });
    }

    /**
     * Handle login click
     */
    onLogin(): void {
        // Send the user to the Auth0 sign up page
        this._auth0Service.loginWithRedirect({ login_hint: this.invitation?.email });
    }
}
