import { Injectable } from '@angular/core';
import { FeatureFlags, FeatureFlagsKeys } from '@ta/app/shared-modules/toggle/models/feature-flags.model';
import { EnvironmentService } from '@ta/app/shared/services/environment.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    /**
     * our current features
     */
    private _features: FeatureFlags = {} as FeatureFlags;

    constructor(private readonly _environmentService: EnvironmentService) {
        this._features = _environmentService?.config ? this._environmentService.config.FEATURE_FLAGS : ({} as FeatureFlags);
    }

    /**
     * check if a feature is switched on
     */
    isOn(key: FeatureFlagsKeys): boolean {
        // The intended behaviour is that feature flags that are present are enabled / disabled based on their value.
        // Feature flags that are not present are enabled by default.
        // This makes it safer to trim feature flags as features become stable and flags are not required.
        return this._features[key] === undefined ? true : this._features[key];
    }
}
