<div class="usage-bar-container">
    <div class="total">
        {{ totalCapLabel | translate }} <span class="total__amount">{{ totalCapString ? totalCapString : totalCap }}</span>
    </div>
    <div *ngIf="simple" class="bar">
        <div class="bar__primary-used" [class.bar__total-used--exceeded]="isTotalCapExceeded" [style.width]="totalUsedPercentage + '%'"></div>
    </div>
    <div *ngIf="!simple" class="bar">
        <!-- These are all displayed on top of each other relative to their parent so their order is important! -->
        <div class="bar__active-cap" [style.width]="activeCapPercentageInverted + '%'"></div>
        <div class="bar__total-used" [style.width]="totalUsedPercentage + '%'" [class.bar__total-used--exceeded]="isTotalCapExceeded"></div>
        <div class="bar__active-used bar__primary-used" [style.width]="activeUsedPercentage + '%'" [class.bar__active-used--exceeded]="isActiveCapExceeded"></div>
        <!-- the divider sits between the active total and the total. -->
        <div class="bar__divider" [style.left]="activeCapPercentage + '%'" [class.bar__divider--exceeded]="isActiveCapExceeded"></div>
    </div>
    <div *ngIf="!simple" class="active" [style.margin-right]="activeCapPercentageInverted + '%'" [class.active--exceeded]="isActiveCapExceeded">
        {{ 'system.usage-bar.active-cap' | translate }} <span class="active__amount" [class.active__amount--exceeded]="isActiveCapExceeded">{{ activeCap }}</span>
    </div>
</div>
