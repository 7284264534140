import { Input, TemplateRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ta-empty-directory-overlay',
    templateUrl: './empty-directory-overlay.component.html',
    styleUrls: ['./empty-directory-overlay.component.scss']
})
export class EmptyDirectoryOverlayComponent {
    /**
     * The icon to display in the format 'ta-<icon name>' e.g.: 'ta-briefcase'.
     */
    @Input()
    icon!: string;

    /**
     * The text to display for the title, this text is not processed before display.
     */
    @Input()
    title!: string;

    /**
     * The text to display for the content, this text is not processed before display.
     */
    @Input()
    content!: string;

    constructor() {}
}
