<div class="navbar-left">
    <div class="navbar-left__workspace-switcher">
        <ta-navbar-top-menu icon="ta-office-apps" [blade]="BladeQueryParamValues.WORKSPACE_SWITCHER"></ta-navbar-top-menu>
        <div class="navbar-left__separator"></div>
    </div>
    <h4 class="navbar-left__title">{{ (worspaceService.workspace$ | async)?.name || 'system.nav.workspace.personal' | translate }}</h4>
</div>

<div class="navbar-center">
    <h3 class="navbar-center__title"><i class="ta ta-logo-word"></i></h3>
</div>

<div class="navbar-right">
    <ta-navbar-top-menu *taIfToggle="Toggle.SEARCH" icon="ta-search" [blade]="BladeQueryParamValues.SEARCH"></ta-navbar-top-menu>
    <ta-navbar-top-menu *taIfToggle="Toggle.HELP" icon="ta-question-circle" [blade]="BladeQueryParamValues.HELP"></ta-navbar-top-menu>
    <ta-navbar-top-menu *taIfToggle="Toggle.NOTIFICATION" icon="ta-alert" [blade]="BladeQueryParamValues.NOTIFICATIONS"></ta-navbar-top-menu>
    <ta-navbar-top-menu icon="ta-person-circle" [menu]="MenuQueryParamValues.ACCOUNT">
        <ta-navbar-top-menu-account></ta-navbar-top-menu-account>
    </ta-navbar-top-menu>
</div>
