import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Tag } from '@ta/app/shared/models/tag/tag.model';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';

@Injectable({
    providedIn: 'root'
})
export class TagService {
    constructor(private readonly _http: HttpClient, private readonly _workspaceService: WorkspaceService) {}

    private readonly SEARCH_TAGS_ROUTE = 'tag/search';

    private readonly _tags = new BehaviorSubject<Tag[] | undefined>(undefined);
    readonly tags$ = this._tags.asObservable();

    searchTags(query: any): Observable<Tag[] | undefined> {
        return this._http.get<Tag[]>(`${this._workspaceService.workspaceApiUrl}/${this.SEARCH_TAGS_ROUTE}`, { params: { term: query } }).pipe(
            tap((tags) => {
                this._tags.next(tags);
            }),
            switchMap(() => this.tags$)
        );
    }
}
