import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PageHeaderService {
    private readonly _disableControls = new BehaviorSubject<boolean>(false);
    readonly disableControls$ = this._disableControls.asObservable();

    private set disableControls(disableControls: boolean) {
        this._disableControls.next(disableControls);
    }

    private get disableControls(): boolean {
        return this._disableControls.getValue();
    }

    private readonly _hideEditDelete = new BehaviorSubject<boolean>(false);
    readonly hideEditDelete$ = this._hideEditDelete.asObservable();

    private set hideEditDelete(hideEditDelete: boolean) {
        this._hideEditDelete.next(hideEditDelete);
    }

    private get hideEditDelete(): boolean {
        return this._hideEditDelete.getValue();
    }

    private readonly _isBeingEdited = new BehaviorSubject<boolean>(false);
    readonly isBeingEdited$ = this._isBeingEdited.asObservable();

    private set isBeingEdited(isBeingEdited: boolean) {
        this._isBeingEdited.next(isBeingEdited);
    }

    private get isBeingEdited(): boolean {
        return this._isBeingEdited.getValue();
    }

    constructor() {}

    setChildPageBeingEdited(): void {
        this.setDisableControls();
    }

    setChildPageNotBeingEdited(): void {
        this.resetDisableControls();
    }

    setDisableControls(): void {
        this.disableControls = true;
    }

    resetDisableControls(): void {
        this.disableControls = false;
    }

    setPageEditable(): void {
        this.hideEditDelete = false;
    }

    setPageNotEditable(): void {
        this.hideEditDelete = true;
    }
}
