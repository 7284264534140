import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from '@ta/app/shared/models/routes.model';

@Component({
    selector: 'ta-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
    AppRoutes = AppRoutes;
    constructor(private readonly router: Router, private readonly route: ActivatedRoute) {}
}
