import { AccessLevel } from '@ta/app/shared/models/permissions/access-level.model';
import { PermissionItem } from '@ta/app/shared/models/permissions/permission-item.model';
import { PermissionLevel } from '@ta/app/shared/models/permissions/permission-access-levels.model';

export function getAccessLevel(permissionLevel: number, permissionItem: PermissionItem): string {
    // catch disabled users, they have no permissions
    if (!((permissionLevel & PermissionLevel.WorkspaceAccess) === PermissionLevel.WorkspaceAccess)) return '-';

    switch (permissionItem) {
        case PermissionItem.PEOPLE:
            if ((permissionLevel & PermissionLevel.Administer) === PermissionLevel.Administer) return AccessLevel.UNRESTRICTED;
            if ((permissionLevel & PermissionLevel.PowerUser) === PermissionLevel.PowerUser) return AccessLevel.UNRESTRICTED;
            if ((permissionLevel & PermissionLevel.EditAndReadPeople) === PermissionLevel.EditAndReadPeople) return AccessLevel.EDIT;
            if ((permissionLevel & PermissionLevel.ReadPeople) === PermissionLevel.ReadPeople) return AccessLevel.READ;
            return AccessLevel.BY_EXCEPTION;
        case PermissionItem.JOBS:
            if ((permissionLevel & PermissionLevel.Administer) === PermissionLevel.Administer) return AccessLevel.UNRESTRICTED;
            if ((permissionLevel & PermissionLevel.PowerUser) === PermissionLevel.PowerUser) return AccessLevel.UNRESTRICTED;
            if ((permissionLevel & PermissionLevel.EditAndReadJobs) === PermissionLevel.EditAndReadJobs) return AccessLevel.EDIT;
            if ((permissionLevel & PermissionLevel.ReadJobs) === PermissionLevel.ReadJobs) return AccessLevel.READ;
            return AccessLevel.BY_EXCEPTION;
        case PermissionItem.SITES:
            if ((permissionLevel & PermissionLevel.Administer) === PermissionLevel.Administer) return AccessLevel.UNRESTRICTED;
            if ((permissionLevel & PermissionLevel.PowerUser) === PermissionLevel.PowerUser) return AccessLevel.UNRESTRICTED;
            if ((permissionLevel & PermissionLevel.EditAndReadSites) === PermissionLevel.EditAndReadSites) return AccessLevel.EDIT;
            if ((permissionLevel & PermissionLevel.ReadSites) === PermissionLevel.ReadSites) return AccessLevel.READ;
            return AccessLevel.BY_EXCEPTION;
        case PermissionItem.ASSESSMENTS:
            if ((permissionLevel & PermissionLevel.Administer) === PermissionLevel.Administer) return AccessLevel.UNRESTRICTED;
            if ((permissionLevel & PermissionLevel.PowerUser) === PermissionLevel.PowerUser) return AccessLevel.UNRESTRICTED;
            if ((permissionLevel & PermissionLevel.EditAndReadAssessments) === PermissionLevel.EditAndReadAssessments) return AccessLevel.EDIT;
            if ((permissionLevel & PermissionLevel.ReadAssessments) === PermissionLevel.ReadAssessments) return AccessLevel.READ;
            return AccessLevel.BY_EXCEPTION;
        case PermissionItem.COMPARISONS:
            if ((permissionLevel & PermissionLevel.Administer) === PermissionLevel.Administer) return AccessLevel.UNRESTRICTED;
            if ((permissionLevel & PermissionLevel.PowerUser) === PermissionLevel.PowerUser) return AccessLevel.UNRESTRICTED;
            if ((permissionLevel & PermissionLevel.EditAndReadComparisons) === PermissionLevel.EditAndReadComparisons) return AccessLevel.EDIT;
            if ((permissionLevel & PermissionLevel.ReadComparisons) === PermissionLevel.ReadComparisons) return AccessLevel.READ;
            return AccessLevel.BY_EXCEPTION;
        case PermissionItem.SETTINGS:
            if ((permissionLevel & PermissionLevel.ManageWorkspace) === PermissionLevel.ManageWorkspace) return AccessLevel.UNRESTRICTED;
            if ((permissionLevel & PermissionLevel.AdminRead) === PermissionLevel.AdminRead) return AccessLevel.READ;
            break;
        case PermissionItem.PERMISSIONS:
            if ((permissionLevel & PermissionLevel.ManageWorkspace) === PermissionLevel.ManageWorkspace) return AccessLevel.UNRESTRICTED;
            if ((permissionLevel & PermissionLevel.EditWorkspacePermissions) === PermissionLevel.EditWorkspacePermissions) return AccessLevel.LIMITED_READ;
            break;
        case PermissionItem.BILLING:
            if ((permissionLevel & PermissionLevel.ManageWorkspace) === PermissionLevel.ManageWorkspace) return AccessLevel.UNRESTRICTED;
    }

    return '-';
}
