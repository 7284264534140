import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ta-content-dropdown',
    templateUrl: './content-dropdown.component.html',
    styleUrls: ['./content-dropdown.component.scss']
})
export class ContentDropdownComponent implements OnInit {
    /**
     * Title of the dropdown member
     */
    @Input() title?: string;

    /**
     * Any subtext fields that will be shown below the header
     */
    @Input() subtitles: Array<string> = [];

    /**
     * Boolean which controls if a dropdown is expanded or not
     */
    expanded = false;

    constructor() {}

    ngOnInit(): void {}

    /**
     * Toggle the expansion of the component.
     */
    toggleExpanded(): void {
        this.expanded = !this.expanded;
    }
}
