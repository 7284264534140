import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, take } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Subscription } from '@ta/app/shared/models/subscription/subscription.model';
import { UserService } from '@ta/app/shared/services/user.service';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';
import { SubscriptionSummary } from '@ta/app/shared/models/subscription/subscription-summary.model';
import { SubscriptionEstimate } from '@ta/app/shared/models/subscription/subscription-estimate.model';
import { SubscriptionProduct } from '@ta/app/shared/models/subscription/subscription-product.model';
import { isCurrentWorkspaceUsersHomeWorkspace } from '@ta/app/shared/utils/is-current-workspace-users-workspace';
import { FeatureFlagService } from '@ta/app/shared-modules/toggle/services/feature-flag.service';
import { Toggle } from '@ta/app/shared-modules/toggle/models/toggle.model';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    constructor(
        private readonly _http: HttpClient,
        private readonly _featureFlagService: FeatureFlagService,
        private readonly _userService: UserService,
        private readonly _workspaceService: WorkspaceService
    ) {}

    private readonly SUBSCRIPTION = 'subscription';
    private readonly SUBSCRIPTION_LIST = 'subscription/list';
    private readonly SUBSCRIPTION_ESTIMATE = 'subscription/estimate';
    private readonly SUBSCRIPTION_PRODUCT_LIST = 'subscription/products';

    // Home workspace
    private readonly _homeWorkspaceSubscriptionSummary = new BehaviorSubject<SubscriptionSummary | undefined>(undefined);
    readonly homeWorkspaceSubscriptionSummary$ = this._homeWorkspaceSubscriptionSummary.asObservable();

    private readonly _homeWorkspaceSubscriptionList = new BehaviorSubject<Subscription[] | undefined>(undefined);
    readonly homeWorkspaceSubscriptionList$ = this._homeWorkspaceSubscriptionList.asObservable();

    private readonly _homeWorkspaceSubscriptionProductList = new BehaviorSubject<SubscriptionProduct[] | undefined>(undefined);
    readonly homeWorkspaceSubscriptionProductList$ = this._homeWorkspaceSubscriptionProductList.asObservable();

    // Business workspace
    private readonly _subscriptionSummary = new BehaviorSubject<SubscriptionSummary | undefined>(undefined);
    readonly subscriptionSummary$ = this._subscriptionSummary.asObservable();

    private readonly _subscriptionList = new BehaviorSubject<Subscription[] | undefined>(undefined);
    readonly subscriptionList$ = this._subscriptionList.asObservable();

    private readonly _subscriptionProductList = new BehaviorSubject<SubscriptionProduct[] | undefined>(undefined);
    readonly subscriptionProductList$ = this._subscriptionProductList.asObservable();

    // The following getters return the correct data based on the currently active workspace (home or business)
    get subscriptionSummary(): SubscriptionSummary | undefined {
        return isCurrentWorkspaceUsersHomeWorkspace(this._userService.user!, this._workspaceService.workspace!)
            ? // Current workspace is the user's home workspace
              this._homeWorkspaceSubscriptionSummary.getValue()
            : // Current workspace is a business workspace
              this._subscriptionSummary.getValue();
    }

    get isRemainingStorageQuotaLow(): boolean | undefined {
        return this._subscriptionSummary.getValue()!.remainingStorageBytes < 500 * 1024 * 1024; // 500 MB
    }

    // Common to home and business workspaces
    private readonly _subscriptionUsageEstimate = new BehaviorSubject<SubscriptionEstimate | undefined>(undefined);
    readonly subscriptionUsageEstimate$ = this._subscriptionUsageEstimate.asObservable();

    /**
     * Home workspace
     */
    getHomeWorkspaceSubscriptionSummary(): Observable<SubscriptionSummary | undefined> {
        return this._http.get<SubscriptionSummary>(`${this._workspaceService.homeWorkspaceApiUrl}/${this.SUBSCRIPTION}`).pipe(
            tap((subscriptionSummary) => {
                this._homeWorkspaceSubscriptionSummary.next(subscriptionSummary);
            }),
            switchMap(() => this.homeWorkspaceSubscriptionSummary$)
        );
    }

    getHomeWorkspaceSubscriptionList(): Observable<Subscription[] | undefined> {
        return this._http.get<Subscription[]>(`${this._workspaceService.homeWorkspaceApiUrl}/${this.SUBSCRIPTION_LIST}`).pipe(
            tap((subscriptionList) => {
                this._homeWorkspaceSubscriptionList.next(subscriptionList);
            }),
            switchMap(() => this.homeWorkspaceSubscriptionList$)
        );
    }

    getHomeWorkspaceSubscriptionProductList(): Observable<SubscriptionProduct[] | undefined> {
        // Handle feature flag
        return this._featureFlagService.isOn(Toggle.FEATURE_SUBSCRIPTION_MANAGEMENT)
            ? // Subscription management is on, call the API
              this._http.get<SubscriptionProduct[]>(`${this._workspaceService.homeWorkspaceApiUrl}/${this.SUBSCRIPTION_PRODUCT_LIST}`).pipe(
                  tap((homeWorkspaceSubscriptionProductList) => {
                      this._homeWorkspaceSubscriptionProductList.next(homeWorkspaceSubscriptionProductList);
                  }),
                  switchMap(() => this.homeWorkspaceSubscriptionProductList$)
              )
            : // Feature flag is off, return an empty list
              of([]);
    }

    addSubscriptionToHomeWorkspace(subscriptionId: number): Observable<Subscription[] | undefined> {
        return this._http.post(`${this._workspaceService.homeWorkspaceApiUrl}/${this.SUBSCRIPTION}`, subscriptionId).pipe(
            // Fetch the updated subscription summary
            tap(() => this.getHomeWorkspaceSubscriptionSummary().pipe(take(1)).subscribe()),
            // Fetch and return the observable to the updated subscriptions list
            switchMap(() => this.getHomeWorkspaceSubscriptionList())
        );
    }

    /**
     * Business workspace
     */
    getSubscriptionSummary(): Observable<SubscriptionSummary | undefined> {
        return this._http.get<SubscriptionSummary>(`${this._workspaceService.workspaceApiUrl}/${this.SUBSCRIPTION}`).pipe(
            tap((subscriptionSummary) => {
                this._subscriptionSummary.next(subscriptionSummary);
            }),
            switchMap(() => this.subscriptionSummary$)
        );
    }

    getSubscriptionList(): Observable<Subscription[] | undefined> {
        return this._http.get<Subscription[]>(`${this._workspaceService.workspaceApiUrl}/${this.SUBSCRIPTION_LIST}`).pipe(
            tap((subscriptionList) => {
                this._subscriptionList.next(subscriptionList);
            }),
            switchMap(() => this.subscriptionList$)
        );
    }

    getSubscriptionProductList(): Observable<SubscriptionProduct[] | undefined> {
        // Handle feature flag
        return this._featureFlagService.isOn(Toggle.FEATURE_SUBSCRIPTION_MANAGEMENT)
            ? // Subscription management is on, call the API
              this._http.get<SubscriptionProduct[]>(`${this._workspaceService.workspaceApiUrl}/${this.SUBSCRIPTION_PRODUCT_LIST}`).pipe(
                  tap((subscriptionProductList) => {
                      this._subscriptionProductList.next(subscriptionProductList);
                  }),
                  switchMap(() => this.subscriptionProductList$)
              )
            : // Feature flag is off, return an empty list
              of([]);
    }

    addSubscriptionToWorkspace(subscriptionId: number): Observable<Subscription[] | undefined> {
        return this._http.post(`${this._workspaceService.workspaceApiUrl}/${this.SUBSCRIPTION}`, subscriptionId).pipe(
            // Fetch the updated subscription summary
            tap(() => this.getSubscriptionSummary().pipe(take(1)).subscribe()),
            // Fetch and return the observable to the updated subscriptions list
            switchMap(() => this.getSubscriptionList())
        );
    }

    /**
     * Common to home and business workspaces
     */
    getSubscriptionUsageEstimate(): Observable<SubscriptionEstimate | undefined> {
        return this._http.get<SubscriptionEstimate>(`${this._workspaceService.workspaceApiUrl}/${this.SUBSCRIPTION_ESTIMATE}`).pipe(
            tap((estimate) => {
                this._subscriptionUsageEstimate.next(estimate);
            }),
            switchMap(() => this.subscriptionUsageEstimate$)
        );
    }
}
