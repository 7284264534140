<div class="audit-component-container">
    <div class="main">
        <div class="main__header">
            <ta-filter-dropdown [filterItems]="filterItems" [(selected)]="selectedFilters" (clearFilter)="clearFilter()" (filter)="filter()"></ta-filter-dropdown>
        </div>
        <div class="main__content">
            <ul>
                <li *ngFor="let audit of auditLog">
                    <div class="main__content__item">
                        <div class="main__content__item__left">
                            <i class="ta ta-person-circle"></i>
                        </div>
                        <div class="main__content__item__right">
                            <div class="main__content__item__right__info">
                                <p class="main__content__item__right__info--bold">{{ audit.userName }}</p>
                                <p>{{ AuditAction[audit.auditEventTypeId] | translate }}</p>
                                <p class="main__content__item__right__info--bold">{{ objectName }}</p>
                            </div>
                            <div>
                                <p class="main__content__item__right__time">
                                    {{ audit.actionDate | dateLocaliser | date: 'dd-MMM-YYYY' }} {{ 'system.at' | translate }} {{ audit.actionDate | dateLocaliser | date: 'h:mma' }}
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
