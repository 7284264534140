<div class="blade-account-details-container">
    <p-scrollPanel [style]="{ width: '100%' }" class="scroll">
        <div class="content">
            <!-- User details form -->
            <form class="content__form" [formGroup]="userDetailsForm">
                <h4 for="name">{{ "system.account-blade.account.name" | translate }}</h4>
                <div class="content__form__input-wrapper">
                    <input class="content__form__input-wrapper__input" formControlName="name" aria-describedby="name-invalid" type="text" pInputText />
                    <p-message
                        class="content__form__input-wrapper__message"
                        *ngIf="userDetailsForm.get('name')?.dirty && userDetailsForm.get('name')?.invalid"
                        severity="error"
                        [text]="getErrors('name', 'system.account-blade.account.name' | translate)"
                    ></p-message>
                </div>

                <h4 for="email">{{ "system.account-blade.account.email" | translate }}</h4>
                <div class="content__form__input-wrapper">
                    <input class="content__form__input-wrapper__input" formControlName="email" [attr.disabled]="true" type="text" pInputText />
                    <p-message
                        class="content__form__input-wrapper__message"
                        *ngIf="userDetailsForm.get('email')?.dirty && userDetailsForm.get('email')?.invalid"
                        severity="error"
                        [text]="getErrors('name', 'system.account-blade.account.email' | translate)"
                    ></p-message>
                </div>
            </form>

            <!-- Change password block -->
            <div *ngIf="userDetails?.isAuth0AuthProviderRegistered">
                <h4>{{ 'system.account-blade.account.change-password.heading' | translate }}</h4>
                <p>{{ 'system.account-blade.account.change-password.content' | translate }}</p>
                <p-button
                    (onClick)="onChangePassword()"
                    [disabled]="disableChangePassword"
                    styleClass="p-button-outlined p-button-secondary"
                    icon="pi ta-key"
                    label="{{ 'system.button.change-password' | translate }}"
                ></p-button>
                <p-dialog header="{{ 'system.dialog.password-reset.heading' | translate }}" [(visible)]="showChangePasswordModal">
                    {{ 'system.dialog.password-reset.content' | translate }}
                    <ng-template pTemplate="footer">
                        <p-button (onClick)="onChangePasswordConfirm()">{{ "system.button.ok" | translate }}</p-button>
                    </ng-template>
                </p-dialog>
            </div>

            <!-- Reset MFA block -->
            <div *ngIf="userDetails?.isAuth0AuthProviderRegistered">
                <h4>{{ 'system.account-blade.account.reset-mfa.heading' | translate }}</h4>
                <p>{{ 'system.account-blade.account.reset-mfa.content' | translate }}</p>
                <p-button
                    (onClick)="onResetMfa()"
                    [disabled]="disableResetMfa"
                    styleClass="p-button-outlined p-button-secondary"
                    icon="ta ta-phone-lock"
                    label="{{ 'system.button.reset-mfa' | translate }}"
                ></p-button>
                <p-dialog header="{{ 'system.dialog.reset-mfa.heading' | translate }}" [(visible)]="showResetMfaModal">
                    <div class="dialog__content">
                        <p>{{ 'system.dialog.reset-mfa.content.line1' | translate }}</p>
                        <p>{{ 'system.dialog.reset-mfa.content.line2' | translate }}</p>
                    </div>
                    <ng-template pTemplate="footer">
                        <p-button styleClass="p-button-outlined p-button-secondary" (onClick)="onResetMfaCancel()">{{ "system.button.cancel" | translate }}</p-button>
                        <p-button (onClick)="onResetMfaConfirm()">{{ "system.button.ok" | translate }}</p-button>
                    </ng-template>
                </p-dialog>
            </div>
        </div>
    </p-scrollPanel>
</div>

<!-- Bottom controls block -->
<ta-blade-controls class="controls" [form]="userDetailsForm" [showDiscardChangesPrompt]="showDiscardChangesPrompt" (save)="handleSave()" (discard)="handleDiscard()"></ta-blade-controls>
