import { JobTypes } from '@ta/app/shared/models/job/job-type.model';
import { PersonTypes } from '@ta/app/shared/models/person/person-type.model';
import { RelationshipObjectType } from '@ta/app/shared/models/relationship/relationship-object-type.model';
import { RelationshipType } from '@ta/app/shared/models/relationship/relationship-type.model';
import { SiteTypes } from '@ta/app/shared/models/site/siteType.model';

export function getRelationshipType(context: RelationshipObjectType, type: number): RelationshipType {
    switch (context) {
        case RelationshipObjectType.JOB:
            switch (type) {
                case JobTypes.POSITION:
                    return RelationshipType.JOB_POSITION;
                case JobTypes.TASK:
                    return RelationshipType.JOB_TASK;
                default:
                    return RelationshipType.JOB_ALL;
            }
        case RelationshipObjectType.PERSON:
            switch (type) {
                case PersonTypes.EMPLOYEE:
                    return RelationshipType.PERSON_EMPLOYEE;
                case PersonTypes.CLIENT:
                    return RelationshipType.PERSON_CLIENT;
                default:
                    return RelationshipType.PERSON_ALL;
            }
        case RelationshipObjectType.SITE:
            switch (type) {
                case SiteTypes.SPECIFIC_LOCATION:
                    return RelationshipType.SITE_SPECIFIC_LOCATION;
                case SiteTypes.WORKSPACE:
                    return RelationshipType.SITE_WORKPLACE;
                default:
                    return RelationshipType.SITE_ALL;
            }
    }
}
