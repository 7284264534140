export interface Pagination {
    page: number;
    pageCount: number;
    isOnFirstPage: boolean;
    isOnLastPage: boolean;
}

export const defaultPagination: Pagination = {
    page: 0,
    pageCount: 0,
    isOnFirstPage: true,
    isOnLastPage: false
};
