import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { firstValueFrom } from 'rxjs';
import { AppRoutes } from '../models/routes.model';

@Injectable()
export class UnauthenticatedGuard implements CanActivate, CanActivateChild {
    constructor(private readonly _router: Router, private _auth0Service: Auth0Service) {}

    canActivate(): Promise<true | UrlTree> {
        return this.checkUserIsNotAuthenticated();
    }

    canActivateChild(): Promise<true | UrlTree> {
        return this.checkUserIsNotAuthenticated();
    }

    private async checkUserIsNotAuthenticated(): Promise<true | UrlTree> {
        //////////////////////////////////////
        // Is the User logged in?
        //////////////////////////////////////

        // the isAuthenticated$ observable tracks whether the user is authenticated with Auth0, and not if the user's access token is currently valid. To check if the user's access
        // token is currently valid, we check what the value of getUser() is, which becomes undefined when the user's access token is about to expire.
        const isAuthenticated = await firstValueFrom(this._auth0Service.isAuthenticated$);
        const user = await firstValueFrom(this._auth0Service.getUser());

        // If isAuthenticated$ is true, and user is not undefined, then the user is logged in with Auth0 and has a valid access token, so send them to the root of the app
        // The valid user guard and workspace guard will send them where they need to go
        if (isAuthenticated && user) {
            return this._router.createUrlTree([AppRoutes.ROOT]);
        }

        return true;
    }
}
