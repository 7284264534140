import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@ta/app/shared/services/environment.service';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { WorkspaceUserPermission } from '@ta/app/shared/models/permissions/workspace-user-permission.model';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceUserPermissionService {
    constructor(private _http: HttpClient, private _envService: EnvironmentService, private _workspaceService: WorkspaceService) {}

    private readonly _PERMISSIONS_ROUTE = 'permissions';
    private readonly _LIST_ROUTE = 'list';
    private readonly _MINE = 'mine';

    private readonly _workspaceUserPermissions = new BehaviorSubject<Array<WorkspaceUserPermission> | undefined>(undefined);
    readonly _workspaceUserPermissions$ = this._workspaceUserPermissions.asObservable();

    private readonly _myWorkspaceUserPermissions = new BehaviorSubject<WorkspaceUserPermission | undefined>(undefined);
    readonly _myWorkspaceUserPermissions$ = this._myWorkspaceUserPermissions.asObservable();

    get myWorkspaceUserPermissions(): WorkspaceUserPermission | undefined {
        return this._myWorkspaceUserPermissions.getValue();
    }

    getWorkspaceUserPermissions(): Observable<Array<WorkspaceUserPermission> | undefined> {
        return this._http
            .get<Array<WorkspaceUserPermission>>(`${this._envService.config.ORCHESTRATION_API_URL}/${this._PERMISSIONS_ROUTE}/${this._workspaceService.workspace!.globalId}/${this._LIST_ROUTE}`)
            .pipe(
                tap((userPermissions) => {
                    this._workspaceUserPermissions.next(userPermissions);
                }),
                switchMap(() => this._workspaceUserPermissions$)
            );
    }

    getMyWorkspacePermissions(): Observable<WorkspaceUserPermission | undefined> {
        return this._http.get<WorkspaceUserPermission>(`${this._envService.config.ORCHESTRATION_API_URL}/${this._PERMISSIONS_ROUTE}/${this._workspaceService.workspace!.globalId}/${this._MINE}`).pipe(
            tap((userPermissions) => {
                this._myWorkspaceUserPermissions.next(userPermissions);
            }),
            switchMap(() => this._myWorkspaceUserPermissions$)
        );
    }

    deleteWorkspaceUserPermissions(userId: number): Observable<object> {
        return this._http.delete(`${this._envService.config.ORCHESTRATION_API_URL}/${this._PERMISSIONS_ROUTE}/${this._workspaceService.workspace!.globalId}`, {
            params: { userId: userId.toString() }
        });
    }

    updateWorkspaceUserPermissions(userPermission: WorkspaceUserPermission): Observable<WorkspaceUserPermission | undefined> {
        return this._http.put<WorkspaceUserPermission>(`${this._envService.config.ORCHESTRATION_API_URL}/${this._PERMISSIONS_ROUTE}/${this._workspaceService.workspace!.globalId}`, userPermission);
    }
}
