<ta-auth class="auth">
    <!-- Welcome heading -->
    <h1 class="auth__heading">{{ 'system.auth.invite.heading' | translate }}</h1>

    <!-- Message -->
    <p class="auth__message">{{ (invitationService.invitation$ | async)?.workspaceName }} {{ 'system.auth.invite.message.line1' | translate }}</p>
    <p class="auth__message">
        {{ 'system.auth.invite.message.line2' | translate }} <br /><span class="auth__message--emphasis truncate">{{ (invitationService.invitation$ | async)?.email }}</span>
    </p>

    <!-- Create account -->
    <a class="auth__action">
        <p-button class="button" styleClass="p-button-lg" (onClick)="onCreateAccount()" label="{{ 'system.auth.invite.button' | translate }}"> </p-button>
    </a>

    <!-- Divider -->
    <div class="auth__divider">
        <hr />
        <p>OR</p>
        <hr />
    </div>

    <!-- Social providers -->
    <div class="auth__social-providers">
        <ta-auth-social-provider
            *ngFor="let socialProvider of socialProviders"
            [socialProvider]="socialProvider"
            [mode]="AuthMode.SIGNUP"
            [email]="(invitationService.invitation$ | async)?.email"
        ></ta-auth-social-provider>
    </div>

    <!-- Login -->
    <p class="auth__message">
        {{ 'system.auth.invite.message.line3' | translate }} <a (click)="onLogin()" class="auth__message--link auth__message--emphasis">{{ 'system.auth.invite.message.log-in' | translate }}</a>
    </p>
</ta-auth>
