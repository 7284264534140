import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';

@Component({
    selector: 'ta-blade-controls',
    templateUrl: './blade-controls.component.html',
    styleUrls: ['./blade-controls.component.scss']
})
export class BladeControlsComponent {
    /**
     * The current form being displayed
     */
    @Input() form!: FormGroup;

    /**
     * Whether to show the discard changes prompt
     */
    @Input() showDiscardChangesPrompt: Subject<boolean> = new Subject<boolean>();

    /**
     * Button event emitters
     */
    @Output() save: EventEmitter<any> = new EventEmitter();
    @Output() discard: EventEmitter<any> = new EventEmitter();

    constructor(readonly linkedFormService: LinkedFormService) {}

    /**
     * Handle cancel button click
     */
    onCancel(): void {
        this.form.dirty ? this.showDiscardChangesPrompt.next(true) : this.discardChanges();
    }

    /**
     * Handle save button click
     */
    onSave(): void {
        this.save.emit();
    }

    /**
     * Handle discard cancel click
     */
    onDiscardChangesCancel(): void {
        this.showDiscardChangesPrompt.next(false);
    }

    /**
     * Handle discard confirm click
     */
    onDiscardChangesConfirm(): void {
        this.discardChanges();
    }

    /**
     * Discard changes
     */
    discardChanges(): void {
        this.showDiscardChangesPrompt.next(false);
        this.discard.emit();
    }
}
