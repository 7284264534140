import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ta-blade-notifications',
    templateUrl: './blade-notifications.component.html',
    styleUrls: ['./blade-notifications.component.scss']
})
export class BladeNotificationsComponent implements OnInit {
    // TODO: Refactor into i81n
    heading = 'Notifications';

    constructor() {}

    ngOnInit(): void {}
}
