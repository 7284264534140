<ta-auth class="auth">
    <h1 class="auth__heading">{{ 'system.verify-email.title' | translate }}</h1>
    <p class="auth__message">{{ 'system.verify-email.content-1' | translate }}</p>
    <div *ngIf="!this.verificationEmailResent" class="verify-email-component-container__content__extra">
        <p class="auth__message">
            {{ 'system.verify-email.content-2' | translate }}
            <button (click)="resendVerificationEmail()" [disabled]="this.disableResendEmailButton">{{ 'system.verify-email.action-text' | translate }}</button>
        </p>
    </div>

    <div *ngIf="this.verificationEmailResent" class="verify-email-component-container__content__extra">
        <p class="auth__message">Another verification email has been sent.</p>
    </div>

    <div class="auth__action">
        <p-button (onClick)="onLogoutClick()" styleClass="p-button-primary p-button-text" label="{{ 'system.verify-email.logout' | translate }}"></p-button>
    </div>
</ta-auth>

<p-toast (onClose)="handleCloseToast()" (click)="onToastClick($event)" position="top-right"></p-toast>
