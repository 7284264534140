<div class="nav-left" [class.nav-left--expanded]="expandedFloatingMode">
    <div [ngClass]="compactMode ? 'left-nav-compact' : 'left-nav-regular'">
        <!-- Menus above the divider-->
        <ng-container *ngFor="let menu of aboveDividerMenus">
            <ng-container *taIfToggle="menu.toggle">
                <ta-navbar-left-menu [menu]="menu" *ngIf="!compactMode" (menuClicked)="handleMenuItemClick()"></ta-navbar-left-menu>
                <ta-navbar-left-menu-compact [menu]="menu" *ngIf="compactMode"></ta-navbar-left-menu-compact>
            </ng-container>
        </ng-container>
        <!-- Menus below the divider -->
        <ng-container *ngFor="let menu of belowDividerMenus">
            <ng-container *taIfToggle="menu.toggle">
                <ta-navbar-left-menu [menu]="menu" *ngIf="!compactMode" (menuClicked)="handleMenuItemClick()"></ta-navbar-left-menu>
                <ta-navbar-left-menu-compact [menu]="menu" *ngIf="compactMode"></ta-navbar-left-menu-compact>
            </ng-container>
        </ng-container>
    </div>

    <div (click)="toggleCompact()" [ngClass]="compactMode ? 'bottom-left-compact' : 'bottom-left-regular'">
        <i [ngClass]="compactMode ? 'ta-chevron-double-right left-nav-chevron-compact' : 'ta-chevron-double-left left-nav-chevron-regular'"></i>
    </div>
</div>

<div class="app-overlay" [class.display]="!compactMode && belowResponsiveBreakpoint" (click)="compactMode = true"></div>
<div class="app-overlay-workspace-switcher" [class.display]="!compactMode && belowResponsiveBreakpoint" (click)="compactMode = true"></div>
