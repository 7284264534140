<!-- Clear search icon -->
<p-button *ngIf="!!input.value" icon="ta-dismiss" styleClass="p-button-text p-button-plain p-button-md" (click)="onClear()"></p-button>
<!-- Search box with icon -->
<span class="p-input-icon-right search">
    <i class="ta-search search__icon" (mousedown)="onSearchMousedown($event)" [class.search__icon--disabled]="disabled"></i>
    <input
        #search
        [disabled]="disabled"
        [formControl]="input"
        class="search__input"
        [ngClass]="{ 'search__input--active': !!input.value || isActive }"
        (blur)="onBlur()"
        pInputText
        type="text"
        placeholder="{{ 'system.form.global-search-placeholder' | translate }}"
        [attr.maxlength]="maxLength"
    />
</span>
