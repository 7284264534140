import { Pipe, PipeTransform } from '@angular/core';
import { BodyPartJointMovementTypeV1 } from '@ta/app/assessments/models/insights/body-part-joint-movement-type-v1.model';
@Pipe({
    name: 'isWristDeviation'
})
export class IsWristDeviationPipe implements PipeTransform {
    /**
     * Checks whether a given movement is a wrist deviation of any type. This is used to differentiate styling in the informational pictures in the range of motion sections
     * in question modules and insights.
     * @param movement a movement to check
     * @returns A boolean representing if a movement is a wrist deviation.
     */
    transform(movement: BodyPartJointMovementTypeV1 | null | undefined): boolean {
        if (movement === null || movement === undefined) return false;
        return (
            movement === BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_WRIST_ULNAR_DEVIATION ||
            movement === BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_WRIST_ULNAR_DEVIATION ||
            movement === BodyPartJointMovementTypeV1.UPPER_LIMBS_BEST_WRIST_RADIAL_DEVIATION ||
            movement === BodyPartJointMovementTypeV1.UPPER_LIMBS_LEFT_WRIST_RADIAL_DEVIATION ||
            movement === BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_WRIST_ULNAR_DEVIATION ||
            movement === BodyPartJointMovementTypeV1.UPPER_LIMBS_RIGHT_WRIST_RADIAL_DEVIATION
        );
    }
}
