import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavbarLeftMenuItem } from '@ta/app/core/models/navbar-left-menu-item.model';
import { Toggle } from '@ta/app/shared-modules/toggle/models/toggle.model';
import { ToggleService } from '@ta/app/shared-modules/toggle/services/toggle.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'ta-navbar-left-menu',
    templateUrl: './navbar-left-menu.component.html',
    styleUrls: ['./navbar-left-menu.component.scss']
})
export class NavbarLeftMenuComponent implements OnInit, OnDestroy {
    constructor(private _router: Router, public _toggleService: ToggleService) {}
    /**
     * Menu to display
     */
    @Input() menu!: NavbarLeftMenuItem;

    @Output() menuClicked: EventEmitter<undefined> = new EventEmitter();

    /**
     * Whether the menu is expanded to show sub-menus
     */
    isMenuExpanded = false;

    private _subscription?: Subscription;

    /**
     * Prevent click propagation to prevent interference with global click handler
     */
    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        event.stopPropagation();
    }

    ngOnInit(): void {
        // Default the menu to expanded if one of the submenus is active, else default to closed
        this.isMenuExpanded =
            this.hasSubMenus() &&
            !!this.menu.subMenus?.find((subMenu) => {
                return this._router.isActive(subMenu.path.join('/'), false);
            });

        // Close submenus on navigate away
        if (this.hasSubMenus()) {
            this._subscription = this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
                this.isMenuExpanded =
                    this.hasSubMenus() &&
                    !!this.menu.subMenus?.find((subMenu) => {
                        return this._router.isActive(subMenu.path.join('/'), false);
                    });
            });
        }
    }

    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    /**
     * Whether the menu has sub menus (and there has an expandable menu with submenus)
     */
    hasSubMenus = (): boolean => !!this.menu.subMenus;

    /**
     * Handle toggling menu open or closed
     */
    toggleMenuExpanded(): void {
        this.isMenuExpanded = !this.isMenuExpanded;
    }

    onMenuItemClick(): void {
        this.menuClicked.emit();
    }

    isMenuItemDisabled(toggle: Toggle | undefined): boolean {
        // as disabled is option check that toggle has been provided and that isn't on
        return !!toggle && !this._toggleService.isOn(toggle);
    }
}
