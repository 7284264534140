import { Injectable, isDevMode } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { AuthService as Auth0Service, User as Auth0User } from '@auth0/auth0-angular';
import { firstValueFrom, Observable } from 'rxjs';
import { AppQueryParams, AppRoutes } from '@ta/app/shared/models/routes.model';
import { AuthenticatedGuard } from './authenticated.guard';
import { ErrorCodes } from '../models/api-response.model';

@Injectable()
export class EmailNotVerifiedGuard implements CanActivate, CanActivateChild {
    constructor(private readonly _router: Router, private _auth0Service: Auth0Service, private _authenticatedGuard: AuthenticatedGuard) {}

    canActivate(): Promise<true | UrlTree> {
        return this.checkEmailIsVerified(this._authenticatedGuard.canActivate());
    }

    canActivateChild(): Promise<true | UrlTree> {
        return this.checkEmailIsVerified(this._authenticatedGuard.canActivateChild());
    }

    private async checkEmailIsVerified(authenticatedGuard: Promise<true | UrlTree>): Promise<true | UrlTree> {
        //////////////////////////////////////
        // Check preceding guards
        //////////////////////////////////////
        const isAuthenticated = await authenticatedGuard;
        if (isAuthenticated instanceof UrlTree) return isAuthenticated;

        //////////////////////////////////////
        // Is the User's email verified?
        //////////////////////////////////////
        // Get the user
        const auth0User: Auth0User | undefined = await firstValueFrom(this._auth0Service.getUser());

        // This shouldn't happen
        if (!auth0User) {
            if (isDevMode()) {
                return this._router.createUrlTree([AppRoutes.OOPS], {
                    queryParams: { [AppQueryParams.ERROR]: ErrorCodes.CLIENT_EMAIL_NOT_VERIFIED_GUARD_GET_USER_FAILED_AUTH0 },
                    queryParamsHandling: 'merge'
                });
            } else {
                return this._router.createUrlTree([AppRoutes.OOPS]);
            }
        }

        // if the user is email verified, they can't access the verify email page
        if (auth0User?.email_verified) {
            return this._router.createUrlTree([AppRoutes.ROOT]);
        }

        return true;
    }
}
