import { Component, OnInit } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { Toggle } from '@ta/app/shared-modules/toggle/models/toggle.model';
import { AppRoutes } from '@ta/app/shared/models/routes.model';
import { EnvironmentService } from '@ta/app/shared/services/environment.service';
import { UserService } from '@ta/app/shared/services/user.service';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';
import { firstValueFrom, take } from 'rxjs';
import { WorkspaceSwitcherItem } from '../../models/workspace-switcher-item.model';

@Component({
    selector: 'ta-blade-account-data',
    templateUrl: './blade-account-data.component.html',
    styleUrls: ['./blade-account-data.component.scss']
})
export class BladeAccountDataComponent implements OnInit {
    AppRoutes = AppRoutes;
    Toggle = Toggle;
    showDeleteAccountModal = false;
    workspaces?: Array<WorkspaceSwitcherItem>;

    constructor(
        private readonly _auth0Service: Auth0Service,
        private readonly _userService: UserService,
        private readonly _workspaceService: WorkspaceService,
        public environmentService: EnvironmentService
    ) {}

    async ngOnInit(): Promise<void> {
        this.workspaces = (await firstValueFrom(this._workspaceService.workspaceList$.pipe()))?.filter((x) => !x.isHomeWorkspace);
    }

    /**
     * Handle delete account button click
     */
    onDeleteAccount(): void {
        this.showDeleteAccountModal = true;
    }

    /**
     * Handle confirmation of delete account modal
     */
    onDeleteAccountConfirm(): void {
        this.showDeleteAccountModal = false;
        this._userService
            .deleteUser()
            .pipe(take(1))
            .subscribe(() => {
                this._auth0Service.logout({ returnTo: window.location.origin });
            });
    }

    /**
     * Handle cancel of delete account modal
     */
    onDeleteAccountCancel(): void {
        this.showDeleteAccountModal = false;
    }
}
