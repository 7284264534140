<div class="content">
    <div class="content__top">
        <div class="content__top__title">
            <h4>Tags</h4>
        </div>
        <div *ngIf="!expanded && !edit" (click)="onToggleDetails()" class="content__top__button">
            <p>{{ 'system.button.expand-all' | translate }}</p>
            <i class="ta ta-chevron-double-down"></i>
        </div>
        <div *ngIf="expanded && !edit" (click)="onToggleDetails()" class="content__top__button">
            <p>{{ 'system.button.collapse-all' | translate }}</p>
            <i class="ta ta-chevron-double-up"></i>
        </div>
    </div>
    <div *ngIf="!expanded || edit" class="content__main" [class.content__main--expanded]="expanded">
        <div class="content__main__set">
            <div class="content__main__set__tags">
                <ng-container *ngFor="let tagAction of tags.controls">
                    <div
                        *ngIf="!!tagAction.get(FormArrayLabel.TAG) && !(tagAction.get(FormArrayLabel.ACTION)?.value === TagActions.DELETE)"
                        class="content__main__set__tags__tag"
                        [class.content__main__set__tags__tag--inactive]="!active"
                    >
                        {{ tagAction.get(FormArrayLabel.TAG)?.value.value | uppercase }} ({{ tagAction.get(FormArrayLabel.TAG)?.value.code
                        }}<span *ngIf="!!tagAction.get(FormArrayLabel.TAG)?.value.specifier">,&nbsp;</span>{{ tagAction.get(FormArrayLabel.TAG)?.value.specifier }})
                        <i *ngIf="edit" (click)="onRemoveTag(tagAction.get(FormArrayLabel.TAG)?.value.id)" class="ta ta-dismiss-circle content__main__set__tags__tag__icon"></i>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="edit" class="content__autocomplete-form">
        <span class="p-input-icon-left">
            <i class="ta ta-tag"></i>
            <p-autoComplete
                [(ngModel)]="selectedTag"
                (onKeyUp)="handleKeyUp($event)"
                (onSelect)="onAddTag()"
                [suggestions]="filteredTags"
                field="value"
                (completeMethod)="filter($event)"
                placeholder="{{ 'system.form.tag-input-placeholder' | translate }}"
                [maxlength]=100
            ></p-autoComplete
        ></span>
    </div>
    <div *ngIf="expanded && !edit" class="content__main" [class.content__main--expanded]="expanded">
        <div *ngFor="let set of setsOfTags" class="content__main__set">
            <div class="content__main__set__title" [class.content__main__set__title--hidden]="!expanded">
                <h4>{{ set.name | uppercase }}</h4>
            </div>
            <div class="content__main__set__tags">
                <div *ngFor="let tag of set.tags; let last = last" class="content__main__set__tags__tag" [class.content__main__set__tags__tag--inactive]="!active">
                    {{ tag?.value | uppercase }} ({{ tag.code }}<span *ngIf="!!tag.specifier">,&nbsp;</span>{{ tag.specifier }})
                </div>
            </div>
        </div>
    </div>
</div>
