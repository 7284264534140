import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Country } from '@ta/app/shared/models/country.model';
import { FormName } from '@ta/app/shared/models/form.model';
import { User } from '@ta/app/shared/models/user.model';
import { LinkedFormService } from '@ta/app/shared/services/linked-form.service';
import { UserService } from '@ta/app/shared/services/user.service';
import { WorkspaceService } from '@ta/app/shared/services/workspace.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'ta-workspace-create-premium',
    templateUrl: './workspace-create-premium.component.html',
    styleUrls: ['./workspace-create-premium.component.scss']
})
export class WorkspaceCreatePremiumComponent implements OnInit, OnDestroy {
    /**
     * User details for form information
     */
    userDetails?: User;

    /**
     * An array containing all countries availible for selection
     */
    countries: Country[] = new Array<Country>();
    selectedCountry!: Country;

    /**
     * Form that facilitates the request of a premium workspace
     */
    premiumWorkspaceRequestForm!: FormGroup;

    /**
     * Name of the form used in this component
     */
    premiumWorkspaceRequestFormName: FormName = FormName.PREMIUM_WORKSPACE_REQUEST;

    emailInputActive = false;
    contactNumberInputActive = false;

    /**
     * Single subscription to simplify clean-up on destroy
     */
    private _subscription?: Subscription;

    constructor(private readonly userService: UserService, private readonly _workspaceService: WorkspaceService, private readonly _linkedFormService: LinkedFormService) {}

    ngOnInit(): void {
        this.premiumWorkspaceRequestForm = this._linkedFormService.getForm(this.premiumWorkspaceRequestFormName);

        // Populate country array
        this._workspaceService
            .listCountries()
            .pipe(take(1))
            .subscribe((result) => {
                this.countries = result ?? new Array<Country>();
            });

        // Get user details
        this._subscription = this.userService
            .getUser()
            .pipe(take(1))
            .subscribe((result) => {
                this.userDetails = result;
            });
    }

    ngOnDestroy(): void {
        // Clean up subscriptions
        this._subscription?.unsubscribe();
    }

    /**
     * Get errors relating to a standard workspace form control
     * @returns One or more errors, as a string
     */
    getErrors = (formControName: string, friendlyControlName: string): string => this._linkedFormService.getErrors(this.premiumWorkspaceRequestFormName, formControName, friendlyControlName);
}
