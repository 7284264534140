<!-- Menu with link to a module -->
<div
    *ngIf="!menu.subMenus"
    class="menu__item"
    [routerLink]="isMenuItemDisabled(menu.disabled) ? null : menu.path"
    pTooltip="{{ 'system.nav.future-feature' | translate }}"
    [tooltipDisabled]="!isMenuItemDisabled(menu.disabled)"
    routerLinkActive="menu__item--active"
    [class.menu__item--disabled]="isMenuItemDisabled(menu.disabled)"
    (click)="onMenuItemClick()"
>
    <i class="menu__item__icon" [ngClass]="menu.icon"></i>
    <h4 class="menu__item__label">{{ menu.label }}</h4>
</div>

<!-- Menu with submodules (no link to a module) -->
<div *ngIf="hasSubMenus()" class="menu__item" (click)="toggleMenuExpanded()">
    <i class="menu__item__icon" [ngClass]="menu.icon"></i>
    <h4 class="menu__item__label">{{ menu.label }}</h4>
    <i class="menu__item__chevron" [ngClass]="isMenuExpanded ? 'ta-chevron-up' : 'ta-chevron-down'"></i>
</div>

<div class="submenu" *ngIf="hasSubMenus()" [ngClass]="{ 'submenu--hidden': !isMenuExpanded }">
    <ng-container *ngFor="let submenu of menu.subMenus">
        <ng-container *taIfToggle="submenu.toggle">
            <div
                class="submenu__item"
                [routerLink]="isMenuItemDisabled(submenu.disabled) ? null : submenu.path"
                pTooltip="{{ 'system.future-feature' | translate }}"
                [tooltipDisabled]="!isMenuItemDisabled(submenu.disabled)"
                routerLinkActive="submenu__item--active"
                [class.submenu__item--disabled]="isMenuItemDisabled(submenu.disabled)"
                (click)="onMenuItemClick()"
            >
                <i class="submenu__item__icon" [ngClass]="submenu.icon"></i>
                <h4 class="submenu__item__label">{{ submenu.label }}</h4>
            </div>
        </ng-container>
    </ng-container>
</div>
